import {solicitudTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: {},
    filtered:{},
    byId: {},
    isFetching: false,
    isPending: false,
    error: null,
    showModal: false
};
 
export default function index(state = initialState, action={}) {
    switch (action.type) {
        case solicitudTypes.MOSTRAR_SOLICITUDES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: null
            }
        }
        case solicitudTypes.MOSTRAR_SOLICITUDES + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                filtered: action.payload?.config?.params ?? {},
                isFetching: false,
                error: null
            }
        }
        case solicitudTypes.MOSTRAR_SOLICITUDES + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //mostrar por id
        case solicitudTypes.MOSTRAR_SOLICITUD_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: null
            }
        }
        case solicitudTypes.MOSTRAR_SOLICITUD_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
            }
        }
        case solicitudTypes.MOSTRAR_SOLICITUD_POR_ID + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }

        //aprobar
        case solicitudTypes.APROBAR_SOLICITUD + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: null
            }
        }
        case solicitudTypes.APROBAR_SOLICITUD + _FULFILLED: {
            return {
                ...state,
                isPending: false,
                error: null
            }
        }
        case solicitudTypes.APROBAR_SOLICITUD + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //rechazar
        case solicitudTypes.RECHAZAR_SOLICITUD + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: null
            }
        }
        case solicitudTypes.RECHAZAR_SOLICITUD + _FULFILLED: {
            return {
                ...state,
                isPending: false,
            }
        }
        case solicitudTypes.RECHAZAR_SOLICITUD + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false
            }
        }
    default:
      return state;
  }
}
