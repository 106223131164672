export const PERSMISOS = {

    dashboard:1,
    map:2,

    clientes:10,
    clientesCrear:11,
    clientesActualizar:12,
    clientesEliminar:13,

    prestamos:20,
    prestamosCrear:21,
    prestamosActualizar:22,
    prestamosEliminar:23,
    prestamosAjustes:24,
    prestamosCrearNotas:25,
    prestamosEliminarNotas:26,


    pagos:30,
    pagosCrear:31,
    pagosEliminar:32,
    pagosCambiarFecha:33,
    pagosCambiarCaja:34,
    pagosModificarMora:35,
    pagosDescuento:36,
    pagosReImprimir: 37,
    pagosPermitirPagoIncompleto:38,
    pagoInicial: 39,

    cajas:40,
    cajasCrear:41,
    cajasActualizar:42,
    cajasEliminar:43,
    cajasRealizarCierres:44,
    cajasTransferencias:45,

    gastos:50,
    gastosCrear:51,
    gastosEliminar:52,

    solicitudes:60,
    solicitudesAprobar:61,
    solicitudesRechazar:62,
    verTodas:63,
    solicitudesActualizar:64,

    rutas:70,
    rutasCrear:71,
    rutasActualizar:72,
    rutasEliminar:73,

    plantillas: 80,
    plantillasCrear: 81,
    plantillasActualizar: 82,
    plantillasEliminar: 83,
    plantillasImprimir: 84,

    carteras: 90,
    carterasCrear: 91,
    carterasActualizar: 92,
    carterasEliminar: 93,
};
export const PERSMISOS_USUARIOS = {
    webApp:500,
    cilosPago:501,
    documentos:502,
    geoMapa:503,
    cajas:504,
    rutas:505,
    gastos:506,
    carteras:507,
    solicitudes:508,
    plantillas:509,
    garantias:510,
    alquilerInmueble:5011
}
export const TIPOUSUARIO = {
    titular: 1,
    agente: 2,
    admin: 99,
}
export const TIPOPLAN = {
    gratis: 1,
    basico: 2,
    profesional: 3,
    avanzado: 4,
    basicoPro: 5,
    ilimitado: 7,

}
export const TIPOPAGO={
    CUOTA_COMPLETA: 1,
    PAGO_PARCIAL: 2,
    ABONO_CAPITAL: 3,
    AUSTE_CAPITAL: 4,
    SOLO_INTERES: 5,
    SAlDAR:6
};
export const mapApiOptions = {
    id: 'google-map-script',
    libraries:["places"],
    googleMapsApiKey: "AIzaSyAX7wSF1C-XJwlBRJUP2c4W2l89lq4mblY"//window.location.hostname === 'localhost'? "AIzaSyAX7wSF1C-XJwlBRJUP2c4W2l89lq4mblY":"AIzaSyDpi0iwVulr11eYWuk66OgcW4w-tq4sAh4",
}
export const firebaseConfig = {
    config: {
        apiKey: "AIzaSyDC9Vs5AXexiDePs7__NrF7bz3eS6uUceQ",
        authDomain: "prestamistapp2.firebaseapp.com",
        projectId: "prestamistapp2",
        storageBucket: "prestamistapp2.appspot.com",
        messagingSenderId: "1072506057274",
        appId: "1:1072506057274:web:6733c5cbf99407b4fefb03",
        measurementId: "G-928F3609CH"
    },
    publicVapidKey:"BKnNEcgB2h9mnzAWtXW1KKWU31qBqbq8n4GmoX9iZjEdf6fClNMsFequSH8xGSHb94-pVN25b-MPXGDk3Hq15nU"
};
