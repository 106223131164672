import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { mostrarCarteras} from '../../redux/actions/carterasActions';
import AppBar from "../../components/appBar";
import Crear  from './crear';
import {Button, TableBody, TableRow, TableCell} from "@mui/material";
import PlusFab from "../../components/PlusFab";
import LoadingProgress from "../../components/LoadingProgress";
import {useHistory} from "react-router-dom";
import {MostrarError} from "../../components/MostrarError";
import Strings from "../../assets/strings";
import {formatDate, formatPeso, hasPermissionTo, PERSMISOS} from "../../helpers";
import {ButtonResponsive, Empty, SeacrhAppBar, TableSort} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/wallet.svg";
import {Help} from "@mui/icons-material";
const initialState={
    name: '',
    page:1,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
};
function Carteras(props) {
    const history = useHistory();
    const [filter, setFilter] = useState(initialState);

    const { mostrarCarteras } = props;
     useEffect(() => {
        mostrarCarteras();
     }, [mostrarCarteras]);
    const handleFilter = e => {
        const {name, value} = e.target;
        if(name === "name" || name === "page" || name === "pageSize"){
            return mostrarCarteras({
                ...filter,
                page:null,
                ...(name === "name" && {estado: "-2"}),
                [name]:value
            });
        }
        setFilter({...filter,page:null,[name]:value})
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=>mostrarCarteras({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending})
        };
    };

     const { title: mainTitle, carteras: { list, isFetching, error } } = props;
        const isEmpty = (!list.results || list.results.length === 0);
      return (
          <>
              <AppBar title={Strings[mainTitle]}>
                  <ButtonResponsive icon={<Help/>} text={Strings.ayuda} onClick={() => window.open("https://youtu.be/Tn2ZFByRZls","_blank")}/>
                  <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
              </AppBar>
              <section className="contentInner">
                  <MostrarError errors={error}/>
                  {isFetching && isEmpty && <LoadingProgress vh/>}


                  <TableSort data={[
                      {pro:"carDescripcion",text:Strings.descripcion},
                      {pro:"carCapital",text:Strings.capital},
                      {pro:"cantidadPrestamos",text:Strings.prestamos},
                      {pro:"carFechaCreacion",text:Strings.fecha}
                  ]} onSort={tableSortProps} lessVh={205}>
                      <TableBody>
                          {!isEmpty && list.results.map(item =>
                              <TableRow key={item.carId} onClick={()=>history.push(`/cartera/${item.carId}`)}>

                                  <TableCell>{item.carDescripcion}</TableCell>
                                  <TableCell>{formatPeso(item.carCapital)}</TableCell>
                                  <TableCell>{item.cantidadPrestamos}</TableCell>
                                  <TableCell>{formatDate(item.carFechaCreacion)}</TableCell>
                              </TableRow>
                          )}
                      </TableBody>
                  </TableSort>

                  {isEmpty &&
                  <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.carteras}>
                      <p>{Strings.carterasHelpMsj}</p>
                  </Empty>
                   }


                  {hasPermissionTo(PERSMISOS.carterasCrear) &&
                      <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{carId:""})} />
                  }
                  <Crear/>

              </section>
          </>
        );

}

const mapStateToProps = (state) => ({
    carteras: state.carteras
});
export default connect(mapStateToProps, { mostrarCarteras })(Carteras);
