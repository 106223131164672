import React, {useEffect, useState} from "react";

import {DatePicker, LocalizationProvider, PickersDay} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

import {formatPeso, moment, noTilde, openNewWin} from "../../helpers";
import {Box, Button, FormControl, Grid, InputLabel, TextField, Typography, useMediaQuery} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {connect} from "react-redux";
import {mostrarCxC, setDashBoardFilter} from "../../redux/actions/dashBoardActions"
import {useHistory} from "react-router-dom";
import {Close, EventOutlined, FilterList, Search} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import ItemPrestamo from "../CustomListItem/itemPrestamo";
import {SelectSmart} from "../CustomTextField";
import {SelectEnum, SelectRuta} from "../selects";
import HtmlTooltip from "../HtmlTooltip";
import {ReactComponent as EmptySvg} from '../../assets/img/empty.svg';
import {AutoSizer, List} from 'react-virtualized';
import Strings from "../../assets/strings";

const useStyles = makeStyles(theme => ({
    root:{
        background: theme.palette.common.white,
        border: "1px solid " +theme.palette.grey[300],
        position: "relative",

        "& .inputSearch":{
            position: "absolute",
            padding: "4px 22px 2px 8px",
            top: "10px",
            left: "9px",
            width: "calc(100% - 116px)"
        }
    },
    content:{
        height: 355,
        paddingBottom: 38,
        overflow: "auto",
        //overflowX:"hidden",
        [theme.breakpoints.up('md')]: {
            overflow: "hidden",
            "&:hover":{
                overflow: "overlay",

            }
        },
    },
    filterButton: {
        //margin: theme.spacing.unit,
        padding: "2px 5px",
        minHeight: "auto",
        minWidth: 104,
        marginLeft: 4,
        "& svg":{
            width:18,
        }
    },
    head:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "10px "+theme.spacing(1),
        borderBottom: "1px solid "+theme.palette.grey[300],
        "& .title":{
            marginRight: "auto",
        },
        "& .MuiButtonBase-root":{
            marginLeft:theme.spacing(1),
        },
        // "& select":{
        //     marginLeft: "10px",
        //     paddingLeft: "7px",
        //     border: "none",
        //     borderLeft: "1px solid "+theme.palette.grey[400],
        //     outline: "none",
        //     width: 59,
        //     background: "white",
        // }

    },
    filterCont:{
        background: theme.palette.grey[100],
        padding: theme.spacing(1),
        "& label":{
            fontSize:".8rem"
        },
        "& .MuiInput-root":{
            "& input,& select, & .MuiSelect-root":{
                padding: "4px 5px",
                fontSize: ".9rem",
            }
        }

    },
    filterControls:{
        display: "flex",
        [theme.breakpoints.up('sm')]:{
            marginTop: 23,
        },
        [theme.breakpoints.only('xs')]:{
            marginTop: 8,
        },
        "& button":{
            fontSize: ".75rem",
            width:"100%",
            background:theme.palette.grey[200],
            marginLeft:2,
            height: 36,
            padding: "5px 8px"
        }
    },
    emptyCont:{
        paddingTop:"15%",
        textAlign:"center",
        opacity: .7,
        "&:hover":{
            opacity: 1,
        },
        "& p":{
            marginTop:0,
            color: theme.palette.text.secondary,
        }
    },
    btnShowAll:{
        position: "absolute",
        bottom: 37,
        background: theme.palette.grey[200],
        opacity: .6,
        "&:hover":{
            opacity: 1,
        },
    },
    total:{

        alignItems: "center",
        fontSize: "0.9em",
        bottom: "0",
        height: 37,
        position: "absolute",
        background: "#ffffff",
        width: "100%",
        padding: "10px",
        boxSizing: "border-box",
        borderTop: "1px solid #e0e0e0",

    }
    // total:{
    //     display: "flex",
    //     justifyContent: "flex-end",
    //     alignItems: "center",
    //     fontSize: "0.9em",
    //     bottom: "0",
    //     height: 37,
    //     position: "absolute",
    //     background: "#ffffff",
    //     width: "100%",
    //     padding: "10px",
    //     boxSizing: "border-box",
    //     borderTop: "1px solid #e0e0e0",
    //     "&>div":{
    //         marginRight: 10,
    //         "&:nth-child(2)": {
    //             fontSize: "0.8em",
    //             textAlign: "right",
    //         }
    //     },
    // }
}));
const initialState={
    cliente:"",
    rutId: "",
    estado: "",
    fechaDesde: "",
    fechaHasta: "",
    preFechaProximoPago:"",
    preTipoAmortizacion:""
};

//ButtonDatePicker
function ButtonField(props) {
    const {
        setOpen,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    return (

        <HtmlTooltip title={Strings.proximo+" "+Strings.pago} placement={"top"}>
            <IconButton size={"small"}
                        variant="outlined"
                        id={id}
                        disabled={disabled}
                        ref={ref}
                        aria-label={ariaLabel}
                        onClick={() => setOpen?.((prev) => !prev)}
            >
                <EventOutlined fontSize={"small"}/>
            </IconButton>
        </HtmlTooltip>
    );
}
function ButtonDatePicker({dayComponet, ...props}) {
    const [open, setOpen] = React.useState(false);
    return (
        <DatePicker
            slots={{ day:dayComponet,field: ButtonField}}
            slotProps={{ field: { setOpen } }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
        />
    );
}
// end ButtonDatePicker

function CxC({list,mostrarCxC,filter,setDashBoardFilter,loading}) {
    let history = useHistory();
    const xxsVewport = useMediaQuery((theme)=>theme.breakpoints.down("xs"));
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));


    const classes = useStyles();
   // const [selectedDay, setSelectedDay] = useState(new Date());
    const [showSearch, setShowSearch] = useState(false);
    const [showFilter, setShowFilter] = useState(false)
   // const [filter, setFilter] = useState(initialState);
    const [filteredList, setFilteredList] = useState([]);

    useEffect(() => {
        if(list){
            setFilteredList(filterCxCList(list,filter))
        }
    }, [filter,list,setFilteredList])
    const handleFilter = e => {
        const {name, value} = e.target;
        //setFilter({...filter,[name]:value})
        setDashBoardFilter({...filter,[name]:value})

        if(name === "estado" && value === "incluirCxcAldia"){
            mostrarCxC({incluirCxcAldia:true})
        }
    };
    const handleCalChange = (day) => {
        const listInclude =  list.find(p=>moment(p.preFechaProximoPago).isSame(day,"day"));

        if(!listInclude) return false;
        //setSelectedDay(day)
       // setFilter({...filter,preFechaProximoPago: day.format("YYYY-MM-DD")})
        setDashBoardFilter({...filter,preFechaProximoPago:day.format("YYYY-MM-DD")})
    };
    // let totalMontoCuota = filteredList?.reduce((a, b) => +a + +b.preMontoCuota, 0);
    // let totalMora = filteredList?.reduce((a, b) => +a + +b.mora, 0);
    let totalBalancePendiente = filteredList?.reduce((a, b) => +a + +b.preBalancePendiente, 0);

    // let today     = moment();
    // let tomorrow  = moment().add(1,'days');
    // let yesterday = moment().add(-1, 'days');


    const ClrDay = (props) => {
        const { day,selectedDate, outsideCurrentMonth, ...other } = props; ///highlightedDays = [],
        let listInclude = list.find(p=>moment(p.preFechaProximoPago).isSame(day,"day"));
        return (
            <Box key={day.toString()} sx={listInclude?{"& button":{
                    background: "#d4e8ff !important",
                    color:"black !important"
            }}:{}}
            >
                <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
            </Box>
        );
    }



    return (
        <>
            <div className={"cxcContent "+classes.root}>
                {showSearch &&
                    <input type="text" name="cliente" className="inputSearch" autoFocus value={filter?.cliente ?? ""} onChange={handleFilter}/>
                }
                <div className={classes.head}>
                    <Typography className="title">{Strings.cuentasXcobrar}
                        {/*<select*/}
                        {/*    value={filter?.fechaHasta ?? ""}*/}
                        {/*    onChange={handleFilter}*/}
                        {/*    name="fechaHasta"*/}
                        {/*>*/}
                        {/*    <option value={""}>- - -</option>*/}
                        {/*    <option value={today.format("YYYY-MM-DD")}>{Strings.hoy}</option>*/}
                        {/*    <option value={tomorrow.format("YYYY-MM-DD")}>{Strings.manana}</option>*/}
                        {/*    <option value={yesterday.format("YYYY-MM-DD")}>{Strings.ayer}</option>*/}
                        {/*</select>*/}
                    </Typography>

                    <HtmlTooltip title={Strings.buscar+" "+Strings.por+" "+Strings.nombres} placement={"top"}>
                        <IconButton size={"small"} onClick={()=>setShowSearch(!showSearch)}>
                            {showSearch ? (<Close fontSize={"small"}/>):(<Search fontSize={"small"}/>)}</IconButton>
                    </HtmlTooltip>

                    <HtmlTooltip title={Strings.filtrar} placement={"top"}>
                        <IconButton size={"small"} color={showFilter?"primary":"default"}
                                onClick={()=>setShowFilter(!showFilter)}> <FilterList fontSize={"small"}/></IconButton>
                    </HtmlTooltip>

                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <ButtonDatePicker
                                // value={value}
                                dayComponet={ClrDay}
                                onChange={handleCalChange}
                            />
                        </LocalizationProvider>

                </div>
                <div className={"content "+classes.content}>
                    {showFilter &&
                        <div className={classes.filterCont}>
                            <Grid container spacing={1}>
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        label={Strings.fecha+" "+Strings.desde}
                                        type="date"
                                        value={filter?.fechaDesde ?? ""}
                                        onChange={handleFilter}
                                        name="fechaDesde"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <TextField
                                        label={Strings.fecha+" "+Strings.hasta}
                                        type="date"
                                        value={filter?.fechaHasta ?? ""}
                                        onChange={handleFilter}
                                        name="fechaHasta"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>{Strings.estado}</InputLabel>
                                        <SelectSmart
                                            value={filter?.estado ?? ""}
                                            onChange={handleFilter}
                                            name="estado"
                                            opciones={[
                                                {key: '', value: Strings.todo},
                                                {key: 'pendiente', value: Strings.pendiente},
                                                {key: 'c_pendientes', value: Strings.cuotaVencida},
                                                {key: 'mora', value: Strings.mora},
                                                {key: 'incluirCxcAldia', value: Strings.incluir+" "+Strings.aldia},
                                            ]}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={4}>
                                    <SelectEnum
                                        fullWidth
                                        label={Strings.modalidad}
                                        group="ciclosPago"
                                        name='preTipoAmortizacion'
                                        all
                                        value={filter?.preTipoAmortizacion ?? ""}
                                        onChange={handleFilter}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <SelectRuta
                                        fullWidth
                                        label={Strings.ruta}
                                        name='rutId'
                                        value={filter?.rutId ?? ""}
                                        all
                                        onChange={handleFilter}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <div className={classes.filterControls}>
                                        <Button onClick={()=>{setShowFilter(false);
                                                              setDashBoardFilter(initialState)}}>{Strings.todo}</Button>
                                        <Button onClick={()=>openNewWin("/reports/cxc",filter)}>Exportar</Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>}
                    <div className="items">
                        {filteredList.length >0 ?(
                            <AutoSizer>
                                {({width }) => (
                                    <List
                                        width={width-1}
                                        height={smVewport?window.innerHeight - 193:355}
                                        rowCount={filteredList.length}
                                        rowHeight={xxsVewport? 156:118}
                                        rowRenderer={({key, index,style}) =>(
                                            <div key={key} style={style}>
                                                <ItemPrestamo onClick={()=>history.push(`/prestamo/${filteredList[index].preId}`)} item={filteredList[index]}/>
                                            </div>
                                        )}
                                    />
                                )}
                            </AutoSizer>
                            // filteredList.map(pre =>
                            //     <ItemPrestamo key={pre.preId} onClick={()=>history.push(`/prestamo/${pre.preId}`)} item={pre}/>
                            // )
                        ):(
                            !loading &&
                            <div className={classes.emptyCont}>
                                <EmptySvg width={140} height={140}/>
                                {list.length > 0 ?(
                                    <p>{Strings.emptyMsj}</p>
                                ):(
                                    <p>{Strings.noCuentasXcobrarMjs}</p>
                                )}
                            </div>
                        )}
                    </div>
                    {filteredList.length !== list.length &&
                    <Button fullWidth size={"small"} className={classes.btnShowAll} onClick={()=>setDashBoardFilter(initialState)}>
                        {Strings.mostrar+" "+Strings.todo}
                    </Button>
                    }
                    <div className={"total "+classes.total}>
                        <HtmlTooltip title={Strings.balancePendienteMsj} placement={"top"}>
                            <span>{Strings.total} {Strings.balance} {Strings.pendiente} = {formatPeso(totalBalancePendiente)}</span>
                        </HtmlTooltip>
                        {/*<div>*/}
                        {/*    <div>Cuotas: {formatPeso(totalMontoCuota)}</div>*/}
                        {/*    <div>Mora: {formatPeso(totalMora)}</div>*/}
                        {/*</div>*/}
                        {/*<div>= {formatPeso(totalMontoCuota + totalMora)}</div>*/}

                        {/*{Strings.total+" "+Strings.cuotas} {formatPeso(filteredList?.reduce((a, b) => +a + +b.preMontoCuota, 0))}*/}
                    </div>
                </div>
            </div>
        </>
    )
}
export function filterCxCList(list = [], filter  = {}){
    let f = {...initialState,...filter};
    const {cliente, rutId, estado: est, fechaDesde, fechaHasta, preFechaProximoPago, preTipoAmortizacion} = f;
    let estado = est === "incluirCxcAldia"? "" : est;

    let filtered = [];
    let fDesde = moment(fechaDesde).isValid() ? fechaDesde : moment().subtract(5, 'years');
    let fHasta = moment(fechaHasta).isValid() ? fechaHasta : moment().add(5, 'years');
    let fProximoPago = moment(preFechaProximoPago).isValid() ? preFechaProximoPago : "";

    list.forEach(item => {
        if (
            (item.rutId ===  parseInt(rutId) || rutId === "") &&
            noTilde(item.cliente).indexOf(noTilde(cliente)) !== -1 &&
            (item.estado === estado || estado === "") &&
            (item.preTipoAmortizacion === parseInt(preTipoAmortizacion) || preTipoAmortizacion === "") &&
            (fProximoPago === "" || moment(item.preFechaProximoPago).isSame(fProximoPago,"day")) &&
            moment(item.preFechaProximoPago).isBetween(fDesde, fHasta)
        ) {
            filtered.push(item);
        }
    });
    return filtered;
}

const mapStateToProps = (state) => ({
    list: state.prestamos.cxc || [],
    loading: state.dashBoard.isFetching,
    viewTableList: state.theme.viewTableList,
    filter:state.dashBoard.filtered?.cxc ?? {},
})
export default connect(mapStateToProps,{mostrarCxC,setDashBoardFilter})(CxC);
