import React, {Suspense, lazy} from "react";
import { useMediaQuery, useTheme } from '@mui/material';
import './App.scss';
import  history  from "./helpers/history";
import {Router, Route, Switch } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
import 'typeface-roboto'
import Login from "./views/cuenta/login";
import Register from "./views/cuenta/register";
import ForgotPassword from "./views/cuenta/ForgotPassword";
import Solicitar from "./views/solicitud/Solicitar";
import EstadoCliente from "./views/EstadoCliente";
import Contacto from "./views/ayuda";


import Reports from "./views/reports";
import {connect} from "react-redux";
import {getColor} from "./assets/colors";
import LoadingProgress from "./components/LoadingProgress";

const Dashboard = lazy(() => import('./layouts/layout'));


function App(props){
  const {usuUIColor} = props.auth.user || {usuUIColor:"#17a2b8"};
  const smVewport = useMediaQuery(useTheme().breakpoints.down('sm'));

  //console.log(themeObj)
  // const hist = createBrowserHistory();
  let theme = createTheme({
      components: {
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiFormControl: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      ...(!smVewport? {
        MuiInputLabel:{
          styleOverrides:{
            root: {
              "&:not([class*='-outlined'])":{
                lineHeight: 1,
                position: "initial !important",
                transform: "none !important",
                color: "inherit !important",
                marginBottom: "10px !important",

              }
            },
          }
        },
        MuiInputBase:{
          styleOverrides:{
            root:{
              background: "white",
              "&:not([class*='MuiOutlinedInput'])":{
                border: "1px solid #e6e6e6",
                marginTop: "0px !important",
                "& [class*='-input']:not([class*='Pagination'])":{ //:not(select)
                  padding: "6px 15px",
                },
                "&:before, &:after":{
                  display:"none !important",
                }
              }
            }
          }

        }
      }:{}),
      MuiButton: {
        styleOverrides:{
          root: {
            borderRadius: "0"
          },
        }
      },
      MuiTableRow: {
        styleOverrides:{
          head: {
            height: 34,
          },
          root: {
            //height: 44,
          },
          footer:{
            cursor: "auto !important",
          }
        }
      },
      MuiTableCell: {
        styleOverrides:{
          root:{
            "&:not([class*='sizeSmall'])":{
              height: 44,
              padding: ".5rem",
              boxSizing: "border-box",
            },
          },
          head: {
            color: usuUIColor,
            borderBottom: usuUIColor + " 2px solid"
          },
          body: {
          },
          footer:{
            fontSize: "0.85rem"
          }
        }
      },
    },
      palette: {
       // mode: 'dark',
        ...getColor(usuUIColor),
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
      },
      mixins: {
        toolbar: {
          minHeight: 50,
        }
      },
    }, esES);

    return (
      <div className="App">
        <StyledEngineProvider injectFirst>
          <ThemeProvider  theme={theme}>
          <Router history={history}>
            <Switch>
              <Route path="/login" component={Login}/>
              <Route path="/register/:noPayment?" component={Register}/>

              <Route path="/reports/:type/:id?" component={Reports}/>
              <Route path="/solicitar/:user" component={Solicitar}/>
              <Route path={["/forgotPassword","/forgotPasswordConfirm"]} component={ForgotPassword}/>
              <Route path={"/estadoCliente/:user/:preId?"} component={EstadoCliente}/>
              <Route path={"/contacto"} component={Contacto}/>



              {props.auth.isAuthenticated? (
                  <Suspense fallback={<LoadingProgress/>}>
                      <Route path="/" component={Dashboard}/>
                  </Suspense>
                  ):(
                  props.auth.isFetching?(<LoadingProgress/>):(<Route path="/" component={Login}/>)
              )}
            </Switch>
          </Router>
          </ThemeProvider >
        </StyledEngineProvider>
      </div>
    );

}

const mapStateToProps = (state) => ({
  auth: state.auth
});
export default connect(mapStateToProps)(App);
