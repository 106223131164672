import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {useHistory} from "react-router-dom";
import {PERSMISOS, hasPermissionTo, noTilde} from '../../helpers';
import { mostrarCajas, eliminarCaja} from '../../redux/actions/cajasActions';
import {AppBar, Empty, SeacrhAppBar} from "../../components";
import Strings from "../../assets/strings";

import {Button, List, ListItem, ListItemText, Box} from "@mui/material";
import PlusFab from "../../components/PlusFab";
import {formatPeso} from "../../helpers";
import LoadingProgress from "../../components/LoadingProgress";
import TransferenciaCajas from "./TransferenciaCajas";
import Crear from "./crear";
import {SyncAlt} from "@mui/icons-material"
import {ReactComponent as EmptySvg} from "../../assets/img/caja.svg";

function Cajas(props) {
    let history = useHistory();
    const [searchBox, setSearchBox] = useState("");

    const { mostrarCajas } = props
    useEffect(() => {
        mostrarCajas();
    }, [mostrarCajas]);


    const { title: mainTitle, cajas: { list, isFetching } } = props;
    const isEmpty = (!list || list.length === 0);
    const filterItems = () => {
        return list.filter(item=> noTilde(item.cajDescripcion).indexOf(noTilde(searchBox)) !== -1);
    }
    return (
        <>
            <AppBar title={Strings[mainTitle]}>
                <SeacrhAppBar name="search" value={searchBox} onChange={({target})=>setSearchBox(target.value)}/>

                {!isEmpty && hasPermissionTo(PERSMISOS.cajasTransferencias) &&
                    <Button  color="inherit" onClick={()=> history.push(history.location.pathname,{modalTransferenciaCaja:true})} >
                        <SyncAlt/>
                    </Button >
                }
            </AppBar>
            <section className="contentInner" style={!isEmpty ? {maxWidth:600}:{}}>
                {isFetching  && !list.length && <LoadingProgress vh/>}
                <List >
                    {!isEmpty && filterItems().map(item =>
                        <ListItem  button key={item.cajId} onClick={()=> history.push("/caja/"+item.cajId,null)}>
                            <ListItemText>
                                <Box display="flex" justifyContent={"space-between"}>
                                    <span>{item.cajDescripcion}</span>
                                    <span>{formatPeso(item.cajBalance)}</span>
                                </Box>
                            </ListItemText>
                        </ListItem>
                    )}
                    {list.length > 1 &&
                        <ListItem>
                            <ListItemText>
                                <Box display="flex" justifyContent={"space-between"}>
                                    <span>{Strings.total}:</span>
                                    <span>{formatPeso(
                                        filterItems().reduce((a, b) => a + b.cajBalance, 0)
                                    )}</span>
                                </Box>
                            </ListItemText>
                        </ListItem>
                    }
                </List>
                {isEmpty && !isFetching &&
                <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.cajas}>
                    <p>{Strings.cajasHelpMsj}</p>
                </Empty>
                }
                <TransferenciaCajas/>

                {hasPermissionTo(PERSMISOS.cajasCrear) &&
                    <>
                        <PlusFab component={Button} onClick={()=> history.push(history.location.pathname,{cajId:""})} />
                        <Crear/>
                    </>
                }
            </section>
        </>
    );
}

const mapStateToProps = (state) => ({
    cajas: state.cajas
})
export default connect(mapStateToProps, { mostrarCajas, eliminarCaja })(Cajas);


