import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarRutasSinoEsta } from '../../redux/actions/rutasActions';
import {FormControl, InputLabel} from "@mui/material";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS, PERSMISOS_USUARIOS} from "../../helpers";

function SelectRuta({ value = "", name = "rutId",label=Strings.rutas, onChange, all, rutas,mostrarRutasSinoEsta}) {

    useEffect((e) => {
        if(hasPermissionTo(PERSMISOS.rutas) && hasPermissionTo(PERSMISOS_USUARIOS.rutas)) {
            mostrarRutasSinoEsta();
        }
    }, [mostrarRutasSinoEsta]);


    var options = (rutas.list && rutas.list.map(opc=>({key:opc.rutId,value:opc.rutDescripcion}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})

    return (

        <FormControl fullWidth>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value}
                onChange={onChange}
                name={name}
                loading={rutas.isFetching }
                opciones={options}
            />
        </FormControl>

    );
}

const mapStateToProps = (state) => ({
    rutas: state.rutas,
})
export default connect(mapStateToProps, { mostrarRutasSinoEsta })(SelectRuta);
