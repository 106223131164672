import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom'
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    Grid, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
    TextField,
    Typography
} from '@mui/material';
import {AccountOutline} from "mdi-material-ui";

import makeStyles from '@mui/styles/makeStyles';
import {HtmlTooltip, LoadingProgress, MostrarError, TopBarDialog} from "../../components";
import {clienteAgregarPago, clienteLogin, clientePrestamos, clientePrestamosById, getPublicUser} from "../../services";
import {connect} from "react-redux";
import {setEnums} from "../../redux/actions/enumsActions";
import {setPaises} from "../../redux/actions/paisesActions";
import {formatDate, formatPeso, geoString, getLSLang, setLSLang} from "../../helpers";
import Strings from "../../assets/strings";
import {setCurrentUser} from "../../redux/actions/authentication";
import {setGenerales as setGlobalGenerales} from "../../redux/actions/generalesActions";
import ListAmort from "../../components/listAmort";
import ItemPrestamo from "../../components/CustomListItem/itemPrestamo";
import {SelectFiles} from "../../components/selects";

import defaultImg from '../../assets/img/reciboImg.jpg';
import {TextFieldCurrency} from "../../components/CustomTextField";
import {isMobile} from "../../helpers/browser_detect";
import ReciboPago from "../../components/recibo";

const useStyles = makeStyles(theme => ({
    root:{
        boxShadow: "#969696 0px 0px 12px",
        maxWidth: 990,
        margin: "-110px auto 30px auto",
        boxSizing: "border-box",
        background:"#fff",
        [theme.breakpoints.down('md')]: {
            marginTop:-71,
            "& h1":{
                marginTop: "-64px !important",
                color: "white  !important",
                marginBottom: "39px !important",
            }
        },
        "& .loginContent":{
            maxWidth: 400,
            margin: "0 auto",
            padding: 40,
        },
        "& .detalleContent":{
            padding: theme.spacing(1),
            paddingBottom: theme.spacing(4),
        }
    },
    topHead: {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "right",
        height: "103px",
        alignItems: "center",
        padding: theme.spacing(2),
        borderBottom: "1px solid #ddd",
        "& .info":{
            display: 'flex',
            alignItems: "center",
        },
        "& h1":{
            color:theme.palette.secondary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.5rem",
            minWidth: 254,
            textAlign: "left",
            textTransform: "uppercase",

        },
        "& h2":{
            color:theme.palette.primary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.4rem",
        },
        "& .logo":{
            marginLeft: 10,
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: "100%",
                maxHeight: 99,
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".9rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            height: "auto",
            "& .info":{
                flexDirection: "column-reverse",
                textAlign: "center",
            },
            "& h1":{
                textAlign: "center",
            }
        }
    },
    lang:{
        textAlign: "center",
        marginBottom: theme.spacing(3),
        "& select":{
            padding: 5,
            fontSize: "1em",
        }
    },
    info:{
        overflowX: "auto",
        overflowY: "hidden",

        padding: 10,
        [theme.breakpoints.up('md')]: {
            background:"#eceeef",
            padding: 6,
            boxShadow: "none",
            borderRadius: 0,
            "&:not(.all)":{
                height: 167,
            },
            "&>div":{
                minWidth: 785,
            }
        },
        position: "relative",
        [theme.breakpoints.down('md')]: {
            padding: 0,
            margin: "53px 0 0 0px",
            borderRadius: "0",
            boxShadow: "none"
        },
    },
    itemInfo:{
        ...theme.typography.subheading,
        padding: "10px !important",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        [theme.breakpoints.down('md')]: {
            display: "flex",
            flexDirection: "column-reverse",
            padding: "5px 16px !important",
        },
        "& span:last-child":{
            background: "#f6f6f6",
            padding: "5px 13px",
            marginLeft: 10,
            [theme.breakpoints.down('md')]: {
                background: "none",
                marginLeft: 0,
                padding: 0,
                fontSize: "0.9rem",
            },
        },
        "& span:first-child":{
            [theme.breakpoints.down('md')]: {
                display: "block",
                fontSize: ".7rem",
            },
        }
    },
    webview:{
        boxShadow:"none",
        margin:0,
        padding:0,
        "& .topLogin":{
            textAlign:"center",
            background: theme.palette.primary.main,
            padding: "25px 36px 24px 36px",
            "& .avatar":{
                fontSize: 62,
                color: theme.palette.common.white,
                border: "4px solid "+theme.palette.common.white,
                margin: "0 auto",
                borderRadius: "50%",
                padding: 6
            },
            "& p":{
                fontSize: ".8rem",
                color:theme.palette.common.white,
            }

        },
        "& .loginContent":{
            margin: "8px 28px",

            "& form button":{
                marginTop:theme.spacing(3)
            }
        },
        "& .detalleContent":{
            padding: 0,
        }

    }
}));
let inicialPagoState = {
    pagMonto:0,
    pagComentario:"",
    pagAdjunto:""
}
let inicialLoginState = {
    userNameMaster:"",
    user:"",
    password:"",
}
function Solicitar(props){
    const webview = isMobile.anyWebView();
    let history = useHistory();
    const {hash} = useLocation();

    let { user,preId } = useParams();
    const [logged, setLogged] = useState(false);
    const [addPagoModal, setAddPagoModal] = useState(false);
    const [pagoState, setPagoState] = useState(inicialPagoState);

    const [cliente,setCliente]=useState("");
    const [prestamos, setPrestamos] = useState([]);
    const [prestamo, setPrestamo] = useState({});

    const [isLoading, setLoading] = useState(true);
    const [showAmort, setShowAmort] = useState(false);

    const [userState, setUserState] = useState(inicialLoginState);

    useEffect(()=>{
        //let tokenId = localStorage.getItem("cliToken");

        setUserState(prevState => ({...prevState,userNameMaster:user}))
    },[user,setUserState])

    useEffect(()=>{
        if(!!preId){
            setLoading(true)
            clientePrestamosById(preId).then(({data})=>{
                setPrestamo(data)
            }).finally(()=>setLoading(false))
        }else {
            setPrestamo({})
        }
    },[setLoading,preId,setPrestamo])
    useEffect(()=>{
        let tokenId = localStorage.getItem("cliToken");
        if(tokenId){
            setLoading(false);
            clientePrestamos().then(({data})=>{
                setLogged(true);
                setPrestamos(data.results)
                setCliente(data.results[0]?.cliente);

                if(window.ReactNativeWebView?.postMessage){
                    return window.ReactNativeWebView.postMessage(JSON.stringify({cliToken:tokenId}))
                }

            }).catch(()=>setLogged(false)).finally(()=>setLoading(false))
        }
    },[setLogged,setCliente,setPrestamos,setLoading])
    useEffect(()=>{
        setAddPagoModal(!!hash.includes("addPago"))
    },[hash,setAddPagoModal])

    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const {setEnums,setPaises,setCurrentUser,setGlobalGenerales} = props;
    const [generales, setGenerales] = useState({});
    const [error, setError] = useState();
    const [lang, setLang] = useState(getLSLang("l"));
    useEffect(()=>{
        getPublicUser(user).then((res)=>{
            let enums = res?.data ?? {};

            let generales = enums?.generales ?? {};
            let paises = enums?.paises ?? [];
            paises = paises.map(p=>({paiCodigo:p.key,paiNacionalidad:p.value}));


            setGenerales(generales); //local
            setGlobalGenerales(generales); //global

            setPaises(paises); //global
            delete enums.generales;
            delete enums.paises;
            setEnums(enums); //global
            setCurrentUser({usuUIColor: generales?.uiColor,genLocalidad:generales?.genLocalidad }); //Global

            setLoaded(true);


        }).catch((e)=>setError(e?.response)).finally(()=>setLoading(false))
    },[user,setGenerales,setEnums,setPaises,setCurrentUser,setLoaded,setGlobalGenerales,setError,setLoading])

    const login = (e) => {
        e.preventDefault();
      clienteLogin({
          userNameMaster:userState.userNameMaster,
          userName: userState.user,
          userPassword: userState.password
      }).then(({data})=>{
          localStorage.setItem("cliToken",data.accessToken)
          setLogged(true);
          setUserState(inicialLoginState)
          clientePrestamos().then(({data})=>{
              setPrestamos(data.results)
          })

      })
    }
    const logout = () => {
        setLogged(false);
        history.replace("/estadoCliente/"+user)
        localStorage.removeItem("cliToken");
        if(window.ReactNativeWebView?.postMessage){
            return window.ReactNativeWebView.postMessage(JSON.stringify({cliToken:""}))
        }
    }
    const handleChange = e => {
        const {value,name} = e.target
        setPagoState({...pagoState,[name]:value});
    };
    const onImgChange = (baseb4)=> {
        setPagoState({...pagoState, pagAdjunto: baseb4});
    }
    const addPago = (e) =>{
        e.preventDefault();
        setLoading(true)
        clienteAgregarPago({...pagoState,preId}).then(({data})=>{
            setPagoState(inicialPagoState);
            setPrestamo(prev =>({...prev,pagos:[data,...prev.pagos]}));
            history.goBack();

        }).finally(setLoading(false))
    }
    //----
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = event => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    //---
    return(
        <>
            {!loaded?(
                error ? <MostrarError errors={error} action={false}/> : <LoadingProgress vh />
            ):(
                <>
                    {isLoading && <LoadingProgress />}

                    {!webview && <Box bgcolor="primary.main" width={"100%"} height={138} />}
                    <div className={classes.root+(webview?" "+classes.webview:"")}>
                        {!webview &&
                            <div className={classes.topHead}>
                                <h1>{Strings.estado} {Strings.prestamo}</h1>
                                <div className="info">
                                    <div>
                                        <h2>{generales?.genEmpresaNombre}</h2>
                                        <span className="tel">
                                                <i>
                                                    {!!generales?.genEmpresaRNC && <><span>{geoString("rnc",true)}: {generales?.genEmpresaRNC}</span> <br/></>}
                                                    {!!generales?.genEmpresaTelefono && <span> Tel: {generales?.genEmpresaTelefono}</span>}
                                                    {!!generales?.genEmpresaTelefono2 && <span> &nbsp;/ &nbsp; {generales?.genEmpresaTelefono2}</span>}
                                                </i>
                                            </span>
                                        <br/>
                                        <span className="dir">{generales?.genEmpresaDireccion}</span>
                                    </div>
                                    {!!generales?.genEmpresaLogo &&
                                        <div className="logo">
                                            <img alt="logo" src={generales?.genEmpresaLogo} />
                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {!logged?(
                            <>
                                {webview &&
                                    <div className={"topLogin"}>
                                        <AccountOutline className={"avatar"} />
                                        <Typography>
                                            Ingrese los datos suministrados por su prestador para ver el estado de sus préstamos.
                                        </Typography>
                                    </div>
                                }
                                <Box className={"loginContent"}>
                                    <form onSubmit={login}>
                                        <TextField
                                            label={Strings.cedula}
                                            required
                                            margin="dense"
                                            sx={{mb:0}}
                                            value={userState?.user??""}
                                            onChange={(e)=>setUserState({...userState,user:e.target.value})}
                                            fullWidth
                                        />
                                        <TextField
                                            label={Strings.contrasena}
                                            required
                                            margin="normal"
                                            value={userState?.password??""}
                                            onChange={(e)=>setUserState({...userState,password:e.target.value})}
                                            fullWidth
                                        />

                                        <Button
                                            disabled={isLoading}
                                            fullWidth={webview}
                                            variant="contained"
                                            color="primary"
                                            className={classes.submitBtn}
                                            type="submit"
                                        >
                                            {isLoading? (
                                                <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                            ):(
                                                <span>{Strings.entrar}</span>
                                            )}
                                        </Button>
                                    </form>
                                </Box>
                            </>

                        ):(
                            <Box  className={"detalleContent"}>
                                {!webview &&
                                    <Box sx={{
                                        background: "#f2f2f2",
                                        justifyContent: "space-between",
                                        display: "flex",
                                        alignItems: "center",
                                        p: "2px 10px",
                                        mb:1
                                    }}>
                                        <div><Typography color={"primary"} variant={"h6"}>{cliente}</Typography></div>
                                        <Button color={"secondary"} onClick={logout}>{Strings.cerrarSesion}</Button>
                                    </Box>
                                }
                                <div>
                                    {!Object.keys(prestamo).length && prestamos.map((pre,i) =>
                                        <ItemPrestamo key={i} item={pre} onClick={()=>history.push(history.location.pathname+"/"+pre.preId)}/>
                                    )}
                                    {!!Object.keys(prestamo).length  &&
                                        <div>
                                            {!webview &&
                                            <Box>
                                                <Button color={"secondary"} onClick={()=>history.replace("/estadoCliente/"+user)}>Ir atras</Button>
                                            </Box>}
                                            <Grid container >
                                                <Grid item xs={4} className={classes.itemInfo}>
                                                    <span>{Strings.referencia}:</span>
                                                    <span>{prestamo.preReferencia}</span>
                                                </Grid>
                                                <Grid item xs={4}  className={classes.itemInfo}>
                                                    <span>{Strings.cuotas}:</span>
                                                    <span>{prestamo.prePlazoString}</span>
                                                </Grid>
                                                <Grid item xs={4}  className={classes.itemInfo}>
                                                    <span>{Strings.proximo} {Strings.pago}:</span>
                                                    <Typography noWrap component={"span"}>{formatDate(prestamo.preFechaProximoPago,"dd, DD/MM/YYYY")}</Typography>
                                                </Grid>
                                                <Grid item xs={4}  className={classes.itemInfo}>
                                                    <span>{Strings.monto} {Strings.prestado}:</span>
                                                    <span>{formatPeso(prestamo.preCapitalInicial)}</span>
                                                </Grid>
                                                <Grid item xs={4}  className={classes.itemInfo}>
                                                    <HtmlTooltip title={Strings.cuotaVencida +"+"+ Strings.mora}>
                                                        <Typography noWrap component={"span"}>
                                                            {Strings.balancePendiente}
                                                        </Typography>
                                                    </HtmlTooltip>
                                                    <span>{formatPeso(prestamo.preBalancePendiente)}</span>
                                                </Grid>
                                                <Grid item xs={4} className={classes.itemInfo}>
                                                    <span>{Strings.modalidad}:</span>
                                                    <span>{Strings[prestamo.preCicloPagoString]}</span>
                                                </Grid>

                                                <Grid item xs={4}  className={classes.itemInfo}>
                                                    <span>{Strings.parcialPendiente}:</span>
                                                    <span>{formatPeso(prestamo.prePagoPendiente)}</span>
                                                </Grid>
                                                <Grid item xs={4}  className={classes.itemInfo} sx={{color:prestamo.cuotasAtrasadas>0?"#ff7d2c":"inherit"}}>
                                                    <span>{Strings.cuotas} {Strings.pendiente}:</span>
                                                    <span>{prestamo.cuotasAtrasadas}</span>
                                                </Grid>
                                            </Grid>
                                            <Box sx={{display:"flex",justifyContent: "space-between",background: "#f2f2f2",mt:2,mb:2}}>
                                                <div>
                                                    <Button onClick={()=>setShowAmort(false)} sx={{ color: showAmort? 'text.secondary':'primary.main' }}>{Strings.pagos}</Button>
                                                    <Button disabled onClick={()=>setShowAmort(true)} sx={{ color: showAmort? 'primary.main':'text.secondary' }}>{Strings.amortizacion}</Button>
                                                </div>
                                                <div>
                                                    {!showAmort &&
                                                        <Button color={"secondary"} onClick={()=>history.push(history.location.pathname+"#addPago")}>{Strings.agregar} {Strings.pagos}</Button>
                                                    }
                                                </div>
                                            </Box>
                                            <div className={classes.table}>
                                                {showAmort ? (
                                                    <ListAmort data={prestamo.amortizacion} noHead/>
                                                ): (
                                                    <>
                                                    <TableContainer>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>{Strings.adjuntos}</TableCell>
                                                                <TableCell>{Strings.concepto}</TableCell>
                                                                <TableCell>{Strings.monto}</TableCell>
                                                                <TableCell>{Strings.balance}</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {prestamo.pagos.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(pag =>
                                                                <TableRow key={pag.pagId}
                                                                          onClick={()=> {
                                                                              if(pag.pagTipo === 8) return false;
                                                                              history.push(history.location.pathname, {recibo: pag})
                                                                          }}
                                                                >
                                                                    <TableCell><img src={pag.pagAdjunto?pag.pagAdjunto:defaultImg} alt={""}
                                                                                    style={{height: 27, verticalAlign: "middle"}}/></TableCell>
                                                                    <TableCell>{pag.pagConcepto}</TableCell>
                                                                    <TableCell>{formatPeso(pag.pagMonto)}</TableCell>
                                                                    <TableCell>{formatPeso(pag.pagBalance)}</TableCell>
                                                                </TableRow>
                                                            )}
                                                        </TableBody>
                                                    </Table>
                                                    </TableContainer>
                                                        {prestamo?.pagos.length > 10 &&
                                                            <TablePagination
                                                                rowsPerPageOptions={[10, 25, 100]}
                                                                component="div"
                                                                count={prestamo.pagos.length}
                                                                rowsPerPage={rowsPerPage}
                                                                SelectProps={{
                                                                    inputProps: {'aria-label': 'No. registros'},
                                                                    native: true,
                                                                }}
                                                                page={page}
                                                                onPageChange={handleChangePage}
                                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                            />
                                                        }
                                                        <ReciboPago generales={generales}/>

                                                    </>
                                                )}
                                            </div>

                                            <Dialog open={addPagoModal}>
                                                <TopBarDialog title={Strings.agregar+" "+Strings.pago} nofullScreen onClose={history.goBack}/>
                                                <DialogContent>
                                                    <form id={"formPago"} onSubmit={addPago}>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12}>
                                                                <Typography>{Strings.comprobante}</Typography>
                                                                <SelectFiles  showImg={false} src={pagoState.pagAdjunto??null} onChange={onImgChange} defaultImgProp={defaultImg}/>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextFieldCurrency label={Strings.monto} fullWidth name={"pagMonto"} value={pagoState.pagMonto} onChange={handleChange} />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField multiline fullWidth label={Strings.comentario} name={"pagComentario"} value={pagoState.pagComentario} onChange={handleChange}/>
                                                            </Grid>
                                                        </Grid>
                                                    </form>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button color="primary" type={"submit"} form={"formPago"} disabled={isLoading}>{Strings.agregar} {Strings.pago}</Button>
                                                </DialogActions>
                                            </Dialog>
                                        </div>
                                    }
                                </div>
                            </Box>
                        )}
                    </div>

                    {!webview &&
                        <div className={classes.lang}>
                            <select value={lang} onChange={e=>{
                                let lang = e.target.value;
                                setLSLang(lang);
                                Strings.setLanguage(lang);
                                setLang(lang);
                            }}>
                                {Strings.getAvailableLanguages().map(l=>
                                    <option key={l} value={l}>{Strings[l]}</option>
                                )}
                            </select>
                        </div>
                    }
                    <br/>
                </>
            )}
        </>
    );
}

export default connect(null, { setEnums,setPaises,setGlobalGenerales,setCurrentUser })(Solicitar);
