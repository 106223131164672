import React, {useEffect, useState} from 'react';
import {Link, useHistory, useLocation, useParams} from 'react-router-dom';
import {connect} from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import {marcarPrestamoIncobrable, mostrarPrestamoById} from '../../redux/actions/prestamoActions';
import { mostrarClienteById} from "../../redux/actions/clientesActions";

import {
  formatPeso,
  formatDate,
  alertNote,
  hasPermissionTo,
  PERSMISOS,
  moment,
  openNewWin,
} from '../../helpers';
import {IconButton, Typography, Grid, MenuItem, Menu, Button, Paper, Hidden , useMediaQuery, Box} from '@mui/material';
import {MoreVert,MoreHoriz} from '@mui/icons-material';

import ListPagos from '../../components/listPagos'
import ListAmort from '../../components/listAmort'
import {AppBar, HtmlTooltip, LoadingProgress} from "../../components";
//import ContratoDialog from "./ContratoDialog";
import AjustarCapitalDialog from "./AjustarCapitalDialog";
import CancelDialog from './CancelDialog'
import HacerPago from './addPago'
import HacerPagoInicial from './addPagoInicial'
import ContactDetails from "../clientes/ContactDetails";
import Strings from "../../assets/strings";
import NotasPrestamo from "./NotasPrestamo";
import { Rating } from '@mui/material';
import NoImg from '../../assets/img/defaultAvatar.jpg';
import {useCustomFormInput} from "../../hooks/useCustomFormInput";
import AddPagoRapido from "./AddPagoRapido";

const useStyles = makeStyles(theme => ({
  clientAvatar: {
    textAlign: "center",
    padding: 20,
    border: "1px solid #ddd",
    height: 138,
    [theme.breakpoints.down('md')]: {
      textAlign: "left",
      padding: "0px 16px 8px",
      border: "none",
      background: theme.palette.primary.main,
      color:"white",
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      height: "auto",
      position: "fixed",
      width: "100%",
      zIndex: 1,
    },
  },
  imgCont:{
    width: 100,
    height: 100,
    marginBottom:8,
    margin: "0 auto",
    position: "relative",
    [theme.breakpoints.down('md')]: {
      margin: "0",
      marginRight: theme.spacing(2),
      height: 45,
      width: 45,
      "& img":{
        width: 45,
        height: 45,
      }
    },

  },
  contentInner:{
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  estadoRating:{
    [theme.breakpoints.down('md')]: {
      display:"flex",
    },

  },
  estado:{
    marginTop: 10,
    textAlign: "center",
    height: 16,
    padding: "7px 14px",
    background: "#eceeef",
    [theme.breakpoints.down('md')]: {
      // marginTop: 0,
      // marginBottom:theme.spacing(1),
      display:"none",
    },
    "&.i":{
      background:"rgba(21,216,193,0.13)",
      "& .estado":{
        color: "#15d2d8",
      }
    },
    "&.c":{
      background:"rgba(216, 67, 21, 0.13)",
      "& .estado":{
        color: "#d84315",
      }
    },
    "&.m":{
      background:"rgba(255, 111, 0, 0.05)",
      "& .estado":{
        color: "#ff6f00",
      }
    },
    "&.p":{
      background:"rgba(25, 118, 210, 0.07)",
      "& .estado":{
        color: "#1976d2",
      }
    }
  },

  imgCircle: {
    borderRadius: "50%",
    [theme.breakpoints.down('md')]: {
      borderRadius: theme.shape.borderRadius,
    },
  },
  controls:{
    marginTop: 10,
    overflowX: 'auto',
    boxShadow: "none",
    borderRadius: 0,
    "& > div":{
      minWidth: 479,
    },
    "& a , button:not(:first-child)":{
      textTransform: "capitalize",
      fontWeight: "normal",
      fontSize: ".9rem",
    },
    [theme.breakpoints.down('xl')]: {
      textAlign: "center",
    }
  },

  table: {
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(3),
    },
    marginBottom: 35,
  },
  button: {
    marginLeft: theme.spacing(1),
    color:"inherit"
  },
  info:{
    overflowX: "auto",
    overflowY: "hidden",

    padding: 10,
    [theme.breakpoints.up('md')]: {
      background:"#eceeef",
      padding: 6,
      boxShadow: "none",
      borderRadius: 0,
      "&:not(.all)":{
        height: 167,
      },
      "&>div":{
        minWidth: 785,
      }
    },
    position: "relative",
    [theme.breakpoints.down('md')]: {
      padding: 0,
      margin: "53px 0 0 0px",
      borderRadius: "0",
      boxShadow: "none"
    },
  },
  itemInfo:{
    ...theme.typography.subheading,
    padding: "10px !important",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.down('md')]: {
      display: "flex",
      flexDirection: "column-reverse",
      padding: "5px 16px !important",
    },
    "& span:last-child":{
      background: "#f6f6f6",
      padding: "5px 13px",
      marginLeft: 10,
      [theme.breakpoints.down('md')]: {
        background: "none",
        marginLeft: 0,
        padding: 0,
        fontSize: "0.9rem",
      },
    },
    "& span:first-child":{
      [theme.breakpoints.down('md')]: {
        display: "block",
        fontSize: ".7rem",
      },
    }
  },
  btnMoreInfo:{
    position:"absolute",
    right:theme.spacing(2),
    cursor:"pointer",
  },
  moreInfo:{
    background:theme.palette.grey[100],
    "& span":{
      fontSize: 15,
    }
  },
  cancelBox:{
    "& .title":{
      background: "#ffdede",
    },
    "& textarea":{
      width: "calc(100% - 7px)",
      border: "1px solid #e6e6e6",
      resize: "none",
    },
    "& .content":{
      padding: "10px 10px 0 10px",
    }
  },
  contGrid:{
    [theme.breakpoints.down('md')]: {
      padding: "0px !important",
      margin: "0px !important",
    },
  },
  btnPagoRapido:{
    position: "absolute",
    width: 170,
    bottom: 10,
    right: 10
  },
  notaNumber:{
    background: "#b5b5b5",
    fontSize: "10px !important",
    width: 18,
    height: 18,
    borderRadius: 9,
    color: "white"
  }
}));


function DetallesPrestamo({usuInicioSesion,prestamo,mostrarPrestamoById,mostrarClienteById,marcarPrestamoIncobrable,isFetching}) {

  const {goBack,...history} = useHistory();
  const {state:hState={}} = useLocation();
  const customFormInput = useCustomFormInput("pre");

  let { preId } = useParams();

  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
  const classes = useStyles();
  const [showAllInfo, setShowAllInfo] = useState(false);

  const [showAmort, setShowAmort] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);

  useEffect(() => {
    if (preId > 0)
      mostrarPrestamoById(preId);
  }, [mostrarPrestamoById, preId]);
  const pushUrl = (state)=>{
    if(openMenu)setOpenMenu(false);
    let pathname = history.location.pathname;
    history.push(pathname,state);
  };
  const showClientModal = cliId => {
    mostrarClienteById(cliId);
    pushUrl({cliId});
  }
  const exportar = ()=>{
    openNewWin("/reports/detallesPrestamo/"+preId);
    // if(showAmort)
    //   downloadFiles(
    //       {
    //         Monto:prestamo.preCapitalInicial,
    //         TipoAmortizacion:prestamo.preTipoAmortizacion,
    //         CicloPago: prestamo.preCiclosPago,
    //         Tasa:prestamo.preTasaInteres,
    //         Cuotas:prestamo.prePlazo,
    //         FechaPrimerPago:prestamo.preFechaPrimerPago
    //       },"rptAmortizacion"
    //   )
    // else
    //   downloadFiles({preId:prestamo.preId},"rptDetallePrestamo");
  }
  const onClickPendiente = (v) => {
    pushUrl({modalPago: v})
  }
  return (
      <>
        <AppBar title={Strings.detalles}>
          {/*<ButtonResponsive icon={<SmsOutlined/>} text={Strings.notas} onClick={()=> pushUrl({verNotas:prestamo.preId})}/>*/}
          <Button startIcon={<Box className={classes.notaNumber}>{prestamo?.notas?.length ?? 0}</Box>} onClick={()=> pushUrl({verNotas:prestamo.preId})}>{Strings.notas}</Button>
          <Hidden smUp>
            <IconButton aria-haspopup="true" size={"small"}
                        onClick={(e)=>setOpenMenu(e.currentTarget)}>
              <MoreVert />
            </IconButton>
            <Menu id="long-menu" anchorEl={openMenu} keepMounted open={Boolean(openMenu)} onClose={()=>setOpenMenu(null)}>
              {!prestamo.preCancelado && prestamo.preEstado !== "incobrable" && <>
                {(hasPermissionTo(PERSMISOS.prestamosActualizar)) &&
                    <MenuItem component={Link} to={`/editar/prestamo/${prestamo.preId}`}>{Strings.editar}</MenuItem>
                }
                {hasPermissionTo(PERSMISOS.prestamosEliminar) &&
                    <MenuItem className={"text-red"} onClick={()=> pushUrl({modalCancel:prestamo.preId})}>{Strings.anular} {Strings.prestamo}</MenuItem>
                }
                {hasPermissionTo(PERSMISOS.prestamosAjustes) &&
                    <MenuItem onClick={()=> pushUrl({modalAjustar:prestamo.preId})}>{Strings.ajustar} {Strings.capital}</MenuItem>
                }
                {hasPermissionTo(PERSMISOS.prestamosCrear) &&
                    <MenuItem  component={Link} to={`/reenganche/${prestamo.preId}`}> {Strings.reenganche}</MenuItem>
                }

              </>}
              {!prestamo.preCancelado &&
                  <MenuItem component={Link} onClick={()=>marcarPrestamoIncobrable(preId)}>
                    {prestamo.preEstado === "incobrable"? "Marcar cobrable":"Incobrable"}
                  </MenuItem>
              }
              <MenuItem
                  onClick={()=>openNewWin("/reports/contrato/"+preId)}
                  //component={Link} to={"/reports/contrato/"+preId}
              >{Strings.contrato}</MenuItem>
              <MenuItem onClick={exportar}>{Strings.exportar}</MenuItem>
              <MenuItem onClick={()=>{setOpenMenu(false);setShowAmort(!showAmort)}}>{Strings.ver} {!showAmort ? Strings.amortizacion:Strings.pagos}</MenuItem>
            </Menu>
          </Hidden>
        </AppBar>
        <div className={"contentInner "+classes.contentInner}>
          {isFetching && <LoadingProgress vh/>}
          <Grid container spacing={smVewport?0:2}>
            <Grid item xs={12} md={3}>
              <div className={classes.clientAvatar} onClick={()=>showClientModal(prestamo.cliId)}>
                <div className={classes.imgCont}>
                  <img height="100" width="100" alt="Avatar" src={prestamo.cliAvatar ? prestamo.cliAvatar: NoImg} className={classes.imgCircle} />
                </div>
                <div>
                  <Typography  noWrap={true} color="inherit">{prestamo.cliente}</Typography>
                  <div className={classes.estadoRating}>
                    <Hidden mdUp>
                      {prestamo.preEstado === "incobrable" ? (<span>Incobrable</span>):(
                          <span>{prestamo.cuotasAtrasadas>1 && prestamo.cuotasAtrasadas} {Strings[prestamo.preEstado]}  &nbsp; </span>
                      )}
                    </Hidden>
                    <Box display={"flex"} alignItems="center" justifyContent="center">
                      <Rating value={prestamo.cliCalificacion || 0} precision={0.5} size="small" readOnly/> &nbsp;
                      <Typography variant={"caption"}>{prestamo.cliCalificacion}</Typography>
                    </Box>
                  </div>
                </div>
              </div>
              <div className={`${classes.estado} ${prestamo?.preEstado?.charAt(0) ?? ""}`}>
                {prestamo.preEstado === "incobrable" ? (<span>Incobrable</span>):(
                    <span>{prestamo.cuotasAtrasadas>1 && prestamo.cuotasAtrasadas} {Strings[prestamo.preEstado]} </span>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={9}>
              <div className={(showAllInfo?"all":"")+" "+classes.info} onClick={()=>{if(!window.getSelection().toString()) setShowAllInfo(!showAllInfo);}}>
                <Grid container >
                  {!showAllInfo && <MoreHoriz className={classes.btnMoreInfo}/>}
                  {showAllInfo &&
                      <Grid item xs={12} className={classes.moreInfo}>
                        <Grid container>
                          <Grid item xs={4}  className={classes.itemInfo}>
                            <span>ID:</span>
                            <span>{prestamo.preId}</span>
                          </Grid>
                          {!!prestamo.preInicial &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.inicial} {Strings.pendiente}:</span>
                                <span>{formatPeso(prestamo.preInicial)}</span>
                              </Grid>
                          }
                          {!!prestamo.preIdReeganche &&
                              <Grid item xs={4}  className={classes.itemInfo} onClick={()=>history.push("/prestamo/"+prestamo.preIdReeganche)}>
                                <span>{Strings.reenganche}:</span>
                                <span style={{textDecoration: "underline",cursor:"pointer",color: "#2b50d6"}}>{prestamo.preIdReeganche}</span>
                              </Grid>
                          }
                          {!!prestamo.preReferencia &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.codigoRef}:</span>
                                <span>{prestamo.preReferencia}</span>
                              </Grid>
                          }
                          <Grid item xs={4}  className={classes.itemInfo}>
                            <span>{Strings.fechaApertura}:</span>
                            <span>{formatDate(prestamo.preFecha)}</span>
                          </Grid>
                          {!!prestamo.preCodeudor &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.codeudor}:</span>
                                <span>{prestamo.preCodeudor}</span>
                              </Grid>
                          }
                          {!!prestamo.preDiasExcluidos &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.diasExcluidos}:</span>
                                <span>
                              {prestamo.preDiasExcluidos.split(",")
                                  .map(s=>moment().day(s).format("dd")).join(', ')}
                            </span>
                              </Grid>
                          }
                          {!!prestamo.caja &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.caja}:</span>
                                <Typography noWrap component={"span"}>{prestamo.caja}</Typography>
                              </Grid>
                          }
                          {!!prestamo.preFechaActualizacion &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.editado}:</span>
                                <span>{formatDate(prestamo.preFechaActualizacion)}</span>
                              </Grid>
                          }
                          {!!prestamo.creadoPor &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.creadoPor}:</span>
                                <span>{prestamo.creadoPor}</span>
                              </Grid>
                          }

                          {customFormInput && Object.keys(customFormInput).map((key)=><>
                            {prestamo[key] &&
                                <Grid item xs={4}  className={classes.itemInfo}>
                                  <span>{customFormInput[key].name}:</span>
                                  <span>{prestamo[key]}</span>
                                </Grid>
                            }
                          </>)}
                          {!!prestamo.agenteComision &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.agente}:</span>
                                <span>{prestamo.agenteComision}</span>
                              </Grid>
                          }
                          {!!prestamo.carDescripcion &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.cartera}:</span>
                                <Typography noWrap component={"span"}>{prestamo.carDescripcion}</Typography>
                              </Grid>
                          }
                          {!!prestamo.preMontoSeguro &&
                              <Grid item xs={4}  className={classes.itemInfo}>
                                <span>{Strings.seguro}:</span>
                                <Typography noWrap component={"span"}>{prestamo.preMontoSeguro}</Typography>
                              </Grid>
                          }
                        </Grid>
                      </Grid>
                  }
                  <Grid item xs={4}  className={classes.itemInfo}>
                    <span>{Strings.capitalActual}:</span>
                    <span>{formatPeso(prestamo.preCapitalActual)}</span>
                  </Grid>
                  <Grid item xs={4}  className={classes.itemInfo}>
                    <span>{Strings.cuotas}:</span>
                    <span>{prestamo.prePlazoString}</span>
                  </Grid>
                  <Grid item xs={4}  className={classes.itemInfo}>
                    <span>{Strings.amortizacion}:</span>
                    <span>{Strings[prestamo.preTipoAmortizacionString]}</span>
                  </Grid>
                  <Grid item xs={4}  className={classes.itemInfo}>
                    <span>{Strings.proximo} {Strings.pago}:</span>
                    <Typography noWrap component={"span"}>{formatDate(prestamo.preFechaProximoPago,"dd, DD/MM/YYYY")}</Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.itemInfo}>
                    <span>{Strings.interes}:</span>
                    <span>{prestamo.preTasaInteres} %</span>
                  </Grid>

                  <Grid item xs={4}  className={classes.itemInfo}>
                    <span>{Strings.capitalInicial}:</span>
                    <span>{formatPeso(prestamo.preCapitalInicial)}</span>
                  </Grid>
                  <Grid item xs={4}  className={classes.itemInfo}>
                    <HtmlTooltip title={Strings.cuotaVencida +"+"+ Strings.mora}>
                      <Typography noWrap component={"span"}>
                        {Strings.balancePendiente}
                      </Typography>
                    </HtmlTooltip>
                    <span>{formatPeso(prestamo.preBalancePendiente)}</span>
                  </Grid>
                  <Grid item xs={4} className={classes.itemInfo}>
                    <span>{Strings.modalidad}:</span>
                    <span>{Strings[prestamo.preCiclosPagoString]}</span>
                  </Grid>

                  <Grid item xs={4} className={classes.itemInfo}>
                    <span>{Strings.mora}:</span>
                    <span>% {prestamo.prePorcientoMora}</span>
                  </Grid>
                  <Grid item xs={4}  className={classes.itemInfo}>
                    <span>{Strings.parcialPendiente}:</span>
                    <span>{formatPeso(prestamo.prePagoPendiente)}</span>
                  </Grid>
                  <Grid item xs={4} className={classes.itemInfo}>
                    <span>{Strings.comision}:</span>
                    <span>%{prestamo.prePorcientoComision}</span>
                  </Grid>
                  <Grid item xs={4} className={classes.itemInfo}>
                    <span>{Strings.gastosLegales}:</span>
                    <span>{prestamo.preGastosLegales}</span>
                  </Grid>
                </Grid>
              </div>
              <Hidden only={"xs"}>
                <Paper className={"d-print-none " +classes.controls}>
                    {hasPermissionTo(PERSMISOS.pagosCrear) &&
                        <Button size={"small"} onClick={() => pushUrl({modalPago: {preId:prestamo.preId}})}
                                disabled={prestamo.preCancelado || prestamo.preEstado === "incobrable"}
                                color={"primary"} variant={"contained"}>{Strings.agregar} {Strings.pago}</Button>
                    }
                    {hasPermissionTo(PERSMISOS.pagosCrear) && !!prestamo.preInicial &&
                        <Button size={"small"}
                                sx={{ ml: 1}}
                                onClick={() => pushUrl({modalPagoInicial: {preId:prestamo.preId}})}
                                disabled={prestamo.preCancelado || prestamo.preEstado === "incobrable"}
                                color={"secondary"} variant={"contained"}>{Strings.inicial}</Button>
                    }
                  {!prestamo.preCancelado && prestamo.preEstado !== "incobrable" && <>
                    {hasPermissionTo(PERSMISOS.prestamosActualizar) &&
                        <Button size="small" className={classes.button} component={Link} to={`/editar/prestamo/${prestamo.preId}`}> {Strings.editar}</Button>
                    }
                    {hasPermissionTo(PERSMISOS.prestamosEliminar) &&
                        <Button size="small" color="secondary" className={"text-red "+classes.button}
                                onClick={()=> pushUrl({modalCancel:prestamo.preId})}>{Strings.anular}</Button>
                    }
                    {hasPermissionTo(PERSMISOS.prestamosAjustes) &&
                        <Button size="small" className={classes.button}
                                onClick={() => pushUrl({modalAjustar: prestamo.preId})}>{Strings.ajustar} {Strings.capital}</Button>
                    }
                    {hasPermissionTo(PERSMISOS.prestamosCrear) &&
                        <Button size="small"  className={classes.button} component={Link} to={`/reenganche/${prestamo.preId}`}> {Strings.reenganche}</Button>
                    }
                  </>}
                  {!prestamo.preCancelado &&
                      <Button size="small" className={classes.button} onClick={() => marcarPrestamoIncobrable(preId)}>
                        {prestamo.preEstado === "incobrable" ? "Marcar cobrable" : "Incobrable"}
                      </Button>
                  }
                  <Button size="small" className={classes.button} onClick={()=>setShowAmort(!showAmort)}>{!showAmort ? Strings.amortizacion:Strings.pagos }</Button>
                  <Button size="small" className={classes.button}
                          onClick={()=>openNewWin("/reports/contrato/"+preId)}
                      // onClick={()=> pushUrl({modalContrato:true})}
                  >
                    {Strings.contrato}</Button>
                  <Button size="small" className={classes.button} onClick={exportar}>{Strings.exportar}</Button>

                </Paper>
              </Hidden>
            </Grid>
            <Hidden smUp>
              <Grid container spacing={1} sx={{p:1}}>
                <Grid item xs={(hasPermissionTo(PERSMISOS.pagoInicial) && !!prestamo.preInicial) ? 6 : 12} >
                  {hasPermissionTo(PERSMISOS.pagosCrear) &&
                      <Button onClick={()=> pushUrl({modalPago:{preId:prestamo.preId}})}
                              disabled={prestamo.preCancelado || prestamo.preEstado === "incobrable"}
                              fullWidth color={"primary"} variant={"contained"}>{Strings.agregar} {Strings.pago}</Button>

                  }
                </Grid>
                {hasPermissionTo(PERSMISOS.pagoInicial) && !!prestamo.preInicial &&
                    <Grid item xs={6}>
                      <Button onClick={() => pushUrl({modalPagoInicial: {preId:prestamo.preId}})}
                              disabled={prestamo.preCancelado || prestamo.preEstado === "incobrable"}
                              fullWidth color={"secondary"} variant={"contained"}>{Strings.inicial}</Button>

                      </Grid>
                  }
              </Grid>


                </Hidden>
          </Grid>
          <div className={classes.table}>
            {showAmort ? (
                <ListAmort data={prestamo.amortizacion}/>
            ): (
                <ListPagos onClickPendiente={onClickPendiente} seguro={!!prestamo.preMontoSeguro} pagos={prestamo.pagos?prestamo.pagos:[]} alertNote={alertNote}/>
            )
            }
          </div>
        </div>
        {hasPermissionTo(PERSMISOS.pagosCrear) && ["melvin92","ramon19","shiara23","niovi", "jsjprestamos","shiara1223","edyliw","ezequiel","angelosf","demo14","evangelista","carlos","patricia24","yami19","cristhian","putyss.01", "jorfan","deibi12","dsegura","inversioneswilfin","adonis21","jsjinversiones","cruzprestamo","thevionic","plopez","capo03", "emmanuel","capo03","israel2017", "ramon19"].includes(usuInicioSesion?.trim().toLowerCase()) &&
            <Button className={classes.btnPagoRapido} onClick={()=> pushUrl({modalPagoRapido:prestamo.preId})}
                    disabled={prestamo.preCancelado}
                    fullWidth color={"primary"} variant={"contained"}>{Strings.pago} rápido</Button>
        }


        {!!hState?.modalPago?.preId && <HacerPago/>}
        {!!hState?.modalPagoInicial?.preId && <HacerPagoInicial/>}



        {!!hState.cliId && <ContactDetails/>}
        {!!hState.verNotas && <NotasPrestamo/>}
        {!!hState.modalAjustar && <AjustarCapitalDialog/>}
        {/*{!!hState.modalContrato && <ContratoDialog preId={prestamo.preId}/>}*/}
        {!!hState.modalCancel && <CancelDialog/>}



        {!!hState.modalPagoRapido && <AddPagoRapido/>}

      </>
  );


}

const mapStateToProps = (state) => ({
  usuInicioSesion: state.auth?.user?.usuInicioSesion??"",
  prestamo: state.prestamos.byId,
  isFetching: (state.prestamos.isFetching || state.clientes.isFetching), //cliente se puedes ver desde aqui
});
export default connect(mapStateToProps, { mostrarPrestamoById,mostrarClienteById,marcarPrestamoIncobrable })(DetallesPrestamo);
