import React from 'react';
import { Button, Hidden } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
  btn: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 39,
    }
  }
}));
export default function ButtonResponsive({icon,text,onClick,...props}){
  const classes = useStyles();
  return (
    <Button onClick={onClick} {...props} className={classes.btn}>
      <Hidden mdDown>{text}</Hidden>
      <Hidden mdUp>{icon}</Hidden>
    </Button >
  );
}

