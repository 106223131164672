import React from 'react';
import { Grid, useMediaQuery } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CounterItem from "./CounterItem";
import {SupervisorAccountOutlined,DescriptionOutlined,AttachMoneyOutlined, BarChart} from "@mui/icons-material";
import {formatPeso} from "../../helpers";
import {connect} from "react-redux";
import HtmlTooltip from "../HtmlTooltip";
import Strings from "../../assets/strings";

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
         marginBottom: theme.spacing(1)
        },
    }
}));

function HomeCounter({dashBoard}) {
    const xsVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));

    const classes = useStyles();
    return (
        <Grid container spacing={xsVewport?1:2} className={"d-print-none " + classes.root}>
            <Grid item xs={6} md={3}>
                <CounterItem title={Strings.prestatariosActivos} subTitle={`${dashBoard.clientesActivos} ${Strings.de} ${dashBoard.clientes}`} Icon={SupervisorAccountOutlined}/>
            </Grid>
            <Grid item xs={6} md={3}>
                <HtmlTooltip title={Strings["prestamosActivoMsj"]}>
                    <div>
                        <CounterItem title={Strings.prestamosActivos} subTitle={`${dashBoard.prestamos} ${Strings.de} ${dashBoard.maxPrestamos >= 2000 ? Strings.ilimitado: dashBoard.maxPrestamos}`} Icon={DescriptionOutlined} color={"#ffc36d"}/>
                    </div>
                </HtmlTooltip>
            </Grid>
            <Grid item xs={6} md={3}>
                <CounterItem title={Strings["total"] +" "+ Strings["prestado"]} subTitle={formatPeso(dashBoard.capitalActual)} Icon={AttachMoneyOutlined} color={"#54e69d"}/>
            </Grid>
            <Grid item xs={6} md={3}>
                <HtmlTooltip title={Strings.totalInteresMjs}>
                    <div>
                        <CounterItem title={Strings["total"] +" "+ Strings["interes"]}
                                 subTitle={formatPeso(dashBoard.interes)} Icon={BarChart} color={"#796AEE"}/>
                    </div>
                </HtmlTooltip>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    dashBoard: state.dashBoard
});
export default connect(mapStateToProps)(HomeCounter);

