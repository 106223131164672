import {plantillaTypes} from './types';
import {getPlantillas, addPlantilla, editPlantilla, delPlantilla} from '../../services';
import _ from 'lodash';
import history from "../../helpers/history"

export function mostrarPlantillas(filter) {
    return dispatch => {
        dispatch({
            type: plantillaTypes.MOSTRAR_PLANTILLAS,
            payload: getPlantillas(filter)
        })
    }
}
export function mostrarPlantillaById(id) {
    return (dispatch, getState) => {
        try {
            let byId = getState().plantillas.list.find(c=>c.plaId === parseInt(id));
            if(byId)
                dispatch({ type: plantillaTypes.MOSTRAR_PLANTILLA_POR_ID_ENCONTRADO, payload:{byId}}) ;        }
        catch(err) {}

        dispatch({
            type: plantillaTypes.MOSTRAR_PLANTILLA_POR_ID,
            payload: getPlantillas(parseInt(id))
        })
    }
}
export function mostrarPlantillasSinoEsta() {
    return (dispatch, getState) => {
        const plantillas = getState().plantillas;
        if (Object.keys(plantillas.list).length || plantillas.isFetching) return false;
        dispatch({
            type: plantillaTypes.MOSTRAR_PLANTILLAS,
            payload: getPlantillas()
        })
    }
}
export function eliminarPlantilla(id) {
    return dispatch => {
        dispatch({
            type: plantillaTypes.ELIMINAR_PLANTILLA,
            payload: delPlantilla(id)
        })
    }
}
export function agregarEditarPlantilla(obj) {
    let cleanObj = _.omitBy(obj, (v) => !v);
    return dispatch => {
        dispatch({
            type: plantillaTypes.AGREGAR_EDITAR_PLANTILLA,
            payload: cleanObj.plaId ? editPlantilla(cleanObj) : addPlantilla(cleanObj)
        }).then(history.goBack)
    }
}
