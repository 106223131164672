import React, {useEffect, useState} from 'react';
import {useLocation,useHistory, useParams} from 'react-router-dom';
import {mostrarPrestamoById,agregarEditarPrestamo} from "../../redux/actions/prestamoActions";
import {mostrarGeneralesSinoEsta} from "../../redux/actions/generalesActions";

import {connect} from "react-redux";

import { Grid, TextField, Button, CircularProgress, FormControl, Input, InputLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {nullToEmptyString, sumarQuincena} from '../../helpers';
import SearchClienteDialog from '../../components/SearchClienteDialog';

import {MostrarError, AppBar, LoadingProgress, ButtonResponsive, HtmlTooltip} from "../../components";
import {DateInput, TextFieldCurrency, TextFieldPhone} from "../../components/CustomTextField";
import Strings from "../../assets/strings";
import {SelectAgente, SelectCaja, SelectDay, SelectEnum, SelectCartera} from "../../components/selects";
import {AssessmentOutlined} from "@mui/icons-material";
import {moment} from "../../helpers";
import AmortCal, {getAmortList} from "../../components/calAmort";
import Box from "@mui/material/Box";
import {useCustomFormInput} from "../../hooks/useCustomFormInput";

const useStyles = makeStyles(theme => ({

  root: {
      padding: theme.spacing(2),
  },
    btn:{
        marginRight: 5,
        color:theme.palette.common.white,
        fontWeight:"normal",
    },
    btnCodeudor:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2),
        boxShadow: "none !important",
        borderRadius: 0,
        background: theme.palette.grey[100],
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    }

}));
let initialState ={
    preId: "",
    preReferencia: "",
    cliId: "",
    carId:"",
    cliente:"",
    preTipoAmortizacion: "",
    preCapitalInicial: "",
    preTasaInteres: "",
    montoCuota:"",
    prePlazo: "",
    preCiclosPago: "",
    preFecha: moment().format("YYYY-MM-DD"),
    preFechaProximoPago: "",
    preFechaPrimerPago:"",
    preDiasExcluidos: "",
    prePorcientoMora: "",
    prePorcientoComision:"",
    usuIdAgente:"",
    cajId: "",
    preGastosLegales:"",
    preCodeudor: "",
    preCodeudorCedula: "",
    preCodeudorTelefono: "",
    preCodeudorDireccion: "",
    preIdReeganche: "",
    preMontoSeguro:"",
    preInicial:""
};

function CrearPrestamo(props) {
    const {state:urlState} = useLocation();
    const customFormInput = useCustomFormInput("pre");
    const {goBack,...history} = useHistory();
    const [reenganche] = useState(Boolean(window.location.pathname.indexOf("reenganche") !== -1));


    //set cliente por defecto
    if(urlState?.cliente){
        const {cliId,fullName } = urlState.cliente;
        initialState = {
            ...initialState,
            cliId,
            cliente: fullName,
        }
    }


    const classes = useStyles();

    initialState.cajId = props.defaultCajId;
    let { preId:urlPreId} = useParams();
    const {mostrarPrestamoById,mostrarGeneralesSinoEsta,genPorcientoMora, prestamo, isFetching,isPending, error} = props;

    const [state, setState] = useState(initialState);
    useEffect(()=>{
        if(urlPreId){
            mostrarPrestamoById(urlPreId);
        }else{
            mostrarGeneralesSinoEsta();
        }
    },[urlPreId,setState,mostrarPrestamoById,mostrarGeneralesSinoEsta]);
    useEffect(()=>{
        setState({...initialState, prePorcientoMora: genPorcientoMora});
        if(!!prestamo.preId && prestamo.preId === parseInt(urlPreId)) {
            let preOjb = {...prestamo}
            if(reenganche){
                delete preOjb.preId;
                delete preOjb.amortizacion;
                delete preOjb.pagos;
                delete preOjb.preCapitalInicial;
                preOjb.preIdReeganche = prestamo.preId
                preOjb.preFecha = moment().format("YYYY-MM-DD")
            }
            setState(nullToEmptyString(preOjb));
        }
    },[urlPreId,prestamo,genPorcientoMora,reenganche]);

    // Reset fecha si se cambia a modalidad quincenal
    const {preCiclosPago} = state
    useEffect(()=>{
        if(preCiclosPago === "16"){
            setState(prevState => ({...prevState,
                preCiclosPago,
                preFecha: "",
                preFechaProximoPago: "",
                preFechaPrimerPago:"",
            }));
        }

    },[preCiclosPago]);

    const handleChange = (e) => {
        const {name,value} = e.target;
        if(name === "prePlazo"){
            return setState({...state,[name]: parseInt(value)});
        }
        //calcular fecha proximo pago
        else if(!state.preId && (name === "preFecha" || name === "preCiclosPago")){
            const {preFecha,preCiclosPago} = state;
            let cicloPago = name === "preCiclosPago" ? parseInt(value) : parseInt(preCiclosPago);
            let fecha = moment(name === "preFecha" ? value : preFecha,"YYYY-MM-DD");
            let preFechaPrimerPago;

            if (cicloPago === 30) {
                preFechaPrimerPago = fecha.add(1, "months").format("YYYY-MM-DD");
            }else if(cicloPago === 16){
                preFechaPrimerPago = sumarQuincena(fecha) ? moment(sumarQuincena(fecha)).format("YYYY-MM-DD") : "";
            }else {
                preFechaPrimerPago = fecha.add(cicloPago, "days").format("YYYY-MM-DD");
            }
            setState((prev)=>({...prev,[name]: value,preFechaPrimerPago}));
        }else{
            setState({...state,[name]: value});
        }
    };

    const handleCliChange = ({cliId,cliNombres,cliApellidos}) => {
        setState({...state,cliId:cliId,cliente:cliNombres+" "+cliApellidos});
    };
    const submit = (e) =>{
        e.preventDefault();
        let prestamo = {...state,pagos:null,amortizacion:null,cliAvatar:null};
        if(!!state?.montoCuota){
            const {preCapitalInicial, preTasaInteres, prePlazo, preCiclosPago, preTipoAmortizacion, preFechaPrimerPago, montoCuota} = state;
            getAmortList({
                capital: preCapitalInicial,
                interes: preTasaInteres,
                cuotas: prePlazo,
                ciclosPago: preCiclosPago,
                tipoAmortizacian: preTipoAmortizacion,
                fecha: preFechaPrimerPago,
                montoCuota
            }).then(res=> {
                setState(prevState => ({...prevState,preTasaInteres: res.interes}))
                prestamo.preTasaInteres = res.interes;
                props.agregarEditarPrestamo(prestamo);
            })
        }else {
            props.agregarEditarPrestamo(prestamo);
        }
    };
    return (
        <>
            <AppBar title={(!!state.preId ?Strings.editar :Strings.crear)+" "+(reenganche ? "Reenganche": Strings.prestamo)} >
                <ButtonResponsive icon={<AssessmentOutlined/>} text={Strings.amortizacion}
                                  onClick={()=>history.push(history.location.pathname,{showCal:true})}/>
                <Button color="inherit" disabled={isPending} type="submit" form="form1" >
                    {isPending? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>
            <div className={"contentInner "+classes.root}>
                {isFetching && <LoadingProgress vh/>}
                <MostrarError errors={error}/>
                <form onSubmit={submit} id="form1" autoComplete={"off"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <SearchClienteDialog
                                required
                                value={state.cliId}
                                cliente={state.cliente}
                                onChange={handleCliChange}
                                disabled={Boolean(state.preId || state.preIdReeganche)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <SelectEnum value={state.preTipoAmortizacion} name={"preTipoAmortizacion"} label={Strings.amortizacion} required
                                        onChange={handleChange} group={"tipoAmortizacion"} />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                            <SelectEnum value={state.preCiclosPago} name={"preCiclosPago"} label={Strings.modalidad} required
                                        onChange={handleChange} group={"ciclosPago"} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextFieldCurrency
                                name="preCapitalInicial"
                                label={Strings.monto +" "+ (state.preId ? Strings.prestado:Strings.aPrestar)}
                                value={state.preCapitalInicial}
                                onChange={handleChange}
                                disabled={Boolean(!!state.pagos?.filter(p=> !p.pagAnulado).length )}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2} lg={3}>
                            <TextField
                                name="prePlazo"
                                label={"# "+Strings.cuotas}
                                type="number"
                                inputProps={{step:1,inputMode:"numeric"}}
                                required
                                value={state.prePlazo}
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} lg={3}>
                            <FormControl
                                disabled={state.montoCuota>0}
                                required={!state.montoCuota}
                                fullWidth>
                                <InputLabel htmlFor="preTasaInteres">
                                    % {Strings.tasaInteres} {!!state.preCiclosPago.length && <SelectEnum value={ state.preCiclosPago} group={"ciclosPago"} />}
                                </InputLabel>
                                <Input id="preTasaInteres"
                                       name="preTasaInteres"
                                       value={state.preTasaInteres}
                                       type="number"
                                       inputProps={{step:"any",inputMode:"decimal"}}


                                       onChange={handleChange}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={4} md={4} lg={3}>
                            <HtmlTooltip title={
                                <em> {Strings.interesDesconocidoMsj}</em>
                            } disableHoverListener>
                                <div>
                                    <TextFieldCurrency
                                        name="montoCuota"
                                        value={state.montoCuota}
                                        onChange={handleChange}
                                        fullWidth
                                        placeholder={"Opcional"}
                                        label={Strings.monto+" "+Strings.cuotas}

                                    />
                                </div>
                            </HtmlTooltip>
                        </Grid>

                        <Grid item xs={6} sm={4} md={3}>
                            <DateInput
                                name={"preFecha"}
                                value={state.preFecha}
                                label={Strings.fecha}
                                //quincenalFijo={state.preCiclosPago === "16"}
                                required
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <DateInput
                                name="preFechaPrimerPago"
                                value={state.preFechaPrimerPago}
                                label={Strings.fecha+" "+Strings.primerPago}
                                quincenalFijo={state.preCiclosPago?.toString() === "16"}
                                onChange={handleChange}
                                min={state.preFecha}
                                required
                                fullWidth
                            />
                        </Grid>
                        {!!state.preId &&
                        <Grid item xs={6} sm={4} md={3}>
                            <DateInput
                                name="preFechaProximoPago"
                                value={state.preFechaProximoPago}
                                label={Strings.fecha + " " + Strings.proximo + " " + Strings.pago}
                                quincenalFijo={state.preCiclosPago === "16"}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        }
                        {!Boolean(state.preId) &&
                        <Grid item xs={6} sm={4} md={3}>
                            <SelectCaja
                                name="cajId"
                                label={Strings.caja}
                                value={state.cajId}
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        }
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                            <TextField
                                name="preReferencia"
                                label={Strings.codigoRef}
                                value={state.preReferencia}
                                fullWidth
                                onChange={handleChange}
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                            <TextField
                                name="preGastosLegales"
                                type={"number"}
                                label={Strings.gastosLegales}
                                value={state.preGastosLegales}
                                fullWidth
                                disabled={Boolean(state.preId)}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3} lg={2}>
                            <TextField
                                name="prePorcientoMora"
                                type="number"
                                inputProps={{step:"any",inputMode:"decimal"}}
                                label={"% "+Strings.mora}
                                value={state.prePorcientoMora}
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextField
                                name="prePorcientoComision"
                                label={"% "+Strings.comision}
                                type={"number"}
                                inputProps={{
                                    max:100,
                                    step:"any",
                                    inputMode:"decimal"
                                }}
                                value={state.prePorcientoComision}
                                fullWidth
                                onChange={handleChange}
                            />
                        </Grid>
                        {state.preCiclosPago?.toString() === "1" &&
                        <Grid item xs={12}  sm={4} lg={3}>
                            <SelectDay
                                name={"preDiasExcluidos"}
                                value={state.preDiasExcluidos}
                                onChange={handleChange}
                            />
                        </Grid>
                        }
                        {state.prePorcientoComision > 0 &&
                        <Grid item xs={6} sm={4} md={4}>
                            <SelectAgente
                                name="usuIdAgente"
                                label={Strings.agenteAcomisionar}
                                value={state.usuIdAgente}
                                onChange={handleChange}
                            />
                        </Grid>
                        }
                        <Grid item xs={6} sm={4} lg={3}>
                            <SelectCartera value={state.carId}
                                           name={"carId"}
                                           label={Strings.cartera}
                                           onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextFieldCurrency
                                name="preMontoSeguro"
                                label={Strings.monto +" "+Strings.seguro}
                                value={state.preMontoSeguro}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <TextFieldCurrency
                                name="preInicial"
                                disabled={Boolean(state.preId)}
                                label={Strings.inicial}
                                value={state.preInicial}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        {customFormInput && Object.keys(customFormInput).map((key)=>
                            <Grid item xs={12} sm={4} lg={2} key={key}>
                                <TextField
                                    name={key}
                                    label={customFormInput[key].name}
                                    required={customFormInput[key].isRequired}
                                    value={state[key] ? state[key] : ""}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>

                    <br/>
                    <Box color={"secondary.main"} mb={1}>{Strings.codeudor.toLocaleLowerCase()}</Box>
                    <Grid container spacing={3} className={classes.contCodeudor}>
                        <Grid item xs={12} md={3}>
                            <TextField
                                name="preCodeudor"
                                label={Strings.primerNombre}
                                value={state.preCodeudor}
                                fullWidth
                                onChange={handleChange}
                                inputProps={{maxLength: 100}}
                                required={Boolean(state.preCodeudorCedula || state.preCodeudorTelefono || state.preCodeudorDireccion)}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TextField
                                name="preCodeudorCedula"
                                label={Strings.cedula}
                                value={state.preCodeudorCedula} //no trae el parametro en actualizar
                                fullWidth
                                onChange={handleChange}
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                        <Grid item xs={6} md={2}>
                            <TextFieldPhone
                                name="preCodeudorTelefono"
                                label={Strings.telefono}
                                value={state.preCodeudorTelefono} //no trae el parametro en actualizar
                                fullWidth
                                onChange={handleChange}
                                inputProps={{maxLength: 20}}
                            />
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                name="preCodeudorDireccion"
                                label={Strings.direccion}
                                value={state.preCodeudorDireccion}
                                fullWidth
                                onChange={handleChange}
                                inputProps={{maxLength: 100}}
                            />
                        </Grid>
                    </Grid>
                </form>

                {urlState && urlState.showCal &&
                <AmortCal onClose={goBack}
                          inicialState={{
                              fecha: state.preFechaPrimerPago,
                              capital: state.preCapitalInicial,
                              interes: state.preTasaInteres,
                              cuotas: state.prePlazo,
                              ciclosPago:state.preCiclosPago,
                              tipoAmortizacian:state.preTipoAmortizacion,
                              montoCuota:state.montoCuota
                          }}
                          onUseVal={(val)=>{
                              setState(prevState => ({
                                  ...prevState,
                                  preFechaPrimerPago: val.fecha,
                                  preCapitalInicial: val.capital,
                                  preTasaInteres: val.interes,
                                  prePlazo: val.cuotas,
                                  preCiclosPago: val.ciclosPago,
                                  preTipoAmortizacion: val.tipoAmortizacian,
                                  montoCuota: val.montoCuota
                              }))
                          }} />
                }
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    prestamo: state.prestamos.byId,
    isFetching: state.prestamos.isFetching,
    isPending:state.prestamos.isPending,
    error: state.prestamos.error,
    defaultCajId: state.auth.user.cajId || "",
    genPorcientoMora: state.generales.data.genPorcientoMora||"",
});
export default connect(mapStateToProps, { mostrarPrestamoById,agregarEditarPrestamo,mostrarGeneralesSinoEsta })(CrearPrestamo);
