import { usosMultiplesTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';

const initialState = {
    list: {},
    isFetching: false,
    isPending: false,
    error: null,
};

export default function index(state = initialState, action={}) {
    switch(action.type) {
        case usosMultiplesTypes.MOSTRAR_USOS_MULTIPLES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case usosMultiplesTypes.MOSTRAR_USOS_MULTIPLES + _FULFILLED: {
            const { data } = action.payload
            let list = state.list;
            if (data.length) {
                let usoGrupo = new Set(data.map(g=>g.usoGrupo))
                usoGrupo.forEach((g)=>{
                    list[g] = data.filter(item=>item.usoGrupo === g)
                })
            }
            return {
                ...state,
                list,
                isFetching: false,
                error: {}
            }
        }
        case usosMultiplesTypes.MOSTRAR_USOS_MULTIPLES + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //agregar  o editar

        case usosMultiplesTypes.AGREGAR_EDITAR_USO_MULTIPLE + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case usosMultiplesTypes.AGREGAR_EDITAR_USO_MULTIPLE + _FULFILLED: {
             const { data } = action.payload;
             let list = {...state.list};
             let listFiltered = state.list[data.usoGrupo]?.filter(e => String(e.usoCodigo + e.usoGrupo) !== String(data.usoCodigo + data.usoGrupo)) ?? [];
                 listFiltered.unshift(data)
             list[data.usoGrupo] = listFiltered;
             return {
                ...state,
                list,
                isPending: false,
                showModal: false,
                error: {}
            }
        }
        case usosMultiplesTypes.AGREGAR_EDITAR_USO_MULTIPLE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Eliminar

        case usosMultiplesTypes.ELIMINAR_USO_MULTIPLE + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list;
            let listFiltered = state.list[data.usoGrupo].filter(e => String(e.usoCodigo + e.usoGrupo) !== String(data.usoCodigo + data.usoGrupo))
            list[data.usoGrupo] = listFiltered;

            return {
                ...state,
                list,
                isPending: false,
                showModal: false,
                error: {}
            }
        }
        default: 
            return state;
    }
}
