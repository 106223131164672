import React, {useState, useEffect} from 'react';
import {Box, Button} from "@mui/material";
import Strings from "../../assets/strings";
import {agregarEditarCliente} from "../../redux/actions/clientesActions";
import {useDispatch} from "react-redux";

export default function ClienteUsuario({cliente}) {
    const [pass,setPass]=useState("")
    const Dispatch = useDispatch()
    useEffect(()=>{
        setPass(cliente.cliLoginPass)
    },[setPass,cliente])

    const generar = () => {
        let cli = {...cliente,cliLoginPass:pass}
        cli.cliAvatar = "";
        cli.cliDocumento1 = "";
        cli.cliDocumento2 = "";

        Dispatch(agregarEditarCliente(cli))

    }
  return(
      <Box sx={{mb:2}}>
          <Box sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#ececec",
              mt:1,
              pl: 1,
              minHeight:37
          }}>
              <div>Ver estado de cuenta:</div>
              {(cliente.cliLoginPass !== pass) &&
                  <div><Button onClick={generar}>{Strings.guardar}</Button></div>
              }
          </Box>
          <Box p={1}>
              <div>{Strings.cedula}: {cliente.cliCedula}</div>
              <Box sx={{display:"flex",alignItems:"center"}}> <div>PIN: </div> <input style={{border: "1px solid #ececec",marginLeft: 7,padding:5}} max={6} value={pass} onChange={e=>setPass(e.target.value)}/></Box>
          </Box>
      </Box>
  )
}