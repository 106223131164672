import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {mostrarPrestamoById} from "../../redux/actions/prestamoActions"
import {useHistory, useLocation, useParams} from "react-router-dom";
import {postPago, calCuotasPrestamo} from "../../services"
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Tabs,
  Tab,
  useMediaQuery,
  FormControl,
  Select, Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {DateInput,TextFieldCurrency} from "../../components/CustomTextField"
import {formatPeso, getUsuTipo, hasPermissionTo, PERSMISOS, TIPOPAGO, TIPOUSUARIO} from "../../helpers";
import {LoadingProgress,MostrarError,TopBarDialog,HtmlTooltip} from "../../components";
import {SelectEnum, SelectCaja, SelectFiles} from "../../components/selects";
import Strings from "../../assets/strings";
import defaultImg from "../../assets/img/reciboImg.jpg";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
      maxWidth:540,
      margin:"0px auto",
    },
    [theme.breakpoints.only('xs')]: {
      "& .MuiInput-input":{
        position: "relative",
        padding: 7,
        border: "1px solid #e6e6e6",
      },
      "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)":{
        left:theme.spacing(1),

      }
    }
  },
  tabs:{
    minHeight: 37,
    "& button":{
      minHeight: 37,
      "&.Mui-selected":{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderTop: "1px solid rgba(0, 0, 0, 0.1)"
      }
    },
    "& .MuiTabs-indicator":{
      display:"none",
    }
  },
  content:{
    padding:theme.spacing(2),
  },
  cuotaInteres:{
    display: "flex",
    justifyContent: "flex-end",
    fontSize: ".9em",
    fontWeight: 600,
    textAlign: "center",
    marginTop: 40,
    marginBottom: 4,
    "& span":{
      fontWeight: "normal",
    },
    "&> div": {
      "&:first-child":{
          marginRight: "auto"
        },
      "&:nth-child(2)": {
          marginRight: 5
        }
    }
  },
  soloInteresLb:{
    position: "absolute", left: 22, top: 62,
    // [theme.breakpoints.down('md')]: {
    //   top: 62
    // },
  },
  montoInput:{
    background: "#f3f3f3",
    padding: theme.spacing(2),
    textAlign: "center",
    marginBottom: theme.spacing(2),
    "& .MuiInput-root":{
      margin:"0px !important",
    },
    "& input": {
      fontSize: "1em",
      color:theme.palette.primary.main,
      textAlign: "center",
      background: theme.palette.common.white,

      "&:disabled":{
        background: theme.palette.grey[100]
      }
    }
  },
  alertText:{
    background:theme.palette.secondary.light,
    padding: "5px "+ theme.spacing(2),
    textAlign: "center",
    fontSize: ".9rem",
    color:theme.palette.secondary.contrastText,
  },
  dialogActions:{
    justifyContent: "space-between",
    background: "#f8f8f8",
  },
  totalApagar:{
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    "& span":{
      color:theme.palette.secondary.main
    }
  },
  authMask:{ position: "absolute", height: "calc(100% - 16px)", width: "calc(100% - 16px)", zIndex: 1, cursor: "pointer" }
}));
let initialState = {
  pagFormaPago:0,
  preId:0,
  pagMonto: 0,
  pagMora: 0,
  pagDescuento: 0,
  pagNota: "",
  pagTipo: TIPOPAGO.CUOTA_COMPLETA,
  pagFecha: "",
  cajId: "",
  cuotasAPagar: 1,
  soloInteres:false,
  cerrarCuota:false,
  autPin:""
};
function AddPago({prestamo,mostrarPrestamoById,defaultCajId}){
  let history = useHistory();
  const {state:hState={}} = useLocation();
  let {preId} = useParams();



  const classes = useStyles();
  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));

  const [solAuthModal, setSolAuthModal] = useState(false);

  const [state, setState] = useState(initialState);
  const [cuotas, setCuotas] = useState(1);

  const [isPending, setIsPending] = useState(false);

  const [error, setError] = useState(null);
  const [msj, setMsj] = useState(null);

  useEffect(()=>{
    //setear si es un pago pediente
    if(Object.keys(hState?.modalPago ?? {}).length){
      const {pagId,pagFecha,pagAdjunto,pagComentario} = hState.modalPago;
      setState(prev=>({...prev,pagId,pagFecha,pagAdjunto,pagNota:pagComentario}))
    }
  },[hState])

  //watch changes on pagMonto
  const validate = useCallback(() => {
    const inputPagMonto = document.getElementById("pagMonto");
    setMsj(null);
    if(!!inputPagMonto) inputPagMonto.setCustomValidity("");

    const {pagTipo,cuotasAPagar,pagMonto} = state;
    const {preCuotaInteres,preCapitalActual,preMontoCuota,cuotasAtrasadas} = prestamo

    let cuotasAPagarToInt = parseInt(cuotasAPagar) > 0 ? parseInt(cuotasAPagar) : 0;

    switch (pagTipo) {
      case TIPOPAGO.CUOTA_COMPLETA:
        if(cuotasAPagar === "cancelar"){
          let nC = cuotasAtrasadas>0?cuotasAtrasadas:1;
          if(Math.trunc(preCapitalActual + (nC*preCuotaInteres)) === Math.trunc(pagMonto)){
            setMsj(`${Strings.capitalActual} + ${Strings.interes} ${Strings.de} ${nC} ${Strings.cuotas}.`);
          }else {
            setMsj(`${Strings.capitalActual} + ${Strings.interes} ${Strings.de} ${Strings.cuotasRestantes}`);
          }
        }else if(pagMonto < preCuotaInteres * cuotasAPagarToInt){
          setMsj(Strings.montoMayorInteresMsj+" " +cuotasAPagarToInt+ " "+Strings.cuotas);
          if(!!inputPagMonto) inputPagMonto.setCustomValidity(Strings.montoMayorInteresMsj+" " +cuotasAPagarToInt+ " "+Strings.cuotas)
        }
        break;
      case TIPOPAGO.ABONO_CAPITAL:
        if(pagMonto === preCapitalActual){
          setMsj(Strings.pagoCancelaMsj);
        }else if(pagMonto > preCapitalActual){
          setMsj(Strings.abonoMenosCapitalMsj);
          if(!!inputPagMonto) inputPagMonto.setCustomValidity(Strings.abonoMenosCapitalMsj)
        }
        break;
      case TIPOPAGO.PAGO_PARCIAL:
        if(pagMonto > preMontoCuota){
          setMsj(Strings.parcialMenorCuota);
        }
        break;
      default:
        setMsj("");
        break
    }
  },[prestamo,state])
  const {pagMonto} = state;
  useEffect(()=>{
    validate()
  },[pagMonto,validate])

  useEffect(()=>{
    if (prestamo.preId) {
      let obj={
         preId:prestamo.preId,
         pagMora:prestamo.mora ? prestamo.mora : 0,
         pagMonto:prestamo.preMontoCuota,
         pagFecha:prestamo.currentDate
      };

      //setear la caja por defecto al pagar, si tiene permiso
      let dCajId = "";
      if (getUsuTipo() !== TIPOUSUARIO.agente){ //titutar o admin
        dCajId = defaultCajId || prestamo.cajId || ""
      }else{
          if(defaultCajId === -1 || defaultCajId === prestamo.cajId){
            dCajId = prestamo.cajId;
          }else{
            dCajId = defaultCajId
          }
      }

      setState(prevState => ({...prevState,cajId: dCajId, ...obj}));
    }
  },[prestamo, setState,defaultCajId]);

//watch changes on soloInteres and cuotas a pagar
  const {soloInteres} = state;
  const {preCapitalActual,preCuotaInteres,prePagoPendiente,cuotasAtrasadas} = prestamo;
  useEffect(()=>{
    let pagMonto = 0;
    //si es solo interes, solo multiplica el interes por el numero de cuotas
    if (soloInteres)
      return setState(prev => ({...prev, cuotasAPagar: cuotas.toString(), pagMonto: preCuotaInteres * parseFloat(cuotas)}));
    async function f() {
      switch (cuotas) {
        case "cancelar":
          setIsPending(true);
          await calCuotasPrestamo(preId, 0).then(({data}) =>pagMonto = data);
          setIsPending(false)
          break;
        case "pendiente":
          pagMonto = prePagoPendiente;
          break;
        case "capitalActual":
          pagMonto = preCapitalActual;
          break;
        case "interes":
          pagMonto = preCuotaInteres;
          break;
        default:
          if (parseInt(cuotas) > 0) {
            setIsPending(true);
            await calCuotasPrestamo(preId, parseInt(cuotas)).then(({data}) =>pagMonto = data);
            setIsPending(false);
          } else {
            pagMonto = 0;
          }
          break
      }
    }
    f().then(()=>{
      setState(prev => ({...prev, cuotasAPagar: cuotas.toString(), pagMonto}));
    })

  },[setIsPending,cuotas,soloInteres,setState,preCapitalActual,preCuotaInteres,preId,prePagoPendiente,cuotasAtrasadas]);

  const handleChange = e => {
    const {value,name, checked} = e.target;
    if(name === "soloInteres" || name === "cerrarCuota") return setState({...state,[name]:checked});
    if(name === "pagFecha") return setState({...state,[name]: value+""+prestamo.currentDate?.substring(10)});

    setState({...state,[name]:value});
  };
  const handleTabChange = (e,pagTipo) => {
    var obj = {...state, soloInteres:false,cerrarCuota:false, pagTipo};
    setState(obj);
    setCuotas(pagTipo === TIPOPAGO.CUOTA_COMPLETA ? "1":"0")
  };
  const submit = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let data = {...state};
    if (state.pagTipo === TIPOPAGO.PAGO_PARCIAL) {
      data.cuotasAPagar  = 0;
      if (state.pagMonto === prePagoPendiente || state.cerrarCuota) {
        data.cuotasAPagar = 1;
      }
    } else if (state.cuotasAPagar === "cancelar") {
      data.cuotasAPagar = 0;//(cuotasAtrasadas>0?cuotasAtrasadas:1);
      data.pagTipo = TIPOPAGO.SAlDAR;
    } else if (state.cuotasAPagar === "capitalActual") {
      data.cuotasAPagar = 0;
    }else if (state.pagTipo === TIPOPAGO.CUOTA_COMPLETA && state.soloInteres) {
      data.pagTipo = TIPOPAGO.SOLO_INTERES;
    } else if (state.pagTipo === TIPOPAGO.ABONO_CAPITAL ) {
      delete data.cuotasAPagar;
    }

    //no enviar adjunto si es el mismo
    if(hState?.modalPago.pagAdjunto === data.pagAdjunto){
      delete data.pagAdjunto
    }

    postPago(data)
    .then(({data})=>{
      setIsPending(false);
      mostrarPrestamoById(prestamo.preId);
      history.replace(history.location.pathname,{recibo:data})
    }).catch(({response})=>{
      setError(response.data);
      setIsPending(false)
    })
  };
  let select = [];
  switch (state.pagTipo) {
    case TIPOPAGO.ABONO_CAPITAL:
      select = [
        {key:"0", value:Strings.abonoCapital},
        {key:"capitalActual", value:Strings.capitalActual}];
      break;
    case TIPOPAGO.PAGO_PARCIAL:
      select = [
        {key:"0", value:Strings.parcial},
        {key:"interes", value:Strings.interes}];
      if(prestamo.prePagoPendiente>0)
      select.push({key:"pendiente", value:Strings.pendiente});
      break;
    default:
      // if(prestamo.cuotasAtrasadas > 0){
      //   for(let i = 1;i <= prestamo.cuotasAtrasadas;i++){
      //     select.push({key:i, value: i +" "+Strings.cuotas});
      //   }
      // }else{
        let cuotasRestante = 1;
        let spl = prestamo.prePlazoString?.split("/");
        if(spl?.length === 2){
          cuotasRestante = parseInt(spl[1]) - parseInt(spl[0]);
          //if(prestamo.cuotasAtrasadas > cuotasRestante) cuotasRestante = prestamo.cuotasAtrasadas;


        //  if(cuotasRestante > 10) cuotasRestante = 10;
          if(cuotasRestante <= 0) cuotasRestante = 1;
        }

        for(let i = 1;i <= cuotasRestante;i++){
          select.push({key:i, value: i +" "+Strings.cuotas+" "+(i <= prestamo.cuotasAtrasadas ? "("+Strings.pendiente+")":"" )});
        }

        if(prestamo.cuotasAtrasadas > cuotasRestante){
          for(let i = 1;i <= prestamo.cuotasAtrasadas - cuotasRestante;i++){
            select.push({key:select.length + 1, value: select.length + 1 +" "+Strings.cuotas});
          }
        }
      //}

      if(!state.soloInteres) select.push({key:"cancelar", value:Strings.cancelar});
      break
  }
  return (
      <Dialog className={classes.root} fullScreen={smVewport} open={true} onClose={history.goBack}>
        <TopBarDialog title={Strings.agregar+" "+Strings.pago} nofullScreen onClose={history.goBack}>
          {!!hState?.modalPago?.pagTotal && <Typography sx={{mr: 1}}>{formatPeso(hState?.modalPago.pagTotal)}</Typography>}
        </TopBarDialog>
        <Tabs variant={smVewport?"scrollable":"fullWidth"} value={state.pagTipo} onChange={handleTabChange} indicatorColor={"secondary"} className={classes.tabs}>
          <Tab value={TIPOPAGO.CUOTA_COMPLETA} label={Strings.cuotaCompleta} />
          <Tab value={TIPOPAGO.ABONO_CAPITAL} label={Strings.abonoCapital} />
          <Tab value={TIPOPAGO.PAGO_PARCIAL} label={Strings.pagoParcial} />
        </Tabs>
        <MostrarError errors={error}/>

        <DialogContent className={classes.content}>
          {isPending && <LoadingProgress vh={false} />}
          <Grid container spacing={3} style={{position:"relative"}}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Select
                    native
                        value={cuotas}
                        onChange={(e)=>setCuotas(e.target.value)}
                        opciones={select}
                        required
                >
                  {select.map((opt)=>
                      <option value={opt.key} key={opt.key}>{opt.value}</option>
                  )}
                </Select>
              </FormControl>


                {/*<SelectSmart*/}
                {/*    value={cuotas}*/}
                {/*    onChange={(e)=>setCuotas(e.target.value)}*/}
                {/*    opciones={select}*/}
                {/*    required*/}
                {/*    fullWidth*/}
                {/*/>*/}
              {(state.pagTipo === TIPOPAGO.CUOTA_COMPLETA
                  && state.cuotasAPagar !== 'cancelar'
                  && prestamo.preTipoAmortizacion !== 4) &&
                <FormControlLabel className={classes.soloInteresLb}
                    control={
                      <Checkbox
                          name="soloInteres"
                          checked={state.soloInteres}
                          onChange={handleChange}

                      />
                    }
                    label={Strings.soloInteres}
                />
              }

              {state.pagTipo === TIPOPAGO.PAGO_PARCIAL && hasPermissionTo(PERSMISOS.pagosPermitirPagoIncompleto)  &&
                    <FormControlLabel className={classes.soloInteresLb}
                              control={
                                <Checkbox
                                    name="cerrarCuota"
                                    checked={state.cerrarCuota || (state.pagMonto>0 && state.pagMonto === prePagoPendiente)}
                                    onChange={handleChange}

                                />
                              }
                              label={Strings.cerrar+" "+Strings.cuota}
                    />
              }
            </Grid>
            <Grid item xs={6}>
              <DateInput
                  name="pagFecha"
                  value={state.pagFecha}
                  margin={"none"}
                  onChange={handleChange}
                  disabled={!hasPermissionTo(PERSMISOS.pagosCambiarFecha)}
              />
            </Grid>
          </Grid>
          <div className={classes.cuotaInteres}>
            <div>
              {prestamo.prePagoPendiente > 0 &&
                 <>{Strings.pendiente}: <span>{formatPeso(prestamo.prePagoPendiente)}</span></>
              }
            </div>
            <div>
              {Strings.cuota}: <span>{formatPeso(prestamo.preMontoCuota)}</span></div>
            <div>
              {Strings.interes}: <span>{formatPeso(prestamo.preCuotaInteres)}</span></div>
          </div>
          <form onSubmit={submit} id={"formMonto"}>
            <div className={classes.montoInput}>
              <TextFieldCurrency id="pagMonto" fullWidth name="pagMonto" min={1} value={state.pagMonto}
                                 disabled={soloInteres ||
                                 ["interes","capitalActual","cancelar","pendiente"].includes(state.cuotasAPagar)}
                                 onChange={handleChange}/>
            </div>
          </form>
          <Grid container spacing={3}>
            <Grid item xs={6} sx={{ position: "relative"}}>
              {!hasPermissionTo(PERSMISOS.pagosModificarMora) && !state.autPin &&
                  <div className={classes.authMask} onClick={()=>setSolAuthModal(true)}/>
              }
                <TextFieldCurrency
                    disabled={!hasPermissionTo(PERSMISOS.pagosModificarMora) && !state.autPin }
                    label={Strings.mora}
                    name="pagMora"
                    value={state.pagMora}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={6} sx={{ position: "relative"}}>
              {!hasPermissionTo(PERSMISOS.pagosDescuento) && !state.autPin &&
                  <div className={classes.authMask} onClick={()=>setSolAuthModal(true)}/>
              }
              <TextFieldCurrency disabled={!hasPermissionTo(PERSMISOS.pagosDescuento) && !state.autPin }
                  label={Strings.descuento} name="pagDescuento" value={state.pagDescuento} onChange={handleChange} fullWidth/>
            </Grid>
            {solAuthModal &&
            <Grid item xs={12}>
              <TextField label={Strings.autorizacion} placeholder={"ID"} name="autPin" value={state.autPin} onChange={handleChange} fullWidth/>
            </Grid>}
            <Grid item xs={6}>
              <div className="form-group">
                <SelectEnum label={Strings.formaPago} group="formaPago" name="pagFormaPago" required value={state.pagFormaPago} onChange={handleChange}/>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <SelectCaja label={Strings.caja} name="cajId" value={state.cajId === -1 ? "": state.cajId} onChange={handleChange}/>
              </div>
            </Grid>
          </Grid>
          <div className="form-group">
            <TextField label={Strings.comentario} name="pagNota" value={state.pagNota}
                       onChange={handleChange}
                       inputProps={{maxLength: 256}}
                       margin="normal" fullWidth/>
          </div>
          <Grid item xs={12} >
            <SelectFiles showImg={false} src={state.pagAdjunto??null} onChange={(baseb4)=>setState({...state, pagAdjunto: baseb4})} defaultImgProp={defaultImg}/>
          </Grid>
        </DialogContent>
        {/*{state.pagMonto >= prestamo.preCapitalActual &&*/}
        {/*  <div className={classes.alertText}>{Strings.pagoCancelaMsj}</div>*/}
        {/*}*/}


        {!isPending && !!msj &&
            <div className={classes.alertText}>{msj}</div>
        }
        <DialogActions className={classes.dialogActions}>
          <HtmlTooltip title={Strings.totalApagar} >
            <div className={classes.totalApagar}>
              {Strings.total}: <span> {formatPeso(parseFloat(state.pagMonto || 0) +
                  parseFloat(state.pagMora || 0) -
                  parseFloat(state.pagDescuento || 0))}</span>
            </div>
          </HtmlTooltip>
          <Button color="primary" type={"submit"} form={"formMonto"} disabled={isPending}>{Strings.agregar} {Strings.pago}</Button>
        </DialogActions>
      </Dialog>
  );
}
const mapStateToprops = (state) => ({
  prestamo:state.prestamos.byId,
  defaultCajId: state.auth.user.cajId || "",
  rolId: state.auth.user.rolId || "",
});
export default connect(mapStateToprops,{mostrarPrestamoById})(AddPago);
