import {gastoTypes} from './types';
import {getGastos, delGasto, addGasto} from '../../services';
import history from "../../helpers/history";

export function mostrarGastos(filter) {
    return (dispatch,getState) => {
        const gastos = getState().gastos;
        let paramsFilter = filter || gastos.filtered;
        dispatch({
            type: gastoTypes.MOSTRAR_GASTOS,
            payload: getGastos(paramsFilter)
        })
    }
}
export function agregarGasto(obj) {
    return dispatch => {
        dispatch({
            type: gastoTypes.AGREGAR_GASTO,
            payload: addGasto(obj)
        }).then(()=>{
            history.goBack();
            dispatch({
                type: gastoTypes.MOSTRAR_GASTOS,
                payload: getGastos()
            })
        }).catch(()=>{
            setTimeout(() => {
                dispatch({
                    type: "resetGastosErrors",
                })
            }, 5000);
        })
    }
}
export function eliminarGasto(id) {
    return (dispatch) => {
        dispatch({
            type: gastoTypes.ELIMINAR_GASTO,
            payload: delGasto(id)
        }).then(()=>{
            dispatch({
                type: gastoTypes.MOSTRAR_GASTOS,
                payload: getGastos()
            })
        })
    }
}

