import { cajaTypes } from './types';
import {getCajas, addCaja, editCaja, delCaja, transferBetweenCajas, closeCaja} from '../../services';
import _ from 'lodash';
import history from "../../helpers/history"
export function mostrarCajas(name) {
    return dispatch => {
        dispatch({
            type: cajaTypes.MOSTRAR_CAJAS,
            payload: getCajas(name)
        })
    }
}
export function mostrarCajaById(id) {
    return dispatch => {
        dispatch({
            type: cajaTypes.MOSTRAR_CAJAS_POR_ID,
            payload: getCajas(parseInt(id))
        })
    }
}
export function mostrarCajasSinoEsta() { //Object.keys(cajas.list).length ||
    return (dispatch, getState) => {
        const cajas = getState().cajas;
        if (cajas.isFetching) return false;
        dispatch({
            type: cajaTypes.MOSTRAR_CAJAS,
            payload: getCajas()
        })
    }
}
export function eliminarCaja(id) {
    return dispatch => {
        dispatch({
            type: cajaTypes.ELIMINAR_CAJA,
            payload: delCaja(id)
        }).then(()=>history.goBack())
    }
}
export function agregarEditarCaja(obj) {
    let cleanObj = _.omitBy(obj, (v) => !v);
    return dispatch => {
        const response = dispatch({
            type: cajaTypes.AGREGAR_EDITAR_CAJA,
            payload: cleanObj.cajId ? editCaja(cleanObj) : addCaja(cleanObj)
        });
        response.then(() => {
            history.goBack();
            //traer detalles completo
            if(obj.cajId){
                dispatch({
                    type: cajaTypes.MOSTRAR_CAJAS_POR_ID,
                    payload: getCajas(parseInt(obj.cajId))
                })
            }
        })
    }
}
export function cerrarCaja(obj) {
    return dispatch => {
        const response =  dispatch({
            type: cajaTypes.CERRAR_CAJA,
            payload: closeCaja(obj)
        });
        response.then(() => {
            history.goBack();
            dispatch({
                type: cajaTypes.MOSTRAR_CAJAS_POR_ID,
                payload: getCajas(parseInt(obj.cajId))
            })
        })
    }
}
export function transferirEntreCajas(obj) {
    return dispatch => {
        dispatch({
            type: cajaTypes.TRANSFERENCIA_CAJA,
            payload: transferBetweenCajas(obj)
        }).then(() => {
            history.goBack();
            dispatch({
                type: cajaTypes.MOSTRAR_CAJAS,
                payload: getCajas()
            })
        })
    }
}
