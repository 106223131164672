import {prestamoTypes, _FULFILLED, _PENDING, _REJECTED, clienteTypes} from '../actions/types';
const initialState = {
    list: {},
    filtered: {},
    byId: {},
    cxc:[],
    isFetching: false,
    isPending: false,
    error: null,
    showModalPago: false
}

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case prestamoTypes.MOSTRAR_PRESTAMOS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                byId: {},
                error: {}
            }
        }
        case prestamoTypes.MOSTRAR_PRESTAMOS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                filtered: action.payload?.config?.params ?? {},
                isFetching: false,
                error: null
            }
        }
        case prestamoTypes.MOSTRAR_PRESTAMOS + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isFetching: false,
                error: { data, status }
            }
        }

        //cuentas por cobrar
        case prestamoTypes.MOSTRAR_CxC + _FULFILLED: {
            return {
                ...state,
                cxc: action.payload.data,
            }
        }
        //mostrar por id
        case prestamoTypes.MOSTRAR_PRESTAMO_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case prestamoTypes.MOSTRAR_PRESTAMO_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
            }
        }
        case prestamoTypes.MOSTRAR_PRESTAMO_POR_ID + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }

        case prestamoTypes.MOSTRAR_PRESTAMO_POR_ID_ENCONTRADO:  {
            const  byId  = action.payload;
            return {
                ...state,
                isFetching:true,
                byId
            }
        }

        //agregar  o editar
        case prestamoTypes.AGREGAR_EDITAR_PRESTAMO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case prestamoTypes.AGREGAR_EDITAR_PRESTAMO + _FULFILLED: {
            const { data } = action.payload;
            return {
                ...state,
                isPending: false,
                byId:{data},
            }
        }
        case prestamoTypes.AGREGAR_EDITAR_PRESTAMO + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //agregar  nota
        case prestamoTypes.AGREGAR_PRE_NOTA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case prestamoTypes.AGREGAR_PRE_NOTA + _FULFILLED: {
            const { data } = action.payload;
            const notas = state.byId.notas || [];
            notas.push(data);
            return {
                ...state,
                byId: {...state.byId, notas},
                isPending: false,
            }
        }
        case prestamoTypes.AGREGAR_PRE_NOTA + _REJECTED: {
            return {
                ...state,
                isPending: false,
            }
        }
        //eliminar nota
        case prestamoTypes.ELIMINAR_PRE_NOTA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case prestamoTypes.ELIMINAR_PRE_NOTA + _FULFILLED: {
            const { data } = action.payload;
            const notas = state.byId.notas.filter(n=>n.prnId !== data)
            return {
                ...state,
                byId: {...state.byId, notas},
                isPending: false,
            }
        }
        case prestamoTypes.ELIMINAR_PRE_NOTA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }

        //Cancelar
        case prestamoTypes.CANCELAR_PRESTAMO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case prestamoTypes.CANCELAR_PRESTAMO + _FULFILLED: {
            const { data } = action.payload;
            return {
                ...state,
                isPending: false,
                byId:{data},
            }
        }
        case prestamoTypes.CANCELAR_PRESTAMO + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //agregar pago
        case prestamoTypes.AGREGAR_PAGO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case prestamoTypes.AGREGAR_PAGO + _FULFILLED: {
            return {
                ...state,
                isPending: false,
            }
        }
        case prestamoTypes.AGREGAR_PAGO + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //cuando se edita la calificacion de un cliente
        case clienteTypes.EDIT_CLIENTE_RATING + _FULFILLED: {
            const { data } = action.payload;

            //si se actualiza desde un prestamo
            if(state.byId.cliId !== data.cliId) return  {...state};

            let byId = {...state.byId,...data};
            return {...state, byId,}
        }
        //marcar imcobrable
        case prestamoTypes.MARCAR_PRESTAMOS_IMCOBRABLE + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case prestamoTypes.MARCAR_PRESTAMOS_IMCOBRABLE + _FULFILLED: {
            const { data } = action.payload;

            return {
                ...state,
                byId: {...state.byId, preEstado: data?.estado??""},
                isPending: false,
            }
        }
        case prestamoTypes.MARCAR_PRESTAMOS_IMCOBRABLE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        default:
      return state;
  }
}
