import React, {useEffect} from 'react';
import {useSelector,useDispatch} from "react-redux";
import {mostrarGeneralesSinoEsta} from "../redux/actions/generalesActions";

export function useCustomFormInput(prefix="cli") {
    const generales = useSelector(state => state.generales)
    const dispatch = useDispatch();
    const [genConfCampos, setGenConfCampos] = React.useState(null);

    useEffect(()=>{
        dispatch(mostrarGeneralesSinoEsta());
    })
    useEffect(()=>{
        let items = {...(generales?.data?.genConfCampos??{})};
        if(items){
            Object.keys(items).forEach(key=>{
                if(key.indexOf(prefix) !== 0 || items[key] == null){
                    delete items[key];
                }
            })
        }
        setGenConfCampos(items);
    },[generales,prefix])

    return genConfCampos;
}
