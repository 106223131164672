import errorToNotifObj from "./errorToNotif"
import Swal from 'sweetalert2'
import moment from 'moment';
import "moment/min/locales.min"
import {PERSMISOS,PERSMISOS_USUARIOS,TIPOPLAN,TIPOUSUARIO,TIPOPAGO,mapApiOptions} from "./CONSTANTES"
import {getIdToken, getTokenInfo} from "../services/auth";
import geoString from "./geoString"
import Strings from "../assets/strings";
import {isMobile} from "./browser_detect";
import _ from "lodash";
import jsPDF from "jspdf";


//var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
//var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;


export function getLSLang(tipo) {
    const langLS = localStorage.getItem('lang');
    const browserLang = navigator.language.split('-')[0];
    let defaultLang = "es";
    let defaultCountry = "";

    //si el idioma del navegador es soportado sera el idioma por defecto
    if(Strings.getAvailableLanguages().includes(browserLang)) defaultLang = browserLang;
    //valor por defecto por si no esta guardado
    var response = tipo?(tipo === "l" ? defaultLang :defaultCountry):defaultLang+"-"+defaultCountry;


    if(langLS && langLS.split("-").length === 2){
        switch (tipo) {
            case "l":
                response = langLS.split("-")[0];
              break;
            case "c":
                response = langLS.split("-")[1];
                break;
            default:
                response = langLS;
                break;
        }
    }else{
        //si no esta setearlo
        localStorage.setItem("lang",defaultLang+"-"+defaultCountry)
    }
    return response;
}
moment.locale(getLSLang("l"));
export function setLSLang(language,country) {
    const langLS = localStorage.getItem('lang');
    let lang = "es";
    let count = "";

    //set language
    if(language) {
        lang = language;
    }else if(langLS && langLS.split("-").length === 2){
        lang = langLS.split("-")[0];
    }
    //set country
    if(country) {
        count = country;
    }else if(language && langLS && langLS.split("-").length === 2){
        count = langLS.split("-")[1];
    }

    localStorage.setItem("lang", lang+"-"+count)
}
export function formatDate(date, format="DD/MM/YYYY") { //h:mm a
    moment.locale(getLSLang("l"));
    if(!date)
        return "";

   return moment(date).format(format);

    // format = "DD/MM/YYYY";
    //
    // let fecha =  moment(date).calendar(null,{
    //     // lastDay : '[Yesterday]',
    //     // sameDay : '[Hoy]',
    //     // nextDay : '[Tomorrow]',
    //     lastWeek : format,
    //     nextWeek : format,
    //     sameElse : format});
    //
    // fecha = fecha.includes("a la") || fecha.includes("at") ? fecha.split(" ")[0] : fecha;
    //
    // return fecha?.charAt(0).toUpperCase() + fecha.slice(1)


}
export function sumarQuincena(date,restar) {
    let fecha = moment(date);
    if(!fecha.isValid()) return null;

    let ultimoDiaMes = fecha.daysInMonth();

    if (fecha.date() !== 15 && fecha.date() !== ultimoDiaMes){
        //si la fecha no es correcta retorna la siguiente fecha correcta
        if(fecha.date() > 15)
            fecha.set("date",ultimoDiaMes);
        else
            fecha.set("date",15);

        return new Date(fecha.year(), fecha.month(), fecha.date());
    }
    if (fecha.date() === 15) {
        if(restar){
            let prevMonth = fecha.add(-1,"month")
            return new Date(fecha.year(), prevMonth.month(), prevMonth.daysInMonth())
        }else{
            return new Date(fecha.year(), fecha.month(), ultimoDiaMes)
        }
    }else{
        if(restar){
            return new Date(fecha.year(), fecha.month(), 15)
        }else{
            let nextFecha = fecha.add(15,"days");
            return new Date(nextFecha.year(), nextFecha.month(), nextFecha.date())
        }
    }
}
export {moment};
export function formatPeso(val,maxFraction = 2) {
    let value = val ? parseFloat(val): 0;
    let countryCode = "";
    const lSCountryCode = getLSLang("c");
    if (lSCountryCode) countryCode = lSCountryCode;

    const paises = [
        {paiCodigo: "DO",paiModeneda: "DOP", paiIdioma:"es"},
        {paiCodigo: "US",paiModeneda: "USA", paiIdioma:"en"},
        {paiCodigo: "ES",paiModeneda: "EUR", paiIdioma:"es"},
        {paiCodigo: "BO",paiModeneda: "BOB", paiIdioma:"es"},
        {paiCodigo: "CO",paiModeneda: "COP", paiIdioma:"es"},
        {paiCodigo: "CR",paiModeneda: "CRC", paiIdioma:"es"},
        {paiCodigo: "PY",paiModeneda: "PYG", paiIdioma:"es"},
        {paiCodigo: "GT",paiModeneda: "GTQ", paiIdioma:"es"},
        {paiCodigo: "VE",paiModeneda: "VES", paiIdioma:"es"},
        {paiCodigo: "PE",paiModeneda: "PEN", paiIdioma:"es"},
        {paiCodigo: "HN",paiModeneda: "HNL", paiIdioma:"es"},
        {paiCodigo: "NI",paiModeneda: "NIO", paiIdioma:"es"},
        {paiCodigo: "PA",paiModeneda: "PAB", paiIdioma:"es"},
        {paiCodigo: "MX",paiModeneda: "MXN", paiIdioma:"es"},
        {paiCodigo: "AR",paiModeneda: "ARS", paiIdioma:"es"},
        {paiCodigo: "CL",paiModeneda: "CLP", paiIdioma:"es"},
        {paiCodigo: "UY",paiModeneda: "UYU", paiIdioma:"es"}
    ];
    let monedaDisponible = paises.find(p=>p.paiCodigo === countryCode.toUpperCase());
    //si no se encuentra la moneda poner una universal
    if(!monedaDisponible){
        return '$' + parseFloat(value)
            .toFixed(maxFraction)
            .replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    }

    //  if (countryCode && typeof Intl == 'object' && typeof Intl.NumberFormat == 'function' && monedaDisponible){
        return value.toLocaleString(monedaDisponible.paiIdioma+"-"+monedaDisponible.paiCodigo, {
            style: 'currency',
            currency: monedaDisponible.paiModeneda,
            minimumFractionDigits: 0,
            maximumFractionDigits: maxFraction,
        }).replace("DOP","$");
   // }


}
export const alertNote = msj => ()=>{
    Swal.fire(msj, {
        buttons: false,
        className: "alertNote",
    });
};
export function parseLocaleNumber(stringNumber) {
    var thousandSeparator = ".";//formatPeso(0,true).group;
    var decimalSeparator = ",";//formatPeso(0,true).decimal;



    // // value.replace(/,/g,"")
    // console.log(stringNumber)
    // return  stringNumber
    let value =  stringNumber.replace(new RegExp('\\$'  , 'g'), '');
    if(thousandSeparator === "," && decimalSeparator === "."){
        value = value.replace(/,/g,"")
    }else if(thousandSeparator === "." && decimalSeparator === ","){
        value = value.replace(/,/g,".");
        value = value.replace(".",",")
    }
    return value
    // return parseFloat(stringNumber
    //     .replace(new RegExp('\\$'  , 'g'), '')
    //     .replace(new RegExp('\\' + thousandSeparator , 'g'), '')
    //     .replace(new RegExp('\\' + decimalSeparator), '.')
    // );
}
export function removeEmptyValues(obj) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
      if (obj[prop]) { newObj[prop] = obj[prop]; }
    });
    return newObj;
}
export function nullToEmptyString(obj,noCero) {
    let newObj = {};
    Object.keys(obj).forEach((prop) => {
        newObj[prop] = obj[prop] == null || (noCero && obj[prop] === 0)  ? obj[prop] = "" : obj[prop];
    });
    return newObj;
}
export function getMinMaxDate(dates=[], obj){
    let datesArray = dates.map(d=> new Date(d.fecha));

    if(datesArray.length <= 0) return "";

    //fecha
    let minDate = Math.min.apply(null,datesArray);
    let maxDate = Math.max.apply(null,datesArray);
    if (obj)
        return {minDate,maxDate};

    return  formatDate(minDate)+" - "+formatDate(maxDate);

}
export function getEstado(cuotasAtrasadas,diasAtraso,diasParaMora){
    if(cuotasAtrasadas > 0){
        return "Cutas Atrasadas";
    }else if(diasAtraso >= diasParaMora ){
        return "Mora Generada";
    }else if(diasAtraso < diasParaMora && diasAtraso >=0){
        return "Pendiente"
    }
}
export const isEmpty = (value) => {
    return (
        value === undefined ||
        value === null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
}
// export function imprimirPago(pagId){
//     // var isWebView = true;
//     // try {
//     //     Android.isWebView();
//     // } catch (e) {
//     //     isWebView = false;
//     // }
//     // if (isWebView) {
//     //     //Mandar datos para imprimir desde Movil (WebView).
//     //     $.ajax({
//     //         url: "/Pagos/GetPagoJSON",
//     //         data: { "PreId": preid, "PagId": pagid },
//     //         success: function (res) {
//     //             console.log("Datos Cargados Correctamente.");
//     //             Android.sendData(res);
//     //         }
//     //     });
//     // } else {
//         var url = "/Recibo/"+pagId;
//         var width = 1061;
//         var height = 850;
//         var my_window;
//         var center_left = (window.screen.width / 2) - (width / 2);
//         var center_top = (window.screen.height / 2) - (height / 2);
//
//         my_window = window.open(url, "Title", "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=1, width=" + width + ", height=" + height + ", left=" + center_left + ", top=" + center_top);
//         my_window.focus()
//   //  }
// }
export function noTilde(input) {
    var r = input.toLowerCase();
    r = r.replace(new RegExp(/\s/g), "");
    r = r.replace(new RegExp(/[àáâãäå]/g), "a");
    r = r.replace(new RegExp(/æ/g), "ae");
    r = r.replace(new RegExp(/ç/g), "c");
    r = r.replace(new RegExp(/[èéêë]/g), "e");
    r = r.replace(new RegExp(/[ìíîï]/g), "i");
    r = r.replace(new RegExp(/ñ/g), "n");
    r = r.replace(new RegExp(/[òóôõö]/g), "o");
    r = r.replace(new RegExp(/œ/g), "oe");
    r = r.replace(new RegExp(/[ùúûü]/g), "u");
    r = r.replace(new RegExp(/[ýÿ]/g), "y");
    r = r.replace(new RegExp(/\W/g), "");
    return r;
}


function PMT(capital,interes,plazo){
    var rate = interes / 100;
    var denominator = Math.pow((1 + rate), plazo) - 1;
    return (rate + (rate / denominator)) * capital;
}
export function buildAmort(monto,intere,cuotas,ciclosPago,tipoAmortizacian,fecha){
    const propCiclosPago = parseInt(ciclosPago);
    let prestamo = {
        fecha: moment(fecha),
        capitalPrestamo: parseFloat(monto),
        capitalActual: 0,
        interes: parseFloat(intere),
        ciclosPago: propCiclosPago === 16 ? 15: propCiclosPago,
        plazo: parseInt(cuotas),
        tipoAmortizacion: parseInt(tipoAmortizacian),
    };
    ///quitar una cuota ya que se envia la fecha del primer pago
    if(propCiclosPago === 30){ //si es mensual agrega un mes
        prestamo.fecha = prestamo.fecha.add(-1, 'month');
    }else if(propCiclosPago === 16){
        prestamo.fecha = moment(sumarQuincena(prestamo.fecha.format("YYYY-MM-DD"),true));
    }else{
        prestamo.fecha = prestamo.fecha.add(-prestamo.ciclosPago, 'days');
    }

    let capitalApagar;
    let interes;
    let pago;

    let balanceRestante = prestamo.capitalPrestamo;
    //var cuotas = this.cuotas();

    let amortizacion = [];

    let fechaCuota = prestamo.fecha;

    for (let i = 1; i <= prestamo.plazo; i++)
    {
        if(propCiclosPago === 30){ //si es mensual agrega un mes
            fechaCuota = fechaCuota.add(1, 'month');
        }else if(propCiclosPago === 16){
            fechaCuota = moment(sumarQuincena(fechaCuota.format("YYYY-MM-DD")));
        }else{
            fechaCuota = fechaCuota.add(prestamo.ciclosPago, 'days');
        }

        switch (prestamo.tipoAmortizacion) {
            case 2: //disminuis cuotas
                capitalApagar = prestamo.capitalPrestamo / prestamo.plazo;
                interes = balanceRestante * prestamo.interes / 100;
                pago = capitalApagar + interes;
                break
            case 3://Interés fijo
                capitalApagar = prestamo.capitalPrestamo / prestamo.plazo;
                interes = prestamo.capitalPrestamo * prestamo.interes / 100;
                pago = capitalApagar + interes;
                break
            case 4://capital al final
                capitalApagar = i === parseInt(prestamo.plazo) ? prestamo.capitalPrestamo : 0; // CapitalPrestamo / model.Plazo;
                interes = prestamo.capitalPrestamo * prestamo.interes / 100;
                pago = capitalApagar + interes;
                break
            default://Cuotas Fijas
                interes = balanceRestante * prestamo.interes / 100;
                pago = PMT(prestamo.capitalPrestamo, prestamo.interes, prestamo.plazo);
                pago = (pago * 100) / 100;
                capitalApagar = pago - interes;
        }

        amortizacion.push({
            cuota: i,
            fecha:fechaCuota.format("DD/MM/YYYY"),
            balanceRestante: balanceRestante,
            capitalApagar: capitalApagar,
            interes: interes,
            totalaPagar: pago,
            // fecha = (prestamo.Fecha).AddDays((int)prestamo.ModalidadPago * i)
        });

        balanceRestante = balanceRestante - capitalApagar;
    }

    return amortizacion;
}




export  function  getInteresAmortFrances( montoCuota,  cuotas,  capitalInicial) {
    var time = null;
    var tasa = 0.100000000;
    var res;
    // Task task = Task.Run(() =>
    ///   {

    return new Promise((resolve,reject) => {
        clearTimeout(time);
        time = setTimeout(() => reject("Esta operación tardó demaciado tiempo!."), 1000);
        var denominador = 1 - Math.pow(1 + tasa, cuotas * -1);
        res = montoCuota * (denominador / tasa);
        if (res < capitalInicial)
        {
            while (res <= capitalInicial)
            {
                denominador = 1 - Math.pow(1 + tasa, cuotas * -1);
                res = montoCuota * (denominador / tasa);
                tasa -= 0.000000001;
            }
        }
        else
        {
            while (res >= capitalInicial)
            {
                denominador = 1 - Math.pow(1 + tasa, cuotas * -1);
                res = montoCuota * (denominador / tasa);
                tasa += 0.000000001;
            }
        }

        let porciento = tasa * 100;
        if(porciento.toString().length > 6)
            resolve(porciento.toFixed(4))

        resolve(porciento);


    });

    //TimeSpan ts = TimeSpan.FromSeconds(1);
    //if (!task.Wait(ts))
    //    throw new Exception("Esta operación tardó demaciado tiempo!.");

    //  return Task.FromResult<double>(Math.Round(tasa * 100, 4));

}
export function getRestriccionesList() {
    const {Restricciones} = getTokenInfo();
    let restriccionesList = [];
    if(Restricciones && Restricciones.length > 1){
        try {
            restriccionesList = Restricciones.split(",").map(r=>parseInt(r));
        }catch (e) {
            throw e;
        }
    }
    return restriccionesList
}
export function hasPermissionTo(idperId) {
    return getRestriccionesList().indexOf(idperId) === -1;
}
export function getUsuTipo() {
    const {UsuTipo} = getTokenInfo();
    return parseInt(UsuTipo);
}
export function checkDateInput() {
    let input = document.createElement('input');
    input.setAttribute('type','date');

    let notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);

    return (input.value !== notADateValue);
}

export function openNewWin(link,params){
    let filtro = _.omitBy(params, (v) => v === '' || _.isNil(v));
    let qr;

    switch (typeof params){
        case "object":
            qr = new URLSearchParams(filtro).toString();
            break
        case "string":
            qr = params;
            break
        default:
            qr = undefined;
    }
    let url = link+(qr ? "?"+qr : "")
    if(isMobile.anyWebView()){
       url += (qr ? "&":"?")+"t="+getIdToken()+"&l="+getLSLang();
    }

    let width = 1061;
    let height = 850;
    let my_window;
    let center_left = (window.screen.width / 2) - (width / 2);
    let center_top = (window.screen.height / 2) - (height / 2);

    my_window = window.open(url, "Title", "directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=1, width=" + width + ", height=" + height + ", left=" + center_left + ", top=" + center_top);
    my_window.focus();
    //  }
}
export function urlPramsToObj(querystring){
    // parse query string
    const params = new URLSearchParams(querystring);

    let obj = {};

    // iterate over all keys
    for (const key of params.keys()) {
        if (params.getAll(key).length > 1) {
            obj[key] = params.getAll(key);
        } else {
            obj[key] = params.get(key);
        }
    }

    return obj;
}
const textToPDF= (content,size)=>{

    let docWidth = 78.063 + size//136.063;
    let docHeight = 870;

    let doc = new jsPDF('p', 'pt', [docWidth,docHeight]);
    let splitText = "";
    let yPosition = 10;
    doc.setFont("Helvetica");
    doc.setFontSize(8)
    splitText = doc.splitTextToSize(htmltoText(content), docWidth);
    doc.text(splitText,0,yPosition,{align:"justify"})

    doc.autoPrint();
    doc.output("dataurlnewwindow",{filename:"Contrato"});
}
const htmltoText = (html)=>{
    //remove code brakes and tabs
    html = html.replace(/\n/g, "");
    html = html.replace(/\t/g, "");

    //keep html brakes and tabs
    html = html.replace(/<\/td>/g, "\t");
    html = html.replace(/<\/table>/g, "\n");
    html = html.replace(/<\/tr>/g, "\n");
    html = html.replace(/<\/p>/g, "\n");
    html = html.replace(/<\/div>/g, "\n");
    html = html.replace(/<\/h>/g, "\n");
    html = html.replace(/<br>/g, "\n"); html = html.replace(/<br( )*\/>/g, "\n");

    //parse html into text
    var dom = (new DOMParser()).parseFromString('<!doctype html><body>' + html, 'text/html');
    return dom.body.textContent;
};
export {errorToNotifObj,PERSMISOS,PERSMISOS_USUARIOS,TIPOUSUARIO,TIPOPAGO,TIPOPLAN,mapApiOptions,geoString,textToPDF,htmltoText}
