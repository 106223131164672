import React,{Fragment} from 'react';
import PropTypes from 'prop-types';
import {formatPeso,formatDate} from '../helpers';
import Strings from "../assets/strings";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableContainer,
  Typography,
} from '@mui/material';

import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  root: {
    // width: '100%',
    // overflowX: 'auto',
  },
  barTitle:{
    padding: "6px 12px",
    background: theme.palette.grey[100],
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    "& .total":{
      fontSize: 14,
      color: "rgba(0, 0, 0, 0.87)",
      display: "flex",
      alignItems: "center",
      "& div:first-child":{
        marginRight: 10
      },
      "& div:last-child":{
        textAlign: "right"
      },
      "& span":{
        color: theme.palette.secondary.main,
      },
    },
    [theme.breakpoints.down(undefined)]: {
      flexDirection: "column",
      alignItems: "center",
      "& .total":{
        marginTop:5,
      }
    }
  },
});


function ListAmortizacion(props) {
  const { classes, data = [],noHead } = props;
  //----
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //---
  const totalInteres = data.reduce((a, b) => +a + +b.interes, 0);
  const totalPago = data.reduce((a, b) => +a + +b.total, 0);
  return (
    <Fragment>
      {!noHead &&
          <div className={classes.barTitle}>
            <Typography color={"secondary"}>{Strings.amortizacion}</Typography>
            <div className="total">
              <div>
                <span >{Strings.total} {Strings.interes}: </span> <i>{formatPeso(totalInteres)}</i>
              </div>
              <div>
                <span>{Strings.totalApagar}: </span> <i>{formatPeso(totalPago)}</i>
              </div>
            </div>
          </div>
      }
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No - {Strings.fecha}</TableCell>
              <TableCell>{Strings.balance}</TableCell>
              <TableCell>{Strings.capital}</TableCell>
              <TableCell>{Strings.interes}</TableCell>
              <TableCell>{Strings.cuota}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n =>
                <TableRow key={n.cuota}>
                  <TableCell>{n.cuota+" - "+formatDate(n.fecha)}</TableCell>
                  <TableCell>{formatPeso(n.balance)}</TableCell>
                  <TableCell>{formatPeso(n.abonoCapital)}</TableCell>
                  <TableCell>{formatPeso(n.interes)}</TableCell>
                  <TableCell>{formatPeso(n.total)}</TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length > 10 &&
        <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            SelectProps={{
              inputProps: { 'aria-label': 'No. registros' },
              native: true,
            }}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </Fragment>
  );
}

ListAmortizacion.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

export default withStyles(styles)(ListAmortizacion);
