import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarPlantillas } from '../../redux/actions/plantillasActions';
import Strings from "../../assets/strings";


function SelectPlantilla({tipo, plantillas, mostrarPlantillas,...props}) {

    useEffect(() => {
            mostrarPlantillas({tipo:tipo,mostrarDelSistema:true})
    }, [mostrarPlantillas,tipo]);

    let options = plantillas.list ?? [];

    return (
        <select style={{width: 111}}
               {...props}
        >
            <option value={"2"}>{Strings.seleccione}</option>
            {options?.filter(o=>!o.registroDelSistema).map(opc=>
                <option value={opc.plaId} key={opc.plaId}>{opc.plaNombre}</option>
            )}
            <optgroup label={Strings.registrosDelSistema}>
                {options?.filter(o=>o.registroDelSistema).map(opc=>
                    <option value={opc.plaId} key={opc.plaId}>{opc.plaNombre}</option>
                )}
            </optgroup>
        </select>
    )
}

const mapStateToProps = (state) => ({
    plantillas: state.plantillas,
})
export default connect(mapStateToProps, { mostrarPlantillas })(SelectPlantilla);
