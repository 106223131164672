import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {mostrarClientes,mostrarClienteById} from "../../redux/actions/clientesActions";
import {connect} from "react-redux";

import {
  Grid,
  FormControl,
  List,
  useMediaQuery,
  Dialog,
  DialogContent,
  InputLabel,
  DialogActions,
  Button,
  Input,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Box,
  Avatar,
  Badge,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ClientCard, ClientListItem, AppBar, SeacrhAppBar, PlusFab, ListViewMode, LoadingProgress, Pagination, TableSort,
  ButtonResponsive, FilteredAlert, MostrarError
} from '../../components';
import {SelectSmart} from "../../components/CustomTextField";
import {SelectRuta} from "../../components/selects";

import Strings from "../../assets/strings";
import ContactDetails from "./ContactDetails";
import {formatPeso, hasPermissionTo, PERSMISOS} from "../../helpers";
import {CloudDownloadOutlined, FilterList} from "@mui/icons-material";
import { Rating } from '@mui/material';
import downloadFiles from "../../helpers/downloadFiles";
import DefaultImgEmp from "../../assets/img/defaultAvatar_empresa.jpg";
import NoImg from "../../assets/img/defaultAvatar.jpg";
const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
  item:{
    [theme.breakpoints.down('md')]: {
      padding: "0px !important",
    },
  },
  status:{
    display: "block",
    width: 10,
    height: 10,
    background: "gray",
    border: "2px solid #fff",
    borderRadius: "50%",
    position: "absolute",
    right: 5,
    bottom: 5,
    "&.e0":{
      background: "red"
    },
    "&.e1":{
      background: "#01a855"
    },
    "&.e2":{
      background: "orange"
    }
  },
  nombre:{
    "&>div":{
      display: "flex",
      alignItems: "center",
      "&>span":{
        marginRight: 8,
        //  float: "left",
        "&>div":{
          height: 31,
          width: 31,
        }
      },
      "& p":{
        lineHeight: 1,
      },
      "& .MuiRating-decimal":{
        fontSize: 14,
      }
    }
  }
}));

const initialState={
  name: '',
  rutId:"",
  estado: "",
  page:1,
  pageSize:15,
  orderBy:"",
  orderByIsDescending:""
};
function Clientes(props){
  let history = useHistory();
  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
  const classes = useStyles();
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilter] = useState(initialState);


  const {title:mainTitle, mostrarClientes,mostrarClienteById,clientes: { list,error, isFetching,filtered} } = props;
  const isEmpty = (!list.results || list.results.length === 0);

  useEffect(() => {
    mostrarClientes();
  }, [mostrarClientes]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

  const handleFilter = e => {
    const {name, value} = e.target;
    if(name === "name" || name === "page" || name === "pageSize"){
      return mostrarClientes({
        ...filter,
        page:null,
        ...(name === "name" && {estado: "-2"}),
        [name]:value
      });
    }
    setFilter({...filter,page:null,[name]:value})
  };
  const resetFilter = () => {
    setOpenFilter(false);
    setFilter(initialState);
    mostrarClientes({});
  };
  const searchFilter = () => {
    setOpenFilter(false);
    mostrarClientes(filter);
  };
  const tableSortProps = orderBy => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>mostrarClientes({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending})
    };
  };
  const showClientModal = cliId => {
    mostrarClienteById(cliId);
    history.push(history.location.pathname, {cliId});
  };

  return <>
    <AppBar title={Strings[mainTitle]}>
      <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
      <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(true)}/>
      <ButtonResponsive icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"clientes")}/>
    </AppBar>
    <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel shrink>Estado</InputLabel>
              <SelectSmart
                  value={filter.estado}
                  onChange={handleFilter}
                  input={<Input name="estado" />}
                  opciones={[
                    {key:'',value:Strings.seleccione},
                    {key:'2',value:Strings.nuevo},
                    {key:'1',value:Strings.activo},
                    {key:'0',value:Strings.inactivo},
                    {key:'3',value:Strings.incobrable},
                    {key:'-2',value:Strings.todo},
                  ]}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectRuta
                fullWidth
                label={Strings.ruta}
                name= 'rutId'
                value={filter.rutId}
                all
                onChange={handleFilter}
            />
          </Grid>
        </Grid>
        <br/>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>setOpenFilter(false)}>{Strings.cancelar}</Button>
        <Button color="primary" variant={"contained"} disableElevation onClick={searchFilter}>oK</Button>
      </DialogActions>
    </Dialog>
    <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount} lg>
      <FilteredAlert onClose={resetFilter} filter={filtered}/>
    </ListViewMode>
    <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
      {isFetching && isEmpty && <LoadingProgress vh/>}
      {error && <MostrarError errors={error}/>}

      {props.viewTableList?(
          <TableSort data={[
            // {pro:"cliEstado",text:Strings.estado},
            {pro:"cliNombres",text:Strings.cliente},
            // {pro:"cliCalificacion",text:Strings.calificacion},
            {pro:"capitalPendiente",text:Strings.capitalPendiente},
            {pro:"balancePendiente",text:Strings.balance+" "+Strings.pendiente},
            {pro:"cliIngresos",text:Strings.ingreso},
            {pro:"cliCelular",text:Strings.celular},
            {pro:"cliTelefono",text:Strings.telefono},
            {pro:"ruta",text:Strings.ruta},
            {pro:"cliDireccion",text:Strings.direccion},
          ]} onSort={tableSortProps} lessVh={205}>
            <TableBody>
              {!isEmpty && list.results.map(cliente =>
                  <TableRow key={cliente.cliId} onClick={()=>showClientModal(cliente.cliId)}>
                    {/*<TableCell><span className={classes.status +" e"+ cliente.cliEstado} >{Strings[cliente.cliEstadoString]}</span></TableCell>*/}
                    <TableCell className={classes.nombre}>
                      <div>
                        <Badge
                            classes={{badge:classes.status +" e"+ cliente.cliEstado}}
                            color={"secondary"}
                            variant={"dot"}
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                        >
                          <Avatar alt="" src={ cliente.cliAvatar?cliente.cliAvatar: (cliente.cliTipo === 2 ?DefaultImgEmp: NoImg)}/>
                        </Badge>
                        <div>
                          <Typography>{cliente.cliNombres +" "+cliente.cliApellidos}</Typography>
                          <Box display={"flex"} alignItems="center">
                            <Rating value={cliente.cliCalificacion || 0} precision={0.5} size="small" readOnly /> &nbsp;
                            <Typography variant={"caption"}>{cliente.cliCalificacion}</Typography>
                          </Box>
                        </div>
                      </div>
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  <Box display={"flex"} alignItems="center">*/}
                    {/*    <Rating value={cliente.cliCalificacion || 0} precision={0.5} size="small" readOnly /> &nbsp;*/}
                    {/*    <Typography variant={"caption"}>{cliente.cliCalificacion}</Typography>*/}
                    {/*  </Box>*/}
                    {/*</TableCell>*/}
                    <TableCell>{formatPeso(cliente.capitalPendiente)}</TableCell>
                    <TableCell>{formatPeso(cliente.balancePendiente)}</TableCell>
                    <TableCell>{formatPeso(cliente.cliIngresos)}</TableCell>
                    <TableCell>{cliente.cliCelular}</TableCell>
                    <TableCell>{cliente.cliTelefono}</TableCell>
                    <TableCell>{cliente.ruta}</TableCell>
                    <TableCell className="comment"><span>{cliente.cliDireccion}</span></TableCell>
                  </TableRow>
              )}
            </TableBody>
          </TableSort>
      ):(
          smVewport ?(
              <List>
                {!isEmpty && list.results.map(cliente => (
                    <ClientListItem key={cliente.cliId} cliente={cliente} onClick={()=>
                        showClientModal(cliente.cliId)
                    } />
                ))}
              </List>
          ):(
              <Grid container spacing={3}>
                {!isEmpty && list.results.map(cliente => (
                    <Grid key={cliente.cliId} item xs={12} sm={6} md={4} lg={3} className={classes.item}>
                      <ClientCard  onClick={()=>showClientModal(cliente.cliId)} cliente={cliente} />
                    </Grid>
                ))}
              </Grid>
          )
      )}

      <Pagination
          empty={Boolean(isEmpty && !isFetching)}
          count={list.totalRecordCount || 0}
          rowsPerPage={filter.pageSize}
          page={list.pageNo -1}
          onPageChange={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}

          //  onPageChange={(e,page)=> setFilter({...filter,page:page+1})}
          onRowsPerPageChange={handleFilter}
          //onRowsPerPageChange={(e)=>  setFilter({...filter,pageSize:parseInt(e.target.value)})}
      />


      <ContactDetails />
    </section>
    {hasPermissionTo(PERSMISOS.clientesCrear) &&
    <PlusFab component={Link} to="/crear/cliente" />
    }

  </>;
}

const mapStateToProps = (state) => ({
  clientes: state.clientes,
  viewTableList: state.theme.viewTableList
});
export default connect(mapStateToProps, { mostrarClientes,mostrarClienteById })(Clientes);

