import { enumsTypes } from './types';
import { getEnums } from '../../services';

export function mostrarEnums() {
    return dispatch => {
        dispatch({
            type: enumsTypes.MOSTRAR_ENUMS,
            payload: getEnums()
        })
    }
}
export function setEnums(payload) {
    return (dispatch, getState) => {
        const enums = getState().enums
        if (Object.keys(enums.list).length || enums.isFetching) return false;
        dispatch({
            type: enumsTypes.SET_ENUMS,
            payload
        })
    }
}
export function mostrarEnumsSinoEsta() {
    return (dispatch, getState) => {
        const enums = getState().enums
        if (Object.keys(enums.list).length || enums.isFetching) return false;
        dispatch({
            type: enumsTypes.MOSTRAR_ENUMS,
            payload: getEnums()
        })
    }
}