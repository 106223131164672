import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mostrarPaisesSinoEsta } from '../../redux/actions/paisesActions';
import {FormControl, InputLabel} from "@mui/material";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";

function SelectPais({ value = -1, label="",name="paiNacionalidad", display="paiNacionalidad", onChange, paises, mostrarPaisesSinoEsta, required}) {
    useEffect((e) => {
        mostrarPaisesSinoEsta()
    }, [mostrarPaisesSinoEsta]);

    var options = (paises.list && paises.list.map(opc=>({key:opc.paiCodigo,value:opc[display]}))) || [];
    options.unshift({key:"",value:Strings.seleccione});



    if(!onChange){
        let opcion = options.find(o=>o.key === value.toString())
        if(opcion) return  <>{opcion.value}</>;
        return <>-</>
    }

    return (

        <FormControl fullWidth required={required}>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value}
                onChange={onChange}
                name={name}
                loading={paises.isFetching }
                opciones={options}
            />
        </FormControl>

    );
}

const mapStateToProps = (state) => ({
    paises: state.paises,
});
export default connect(mapStateToProps, { mostrarPaisesSinoEsta })(SelectPais);

SelectPais.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    display: PropTypes.string,
};