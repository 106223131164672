import { rutaTypes } from './types';
import { getRutas, addRuta, editRuta, delRuta } from '../../services';
import _ from 'lodash';
import history from "../../helpers/history"

export function mostrarRutas() {
    return dispatch => {
        dispatch({
            type: rutaTypes.MOSTRAR_RUTAS,
            payload: getRutas()
        })
    }
}
export function mostrarRutasSinoEsta() {
    return (dispatch, getState) => {
        const rutas = getState().rutas;
        if (Object.keys(rutas.list).length || rutas.isFetching) return false;
        dispatch({
            type: rutaTypes.MOSTRAR_RUTAS,
            payload: getRutas()
        })
    }
}
export function eliminarRuta(id) {
    return dispatch => {
        dispatch({
            type: rutaTypes.ELIMINAR_RUTA,
            payload: delRuta(id)
        })
    }
}
export function agregarEditarRuta(obj) {
    let cleanObj = _.omitBy(obj, (v) => !v);
    return dispatch => {
        dispatch({
            type: rutaTypes.AGREGAR_EDITAR_RUTA,
            payload: cleanObj.rutId ? editRuta(cleanObj) : addRuta(cleanObj) 
        }).then(history.goBack)
    }
}
