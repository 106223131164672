import { rutaTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
    isPending: false,
    error: null,
    fulfilled: false,
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case rutaTypes.MOSTRAR_RUTAS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case rutaTypes.MOSTRAR_RUTAS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
            }
        }
        case rutaTypes.MOSTRAR_RUTAS + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //agregar  o editar
        
        case rutaTypes.AGREGAR_EDITAR_RUTA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case rutaTypes.AGREGAR_EDITAR_RUTA + _FULFILLED: {
            const { data } = action.payload;
            var list = state.list.filter(e => e.rutId !== data.rutId);
            list.unshift(data);

            return {
                ...state,
                list,
                isPending: false,
                fulfilled: true,
                error: {}
            }
        }
        case rutaTypes.AGREGAR_EDITAR_RUTA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //Eliminar

        case rutaTypes.ELIMINAR_RUTA + _FULFILLED: {
            const { data: id  } = action.payload;
            let list = state.list.filter(e => e.rutId !== id);
            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case rutaTypes.ELIMINAR_RUTA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }

    default:
      return state;
  }
}
