import {carteraTypes} from './types';
import {getCarteras, addCartera, editCartera, delCartera} from '../../services';
import _ from 'lodash';
import history from "../../helpers/history"

export function mostrarCarteras() {
    return dispatch => {
        dispatch({
            type: carteraTypes.MOSTRAR_CARTERAS,
            payload: getCarteras()
        })
    }
}
export function mostrarCarteraById(id) {
    return (dispatch, getState) => {
        let byId = getState().carteras.list?.results?.find(c=>c.carId === id);
        if(byId)
            dispatch({ type: carteraTypes.MOSTRAR_CARTERA_POR_ID_ENCONTRADO, payload:{byId}}) ;

        dispatch({
            type: carteraTypes.MOSTRAR_CARTERA_POR_ID,
            payload: getCarteras(parseInt(id))
        })
    }
}
export function mostrarCarterasSinoEsta() {
    return (dispatch, getState) => {
        const carteras = getState().carteras;
        if (carteras.list?.results?.length || carteras.isFetching) return false;
        dispatch({
            type: carteraTypes.MOSTRAR_CARTERAS,
            payload: getCarteras()
        })
    }
}
export function eliminarCartera(id) {
    return dispatch => {
        dispatch({
            type: carteraTypes.ELIMINAR_CARTERA,
            payload: delCartera(id)
        })
    }
}
export function agregarEditarCartera(obj) {
    let cleanObj = _.omitBy(obj, (v) => !v);
    return dispatch => {
        dispatch({
            type: carteraTypes.AGREGAR_EDITAR_CARTERA,
            payload: cleanObj.carId ? editCartera(cleanObj) : addCartera(cleanObj)
        }).then(history.goBack)
    }
}
