import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import { mostrarPrestamoById} from '../../redux/actions/prestamoActions';
import {cancelarPrestamo} from "../../services";
import {
    Dialog,
    DialogActions,
    DialogContent,
    Checkbox,
    FormControlLabel,
    Button,
    TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {connect} from "react-redux";
import Strings from "../../assets/strings";
import {MostrarError,LoadingProgress,TopBarDialog} from "../../components";
const useStyles = makeStyles(theme => ({
  cancelBox:{
    "& .content":{
      padding: "10px 10px 0 10px",
    },
    "& header":{
        backgroundColor: theme.palette.error.light,
    }
  }
}));


function CancelDialog(props) {
    const {goBack,...history} = useHistory();

    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [state, setState] = useState({comment:"", anularPagos:false});

    const submit = () => {
        setLoading(true)
        cancelarPrestamo(props.preId,state.comment,state.anularPagos).then(res=>{
            setLoading(false)
            if (res.data){
                props.mostrarPrestamoById(props.preId)
                goBack()
                return false
            }
            history.replace("/prestamos")
        }).catch(error=>{
            setError(error.response)
            setLoading(false)
        })
    };
    const handleChange = e => {
          const {name,value,checked} = e.target;
          if(name === "anularPagos"){
            setState({...state,  [name]: checked });
          }else{
            setState({...state,  [name]: value });
          }
    };

    return (
        <Dialog open={true} onClose={goBack} className={classes.cancelBox} maxWidth={"xs"} fullWidth>
          <TopBarDialog title={Strings.cancelPreMsj} onClose={goBack} />

            <MostrarError errors={error}/>

            <DialogContent className="content">
            {loading && <LoadingProgress />}
                {state.anularPagos?(
                    <p>{Strings.prestamoSeEliminara}</p>
                ):(
                    <TextField
                        label={Strings.comentario}
                        multiline
                        name="comment"
                        maxRows="4"
                        value={state.comment}
                        fullWidth
                        variant={"outlined"}
                        onChange={handleChange}
                        inputProps={{maxLength: 100}}

                    />
                )}

            <FormControlLabel
                control={<Checkbox name="anularPagos" checked={state.anularPagos} onChange={handleChange}/>}
                label={Strings.eliminar+" "+Strings.pagos} />
          </DialogContent>
          <DialogActions>
            <Button onClick={goBack}>{Strings.abordar}</Button>
            <Button onClick={submit} disabled={loading} color="secondary">{Strings.confirmar}</Button>
          </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
  preId: state.prestamos.byId.preId
})
export default connect(mapStateToProps, { mostrarPrestamoById })(CancelDialog);



