import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom'
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {LoadingProgress, MostrarError} from "../../components";
import {getPublicUser} from "../../services";
import {connect} from "react-redux";
import {setEnums} from "../../redux/actions/enumsActions";
import {setPaises} from "../../redux/actions/paisesActions";
import DetallesSolicitud from "../../views/solicitud/DetallesSolicitud";
import {geoString, getLSLang,mapApiOptions, setLSLang} from "../../helpers";
import Strings from "../../assets/strings";
import {setCurrentUser} from "../../redux/actions/authentication";
import {setGenerales as setGlobalGenerales} from "../../redux/actions/generalesActions";
import {LoadScript} from "@react-google-maps/api";
import { Alert } from '@mui/material';
import {isMobile} from "../../helpers/browser_detect";

const useStyles = makeStyles(theme => ({
    root:{
        boxShadow: "#969696 0px 0px 12px",
        maxWidth: 990,
        margin: "-110px auto 30px auto",
        boxSizing: "border-box",
        background:"#fff",
        [theme.breakpoints.down('md')]: {
            marginTop:-71,
            "& h1":{
                marginTop: "-64px !important",
                color: "white  !important",
                marginBottom: "39px !important",
            }
        }


    },
    topHead: {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "right",
        height: "103px",
        alignItems: "center",
        padding: theme.spacing(2),
        borderBottom: "1px solid #ddd",
        "& .info":{
            display: 'flex',
            alignItems: "center",
        },
        "& h1":{
            color:theme.palette.secondary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.5rem",
            minWidth: 254,
            textAlign: "left",
            textTransform: "uppercase",

        },
        "& h2":{
            color:theme.palette.primary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.4rem",
        },
        "& .logo":{
            marginLeft: 10,
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: "100%",
                maxHeight: 99,
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".9rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        },
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            height: "auto",
            "& .info":{
                flexDirection: "column-reverse",
                textAlign: "center",
            },
            "& h1":{
                textAlign: "center",
            }
        }
    },
    lang:{
        textAlign: "center",
        marginBottom: theme.spacing(3),
        "& select":{
            padding: 5,
            fontSize: "1em",
        }
    },
    webview:{
        boxShadow:"none",
        margin:0,
        padding:0,
        "&>div":{
            padding:theme.spacing(1),
        }
    }
}));
function Solicitar(props){
    let { user } = useParams();
    const webview = isMobile.anyWebView();

    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const {setEnums,setPaises,setCurrentUser,setGlobalGenerales} = props;
    const [generales, setGenerales] = useState({});
    const [error, setError] = useState();
    const [lang, setLang] = useState(getLSLang("l"));
    useEffect(()=>{
        getPublicUser(user).then((res)=>{
            let enums = res?.data ?? {};

            let generales = enums?.generales ?? {};
            let paises = enums?.paises ?? [];
            paises = paises.map(p=>({paiCodigo:p.key,paiNacionalidad:p.value}));


            setGenerales(generales); //local
            setGlobalGenerales(generales); //global

            setPaises(paises); //global
            delete enums.generales;
            delete enums.paises;
            setEnums(enums); //global
            setCurrentUser({usuUIColor: generales?.uiColor,genLocalidad:generales?.genLocalidad }); //Global

            setLoaded(true);


        }).catch((e)=>setError(e?.response))
    },[user,setGenerales,setEnums,setPaises,setCurrentUser,setLoaded,setGlobalGenerales,setError])

    return(
            <LoadScript {...{...mapApiOptions,language:getLSLang("l")}}>
                {!loaded?(
                    error ? <MostrarError errors={error} action={false}/> : <LoadingProgress vh />
                ):(
                    <>
                        {!webview && <Box bgcolor="primary.main" width={"100%"} height={138} />}
                        <div className={classes.root+(webview?" "+classes.webview:"")}>
                            {!webview &&
                                <>
                                    <div className={classes.topHead}>
                                <h1>{Strings.solicitud} {Strings.prestamo}</h1>
                                <div className="info">
                                    <div>
                                        <h2>{generales?.genEmpresaNombre}</h2>
                                        <span className="tel">
                                            <i>
                                                {!!generales?.genEmpresaRNC && <><span>{geoString("rnc",true)}: {generales?.genEmpresaRNC}</span> <br/></>}
                                                {!!generales?.genEmpresaTelefono && <span> Tel: {generales?.genEmpresaTelefono}</span>}
                                                {!!generales?.genEmpresaTelefono2 && <span> &nbsp;/ &nbsp; {generales?.genEmpresaTelefono2}</span>}
                                            </i>
                                        </span>
                                        <br/>
                                        <span className="dir">{generales?.genEmpresaDireccion}</span>
                                    </div>
                                    {!!generales?.genEmpresaLogo &&
                                    <div className="logo">
                                        <img alt="logo" src={generales?.genEmpresaLogo} />
                                    </div>
                                    }
                                </div>
                            </div>
                                    <Alert severity="info">Para aumentar la probabilidad de que su solicitud sea aprobada, proporcione la mayor cantidad de datos posible.</Alert>
                                </>
                            }
                            <DetallesSolicitud lang={lang}/>
                        </div>
                        {!webview &&
                            <div className={classes.lang}>
                                <select value={lang} onChange={e=>{
                                    let lang = e.target.value;
                                    setLSLang(lang);
                                    Strings.setLanguage(lang);
                                    setLang(lang);
                                }}>
                                    {Strings.getAvailableLanguages().map(l=>
                                        <option key={l} value={l}>{Strings[l]}</option>
                                    )}
                                </select>
                            </div>
                        }
                        <br/>
                    </>
                )}
            </LoadScript>
        );
}

export default connect(null, { setEnums,setPaises,setGlobalGenerales,setCurrentUser })(Solicitar);
