import {SET_CURRENT_USER,USER_LOGOUT,usuarioTypes } from './types';
import {getPerfil,ediPerfil} from '../../services';
import Notifications from 'react-notification-system-redux';

import jwt_decode from 'jwt-decode';
import {setLSLang} from "../../helpers";
import history from "../../helpers/history";
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export function mostrarPerfil(obj) {
    //todo token
    if(obj && obj.tocken){
        if(obj.genLocalidad) setLSLang(null,obj.genLocalidad);

        return setCurrentUser(obj)
    }
    return (dispatch) => {
        dispatch({
            type: usuarioTypes.MOSTRAR_PERFIL,
            payload: getPerfil()
        }).then(({value})=>{
            setLSLang(null,value.data.genLocalidad)
        })
    }
}
export function editarPerfil(obj) {
    return dispatch => {
        dispatch({
            type: usuarioTypes.EDITAR_PERFIL,
            payload: ediPerfil(obj)
        }).then(()=>{
            dispatch(Notifications.success({ title: 'Guardado con éxito', message: '', autoDismiss: 5, }));
        })
    }
}

export const setCurrentUserInfo = (token) => dispatch => {
    const decoded = jwt_decode(token);

    dispatch(setCurrentUser(decoded));
}
export const setCurrentUser = obj => {
    return {
        type: SET_CURRENT_USER,
        payload: obj
    }
}
export const userLogout = () => {
    localStorage.removeItem('_token');
    if(iOS){
        window.location = "/login"
    }else{
        history.replace("/login");
    }
    return {
        type: USER_LOGOUT,
    }
}