import React, {useEffect, useState} from 'react';
import { MostrarError } from "../../components";
import { connect } from 'react-redux';
import { mostrarGastos, eliminarGasto} from '../../redux/actions/gastosActions';
import {
    TableCell,
    TableBody,
    TableRow,
    Button,
    Dialog,
    DialogContent,
    Grid,
    TextField,
    DialogActions,
    IconButton,
    Typography,
    useMediaQuery,
    TableFooter
} from "@mui/material";
import Strings from "../../assets/strings"
import {useHistory, useLocation} from "react-router-dom";
import {formatDate, formatPeso, hasPermissionTo, PERSMISOS} from "../../helpers";
import { SeacrhAppBar, ConfirmDialog, CustomListItem, ButtonResponsive, Pagination, TableSort, FilteredAlert,PlusFab,LoadingProgress,
    AppBar,ListViewMode} from "../../components";
import {SelectCaja,SelectAgente,SelectUsoMultiples} from "../../components/selects";
import DetallesGastos from "./detallesGastos";
import Crear from "./crear";
import {Close, CloudDownloadOutlined, FilterList} from "@mui/icons-material";
import downloadFiles from "../../helpers/downloadFiles";
const initialState={
    name:'',
    creadoPor: '',
    catId:'',
    cajId:'-1',
    fechaDesde: '',
    fechaHasta: '',
    page: 1,
    pageSize: 15,
    orderBy: "",
    orderByIsDescending:"",
};

function  Index(props) {
    const smVewport = useMediaQuery((theme) =>theme.breakpoints.down('md'));
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();


    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState(initialState);

    const { title: mainTitle, mostrarGastos,gastos: { list, isFetching, error,filtered } } = props;
    const isEmpty = (!list.results || list.results.length === 0);


    useEffect(() => {
        mostrarGastos();
    }, [mostrarGastos]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const [gastoDetalles, setGastoDetalles] = useState(null);

    const handleFilter = e => {
        const {name, value,checked} = e.target;
        if(name === "name" || name === "page" || name === "pageSize") return  mostrarGastos({...filter,page:null,[name]:value});

        if(name === "verAnulados")
            setFilter({...filter,page:null,[name]:checked});
        else
            setFilter({...filter,page:null,[name]:value});
    };
    const resetFilter = () => {
        setOpenFilter(false);
        mostrarGastos({});
    };
    const searchFilter = () => {
        setOpenFilter(false);
        mostrarGastos(filter);
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=> mostrarGastos({...filter,page:null, orderBy, orderByIsDescending: !filter.orderByIsDescending})
        }
    };


    return <>
        <AppBar title={Strings[mainTitle]}>
            <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>
            <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(true)}/>
            <ButtonResponsive icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"gastos")}/>
        </AppBar>
        <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} maxWidth={"xs"} >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6} >
                        <TextField
                            label={Strings.fechaDesde}
                            type="date"
                            name="fechaDesde"
                            value={filter.fechaDesde}
                            onChange={handleFilter}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <TextField
                            label={Strings.fechaHasta}
                            type="date"
                            name="fechaHasta"
                            value={filter.fechaHasta}
                            onChange={handleFilter}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <SelectAgente
                            label={Strings.creadoPor}
                            value={filter.creadoPor}
                            onChange={handleFilter}
                            name="creadoPor"
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <SelectUsoMultiples
                            group="CATEGORIAGASTOS"
                            name="catId"
                            value={filter.catId}
                            onChange={handleFilter}
                            label={Strings.categoria}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectCaja
                            fullWidth
                            name= 'cajId'
                            value={filter.cajId}
                            allowAll
                            onChange={handleFilter}
                        />
                    </Grid>
                </Grid>
                <br/>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                <Button color="primary" onClick={searchFilter}>oK</Button>
            </DialogActions>
        </Dialog>
        <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount}>
            <FilteredAlert onClose={resetFilter} filter={filtered}/>
        </ListViewMode>
        <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
            {isFetching && isEmpty && <LoadingProgress vh/>}
            {!urlState && <MostrarError errors={error}/>}


            {(!props.viewTableList && smVewport)?(
                !isEmpty && list.results.map(item =>
                 <CustomListItem
                      key={item.gasId} onClick={()=>setGastoDetalles(item)}
                      lt={item.gasConcepto}
                      lb={<Typography variant="caption">{item.categoriaString}</Typography>}
                      rt={<Typography color={"primary"}>{formatPeso(item.gasMonto)}</Typography>}
                      rb={<Typography variant="caption">{formatDate(item.gasFecha)}</Typography>}
                 />

                )
            ):(

                <TableSort data={[
                    {text:""},
                    {pro:"gasFecha",text:Strings.fecha},
                    {pro:"gasMonto",text:Strings.monto},
                    {pro:"gasConcepto",text:Strings.concepto},
                    {pro:"categoriaId",text:Strings.categoria},
                    {pro:"caja",text:Strings.cajas},
                    {pro:"creadoPor",text:Strings.creadoPor},
                    {pro:"gasComentario",text:Strings.comentario},
                ]} onSort={tableSortProps} lessVh={205}>
                    <TableBody>
                    {!isEmpty && list.results.map(item =>
                        <TableRow key={item.gasId} className={!!item.gasAnulado ? "text-red-deep":""}>
                            <TableCell className="actionCell">
                                {hasPermissionTo(PERSMISOS.gastosEliminar) &&
                                    <IconButton
                                        onClick={()=>history.push(history.location.pathname,{confirmDialog:item.gasId})}
                                        size="large">
                                        <Close className={"text-red"}/>
                                    </IconButton>
                                }
                            </TableCell>
                            <TableCell> {formatDate(item.gasFecha)}</TableCell>
                            <TableCell> {formatPeso(item.gasMonto)}</TableCell>
                            <TableCell> {item.gasConcepto}</TableCell>
                            <TableCell>{item.categoriaString}</TableCell>
                            <TableCell> {item.caja}</TableCell>
                            <TableCell> {item.creadoPor}</TableCell>
                            <TableCell className="comment">{item.gasComentario}</TableCell>

                        </TableRow>
                    )}
                    </TableBody>
                    {!isEmpty &&
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={2}><Typography align={"right"}>Total:</Typography> </TableCell>
                            <TableCell>{formatPeso(list.footerTotals.gasMonto)}</TableCell>
                            <TableCell colSpan={5} />
                        </TableRow>
                    </TableFooter>
                    }
                </TableSort>
            )}
            <Pagination
                empty={Boolean(isEmpty && !isFetching)}
                count={list.totalRecordCount || 0}
                rowsPerPage={filter.pageSize}
                page={list.pageNo -1}
                onPageChange={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                onRowsPerPageChange={handleFilter}
            />
            {hasPermissionTo(PERSMISOS.gastosCrear) &&
                <>
                    <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{gasId:""})} />
                    <Crear/>
                </>
            }
            <ConfirmDialog onConfirm={()=>props.eliminarGasto(urlState && urlState.confirmDialog)}/>
        </section>
        <DetallesGastos item={gastoDetalles} onClose={()=>setGastoDetalles(null)}/>
    </>;


}


const mapStateToProps = (state) => ({
    gastos: state.gastos,
    viewTableList: state.theme.viewTableList
});
export default connect(mapStateToProps, { mostrarGastos,eliminarGasto })(Index);
