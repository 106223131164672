import React, {useEffect, useState} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {connect} from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import { mostrarCarteraById,eliminarCartera } from '../../redux/actions/carterasActions';

import {formatDate, formatPeso, hasPermissionTo, PERSMISOS,} from '../../helpers';
import {IconButton, Grid, MenuItem, Menu, useMediaQuery, TableBody, TableRow, TableCell
} from '@mui/material';
import {AttachMoney,MoreVert} from '@mui/icons-material';

import {AppBar, LoadingProgress, TableSort} from "../../components";
import Strings from "../../assets/strings";
import CounterItem from "../../components/homeWidget/CounterItem";
import ItemPrestamo from "../../components/CustomListItem/itemPrestamo";
import ConfirmDialog from "../../components/ConfirmDialog";
import Crear from "./crear";

const useStyles = makeStyles(theme => ({
  tRow:{
    cursor: "pointer",
    "& .estado":{
      fontSize: ".9em"
    },
    "&.i":{
      background:"rgba(21,216,193,0.13) !important",
      "& .estado":{
        color: "#15d2d8",
      }
    },
    "&.c":{
      background:"rgba(216, 67, 21, 0.13) !important",
      "& .estado":{
        color: "#d84315",
      }
    },
    "&.m":{
      background:"rgba(255, 111, 0, 0.05) !important",
      "& .estado":{
        color: "#ff6f00",
      }
    },
    "&.p":{
      background:"rgba(25, 118, 210, 0.07) !important",
      "& .estado":{
        color: "#1976d2",
      }
    }
  },
}));


function DetallesCartera({cartera,isFetching,viewTableList,mostrarCarteraById,eliminarCartera}) {
  const xsVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
  const classes = useStyles();
  const {goBack,...history} = useHistory();
  const {state:urlState} = useLocation();

  let { carId } = useParams();

  const [openMenu, setOpenMenu] = useState(null);


  useEffect(() => {
    if (carId > 0)
      mostrarCarteraById(carId);
  }, [mostrarCarteraById, carId]);


  const editar = ()=>{
    if(!hasPermissionTo(PERSMISOS.carterasActualizar)) return false;
    history.push(history.location.pathname, {
      carId: cartera.carId,
      carDescripcion: cartera.carDescripcion,
      carCapital: cartera.carCapital,
    })
  }
  return (
      <>
        <AppBar title={cartera.carDescripcion}>
            <IconButton aria-haspopup="true" size={"small"}
                        onClick={(e)=>setOpenMenu(e.currentTarget)}>
              <MoreVert />
            </IconButton>
            <Menu id="long-menu" anchorEl={openMenu} keepMounted open={Boolean(openMenu)} onClose={()=>setOpenMenu(null)}>
              {hasPermissionTo(PERSMISOS.carterasActualizar) &&
              <MenuItem onClick={() => history.push(history.location.pathname, {confirmDialog: carId})}>
                {Strings.eliminar}
              </MenuItem>
              }
              {hasPermissionTo(PERSMISOS.carterasEliminar) &&
                <MenuItem onClick={editar}>{Strings.editar}</MenuItem>
              }
            </Menu>
        </AppBar>
        <div className={"contentInner "+classes.contentInner}>
          {isFetching && <LoadingProgress vh/>}

          <Grid container spacing={xsVewport?1:2} className={"d-print-none " + classes.root}>
            {cartera?.dataReporte?.map(pre =>
                <Grid item xs={6} md={3} key={pre.key}>
                  <CounterItem title={Strings[pre.key]} color={"#ccc"} subTitle={formatPeso(pre.value)} Icon={AttachMoney}/>
                </Grid>
            )}
          </Grid>
          <div className={classes.table}>
            {(!viewTableList && smVewport)?(
                cartera?.prestamos?.map(pre =>
                    <ItemPrestamo key={pre.preId} onClick={()=>history.push(`/prestamo/${pre.preId}`)} item={pre}/>
                )
            ):(
                <TableSort data={[
                  {pro:"cliente",text:Strings.prestatarios},

                  {pro:"preBalancePendiente",text:Strings.balance+" "+Strings.pendiente},
                  {pro:"preMontoCuota",text:Strings.monto+" "+Strings.cuota},
                  {pro:"mora",text:Strings.mora},
                  {pro:"preFechaProximoPago",text:Strings.proximo+" "+Strings.pago},
                  {pro:"preCapitalActual",text:Strings.capitalPendiente},
                  {pro:"preGastosLegales",text:Strings.gastosLegales},

                  {pro:"preTipoAmortizacion",text:Strings.amortizacion},
                  {pro:"prePlazo",text:"# "+Strings.cuota},
                  {pro:"preCapitalInicial",text:Strings.capitalInicial},
                  {pro:"totalPagos",text:Strings.totalPagado},

                  {pro:"preTasaInteres",text:Strings.interes},
                  {pro:"preCiclosPago",text:Strings.modalidad},

                  {pro:"preReferencia",text:Strings.codigoRef},
                  {pro:"preFecha",text:Strings.fecha+" "+Strings.prestamo},
                  {pro:"cajId",text:Strings.caja},

                ]} onSort={()=>{}}>
                  <TableBody>
                    {cartera?.prestamos?.map(pre =>
                        <TableRow key={pre.preId} className={classes.tRow +" "+pre.estado?.charAt(0)} onClick={()=>history.push(`/prestamo/${pre.preId}`)}
                                  onContextMenu={(e)=>{e.preventDefault();window.open(`/prestamo/${pre.preId}`,"_blank")}}>
                          <TableCell className={classes.name}>
                            {pre.cliente}
                            <div className="estado">{pre.cuotasAtrasadas>1 && pre.cuotasAtrasadas} {Strings[pre.estado]}</div>
                          </TableCell>

                          <TableCell>{formatPeso(pre.preBalancePendiente)}</TableCell>
                          <TableCell>{formatPeso(pre.preMontoCuota)}</TableCell>
                          <TableCell>{formatPeso(pre.mora)}</TableCell>

                          <TableCell>{formatDate(pre.preFechaProximoPago,"dd, DD/MM/YYYY")}</TableCell>
                          <TableCell>{formatPeso(pre.preCapitalActual)}</TableCell>

                          <TableCell>{formatPeso(pre.preGastosLegales)}</TableCell>


                          <TableCell>{Strings[pre.preAmortizacionString]}</TableCell>
                          <TableCell>{pre.prePlazoString}</TableCell>
                          <TableCell>{formatPeso(pre.preCapitalInicial)}</TableCell>
                          <TableCell>{formatPeso(pre.totalPagos)}</TableCell>

                          <TableCell>{pre.preTasaInteres}%</TableCell>
                          <TableCell>{Strings[pre.preCicloPagoString]}</TableCell>
                          <TableCell>{pre.preReferencia}</TableCell>
                          <TableCell>{formatDate(pre.preFecha)}</TableCell>
                          <TableCell>{pre.caja}</TableCell>

                        </TableRow>
                    )}
                  </TableBody>
                  {/*{!isEmpty &&*/}
                  {/*<TableFooter>*/}
                  {/*  <TableRow >*/}
                  {/*    <TableCell><Typography align={"right"}>Total:</Typography> </TableCell>*/}

                  {/*    <TableCell>{formatPeso(list.footerTotals.preBalancePendiente)}</TableCell>*/}
                  {/*    <TableCell>{formatPeso(list.footerTotals.preMontoCuota)}</TableCell>*/}
                  {/*    <TableCell>{formatPeso(list.footerTotals.mora)}</TableCell>*/}

                  {/*    <TableCell />*/}
                  {/*    <TableCell>{formatPeso(list.footerTotals.preCapitalActual)}</TableCell>*/}
                  {/*    <TableCell>{formatPeso(list.footerTotals.preGastosLegales)}</TableCell>*/}
                  {/*    <TableCell colSpan={2}/>*/}
                  {/*    <TableCell>{formatPeso(list.footerTotals.preCapitalInicial)}</TableCell>*/}
                  {/*    <TableCell>{formatPeso(list.footerTotals.totalPagos)}</TableCell>*/}
                  {/*    <TableCell colSpan={3}/>*/}
                  {/*  </TableRow>*/}
                  {/*</TableFooter>}*/}
                </TableSort>
            ) }
          </div>

        </div>
        <Crear/>
        <ConfirmDialog onConfirm={()=>eliminarCartera(urlState?.confirmDialog)} replace={"/carteras"}/>
      </>
  );


}

const mapStateToProps = (state) => ({
  cartera: state.carteras.byId,
  isFetching: state.carteras.isFetching,
});
export default connect(mapStateToProps, { mostrarCarteraById,eliminarCartera })(DetallesCartera);



