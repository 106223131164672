import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarEnumsSinoEsta } from '../../redux/actions/enumsActions';
import {FormControl, InputLabel} from "@mui/material";
import Strings from "../../assets/strings"
import {SelectSmart} from "../CustomTextField";
import _ from "lodash";
import {hasPermissionTo, PERSMISOS_USUARIOS} from "../../helpers";

function SelectEnum({required,disabled,margin="none",value="", name="tipo",label="", all, onChange, group, enums, mostrarEnumsSinoEsta}) {

    useEffect(() => {
            mostrarEnumsSinoEsta()
    }, [mostrarEnumsSinoEsta]);


    let options = (enums.list[group] && enums.list[group].map(opc=>({key:opc.key,value: Strings[_.camelCase(opc.value)]}))) || [];

    //pemiso para diario y semanan
    if(group === "ciclosPago" && !hasPermissionTo(PERSMISOS_USUARIOS.cilosPago)){
        options = options.filter(o=>o.key.toString() === "1" || o.key.toString() === "7")
    }
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})


    if(!onChange){
        let opcion = options.find(o=>o.key.toString() === value.toString())
        if(opcion) return  <>{opcion.value}</>;
        return <>-</>
    }
    return (
        <FormControl fullWidth required={required} margin={margin}>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value.toString()}
                onChange={onChange}
                name={name}
                loading={enums.isFetching }
                disabled={disabled}
                required={required}
                opciones={options}
            />
        </FormControl>
    );
}

const mapStateToProps = (state) => ({
    enums: state.enums,
})
export default connect(mapStateToProps, { mostrarEnumsSinoEsta })(SelectEnum);
