import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { mostrarZonasHorariasSinoEsta } from '../../redux/actions/zonasHorariasActions';
import {FormControl, InputLabel} from "@mui/material";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";

function SelectZonaHoraria({ value = "", label="",name="timeZoneId", onChange, zonasHorarias, mostrarZonasHorariasSinoEsta, required}) {
    useEffect((e) => {
        mostrarZonasHorariasSinoEsta()
    }, [mostrarZonasHorariasSinoEsta]);

    let options = (zonasHorarias.list && zonasHorarias.list.map(opc=>({key:opc.key,value:opc.value}))) || [];
    options.unshift({key:"",value:Strings.seleccione});



    if(!onChange){
        let opcion = options.find(o=>o.key.toString() === value.toString())
        if(opcion) return  <>{opcion.value}</>;
        return <>-</>
    }

    return (

        <FormControl fullWidth required={required}>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value}
                onChange={onChange}
                name={name}
                loading={zonasHorarias.isFetching }
                opciones={options}
            />
        </FormControl>

    );
}

const mapStateToProps = (state) => ({
    zonasHorarias: state.zonasHorarias,
});
export default connect(mapStateToProps, { mostrarZonasHorariasSinoEsta })(SelectZonaHoraria);

SelectZonaHoraria.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
};
