import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { mostrarRutasSinoEsta } from '../../redux/actions/rutasActions';
import {
    FormControl,
    Hidden,
    Input,
    InputLabel,
    ListItemText,
    MenuItem,
    Select
} from "@mui/material";
import Strings from "../../assets/strings";
import StyledCheckbox from "../StyledCheckbox";
import {hasPermissionTo, PERSMISOS, PERSMISOS_USUARIOS} from "../../helpers";

function SelectRutas({ value = "", name = "UsuRutas",label=Strings.rutas, onChange, rutas,mostrarRutasSinoEsta}) {
    const [selected, setSelected] = useState([])

    useEffect(() => {
        if(hasPermissionTo(PERSMISOS.rutas) && hasPermissionTo(PERSMISOS_USUARIOS.rutas)) {
            mostrarRutasSinoEsta();
        }
    }, [mostrarRutasSinoEsta]);

    useEffect(()=>{
       // console.log(value)
       let val = !!value ? value?.split(","):[];
       setSelected(val.map(v=>parseInt(v)))
    },[setSelected,value])

    const handleChange = e => {
        const {value: tValue} = e.target
        onChange({target:{value: tValue.join(','),name}})
    };
    const handleChangeNative = (event) => {
        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
            onChange({target:{value: value.join(','),name}})
    };
    let options = (rutas.list && rutas.list.map(opc=>({key:opc.rutId,value:opc.rutDescripcion}))) || [];
    return (
        <>
            <Hidden only={"xs"}>
                <FormControl fullWidth>
                    <InputLabel shrink>{label}</InputLabel>
                    <Select
                        multiple
                        value={selected}
                        onChange={handleChange}
                        renderValue={(selected) => {
                            return options.filter(opt=>selected.includes(opt.key)).map(opt=>opt.value).join(', ')
                        }}
                        input={<Input />}
                    >
                        {options.map(i => (

                            <MenuItem key={i.key} value={i.key}>
                                <StyledCheckbox checked={selected.indexOf(i.key) !== -1}/>
                                <ListItemText primary={i.value} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Hidden>
            <Hidden smUp>
                <FormControl fullWidth>
                    <InputLabel shrink>{label}</InputLabel>
                    <Select
                        multiple
                        native
                        value={selected}
                        onChange={handleChangeNative}
                    >
                        {options.map(i => (
                            <option key={i.key} value={i.key}>
                                {i.value}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </Hidden>
        </>
    );
}

const mapStateToProps = (state) => ({
    rutas: state.rutas,
})
export default connect(mapStateToProps, { mostrarRutasSinoEsta })(SelectRutas);
