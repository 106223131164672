import React, {useEffect, useState} from 'react';
import {getClientes, getCxC, getGenerales, getPlantillaConverted, getPrestamo} from '../../services';
import "./reportStyle.scss"
import {
    buildAmort,
    formatDate,
    formatPeso,
    geoString,
    htmltoText,
    moment,
    setLSLang,
    textToPDF,
    urlPramsToObj
} from "../../helpers";
import {useLocation, useParams} from "react-router-dom";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogContent,
    FormControl, FormControlLabel,
    Grid,
    Hidden,
    Input,
    ListItemText,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow, TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {ID_TOKEN_KEY} from "../../services/auth";
import renderHTML from "react-render-html";
import {SelectEnum, SelectPais} from "../../components/selects";
import {filterCxCList} from "../../components/homeWidget/CxC";
import {HtmlTooltip, LoadingProgress, MostrarError} from "../../components";
import Collapse from "@mui/material/Collapse";
import {isMobile} from "../../helpers/browser_detect";
import Strings from "../../assets/strings";
import {useCustomFormInput} from "../../hooks/useCustomFormInput";
import SelectPlantilla from "../../components/selects/SelectPlantilla";

const useStyles = makeStyles(theme => ({
    root:{
        background: "white !important",
        maxWidth: 980,
        border:"5px solid white",
        margin: "0 auto",
        '@media print': {
            minWidth: "10in !important",
            "& .MuiTableContainer-root":{
                overflow: "Hidden",
            }
        },
    },
    topBar:{
        position:"sticky",
        top:0,
        left:0,
        background:theme.palette.grey[800],
        height:40,
        width: "100%",
        color:theme.palette.common.white,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "& .controls":{
          "& select":{
              "marginRight": "8px",
              "padding": "5px",
              "border": "none !important",
              "background": "#545454",
              "paddingRight": "10px",
              "color": "inherit",
              "outline": "none",
              "textTransform": "uppercase",
              "& option":{
                  "background": "white",
                  color:"black",
              }
          }
        },
        '@media print': {
            display:"none",
        },
    },
    toShow:{
        marginRight: theme.spacing(1),
        "&>div":{
            background: "#545454",
            border: "none !important",
            paddingRight: 10,
            "&::before":{
              display:"none",
            },
            "& svg":{
                color:"white",
            },

            "&>div":{
                padding: "4px 15px !important",
                fontWeight: 500,
                fontSize: "0.875rem",
                textTransform: "uppercase",
                color: "white"
            }
        }
    },
    infoPrestamo:{
        margin: theme.spacing(1)+" 0px",
        background: "#f9f9f9",
        padding: 8,
        [theme.breakpoints.down(undefined)]: {
            overflow: "auto",
            "&>div":{
                display: "block",
                columns: 2,
                width: 437,
                "&>div":{
                    maxWidth: "100%",
                }
            },
        },
        // [theme.breakpoints.down(400)]:{
        //     columns: 2,
        // },
      "&>div div":{
          marginBottom: 5,
          "&>span:first-child":{
              marginRight: 3,
              fontWeight: 500,
          }
      }
    },
    cliInfo:{
        padding: "8px 0",
        display:"flex",
        "&>div.avatar":{
            padding:0,
            margin:0,
        },
        "&>div.info":{
            margin:0,
        },
        [theme.breakpoints.down(undefined)]: {
            flexDirection: "column",
            "&>div.avatar":{
                justifyContent: "center",
                marginBottom: 10,
            },
        },
    },
    client:{
        margin: theme.spacing(1)+" 0px",
        padding: 8,
        display: "flex",
        alignItems: "center",
        "& img":{
            marginRight:theme.spacing(1),
            border: "1px solid #ccc",
        }
    },
    cliItems:{
        [theme.breakpoints.down(undefined)]: {
            display: "block",
           // columns: 2,
            "&>div":{
                maxWidth: "100%",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                padding: "8px 22px !important",
            }
        }
    },
    cliImgCont:{
        [theme.breakpoints.down(undefined)]: {
            flexDirection: "column",
            "&>div":{
                maxWidth: "100%",
                "& img":{
                    margin: "0 auto",
                    display: "block",
                }
            }
        }
    },
    topHead: {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "right",
        height: "67px",
        alignItems: "center",
        padding: theme.spacing(2),
        marginBottom: theme.spacing(1),
        borderBottom: "1px solid #ddd",
        "& .info":{
            display: 'flex',
            alignItems: "center",
        },
        "& h1":{
            color:theme.palette.primary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.2rem",
            minWidth: 254,
            textAlign: "left",
            textTransform: "uppercase",

        },
        "& h2":{
            color:theme.palette.secondary.main,
            fontWeight: "normal",
            margin: "0",
            fontSize: "1.2rem",
        },
        "& .logo":{
            marginLeft: 10,
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: 150,
                maxHeight: 64,
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".7rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        }
    },
    barTitle:{
        padding: "6px 12px",
        background: theme.palette.grey[100],
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        "& .total":{
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.87)",
            display: "flex",
            alignItems: "center",
            "& div:first-child":{
                marginRight: 10
            },
            "& div:last-child":{
                textAlign: "right"
            },
            "& span":{
                color: theme.palette.secondary.main,
            },
        },
        [theme.breakpoints.down(undefined)]: {
            flexDirection: "column",
            alignItems: "center",
            "& .total":{
                marginTop:5,
            }
        }
    },
    campo:{
        "& span":{
            display: "block",
            height: 22,
            borderBottom: "1px solid #7b7b7b",
        },
        "& label":{
            fontSize: 13,
        }
    },
    tableCxc:{
        "& .head":{
            borderBottom: "1px solid "+theme.palette.primary.main,
            background: "#f6f6f6",
            marginBottom: 7,
            textTransform: "uppercase",
            padding: "0 8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        "&>div":{
            paddingBottom: 7,
            "& .detalles":{
                "&>div":{
                    background:"white"
                }
                // columns: 2,
                // "& p":{
                //     margin:0,
                //     "& span:first-child":{
                //         fontWeight: 500,
                //         marginRight: 10,
                //     }
                // }
            }

        }
    },
    contratoContent:{
        color:"#000",
        background:"white",
        fontSize: "18px !important",
        "& p":{
            textAlign: "justify",
            color: "black",
            fontFamily: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
            lineHeight: "30px",
        },
        "& table": {
            tableLayout: "fixed",
            width:"100%",
            "& *":{
                color: "inherit",
                border: "none !important",
                textAlign: "center",
                "& p": {
                    margin: 0,
                    lineHeight: "21px",
                    "&:first-child":{
                        fontWeight: "bold",
                        fontSize: 16,
                        textTransform: "uppercase",
                    }
                }

            },
            "& tr": {
                background: "white !important",
            },
            "& td": {
                paddingBottom: 79,
            }
        },
    },


}));
export default function Reports() {
    const classes = useStyles();
    let location = useLocation()
    let {type,id} = useParams();
    let query = urlPramsToObj(location.search);

//to show
    const [mostrarOpt, setMostrarOpt] = useState(["cabecera"]);
    const [aMostrar, setAMostrar] = useState(["cabecera"]);


    const [generales, setGenerales] = useState({});
    const [content, setContent] = useState(null);
    const [plantillaId, setPlantillaId] = useState(2)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState({})

    const [newUpdate, setNewUpdate] = useState(false);

    useEffect(() => {

        let query = urlPramsToObj(location.search);
        if(query.t && query.l){
             localStorage.setItem(ID_TOKEN_KEY, query.t);
             let lc = query["l"].split("-");
             setLSLang(lc[0],lc[1]);
             Strings.setLanguage(lc[0]);

            delete query["t"];
            delete query["l"];
        }

        let req;
        switch (type){
            case "cxc":
                req = getCxC(query?.estado === "incluirCxcAldia"?{incluirCxcAldia:true}:{});
                document.title = Strings.cuentasXcobrar+"_"+formatDate(new Date())
                break
            case "detallesPrestamo":
                req = getPrestamo(id);
                let ar = ["cabecera","total", "foto", "pagos", "amortizacion"];
                setMostrarOpt(ar);
                setAMostrar(ar);
                break
            case "contrato":
                req = getPlantillaConverted(plantillaId, {preId:id});
                document.title = Strings.contrato+"_"+formatDate(new Date());
                setAMostrar([]);
                break
            case "cliente":
                req = getClientes(parseInt(id));
                break
            case "amortizacion":
                document.title = Strings.amortizacion+"_"+formatDate(new Date())
                const {capital,interes,cuotas,ciclosPago,tipoAmortizacian,fecha} = query;
                let amort = buildAmort(capital,interes,cuotas,ciclosPago,tipoAmortizacian,fecha);
                setContent(amort);

                setMostrarOpt(["cabecera","total"]);
                setAMostrar(["cabecera","total"]);
                break
            default:
                req = null
        }

        if(req) {
            setLoading(prev=>({...prev,content:true}));

            req.then(({data})=>{
                setContent(data);
            }).catch(error=>{
                setContent(null);
                setError(error?.response)
            }).finally(()=>setLoading(prev=>({...prev,content:false})));
        }
    }, [type,id,plantillaId,setContent,setError,location,setLoading,setMostrarOpt,setAMostrar]);
    useEffect(()=>{
        setLoading(prev=>({...prev,generales:true}));

        getGenerales().then(({data})=>{
            setGenerales(data)
        }).finally(()=>setLoading(prev=>({...prev,generales:false})));

    },[])
    const imprimir = ()=>{
        //if(isMobile.AndroidWebView()){
        if(isMobile.anyWebView() && type === "contrato"){
            if (isMobile.AndroidWebView()) {
               return  window.Android?.printTextAPP(htmltoText(content));
            }else if(isMobile.IosWebView()){
               return window.webkit?.messageHandlers?.printTextAPP?.postMessage(htmltoText(content));
            }
        }
        window?.print();
    }

    return (
        <>
            <Dialog
                open={newUpdate}
                onClose={()=>setNewUpdate(false)}
            >
                <DialogContent>
                    <Typography>¡Nueva actualización disponible!</Typography>
                    <br/>
                    <Button component={"a"} onClick={()=>setNewUpdate(false)}
                            color={"primary"}
                          href={isMobile.iOS()?"https://apps.apple.com/app/id1526690162":"https://play.google.com/store/apps/details?id=com.dsegura.prestamistapp2"}
                          rel="noopener noreferrer"
                          target={"_Blank"}
                            fullWidth
                    >
                        {Strings.actualizar}
                    </Button>
                    <Button size={"small"} fullWidth onClick={()=>setNewUpdate(false)}>Mas tarde</Button>

                </DialogContent>
            </Dialog>

            <div className={"reportPrint "+ classes.root}>
              {(loading.content || loading.generales) && <LoadingProgress vh/>}

              <div className={classes.topBar}>
                  <div>
                      <Button color={"inherit"} onClick={imprimir}>{Strings.imprimir}</Button>
                      {type === "contrato" && !!content && <Hidden only={"xs"}>
                          <Button color={"inherit"} onClick={()=>textToPDF(content,58)}>58mm</Button>
                          <Button color={"inherit"} onClick={()=>textToPDF(content,80)}>80mm</Button>
                      </Hidden>}
                  </div>
                  <div className={"controls"}>
                      {/*<FormControlLabel control={<Switch size="small" checked={showCabecera} onChange={(e)=>setShowCabecera(e.target.checked)}/>} label={Strings.cabecera} />*/}
                      {type === "contrato" &&
                        <SelectPlantilla
                                tipo={2}
                                value={plantillaId}
                                onChange={(e) => {
                                    setPlantillaId(e.target.value);
                                    setError(null)
                                }}
                        />
                          // <select
                          //        value={plantillaId}
                          //         onChange={(e) => {
                          //             setPlantillaId(e.target.value);
                          //             setError(null)
                          //         }}
                          // >
                          //     <option value="1">{Strings.plantillaId} 1</option>
                          //     <option value="2">{Strings.plantillaId} 2</option>
                          //     <option value="3">{Strings.plantillaId} 3</option>
                          // </select>
                      }

                      <FormControl className={classes.toShow} size={"small"}>
                          <Select
                              multiple
                              value={aMostrar}
                              onChange={({target})=>setAMostrar(target.value)}
                              input={<Input />}
                              displayEmpty={true}
                              renderValue={() => Strings.mostrar}
                          >
                              {mostrarOpt.map((name) => (
                                  <MenuItem key={name} value={name}>
                                      <Checkbox checked={aMostrar.indexOf(name) > -1} />
                                      <ListItemText primary={Strings[name]} />
                                  </MenuItem>
                              ))}
                          </Select>
                      </FormControl>
                  </div>
              </div>
              <Collapse in={aMostrar.includes("cabecera")}>
                  <div className={classes.topHead}>
                      <div></div>
                      {/*{Strings.cuentasXcobrar.toUpperCase()}*/}
                      <div className="info">
                          <div>
                              <h2>{generales?.genEmpresaNombre}</h2>
                              <span className="tel">
                            <i>
                                {!!generales?.genEmpresaTelefono && <span> Tel: {generales?.genEmpresaTelefono}</span>}
                                {!!generales?.genEmpresaTelefono2 && <span> &nbsp;/ &nbsp; {generales?.genEmpresaTelefono2}</span>}
                                {!!generales?.genEmpresaRNC && <> - <span>{geoString("rnc",true)}: {generales?.genEmpresaRNC}</span></>}

                            </i>
                          </span>
                              <br/>
                              <span className="dir">{generales?.genEmpresaDireccion}</span>
                          </div>
                          {!!generales?.genEmpresaLogo &&
                          <div className="logo">
                              <img alt="logo" src={generales?.genEmpresaLogo} />
                          </div>
                          }
                      </div>
                  </div>
              </Collapse>
              {error && <MostrarError errors={error} />}

              {type === "cxc" && !!content &&
                  <CxC content={content} query={query} />
              }
              {type === "detallesPrestamo" && !!content &&
                  <DetallesPrestamo content={content} aMostrar={aMostrar}/>
              }
              {type === "contrato" && !!content &&
                <Contrato content={content} />
              }
              {type === "cliente" && !!content &&
                <Cliente content={content} />
              }
              {type === "amortizacion" && !!content &&
                <Amortizacion {...{content, query, aMostrar}}/>
              }
          </div>
        </>
    );
}

function CxC({content,query}){
    const classes = useStyles();
    const [list, setList] = useState([]);
    useEffect(()=>{
        let l = filterCxCList(content,query);
        setList(l);
    },[content,query,setList])

    return (
        <>
            <div className={classes.tableCxc}>
                {list.map(pre=>
                    <div key={pre.preId}>
                        <div className={"head"}>
                            <Typography color={"primary"}>{pre.cliente}</Typography>
                            <Typography variant="caption" className="estado">{pre.cuotasAtrasadas>1 && pre.cuotasAtrasadas}  {Strings[pre.estado]}</Typography>
                        </div>
                        <div className={"detalles"}>
                            <div className={classes.infoPrestamo}>
                                <Grid container spacing={1}>
                                    <Grid item xs={4}>
                                        <span>{Strings.telefono}:</span>
                                        <span>{pre.cliTelefono}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{Strings.celular}:</span>
                                        <span>{pre.cliCelular}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{Strings.direccion}:</span>
                                        <span>{pre.cliDireccion}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{Strings.cuotas} {Strings.pagada}:</span>
                                        <span>{pre.prePlazoString}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{Strings.balance} {Strings.pendiente}:</span>
                                        <span>{formatPeso(pre.preBalancePendiente)}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{Strings.monto} {Strings.cuota}:</span>
                                        <span>{formatPeso(pre.preMontoCuota)}</span>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <span>{Strings.proximo} {Strings.pago}:</span>
                                        <span>{formatDate(pre.preFechaProximoPago,"DD MMMM, YYYY")}</span>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/*{list.map(e=>*/}
            {/*    <ItemPrestamo key={e.preId} item={e}/>*/}
            {/*)}*/}
        </>
    )
}
function DetallesPrestamo({content,aMostrar}){
    const classes = useStyles();
    useEffect(()=>{
        document.title = Strings.detalles+" "+Strings.prestamo+"_"+content.cliente;
    },[content]);


    const pagos = content.pagos.filter(p=>!p.pagAnulado && p.pagTipo !== 4)


    return(
         <>

             <div className={classes.barTitle}>
                 <Typography color={"primary"} variant={"h6"}>{content.cliente}</Typography>
                 <span>{content.cuotasAtrasadas>1 && content.cuotasAtrasadas} {Strings[content.preEstado]} </span>
             </div >


             <div className={classes.cliInfo}>
                 {content.cliAvatar && aMostrar.includes("foto") &&
                     <div className={"avatar "+classes.client}>
                         {!!content.cliAvatar && <img height="135" width="135" alt="Avatar" src={content.cliAvatar}/>}
                     </div>
                 }
                 <div className={"info "+classes.infoPrestamo}>
                         <Grid container spacing={1}>
                             {!!content.preReferencia &&
                             <Grid item xs={4} className={classes.itemInfo}>
                                 <span>{Strings.codigoRef}:</span>
                                 <span>{content.preReferencia}</span>
                             </Grid>
                             }
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.fechaApertura}:</span>
                                 <span>{formatDate(content.preFecha)}</span>
                             </Grid>
                             {!!content.preCodeudor &&
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.codeudor}:</span>
                                 <span>{content.preCodeudor}</span>
                             </Grid>
                             }
                             {!!content.preDiasExcluidos &&
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.diasExcluidos}:</span>
                                 <span>
                                  {content.preDiasExcluidos.split(",")
                                      .map(s=>moment().day(s).format("dd")).join(', ')}
                                </span>
                             </Grid>
                             }
                             {!!content.preFechaActualizacion &&
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.editado}:</span>
                                 <span>{formatDate(content.preFechaActualizacion)}</span>
                             </Grid>
                             }
                             {!!content.creadoPor &&
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.creadoPor}:</span>
                                 <span>{content.creadoPor}</span>
                             </Grid>
                             }
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.capitalActual}:</span>
                                 <span>{formatPeso(content.preCapitalActual)}</span>
                             </Grid>
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.cuotas}:</span>
                                 <span>{content.prePlazoString}</span>
                             </Grid>
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.amortizacion}:</span>
                                 <span>{Strings[content.preTipoAmortizacionString]}</span>
                             </Grid>
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.proximo} {Strings.pago}:</span>
                                 <span>{formatDate(content.preFechaProximoPago)}</span>
                             </Grid>

                             <Grid item xs={4} className={classes.itemInfo}>
                                 <span>{Strings.interes}:</span>
                                 <span>{content.preTasaInteres} %</span>
                             </Grid>
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.capitalInicial}:</span>
                                 <span>{formatPeso(content.preCapitalInicial)}</span>
                             </Grid>
                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.balancePendiente}:</span>
                                 <span>{formatPeso(content.preBalancePendiente)}</span>
                             </Grid>
                             <Grid item xs={4} className={classes.itemInfo}>
                                 <span>{Strings.modalidad}:</span>
                                 <span>{Strings[content.preCiclosPagoString]}</span>
                             </Grid>

                             <Grid item xs={4}  className={classes.itemInfo}>
                                 <span>{Strings.parcialPendiente}:</span>
                                 <span>{formatPeso(content.prePagoPendiente)}</span>
                             </Grid>
                         </Grid>
                     </div>
             </div>
             {aMostrar.includes("pagos") && <>
                 <div className={classes.barTitle}>
                     <Typography color={"secondary"}>{Strings.pagos}</Typography>
                 </div >
                 <TableContainer>
                     <Table className={"noHover"} size="small">
                         <TableHead>
                             <TableRow>
                                 <TableCell>{Strings.concepto}</TableCell>
                                 <TableCell>{Strings.fecha}</TableCell>
                                 <TableCell>{Strings.capital}</TableCell>
                                 <TableCell>{Strings.interes}</TableCell>
                                 <TableCell>{Strings.mora}</TableCell>
                                 <TableCell>{Strings.descuento}</TableCell>
                                 <TableCell>{Strings.totalPagado}</TableCell>
                             </TableRow>
                         </TableHead>
                         <TableBody>
                             {pagos.map(n =>
                                 <TableRow key={n.pagId} className={n.pagAnulado?classes.pagAnulado:n.pagTipo === 4?classes.pagAjuste:""}>

                                     <TableCell component="th" scope="row">{n.pagConcepto}</TableCell>
                                     <TableCell>{formatDate(n.pagFecha)}</TableCell>
                                     {n.pagTipo === 4?(
                                         <TableCell colSpan={4}/>
                                     ):(
                                         <>
                                             <TableCell>{formatPeso(n.pagCapital)}</TableCell>
                                             <TableCell>{formatPeso(n.pagInteres)}</TableCell>
                                             <TableCell>{formatPeso(n.pagMora)}</TableCell>
                                             <TableCell>{formatPeso(n.pagDescuento)}</TableCell>
                                         </>
                                     )}

                                     <TableCell>{formatPeso(n.pagTotal)}</TableCell>
                                 </TableRow>
                             )}
                         </TableBody>
                         {aMostrar.includes("total") &&
                         <TableFooter>
                             <TableRow>
                                 <TableCell align={"right"} colSpan={2}>{Strings.total}:</TableCell>
                                 <TableCell>{formatPeso(pagos?.reduce((a, b) => +a + +b.pagCapital, 0))}</TableCell>
                                 <TableCell>{formatPeso(pagos?.reduce((a, b) => +a + +b.pagInteres, 0))}</TableCell>
                                 <TableCell>{formatPeso(pagos?.reduce((a, b) => +a + +b.pagMora, 0))}</TableCell>
                                 <TableCell>{formatPeso(pagos?.reduce((a, b) => +a + +b.pagDescuento, 0))}</TableCell>
                                 <TableCell>{formatPeso(pagos?.reduce((a, b) => +a + +b.pagTotal, 0))}</TableCell>
                             </TableRow>
                         </TableFooter>
                         }
                     </Table>
                 </TableContainer>
                 <br/>
                 <br/>
                 <p style={{pageBreakBefore: "always"}}/>
             </>}

             {aMostrar.includes("amortizacion") && <>
                 <div className={classes.barTitle}>
                     <Typography color={"secondary"}>{Strings.amortizacion}</Typography>
                     {aMostrar.includes("total") &&
                         <div className="total">
                             <Typography color={"secondary"} component={"span"}>{Strings.total} {Strings.interes}: </Typography>
                             <i>
                                 {formatPeso(content.amortizacion?.reduce((a, b) => +a + +b.interes, 0))}
                             </i> &nbsp; <Typography color={"secondary"} component={"span"}>{Strings.totalApagar}: </Typography>
                             <i>
                                 {formatPeso(content.amortizacion?.reduce((a, b) => +a + +b.total, 0))}
                             </i>
                         </div>
                     }
                 </div >
                 <TableContainer>
                     <Table className={"noHover"} size="small">
                         <TableHead>
                             <TableRow>
                                 <TableCell>No - {Strings.fecha}</TableCell>
                                 <TableCell>{Strings.balance}</TableCell>
                                 <TableCell>{Strings.capital}</TableCell>
                                 <TableCell>{Strings.interes}</TableCell>
                                 <TableCell>{Strings.cuota}</TableCell>
                             </TableRow>
                         </TableHead>
                         <TableBody>
                             {content.amortizacion.map(n =>
                                 <TableRow key={n.cuota}>
                                     <TableCell>{n.cuota+" - "+formatDate(n.fecha)}</TableCell>
                                     <TableCell>{formatPeso(n.balance)}</TableCell>
                                     <TableCell>{formatPeso(n.abonoCapital)}</TableCell>
                                     <TableCell>{formatPeso(n.interes)}</TableCell>
                                     <TableCell>{formatPeso(n.total)}</TableCell>
                                 </TableRow>
                             )}
                         </TableBody>
                     </Table>
                 </TableContainer>
             </>}
         </>
     )
}
function Contrato({content}){
    const classes = useStyles();

    return (
        <div className={classes.contratoContent}>
            {content && renderHTML(content)}
        </div>
    )
}
function Cliente({content}){
    const customFormInput = useCustomFormInput();
    const classes = useStyles();
    useEffect(()=>{
        document.title = Strings.cliente+` ${content.cliNombres} ${content.cliApellidos}`;
    },[content])
    let cliTipo = parseInt(content?.cliTipo ?? 0);
    return (
        <>
            <br/>
            <Grid container spacing={3} className={classes.cliImgCont}>
                {content.cliAvatar &&
                <Grid item xs={2}>
                    <div className={classes.avatar}>
                        <img src={content.cliAvatar} height="135" width="135" alt="Avatar"/>
                    </div>
                </Grid>}
                <Grid item xs={content.cliAvatar ? 10 :12} sx={{marginBottom: 8}}>
                    <Grid container spacing={3} className={classes.cliItems}>
                        {cliTipo === 2 &&
                        <Grid item xs={3}>
                            <div className={classes.campo}>
                                <span>{content.cliRazonSocial}</span>
                                <label>{Strings.razonSocial}</label>
                            </div>
                        </Grid>}
                        <Grid item xs={6}>
                            <div className={classes.campo}>
                                <span>{content.cliNombres} {content.cliApellidos}</span>
                                <label>{Strings.primerNombre}</label>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.campo}>
                                <span>{content.cliCedula}</span>
                                <label>{cliTipo === 2 ? geoString("rnc",true):Strings.cedula}</label>
                            </div>
                        </Grid>
                        {cliTipo !== 2 &&
                        <Grid item xs={3}>
                            <div className={classes.campo}>
                                <span>{content.cliSexo}</span>
                                <label>{Strings.genero}</label>
                            </div>
                        </Grid>
                        }
                        <Grid item xs={3}>
                            <div className={classes.campo}>
                                <span>{content.cliCelular}</span>
                                <label>{Strings.celular}</label>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.campo}>
                                <span>{content.cliTelefono}</span>
                                <label>{Strings.telefono}</label>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.campo}>
                                <span><SelectPais value={content.cliNacionalidad || ""}/></span>
                                <label>{Strings.nacionalidad}</label>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.campo}>
                                <span><SelectEnum value={content.cliTipoVivienda} group={"tiposVivienda"} /></span>
                                <label>{Strings.vivienda}</label>
                            </div>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.cliItems}>
                {cliTipo !== 2 && <>
                    <Grid item xs={2}>
                        <div className={classes.campo}>
                            <span><SelectEnum value={content.cliEmpleo} group={"condicionesLaboral"} /></span>
                            <label>{Strings.situacionLaboral}</label>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span>{formatPeso(content.cliIngresos)}</span>
                            <label>{Strings.ingreso}</label>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.campo}>
                            <span><SelectEnum value={content.cliEstadoCivil}  group={"estadosCivil"} /></span>
                            <label>{Strings.estadoCivil}</label>
                        </div>
                    </Grid>
                    <Grid item xs={2}>
                        <div className={classes.campo}>
                            <span>{content.cliDependientes}</span>
                            <label>{Strings.dependientes}</label>
                        </div>
                    </Grid>
                </>}
                {customFormInput && Object.keys(customFormInput).map((key)=><>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span>{content[key]}</span>
                            <label>{customFormInput[key].name}</label>
                        </div>
                    </Grid>
                </>)}
                <Grid item xs={12}>
                    <div className={classes.campo}>
                        <span>{content.cliDireccion} {content?.cliDireccion2?.indexOf("http") === -1 && " / "+content.cliDireccion2}</span>
                        <label>{Strings.direccion}</label>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className={classes.campo}>
                        <span>{content.cliCodigoPostal}</span>
                        <label>{Strings.codigoPostal}</label>
                    </div>
                </Grid>
                {cliTipo !== 2 &&
                <Grid item xs={3}>
                    <div className={classes.campo}>
                        <span>{formatDate(content.cliFechaNacimiento)}</span>
                        <label>{Strings.fechaNacimiento}</label>
                    </div>
                </Grid>}
                <Grid item xs={2}>
                    <div className={classes.campo}>
                        <span>{content.cliFaceBook}</span>
                        <label>FaceBook</label>
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className={classes.campo}>
                        <span>{content.cliInstagram}</span>
                        <label>Instagram</label>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div className={classes.campo}>
                        <span>{content.cliEmail}</span>
                        <label>Email</label>
                    </div>
                </Grid>
            </Grid>
            {content.cliEmpleo.toString() === "1" && cliTipo !== 2 &&
            <>
                <br/>
                <Typography color={"secondary"}>{Strings.empresa}</Typography>
                <br/>
                <Grid container spacing={3} className={classes.cliItems}>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span>{content.cliTrabajoNombre}</span>
                            <label>{Strings.empresa}</label>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span><SelectEnum value={content.cliTrabajoIntervaloCobro} group={"ciclosPago"} /></span>
                            <label>{Strings.intervaloCobro}</label>
                        </div>

                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span>{content.cliDiasCobro}</span>
                            <label>{Strings.diasCobro}</label>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span>{content.cliTrabajoPosicion}</span>
                            <label>{Strings.posicion}</label>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span>{content.cliTiempoLaborando}</span>
                            <label>{Strings.tiempoLaborando}</label>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.campo}>
                            <span>{content.cliTrabajoTelefono}</span>
                            <label>{Strings.telefono}</label>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={classes.campo}>
                            <span>{content.cliTrabajoDireccion}</span>
                            <label>{Strings.direccion}</label>
                        </div>
                    </Grid>
                </Grid>
            </>
            }
            {!!content?.cliReferencias?.length &&
                <>
                    <br/>
                    <Typography color={"secondary"}>{Strings.referencia}</Typography>
                    <br/>
                    <TableContainer>
                        <Table className={"noHover"} size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{Strings.tipo}</TableCell>
                                    <TableCell>{Strings.nombreCompleto}</TableCell>
                                    <TableCell>{Strings.telefono}</TableCell>
                                    <TableCell>{Strings.parentesco}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {content.cliReferencias.map((ref,i)=>{
                                    return(
                                        <TableRow key={i+1}>
                                            <TableCell><SelectEnum value={ref.tipo} group={"tiposReferencia"}/></TableCell>
                                            <TableCell>{ref.nombre}</TableCell>
                                            <TableCell>{ref.telefono}</TableCell>
                                            <TableCell><SelectEnum value={ref.parentesco} group={"parentescos"}/></TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
        </>
    )
}
function Amortizacion({content,query,aMostrar = []}){
    const classes = useStyles();
    const [simularComision,setSimularComision] = useState(false);
    const [comision, setComision] = useState(0);


    return(
        <>
            <div className={classes.infoPrestamo}>
                <Grid container spacing={1} >
                    <Grid item xs={4}  className={classes.itemInfo}>
                        <span>{Strings.primerPago}:</span>
                        <span>{formatDate(query.fecha)}</span>
                    </Grid>
                    <Grid item xs={4}  className={classes.itemInfo}>
                        <span>{Strings.capitalInicial}:</span>
                        <span>{formatPeso(query.capital)}</span>
                    </Grid>
                    <Grid item xs={4}  className={classes.itemInfo}>
                        <span>{Strings.cuotas}:</span>
                        <span>{query.cuotas}</span>
                    </Grid>
                    <Grid item xs={4}  className={classes.itemInfo}>
                        <span>{Strings.amortizacion}:</span>
                        <span><SelectEnum value={query.tipoAmortizacian} group={"tipoAmortizacion"} /></span>
                    </Grid>
                    <Grid item xs={4} className={classes.itemInfo}>
                        <span>{Strings.interes}:</span>
                        <span>{query.interes} %</span>
                    </Grid>
                    <Grid item xs={4} className={classes.itemInfo}>
                        <span>{Strings.modalidad}:</span>
                        <span><SelectEnum value={query.ciclosPago} group={"ciclosPago"} /></span>
                    </Grid>
                </Grid>
            </div>
            <Box p={1} className={"noPrint"}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <HtmlTooltip title={Strings.simularComisionMsj}>
                            <FormControlLabel
                                control={<Checkbox checked={simularComision}
                                                   onChange={e=>setSimularComision(e.target.checked)} />}
                                label={"Simular "+Strings.comision} />
                        </HtmlTooltip>
                    </Grid>
                    <Grid item xs={6}>
                        {simularComision &&
                        <TextField
                            name="comision"
                            required={simularComision}
                            value={comision}
                            onChange={e=>setComision(e.target.value)}
                            type="number"
                            inputProps={{step:"any",inputMode:"decimal"}}
                            fullWidth
                            label={"% "+Strings.comision}
                            margin={"none"}
                            size="small"
                            variant={"outlined"}
                        />
                        }
                    </Grid>
                </Grid>
            </Box>

            <div className={classes.barTitle}>
                <Typography color={"secondary"}>{Strings.amortizacion}</Typography>
                {aMostrar.includes("total") &&
                <div className="total">
                    <div>
                        <span >{Strings.total} {Strings.interes}: {!!simularComision && <>+ {Strings.comision}</>}</span> <i> {formatPeso(content?.reduce((a, b) => +a + +b.interes, 0))}</i>
                    </div>
                    <div>
                        <span>{Strings.totalApagar}: </span> <i> {formatPeso(content?.reduce((a, b) => +a + +b.totalaPagar, 0))}</i>
                    </div>
                </div>
                }
            </div >
            <TableContainer>
                <Table className={"noHover"} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>No - {Strings.fecha}</TableCell>
                            <TableCell className="hl">{Strings.totalApagar}</TableCell>
                            <TableCell>{Strings.capitalPendiente}</TableCell>
                            <TableCell>{Strings.abonoCapital}</TableCell>
                            <TableCell>{Strings.interes}</TableCell>
                            {!!simularComision &&
                                <TableCell>{Strings.comision}</TableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {content && content.map(n => {
                            return (
                                <TableRow key={n.cuota}>
                                    <TableCell>{n.cuota} - {n.fecha}</TableCell>
                                    <TableCell className="hl">{formatPeso(n.totalaPagar)}</TableCell>
                                    <TableCell>{formatPeso(n.balanceRestante)}</TableCell>
                                    <TableCell>{formatPeso(n.capitalApagar)}</TableCell>
                                    {!!simularComision?(
                                        <>
                                            <TableCell>{formatPeso(n.interes * ((100 - comision) / 100))}</TableCell>
                                            <TableCell>{formatPeso(n.interes * (comision/100))}</TableCell>
                                        </>
                                    ):(
                                        <TableCell>{formatPeso(n.interes)}</TableCell>
                                    )}

                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

