import {useEffect,useState} from 'react';
import {useSelector,useDispatch} from "react-redux";
import {mostrarEnumsSinoEsta} from "../redux/actions/enumsActions";
import Strings from "../assets/strings";
import _ from "lodash";

export function useEnums(group) {
    const enumGroups = useSelector(state => state.enums.list)
    const dispatch = useDispatch();
    const [enums, setEnums] = useState([]);

    useEffect(()=>{
        dispatch(mostrarEnumsSinoEsta());
    })
    useEffect(()=>{
        let items = enumGroups[group];
        if(items){
            setEnums(items.map(e=>({...e,value: Strings[_.camelCase(e.value)]})));
        }
    },[group,setEnums,enumGroups])

    return enums;
}
