import {plantillaTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    byId:null,
    isFetching: false,
    isPending: false,
    error: null,
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case plantillaTypes.MOSTRAR_PLANTILLAS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {},
                byId:null,
            }
        }
        case plantillaTypes.MOSTRAR_PLANTILLAS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
            }
        }
        case plantillaTypes.MOSTRAR_PLANTILLAS + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //mostrar por id
        case plantillaTypes.MOSTRAR_PLANTILLA_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case plantillaTypes.MOSTRAR_PLANTILLA_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
            }
        }
        case plantillaTypes.MOSTRAR_PLANTILLA_POR_ID + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        case plantillaTypes.MOSTRAR_PLANTILLA_POR_ID_ENCONTRADO:  {
            const { byId } = action.payload;
            return {
                ...state,
                byId
            }
        }

        //agregar  o editar
        
        case plantillaTypes.AGREGAR_EDITAR_PLANTILLA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case plantillaTypes.AGREGAR_EDITAR_PLANTILLA + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list.filter(e => e.plaId !== data.plaId);
            list.unshift(data);

            return {
                ...state,
                list,
                isPending: false,
                fulfilled: true,
                error: {}
            }
        }
        case plantillaTypes.AGREGAR_EDITAR_PLANTILLA + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Eliminar

        case plantillaTypes.ELIMINAR_PLANTILLA + _FULFILLED: {
            const { data: id  } = action.payload;
            let list = state.list.filter(e => e.plaId !== id);
            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case plantillaTypes.ELIMINAR_PLANTILLA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }

    default:
      return state;
  }
}
