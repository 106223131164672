import React, {Fragment, useState, useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import { connect } from 'react-redux';
import {mostrarSolicitudById,aprobarSolicitud,rechazarSolicitud } from '../../redux/actions/solicitudActions';
import {nullToEmptyString, PERSMISOS, hasPermissionTo, getLSLang, geoString} from '../../helpers';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Typography,
    Input,
    IconButton,
    Menu,
    Snackbar,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
    SelectEnum,
    SelectRuta,
    SelectFiles,
    SelectPais,
    SelectCaja,
    SelectDay,
    SelectAgente,
    SelectCartera
} from "../../components/selects"
import {AppBar, MostrarError, LoadingProgress, ReferenciasCliente, HtmlTooltip} from "../../components";
import {TextFieldCurrency, TextFieldPhone, DateInput} from "../../components/CustomTextField";
import Strings from "../../assets/strings";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from "@mui/material/Box";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import AddAdjuntos from "../../components/AddAdjuntos";
import {editSolicitud, postSolicitud} from "../../services";
import Swal from "sweetalert2";
import {useCustomFormInput} from "../../hooks/useCustomFormInput";
import MapSearch from "../../components/MapSearch";
import {MoreVert} from "@mui/icons-material";

import AmortCal, {getAmortList} from "../../components/calAmort";
import {isMobile} from "../../helpers/browser_detect";

const useStyles = makeStyles(theme => ({
    root:{
        padding:"20px",
        "& form#form1":{
            marginBottom: theme.spacing(2)
        }
    },
    btn:{
        marginRight: 5,
        color:theme.palette.common.white,
        fontWeight:"normal",
    },
    avatar:{
        "& img":{
            maxWidth: 150
        },
        [theme.breakpoints.down('md')]: {
            "& img":{
                borderRadius: theme.shape.borderRadius,
            }
        }

    },
    submitBtn:{
        margin: 20,
    },
    preCont:{
        borderTop: "2px solid " + theme.palette.secondary.main,
        paddingTop: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
}));
let initialState = {
    actualizarCliente:false,
    solId: "",
    solClienteTipo: "1",
    solRazonSocial:"",
    solNombres: "",
    solApellidos: "",
    solEmail: "",
    solCedula: "",
    solNacionalidad: getLSLang("c"),
    solEstadoCivil: "",
    solSexo: "",
    solFechaNacimiento: "",
    solCelular: "",
    solTelefono: "",
    solAvatar: "",
    solTipoVivienda: "",
    solDireccion: "",
    solDireccion2: "",
    solCoordenadas:"",
    solEmpleo: "",
    solTrabajoNombre: "",
    solTrabajoDireccion: "",
    solTrabajoTelefono: "",
    solTrabajoPosicion: "",
    solTrabajoIntervaloCobro: "",
    solDiasCobro: "",
    solIngresos: "",
    solTiempoLaborando: "",
    referenciaPersonal: "",
    solTelefonoRefPersonal: "",
    userId: null,
    //----------
    solCodigoPostal: "",
    solReferencias: [],
    archivos:[],
    solDependientes: "",
    ruta: "",
    rutId: "",
    carId:"",
    //prestamos
    solReferencia:"",
    solMontoPrestamo: "",
    solCuotas: "",
    solTasaInteres: "",
    solTipoAmortizacion: "",
    solCiclosPago: "",
    solFechaProximoPago: "",
    solDiasExcluidos: "",
    cajId: "",
    usuIdAgente:"",
    solPorcientoComision:"",
    solPorcientoMora:"",
    solCoodeudor: "",
    solCoodeudorCedula: "",
    solCoodeudorTelefono: "",
    solCoodeudorDireccion: "",
};

function DetallesSolicitud(props){
    const classes = useStyles();
    const webview = isMobile.anyWebView()

    const {state:urlState} = useLocation();

    const history = useHistory();

    const customFormInputCli = useCustomFormInput();
    const customFormInputPre = useCustomFormInput("pre");
    const [editar, setEditar] = useState(false);

    const [openMenu, setOpenMenu] = useState(null);

    const { user, solId:urlSolId} = useParams();
    const isPrivate = (!!urlSolId && !user);
    initialState.cajId = props.defaultCajId;


    const {aprobarSolicitud,mostrarSolicitudById, solicitud, isFetching,isPending:reduxIsPending, error:reduxError} = props;
    const [snackbarMsj, setSnackbarMsj] = useState(null);

    const [beforeEditState, setBeforeEditState] = useState({});

    const [state, setState] = useState(initialState);
    const [error, setError] = useState(reduxError);

    const [isPending, setIsPending] = useState(reduxIsPending);

    useEffect(()=>{
        if(urlSolId) mostrarSolicitudById(urlSolId);
    },[urlSolId,mostrarSolicitudById]);
    useEffect(()=>{
        if(!!solicitud.solId) {
            setState({...initialState,...nullToEmptyString(solicitud,true)});
        }else{
            setState(initialState)
        }
    },[solicitud]);

    // Reset fecha si se cambia a modalidad quincenal
    const {solCiclosPago} = state
    useEffect(()=>{
        if(solCiclosPago === "16"){
            setState(prevState => ({...prevState,
                solCiclosPago,
                solFechaProximoPago: "",
            }));
        }
    },[solCiclosPago]);

    const handleChange = (e)=>{
        const {name,value} = e.target;

        if(name === "actualizarCliente"){
            return setState({...state,[name]: (value === "true")});
        }
        return setState({...state,[name]: value});
    };

    const submit = async (e) =>{
        e.preventDefault();
        let obj = {...state};
        if(isPrivate){
            if(obj.solAvatar === solicitud.solAvatar ) obj.solAvatar = "";


            if(!!state?.montoCuota){
                const {solMontoPrestamo, solTasaInteres, solCuotas, solCiclosPago, solTipoAmortizacion, solFechaProximoPago, montoCuota} = state;
                await getAmortList({
                    capital: solMontoPrestamo,
                    interes: solTasaInteres,
                    cuotas: solCuotas,
                    ciclosPago: solCiclosPago,
                    tipoAmortizacian: solTipoAmortizacion,
                    fecha: solFechaProximoPago,
                    montoCuota
                }).then(res=> {
                    setState(prevState => ({...prevState,solTasaInteres: res.interes}))
                    obj.solTasaInteres = res.interes;
                })
            }




            if(editar){
                setIsPending(true);
                editSolicitud(obj).then(()=>{
                    setEditar(false);
                    setSnackbarMsj(Strings.enviadoExitosoMsj);
                }).finally(()=>setIsPending(false))
            }else{
                Swal.fire({
                    title:Strings.confirmar,
                    showCancelButton: true,
                    cancelButtonText:Strings.cancelar,
                    confirmButtonText: Strings.aprobar,
                }).then((result) => {
                    if (result.isConfirmed) {
                        aprobarSolicitud(obj);
                    }
                })
            }}
        else{
            setIsPending(true);
            postSolicitud(user,obj).then(()=>{
                Swal.fire({
                    title:"¡Solicitud enviada con éxito!",
                    text: 'Nos pondremos en contacto con usted a la mayor brevedad. Gracias',
                    icon:'success',
                    showCloseButton: true,
                    showConfirmButton: false,
                    onClose: () => {
                        setState(initialState)
                    }
                })
            }).catch(error=>{
                setError(error?.response)
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }).finally(()=>setIsPending(false))
        }
    };

    let cliTipo = parseInt(state.solClienteTipo);
    const estadoString = (id)=>{
        switch (id) {
            case 1:
                return Strings.pendiente;
            case 2:
                return Strings.aprobada;
            case 3:
                return Strings.rechazada;
            default:
                return ""
        }
    }
    const onClickEdit = ()=>{
        setBeforeEditState(state)
        setEditar(true)
    }
    const onCancelEdit = ()=>{
        setState(beforeEditState)
        setBeforeEditState({})
        setEditar(false)
    }
    const onRechazar = ()=>{
        Swal.fire({
            title:Strings.confirmar,
            showCancelButton: true,
            cancelButtonText:Strings.cancelar,
            confirmButtonText: Strings.rechazar,
        }).then((result) => {
            if (result.isConfirmed) {
                props.rechazarSolicitud(state.solId)
            }
        })
    }
    return(
        <Fragment>
            {isPrivate &&
                <AppBar title={Strings.solicitud+" "+(estadoString(state.solEstado))}>
                    {editar?(
                        <>
                            <Button color="primary" type="submit" form={"form1"} >{Strings.guardar}</Button>
                            <Button color="secondary" type="button" onClick={onCancelEdit}>{Strings.cancelar}</Button>
                        </>
                    ):(
                        <>
                            <IconButton aria-haspopup="true" size={"small"}
                                        onClick={(e)=>setOpenMenu(e.currentTarget)}>
                                <MoreVert />
                            </IconButton>
                            <Menu anchorEl={openMenu} keepMounted open={Boolean(openMenu)} onClick={()=>setOpenMenu(null)} onClose={()=>setOpenMenu(null)}>
                                {hasPermissionTo(PERSMISOS.solicitudesActualizar) &&
                                    <MenuItem type="button" onClick={onClickEdit}>{Strings.editar}</MenuItem>
                                }

                                {hasPermissionTo(PERSMISOS.solicitudesRechazar) && state.solEstado === 1 &&
                                    <MenuItem disabled={isPending} type="button" onClick={onRechazar}>
                                        {Strings.rechazar}
                                    </MenuItem>
                                }
                                {hasPermissionTo(PERSMISOS.solicitudesAprobar) && state.solEstado !== 2 &&
                                    <Button  disabled={isPending} color={"primary"} fullWidth type="submit" form={"form1"}>{Strings.aprobar}</Button>
                                }
                            </Menu>

                        </>
                    )}



                    {/*{hasPermissionTo(PERSMISOS.solicitudesActualizar) && state.solEstado !== 2 &&*/}
                    {/*    <Button color="primary" disabled={isPending} type="button" onClick={editar}>{Strings.guardar}</Button>*/}
                    {/*}*/}
                </AppBar>
            }
            <div className={"contentInner " + classes.root}>
                {error && <>
                    <MostrarError errors={error}/>
                    <br/>
                </>}
                {(isFetching || isPending) && <LoadingProgress vh/>}
                {state.clienteExistente && state.solEstado === 1 &&
                <Box component={Alert} severity="info" mb={1}>
                    <AlertTitle>{Strings.prestatario} {Strings.encontrado}.</AlertTitle>
                    {Strings.clienteEncontradoMsj}
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="actualizarCliente"
                                    value={state.actualizarCliente.toString()} onChange={handleChange}>
                            <FormControlLabel
                                value="false"
                                control={<Radio color="primary"/>}
                                label={Strings.actualizar}
                            />
                            <FormControlLabel
                                value="true"
                                control={<Radio color="primary"/>}
                                label={Strings.crear+" "+Strings.nuevo}
                            />
                        </RadioGroup>
                    </FormControl>
                </Box>
                }
                <form id="form1" onSubmit={submit} autoComplete={"off"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={2}>
                            <div className={classes.avatar}>
                                <SelectFiles src={state.solAvatar ? state.solAvatar : null} emp={cliTipo === 2}
                                             onChange={(baseb4)=>setState({ ...state, solAvatar: baseb4 } )}/>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={10}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={3}>
                                    <SelectEnum value={cliTipo} name={"solClienteTipo"} label={Strings.tipo} required
                                                onChange={handleChange} group={"tiposCliente"} />
                                </Grid>
                                {cliTipo === 2 &&
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name={"solRazonSocial"}
                                        label={Strings.razonSocial}
                                        value={state.solRazonSocial}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        fullWidth
                                    />
                                </Grid>}
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name="solNombres"
                                        label={Strings.primerNombre}
                                        placeholder={cliTipo === 2 ? Strings.representante:""}
                                        value={state.solNombres}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name="solApellidos"
                                        label={Strings.apellidos}
                                        placeholder={cliTipo === 2 ? Strings.representante:""}
                                        value={state.solApellidos}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextField
                                        name="solCedula"
                                        label={cliTipo === 2 ?geoString("rnc",true):Strings.cedula}
                                        placeholder={cliTipo === 2 ?geoString("rnc"):""}
                                        value={state.solCedula}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                {cliTipo !== 2 &&
                                    <Grid item xs={6} sm={4} md={3}>
                                        <FormControl fullWidth >
                                            <InputLabel>{Strings.genero}</InputLabel>
                                            <Select
                                                value={state.solSexo}
                                                onChange={handleChange}
                                                input={<Input name="solSexo" />}
                                            >
                                                <MenuItem value="">{Strings.seleccione}</MenuItem>
                                                <MenuItem value="M">{Strings.masculino}</MenuItem>
                                                <MenuItem value="F">{Strings.femenino}</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextFieldPhone
                                        name="solCelular"
                                        label={Strings.celular}
                                        value={state.solCelular}
                                        onChange={handleChange}
                                        fullWidth
                                        required={state.solTelefono?.length<4}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextFieldPhone
                                        name="solTelefono"
                                        label={Strings.telefono}
                                        value={state.solTelefono}
                                        onChange={handleChange}
                                        required={state.solCelular?.length<4}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6} md={3} sx={{display:{xs:"none",sm:"flex"}}}>
                                    <SelectPais
                                        name="solNacionalidad"
                                        display="paiNacionalidad"
                                        label={Strings.nacionalidad}
                                        value={state.solNacionalidad}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={6} md={3} sx={{display:{xs:"flex",sm:"none"}}}>
                            <SelectPais
                                name="solNacionalidad"
                                display="paiNacionalidad"
                                label={Strings.nacionalidad}
                                value={state.solNacionalidad}
                                onChange={handleChange}
                            />
                        </Grid>
                        {cliTipo !== 2 && <>
                            <Grid item xs={6} sm={3} md={3}>
                                <SelectEnum value={state.solTipoVivienda} name={"solTipoVivienda"} label={Strings.vivienda}
                                            onChange={handleChange} group={"tiposVivienda"} />
                            </Grid>
                            <Grid item xs={6} sm={3} md={4} lg={cliTipo ===2?2: 3}>
                                <SelectEnum value={state.solEmpleo} name={"solEmpleo"} label={Strings.situacionLaboral}
                                                onChange={handleChange} group={"condicionesLaboral"} />
                            </Grid>
                            <Grid item xs={6} sm={3} md={3} lg={2}>
                                <TextFieldCurrency
                                    name="solIngresos"
                                    label={Strings.ingreso}
                                    value={state.solIngresos}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} sm={3} md={2}>
                                <SelectEnum value={state.solEstadoCivil} name={"solEstadoCivil"} label={Strings.estadoCivil}
                                            onChange={handleChange} group={"estadosCivil"} />
                            </Grid>

                            <Grid item xs={6} sm={3} md={2}>
                                <TextField
                                    name="solDependientes"
                                    label={Strings.dependientes}
                                    value={state.solDependientes}
                                    type="number"
                                    inputProps={{step:1,inputMode:"numeric"}}
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </Grid>
                        </>}
                        <Grid item xs={12} sm={cliTipo === 2?6:9} md={cliTipo === 2?6:5} lg={5}>
                            {/*<TextField*/}
                            {/*    name="solDireccion"*/}
                            {/*    value={state.solDireccion}*/}
                            {/*    label={Strings.direccion}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    inputProps={{maxLength: 100}}*/}
                            {/*    fullWidth*/}
                            {/*/>*/}

                            <MapSearch handleChange={({cliDireccion: solDireccion,cliCoordenadas: solCoordenadas })=>
                                       setState({...state,solDireccion,solCoordenadas})}
                                       cliDireccion = {state.solDireccion} cliCoordenadas={state.solCoordenadas}/>

                        </Grid>
                        <Grid item xs={12}  sm={cliTipo === 2?6:5} md={cliTipo === 2?6:5} lg={5}>
                            <TextField
                                name="solDireccion2"
                                label={Strings.direccion + " 2"}
                                placeholder={Strings.referenciaDireccion}
                                value={state.solDireccion2}
                                onChange={handleChange}
                                inputProps={{maxLength: 100}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} lg={2}>
                            <TextField
                                name="solCodigoPostal"
                                label={Strings.codigoPostal}
                                value={state.solCodigoPostal}
                                onChange={handleChange}
                                inputProps={{maxLength: 20}}
                                fullWidth
                            />
                        </Grid>

                        {cliTipo !== 2 &&
                        <Grid item xs={12} sm={4} lg={3}>
                                <DateInput
                                    openTo={"year"}
                                    clearable
                                    value={state.solFechaNacimiento}
                                    name="solFechaNacimiento"
                                    label={Strings.fechaNacimiento}
                                    onChange={handleChange}
                                    fullWidth
                                />
                        </Grid>}
                        <Grid item xs={12} sm={4} md={5} lg={3}>
                            <TextField
                                name="solEmail"
                                type={"email"}
                                label={Strings.email}
                                value={state.solEmail}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                            />
                        </Grid>
                        {isPrivate &&
                            <Grid item xs={12} sm={8} md={4} lg={3}>
                                <SelectRuta value={state.rutId}
                                            name={"rutId"}
                                            label={Strings.ruta}
                                            onChange={handleChange}  />
                            </Grid>
                        }
                        {customFormInputCli && Object.keys(customFormInputCli).map((key)=>
                            <Grid item xs={12} sm={4} lg={3} key={key}>
                                <TextField
                                    name={key}
                                    label={customFormInputCli[key].name}
                                    required={customFormInputCli[key].isRequired}
                                    value={state[key] ? state[key] : ""}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />
                            </Grid>
                        )}
                    </Grid>
                    <br/>

                    <AddAdjuntos adjuntos={state.archivos} onChange={(archivos)=>setState({...state,archivos})} />
                    {state.solEmpleo.toString() === "1" &&
                        <>
                            <br/>
                        <Typography color={"secondary"}>{Strings.empresa}</Typography>
                            <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                name="solTrabajoNombre"
                                label={Strings.nombres}
                                value={state.solTrabajoNombre}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <SelectEnum value={state.solTrabajoIntervaloCobro} name={"solTrabajoIntervaloCobro"}
                                            label={Strings.intervaloCobro} onChange={handleChange} group={"ciclosPago"} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    name="solDiasCobro"
                                    label={Strings.diasCobro}
                                    value={state.solDiasCobro}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                name="solTrabajoPosicion"
                                label={Strings.posicion}
                                value={state.solTrabajoPosicion}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                                required
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                name="solTiempoLaborando"
                                label={Strings.tiempoLaborando}
                                value={state.solTiempoLaborando}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextFieldPhone
                                name="solTrabajoTelefono"
                                label={Strings.telefono}
                                value={state.solTrabajoTelefono}
                                onChange={handleChange}
                                fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                name="solTrabajoDireccion"
                                label={Strings.direccion}
                                value={state.solTrabajoDireccion}
                                onChange={handleChange}
                                inputProps={{maxLength: 100}}
                                fullWidth
                                />
                            </Grid>
                        </Grid>
                        </>
                    }
                    <>
                        <br/>
                        <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"} height={36}>
                            <Typography sx={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                                {Strings.prestamo}
                            </Typography>
                            {isPrivate &&
                                <Button type="button" color={"primary"}
                                        onClick={() => history.push(history.location.pathname, {showCal: true})}
                                >
                                    {Strings.amortizacion}
                                </Button>
                            }
                        </Box>
                        <br/>
                        <Grid container spacing={3}>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextFieldCurrency
                                        name="solMontoPrestamo"
                                        label={Strings.monto +" "+Strings.aPrestar}
                                        value={state.solMontoPrestamo}
                                        onChange={handleChange}
                                        fullWidth
                                        required
                                        min={5}
                                    />
                                </Grid>
                                {isPrivate &&
                                    <Grid item xs={6} sm={4} md={3}>
                                        <SelectEnum value={state.solTipoAmortizacion} name={"solTipoAmortizacion"} label={Strings.amortizacion} required={!editar}
                                                    onChange={handleChange} group={"tipoAmortizacion"} />
                                    </Grid>
                                }
                                <Grid item xs={6} sm={4} md={3}>
                                    <SelectEnum value={state.solCiclosPago} name={"solCiclosPago"} label={Strings.modalidad} required={!editar}
                                                onChange={handleChange} group={"ciclosPago"} />
                                </Grid>
                                {isPrivate &&
                                    <Grid item xs={6} sm={3}>
                                        <FormControl required={!editar} fullWidth>
                                            <InputLabel htmlFor="solTasaInteres">
                                                % {Strings.tasaInteres} &nbsp;
                                                {!!state.solCiclosPago.length &&
                                                <SelectEnum value={state.solCiclosPago} group={"ciclosPago"}/>}
                                            </InputLabel>
                                            <Input id="solTasaInteres"
                                                   name="solTasaInteres"
                                                   value={state.solTasaInteres}
                                                   type="number"
                                                   inputProps={{step: "any", inputMode: "decimal"}}
                                                   required={!editar}
                                                   onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                                <Grid item xs={6} sm={2}>
                                    <TextField
                                        name="solCuotas"
                                        label={"# "+Strings.cuotas}
                                        type="number"
                                        inputProps={{step:1,inputMode:"numeric"}}
                                        required={!editar}
                                        value={state.solCuotas}
                                        fullWidth
                                        onChange={handleChange}
                                    />
                                </Grid>
                            {isPrivate && <>
                                    <Grid item xs={6} sm={2}>
                                        <HtmlTooltip title={
                                            <em> {Strings.interesDesconocidoMsj}</em>
                                        } disableHoverListener>
                                            <div>
                                                <TextFieldCurrency
                                                    name="montoCuota"
                                                    value={state.montoCuota}
                                                    onChange={handleChange}
                                                    fullWidth
                                                    placeholder={"Opcional"}
                                                    label={Strings.monto+" "+Strings.cuotas}
                                                />
                                            </div>
                                        </HtmlTooltip>
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={3}>
                                        <DateInput
                                            name="solFechaProximoPago"
                                            value={state.solFechaProximoPago}
                                            label={Strings.primerPago}
                                            onChange={handleChange}
                                            quincenalFijo={state.solCiclosPago === "16"}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <SelectCaja
                                            name="cajId"
                                            label={Strings.caja}
                                            value={state.cajId}
                                            fullWidth
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm={4} md={2}>
                                        <TextField
                                            name="solReferencia"
                                            label={Strings.codigoRef}
                                            value={state.solReferencia}
                                            fullWidth
                                            onChange={handleChange}
                                            inputProps={{maxLength: 20}}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4} md={2}>
                                        <TextField
                                            name="solPorcientoMora"
                                            type="number"
                                            inputProps={{step:"any",inputMode:"decimal"}}
                                            label={"% "+Strings.mora}
                                            value={state.solPorcientoMora}
                                            fullWidth
                                            onChange={handleChange}
                                        />
                                    </Grid>

                                    <Grid item xs={6} sm={4} md={3}>
                                        <SelectCartera value={state.carId}
                                                       name={"carId"}
                                                       label={Strings.cartera}
                                                       onChange={handleChange} />
                                    </Grid>

                                    <Grid item xs={6} sm={4} md={3}>
                                        <TextField
                                            name="solPorcientoComision"
                                            label={"% "+Strings.comision}
                                            type={"number"}
                                            inputProps={{
                                                max:100,
                                                step:"any",
                                                inputMode:"decimal"
                                            }}
                                            value={state.solPorcientoComision}
                                            fullWidth
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    {state.solPorcientoComision > 0 &&
                                    <Grid item xs={6} md={5} lg={4}>
                                        <SelectAgente
                                            name="usuIdAgente"
                                            label={Strings.agenteAcomisionar}
                                            value={state.usuIdAgente}
                                            onChange={handleChange}
                                        />
                                    </Grid>}
                                    {state.solCiclosPago === "1" &&
                                    <Grid item xs={12} md={4}>
                                        <SelectDay
                                            name={"solDiasExcluidos"}
                                            value={state.solDiasExcluidos}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    }
                                </>}
                            {customFormInputPre && Object.keys(customFormInputPre).map((key)=>
                                <Grid item xs={12} sm={4} lg={2} key={key}>
                                    <TextField
                                        name={key}
                                        label={customFormInputPre[key].name}
                                        required={customFormInputPre[key].isRequired}
                                        value={state[key] ? state[key] : ""}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                            )}
                        </Grid>
                        <br/>
                        <Typography color={"secondary"}>{Strings.codeudor}</Typography>
                        <br/>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    name="solCoodeudor"
                                    label={Strings.nombreCompleto}
                                    value={state.solCoodeudor}
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <TextField
                                    name="solCoodeudorCedula"
                                    label={Strings.cedula}
                                    value={state.solCoodeudorCedula}
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                />
                            </Grid>
                            <Grid item xs={6} md={2}>
                                <TextFieldPhone
                                    name="solCoodeudorTelefono"
                                    label={Strings.telefono}
                                    value={state.solCoodeudorTelefono}
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    name="solCoodeudorDireccion"
                                    label={Strings.direccion}
                                    value={state.solCoodeudorDireccion}
                                    fullWidth
                                    onChange={handleChange}
                                    inputProps={{maxLength: 100}}
                                />
                            </Grid>
                        </Grid>

                    </>
                    <br/>
                    <ReferenciasCliente referencias={state.solReferencias}
                                        onChange={(solReferencias)=>setState({...state,solReferencias})} />
                </form>
                {!isPrivate &&
                    <Button color="primary" fullWidth={webview} variant={"contained"} disabled={isPending} type="submit" form={"form1"}>{Strings.enviar} {Strings.solicitud}</Button>
                }
            </div>


            {urlState && urlState.showCal &&
            <AmortCal onClose={history.goBack}
                      inicialState={{
                        fecha: state.solFechaProximoPago,
                        capital: state.solMontoPrestamo,
                        interes: state.solTasaInteres,
                        cuotas: state.solCuotas,
                        ciclosPago:state.solCiclosPago,
                        tipoAmortizacian:state.solTipoAmortizacion,
                        montoCuota:state.montoCuota

                      }}
                      onUseVal={(val)=>{
                          setState(prevState => ({
                              ...prevState,
                              solFechaProximoPago: val.fecha,
                              solMontoPrestamo: val.capital,
                              solTasaInteres: val.interes,
                              solCuotas: val.cuotas,
                              solCiclosPago: val.ciclosPago,
                              solTipoAmortizacion: val.tipoAmortizacian,
                              montoCuota: val.montoCuota
                          }))
                      }} />
            }
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={!!snackbarMsj}
                onClose={()=>setSnackbarMsj(null)}
                message={snackbarMsj}
                autoHideDuration={6000}
            />
        </Fragment>
    );

}

const mapStateToProps = (state) => ({
    solicitud: state.solicitudes.byId,
    isPending: state.solicitudes.isPending,
    isFetching: state.solicitudes.isFetching,
    error: state.solicitudes.error,
    defaultCajId: state.auth.user.cajId || "",

});
export default connect(mapStateToProps, { aprobarSolicitud, mostrarSolicitudById,rechazarSolicitud })(DetallesSolicitud);
