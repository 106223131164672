import {pagoTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    filtered:{},
    byId: {},
    isFetching: false,
    isPending: false,
    error: null,
}

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case pagoTypes.MOSTRAR_PAGOS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case pagoTypes.MOSTRAR_PAGOS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                filtered: action.payload?.config?.params ?? {},
                isFetching: false,
                error: null
            }
        }
        case pagoTypes.MOSTRAR_PAGOS + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //agregar
        case pagoTypes.AGREGAR_PAGO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case pagoTypes.AGREGAR_PAGO + _FULFILLED: {
            return {
                ...state,
                isPending: false,
            }
        }
        case pagoTypes.AGREGAR_PAGO + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Eliminar
        case pagoTypes.ANULAR_PAGO + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case pagoTypes.ANULAR_PAGO + _FULFILLED: {
            return {
                ...state
            }
        }
        case pagoTypes.ANULAR_PAGO + _REJECTED: {
            return {
                ...state,
                isPending: false,
            }
        }

    default:
      return state;
  }
}
