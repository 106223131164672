import React, {Fragment, useState} from 'react';
import {anularPago} from "../redux/actions/pagosActions"
import {FormControlLabel, Switch, IconButton, Table, TableBody, TableCell, TableHead, TableRow,TableContainer ,Typography,
  TableFooter,TablePagination} from '@mui/material';
import Strings from "../assets/strings";

import {Close } from "@mui/icons-material";
import {formatDate, formatPeso, hasPermissionTo, PERSMISOS} from '../helpers';

import {switchControl } from "../assets/jss";
import ConfirmDialog from "./ConfirmDialog";
import {useHistory} from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import {connect} from "react-redux";
import ReciboPago from "./recibo";
const useStyles = makeStyles(theme => ({
  ...switchControl,
  barTitle:{
    padding: "6px 12px",
    background: theme.palette.grey[100],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    '& > label':{
      marginRight: 0
    }
  },
  pagAnulado:{
    background: "#fff1f1",
    "& td, th":{
      color:theme.palette.error.light,
    }
  },
  print: {
    color: theme.palette.primary.main,
  },
  row:{
    "&.pagTipo4":{
      background:"#d6f4ff !important"
    },
    "&.pagTipo8":{
      background:"#f8eaca !important"
    },
  },
}));


function Pagos(props){
  let history = useHistory();
  const classes = useStyles();
  const [showAnulado, setShowAnulado] = useState(false);

  //----
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //---
  const eliminarPago  = () =>{
    let pag = history.location.state.confirmDialog;
    props.anularPago(pag.preId,pag.pagId)
  };
  const {pagos=[],onClickPendiente} = props;
  const pagosActive = pagos.filter(p=>!p.pagAnulado && p.pagTipo !== 4)


  const totalCapital = pagosActive.reduce((a, b) => +a + +b.pagCapital, 0);
  const totalInteres = pagosActive.reduce((a, b) => +a + +b.pagInteres, 0);
  const totalMora = pagosActive.reduce((a, b) => +a + +b.pagMora, 0);
  const totalDescuento = pagosActive.reduce((a, b) => +a + +b.pagDescuento, 0);
  const totalComision = pagosActive.reduce((a, b) => +a + +b.pagComision, 0);
  const totalSegura = pagosActive.reduce((a, b) => +a + +b.pagSeguro, 0);

  const totalPago = pagosActive.reduce((a, b) => +a + +b.pagTotal, 0);


    return (
      <Fragment>
        <div className={classes.barTitle}>
          <Typography color={"secondary"}>{Strings.pagos}</Typography>
          <FormControlLabel className="d-print-none"
            control={
              <Switch
                //disableRipple
                  size={"small"}
                  color={"secondary"}
                checked={showAnulado}
                onChange={()=>setShowAnulado(!showAnulado)}
                value="checkedB"
              />
            }
            label={Strings.anulado}
          />
        </div >
        <TableContainer >
          <Table className={"hasOnclick"}>
            <TableHead>
              <TableRow>
                <TableCell className={"d-print-none"}/>
                <TableCell>{Strings.concepto}</TableCell>
                <TableCell>{Strings.fecha}</TableCell>
                <TableCell>{Strings.capital}</TableCell>
                <TableCell>{Strings.interes}</TableCell>
                <TableCell>{Strings.mora}</TableCell>
                <TableCell>{Strings.descuento}</TableCell>
                <TableCell>{Strings.comision}</TableCell>
                {props?.seguro &&  <TableCell>{Strings.seguro}</TableCell>}
                <TableCell>{Strings.totalPagado}</TableCell>
                <TableCell>{Strings.capitalPendiente}</TableCell>
                <TableCell>{Strings.caja}</TableCell>
                <TableCell>{Strings.agente}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pagos.filter(p=>!p.pagAnulado || p.pagAnulado === showAnulado ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n =>
                    <TableRow key={n.pagId} className={classes.row + (n.pagAnulado?" "+classes.pagAnulado:" pagTipo"+n.pagTipo)}
                              onClick={()=> {
                                if(n.pagTipo === 8)
                                  onClickPendiente(n)
                                else
                                history.push(history.location.pathname, {recibo: n})
                              }}>
                      <TableCell className={"actionCell"}>
                        {hasPermissionTo(PERSMISOS.pagosEliminar) && n.pagAnulable &&
                            <IconButton
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    history.push(history.location.pathname,{confirmDialog:n})
                                  }
                                }
                                disabled={Boolean(n.pagAnulado)}
                                size="large">
                              <Close className={"text-red"}/>
                            </IconButton>
                        }
                      </TableCell>
                      <TableCell className="comment">{n.pagConcepto}</TableCell>
                      <TableCell>{formatDate(n.pagFecha)}</TableCell>
                      {n.pagTipo === 4 || n.pagTipo === 8?(
                          <>
                              <TableCell colSpan={5}/>
                          </>
                      ):(
                          <>
                            <TableCell>{formatPeso(n.pagCapital)}</TableCell>
                            <TableCell>{formatPeso(n.pagInteres)}</TableCell>
                            <TableCell>{formatPeso(n.pagMora)}</TableCell>
                            <TableCell>{formatPeso(n.pagDescuento)}</TableCell>
                            <TableCell>{formatPeso(n.pagComision)}</TableCell>
                            {props?.seguro && <TableCell>{formatPeso(n.pagSeguro)}</TableCell>}
                          </>
                      )}

                      <TableCell>{formatPeso(n.pagTotal)}</TableCell>
                      <TableCell>{formatPeso(n.pagNuevoCapital)}</TableCell>
                      <TableCell>{n.caja} </TableCell>
                      <TableCell>{n.agente}</TableCell>
                    </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow className={""}>

                <TableCell colSpan={3}><Typography align={"right"}>{Strings.total}:</Typography></TableCell>
                <TableCell>{formatPeso(totalCapital)}</TableCell>
                <TableCell>{formatPeso(totalInteres)}</TableCell>
                <TableCell>{formatPeso(totalMora)}</TableCell>
                <TableCell>{formatPeso(totalDescuento)}</TableCell>
                <TableCell>{formatPeso(totalComision)}</TableCell>
                {props?.seguro && <TableCell>{formatPeso(totalSegura)}</TableCell>}
                <TableCell>{formatPeso(totalPago)}</TableCell>
                <TableCell colSpan={3}/>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer >
        {pagos.length > 10 &&
          <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={pagos.length}
              rowsPerPage={rowsPerPage}
              SelectProps={{
                inputProps: {'aria-label': 'No. registros'},
                native: true,
              }}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
        }
        <ConfirmDialog onConfirm={eliminarPago}/>
        <ReciboPago/>

      </Fragment>
    );

}

const mapStateToProps = (state) => ({
  isPending: state.pagos.isPending,
});
export default connect(mapStateToProps, {anularPago})(Pagos);
