import {dashBoardTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    isFetching: false,
    ingresos:0,
    maxPrestamos:0,
    prestamos:0,
    clientes:0,
    clientesActivos:0,
    capitalActual:0,
    interes:0,
    egresos:0,
    ingresosChart:[],
    solicitudes:0,
    filtered:{},
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case dashBoardTypes.MOSTRAR_DASHBOARD_DATA + _PENDING: {
            return {
                ...state,
                isFetching: true,
            }
        }
        case dashBoardTypes.MOSTRAR_DASHBOARD_DATA + _FULFILLED: {
            const [widgets,ingresos] = action.payload;
            return {
                ...state,
                ...widgets.data,
                ingresosChart:ingresos.data,
                isFetching: false,
            }
        }
        case dashBoardTypes.MOSTRAR_DASHBOARD_DATA + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        case dashBoardTypes.MOSTRAR_NOTIFICACIONES + _FULFILLED: {
            const {data} = action.payload;
            return {
                ...state,
                ...data,
            }
        }

        case dashBoardTypes.MOSTRAR_WIDGETS_GASING + _PENDING: {
            return {
                ...state,
                isFetching: true,
            }
        }
        case dashBoardTypes.MOSTRAR_WIDGETS_GASING + _FULFILLED: {
            return {
                ...state,
                ...action.payload.data,
                isFetching: false,
            }
        }
        case dashBoardTypes.MOSTRAR_WIDGETS_GASING + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //set filter
        case dashBoardTypes.SET_FILTER: {
            return {
                ...state,
                filtered:{...state.filtered,...action.payload},
                isFetching: false,
            }
        }
        default:
      return state;
  }
}
