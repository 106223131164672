import React,{useEffect,useState} from 'react';
import {Link, useHistory,useParams} from 'react-router-dom'
import {connect} from "react-redux";
import {mostrarPrestamos} from "../../redux/actions/prestamoActions";

import {
    TextField,
    InputLabel,
    FormControl,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    TableRow,
    TableCell,
    TableBody,
    TableFooter,
    useMediaQuery,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {
    AppBar,
    SeacrhAppBar,
    LoadingProgress,
    PlusFab,
    ListViewMode,
    TableSort,
    Pagination,
    ButtonResponsive,
    FilteredAlert,
    MostrarError
} from "../../components";
import {SelectAgente, SelectCartera, SelectEnum, SelectRuta} from "../../components/selects";
import {SelectSmart} from "../../components/CustomTextField";

import {formatDate, formatPeso, hasPermissionTo, PERSMISOS} from "../../helpers";
import Strings from '../../assets/strings'

import ItemPrestamo from "../../components/CustomListItem/itemPrestamo";
import downloadFiles from "../../helpers/downloadFiles";
import ButtonSelect from "../../components/ButtonSelect";
import {FilterList,CloudDownloadOutlined} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
const useStyles = makeStyles(theme => ({
    btnBase:{
        margin:theme.spacing(1),
        //width: "100%",
        display: "block",
    },
    root: {
        //margin:theme.spacing(2),
        padding:theme.spacing(2)
    },
    name:{
        whiteSpace: "nowrap",
    },
    tRow:{
        cursor: "pointer",
        "& .estado":{
            fontSize: ".9em"
        },
        "&.i":{
            background:"rgba(21,216,193,0.13) !important",
            "& .estado":{
                color: "#15d2d8",
            }
        },
        "&.c":{
            background:"rgba(216, 67, 21, 0.13) !important",
            "& .estado":{
                color: "#d84315",
            }
        },
        "&.m":{
            background:"rgba(255, 111, 0, 0.05) !important",
            "& .estado":{
                color: "#ff6f00",
            }
        },
        "&.p":{
            background:"rgba(25, 118, 210, 0.07) !important",
            "& .estado":{
                color: "#1976d2",
            }
        }
    },
}));
let initialState={
    rutId: "",
    carId:"",
    estado: "",
    fechaDesde: "",
    fechaHasta: "",
    fechaProximoPago:"",
    agenteComision:"",
    creadopor:"",
    page: 1,
    pageSize: 50,
    orderBy: "",
    orderByIsDescending:"",
    modalidad:""
};
function Prestamos(props){
    let history = useHistory();
    const {cliId} = useParams();
    initialState = cliId ? {...initialState,cliId,estado:-2} : {...initialState,cliId:null,estado:""}; //ver los cancelados de un cliente

    const classes = useStyles();
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState(initialState);
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const { mostrarPrestamos,prestamos: { list, isFetching,error,filtered} } = props;
    const isEmpty = (!list.results || list.results.length === 0);

    useEffect(() => {
        mostrarPrestamos(cliId ? {cliId,estado:-2} : null); //ver los cancelados de un cliente
        //remove filter on unmount
        return ()=> {
            if(cliId) mostrarPrestamos({});
        }
    }, [mostrarPrestamos,cliId]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])

    const handleFilter = e => {
        const {name, value} = e.target;
        if(name === "name" || name === "page" || name === "pageSize"){
            return mostrarPrestamos({...filter,page:null,[name]:value});
        }
        setFilter({...filter,page:null,[name]:value})
    };
    const resetFilter = () => {
        setOpenFilter(false);
        mostrarPrestamos(cliId ? {cliId,estado:-2} : {});
    };
    const searchFilter = () => {
        setOpenFilter(false);
        mostrarPrestamos(filter);
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=> mostrarPrestamos({...filter, page:null,orderBy, orderByIsDescending: !filter.orderByIsDescending})
        }
    };
    return (
      <>
        <AppBar title={Strings.prestamos}>
            <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>
            <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(true)}/>
            <ButtonSelect smIcon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty}
                opcions={[
                    {key:"",value:Strings.exportar +":",disabled:true},
                    {key:"1",value:Strings.soloPrestamos},
                    {key:"2",value:Strings.incluirPagos},
                    {key:"3",value:"Data Crédito"}
                ]}
                onChange={(v)=>downloadFiles(filter,"prestamos",v)}
            />
        </AppBar>
        <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} maxWidth={"xs"}>
              <DialogContent>
                  <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                          <TextField
                              label={Strings.fechaDesde}
                              type="date"
                              value={filter.fechaDesde}
                              onChange={handleFilter}
                              name="fechaDesde"
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <TextField
                              label={Strings.fechaHasta}
                              type="date"
                              value={filter.fechaHasta}
                              onChange={handleFilter}
                              name="fechaHasta"
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <TextField
                              label={Strings.proximo+" "+Strings.pago}
                              type="date"
                              value={filter.fechaProximoPago}
                              onChange={handleFilter}
                              name="fechaProximoPago"
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                              <InputLabel shrink>{Strings.estado}</InputLabel>
                              <SelectSmart
                                  value={filter.estado}
                                  onChange={handleFilter}
                                  name="estado"
                                  opciones={[
                                      {key:'',value:Strings.todo},
                                      {key:'aldia',value:Strings.aldia},
                                      {key:'pendiente',value:Strings.pendiente},
                                      {key:'c_pendientes',value:Strings.cuotaVencida},
                                      {key:'mora',value:Strings.mora},
                                      {key:'cancelado',value:Strings.cancelado},
                                      {key:'incobrable',value:Strings.incobrable}
                                  ]}
                              />
                          </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <SelectEnum
                              fullWidth
                              label={Strings.modalidad}
                              group="ciclosPago"
                              name= 'modalidad'
                              all
                              value={filter.modalidad}
                              onChange={handleFilter}
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <SelectAgente
                              all
                              label={Strings.creadoPor}
                              value={filter.creadopor}
                              onChange={handleFilter}
                              name="creadopor"
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <SelectAgente
                              all
                              label={Strings.agentes}
                              value={filter.agenteComision}
                              onChange={handleFilter}
                              name="agenteComision"
                          />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          <SelectCartera
                              fullWidth
                              value={filter.carId}
                              name={"carId"}
                              label={Strings.cartera}
                              all
                              onChange={handleFilter}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <SelectRuta
                              fullWidth
                              label={Strings.ruta}
                              name= 'rutId'
                              value={filter.rutId}
                              all
                              onChange={handleFilter}
                          />
                      </Grid>

                  </Grid>
                  <br/>
              </DialogContent>
              <DialogActions>
                  <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                  <Button color="primary" onClick={searchFilter}>oK</Button>
              </DialogActions>
          </Dialog>
          <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount}>
              <FilteredAlert onClose={resetFilter} filter={filtered}/>
          </ListViewMode>
          <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
              {isFetching && isEmpty && <LoadingProgress vh/>}
              {error && <MostrarError errors={error}/>}

            {(!props.viewTableList && smVewport)?(
                !isEmpty && list.results.map(pre =>
                    <ItemPrestamo key={pre.preId} onClick={()=>history.push(`/prestamo/${pre.preId}`)} item={pre}/>
                )
            ):(
                <TableSort data={[
                    {pro:"cliente",text:Strings.prestatarios},

                    {pro:"preBalancePendiente",text:Strings.balance+" "+Strings.pendiente},
                    {pro:"preMontoCuota",text:Strings.monto+" "+Strings.cuota},
                    {pro:"mora",text:Strings.mora},
                    {pro:"preFechaProximoPago",text:Strings.proximo+" "+Strings.pago},
                    {pro:"preCapitalActual",text:Strings.capitalPendiente},
                    {pro:"preGastosLegales",text:Strings.gastosLegales},

                    {pro:"preTipoAmortizacion",text:Strings.amortizacion},
                    {pro:"prePlazo",text:"# "+Strings.cuota},
                    {pro:"preCapitalInicial",text:Strings.capitalInicial},
                    {pro:"totalPagos",text:Strings.totalPagado},

                    {pro:"preTasaInteres",text:Strings.interes},
                    {pro:"preCiclosPago",text:Strings.modalidad},

                    {pro:"preReferencia",text:Strings.codigoRef},
                    {pro:"preFecha",text:Strings.fecha+" "+Strings.prestamo},
                    {pro:"cajId",text:Strings.caja},

                ]} onSort={tableSortProps} lessVh={205}>
                    <TableBody>
                        {!isEmpty && list.results.map(pre =>
                                <TableRow key={pre.preId} className={classes.tRow +" "+pre.estado?.charAt(0)} onClick={()=>history.push(`/prestamo/${pre.preId}`)}
                                          onContextMenu={(e)=>{e.preventDefault();window.open(`/prestamo/${pre.preId}`,"_blank")}}>
                                    <TableCell className={classes.name}>
                                        {pre.cliente}
                                        {pre.estado === "incobrable" ? (<div className="estado">Incobrable</div>):(
                                            <div className="estado">{pre.cuotasAtrasadas>1 && pre.cuotasAtrasadas} {Strings[pre.estado]}</div>
                                        )}
                                    </TableCell>

                                    <TableCell>{formatPeso(pre.preBalancePendiente)}</TableCell>
                                    <TableCell>{formatPeso(pre.preMontoCuota)}</TableCell>
                                    <TableCell>{formatPeso(pre.mora)}</TableCell>

                                    <TableCell>{formatDate(pre.preFechaProximoPago,"dd, DD/MM/YYYY")}</TableCell>
                                    <TableCell>{formatPeso(pre.preCapitalActual)}</TableCell>

                                    <TableCell>{formatPeso(pre.preGastosLegales)}</TableCell>


                                    <TableCell>{Strings[pre.preAmortizacionString]}</TableCell>
                                    <TableCell>{pre.prePlazoString}</TableCell>
                                    <TableCell>{formatPeso(pre.preCapitalInicial)}</TableCell>
                                    <TableCell>{formatPeso(pre.totalPagos)}</TableCell>

                                    <TableCell>{pre.preTasaInteres}%</TableCell>
                                    <TableCell>{Strings[pre.preCicloPagoString]}</TableCell>
                                    <TableCell>{pre.preReferencia}</TableCell>
                                    <TableCell>{formatDate(pre.preFecha)}</TableCell>
                                    <TableCell>{pre.caja}</TableCell>

                                </TableRow>
                        )}
                    </TableBody>
                    {!isEmpty &&
                        <TableFooter>
                            <TableRow >
                                <TableCell><Typography align={"right"}>Total:</Typography> </TableCell>

                                <TableCell>{formatPeso(list.footerTotals.preBalancePendiente)}</TableCell>
                                <TableCell>{formatPeso(list.footerTotals.preMontoCuota)}</TableCell>
                                <TableCell>{formatPeso(list.footerTotals.mora)}</TableCell>

                                <TableCell />
                                <TableCell>{formatPeso(list.footerTotals.preCapitalActual)}</TableCell>
                                <TableCell>{formatPeso(list.footerTotals.preGastosLegales)}</TableCell>
                                <TableCell colSpan={2}/>
                                <TableCell>{formatPeso(list.footerTotals.preCapitalInicial)}</TableCell>
                                <TableCell>{formatPeso(list.footerTotals.totalPagos)}</TableCell>
                                <TableCell colSpan={3}/>
                            </TableRow>
                        </TableFooter>}
                </TableSort>
            ) }
              <Pagination
                  empty={Boolean(isEmpty && !isFetching)}
                  count={list.totalRecordCount || 0}
                  page={list.pageNo -1}
                  rowsPerPage={filter.pageSize}
                  onPageChange={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                  onRowsPerPageChange={handleFilter}
              />
        </section>
          {hasPermissionTo(PERSMISOS.prestamosCrear) &&
            <PlusFab component={Link} to={"/crear/prestamo"} />
          }
      </>
    );
}

const mapStateToProps = (state) => ({
    prestamos: state.prestamos,
    viewTableList: state.theme.viewTableList
});
export default connect(mapStateToProps, { mostrarPrestamos })(Prestamos);
