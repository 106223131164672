import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import {mostrarPrestamoById} from "../../redux/actions/prestamoActions"
import {useHistory} from "react-router-dom";
import {postPagoRapido} from "../../services"
import {
  TextField,
  Grid,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {DateInput,TextFieldCurrency} from "../../components/CustomTextField"
import {formatPeso, hasPermissionTo, PERSMISOS} from "../../helpers";
import {LoadingProgress,MostrarError,TopBarDialog,HtmlTooltip} from "../../components";
import {SelectEnum,SelectCaja} from "../../components/selects";
import Strings from "../../assets/strings";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
      maxWidth:540,
      margin:"0px auto",
    },
    [theme.breakpoints.only('xs')]: {
      "& .MuiInput-input":{
        position: "relative",
        padding: 7,
        border: "1px solid #e6e6e6",
      },
      "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)":{
        left:theme.spacing(1),

      }
    }
  },
  tabs:{
    minHeight: 37,
    "& button":{
      minHeight: 37,
      "&.Mui-selected":{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderTop: "1px solid rgba(0, 0, 0, 0.1)"
      }
    },
    "& .MuiTabs-indicator":{
      display:"none",
    }
  },
  content:{
    padding:theme.spacing(2),
  },
  cuotaInteres:{
    display: "flex",
    justifyContent: "flex-end",
    fontSize: ".9em",
    fontWeight: 600,
    textAlign: "center",
    marginTop: 40,
    marginBottom: 4,
    "& span":{
      fontWeight: "normal",
    },
    "&> div": {
      "&:first-child":{
          marginRight: "auto"
        },
      "&:nth-child(2)": {
          marginRight: 5
        }
    }
  },
  soloInteresLb:{
    position: "absolute", left: 11, top: 54,
    [theme.breakpoints.down('md')]: {
      top: 44
    },
  },
  montoInput:{
    background: "#f3f3f3",
    padding: theme.spacing(2),
    textAlign: "center",
    marginBottom: theme.spacing(2),
    "& .MuiInput-root":{
      margin:"0px !important",
    },
    "& input": {
      fontSize: "1em",
      color:theme.palette.primary.main,
      textAlign: "center",
      background: theme.palette.common.white,

      "&:disabled":{
        background: theme.palette.grey[100]
      }
    }
  },
  alertText:{
    background:theme.palette.secondary.light,
    padding: "5px "+ theme.spacing(2),
    textAlign: "center",
    fontSize: ".9rem",
    color:theme.palette.secondary.contrastText,
  },
  dialogActions:{
    justifyContent: "space-between",
    background: "#f8f8f8",
  },
  totalApagar:{
    paddingLeft: theme.spacing(1),
    fontWeight: 500,
    "& span":{
      color:theme.palette.secondary.main
    }
  }
}));
let initialState = {
  pagFormaPago:0,
  preId:0,
  pagMonto: 0,
  pagMora: 0,
  pagDescuento: 0,
  pagNota: "",
  pagFecha: "",
  cajId: "",
};
function AddPagoRapido({prestamo,mostrarPrestamoById,defaultCajId}){
  initialState.cajId = defaultCajId;
  let history = useHistory();
  const classes = useStyles();
  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
  const [state, setState] = useState(initialState);
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);


  useEffect(()=>{
    if (prestamo.preId) {
      let obj={
         preId:prestamo.preId,
         pagMora:prestamo.mora ? prestamo.mora : 0,
         pagMonto: prestamo.prePagoPendiente? prestamo.prePagoPendiente : (prestamo.preMontoCuota < prestamo.mora ? prestamo.preMontoCuota + prestamo.mora : prestamo.preMontoCuota ),
        pagFecha:prestamo.currentDate
      };
      setState(prevState => ({...prevState,...obj}));
    }
  },[prestamo, setState]);

  const handleChange = e => {
    const {value,name} = e.target;
    if(name === "pagFecha") return setState({...state,[name]: value+""+prestamo.currentDate?.substr(10)});
    setState({...state,[name]:value});
  };
  const submit = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let data = {...state};

    postPagoRapido(data)
    .then(({data})=>{
      setIsPending(false);
      mostrarPrestamoById(prestamo.preId);
      history.replace(history.location.pathname,{recibo:data})
    }).catch(({response})=>{
      setError(response.data);
      setIsPending(false)
    })
  };

  return (
      <Dialog className={classes.root} fullScreen={smVewport} open={true} onClose={history.goBack}>
        <TopBarDialog title={Strings.pago +" rápido"} nofullScreen onClose={history.goBack}/>
        <MostrarError errors={error}/>
        <DialogContent className={classes.content}>
          {isPending && <LoadingProgress vh={false} />}
          <Grid container spacing={3} style={{position:"relative"}}>
            <Grid item xs={6}>

            </Grid>
            <Grid item xs={6}>
              <DateInput
                  name="pagFecha"
                  value={state.pagFecha}
                  margin={"none"}
                  onChange={handleChange}
                  disabled={!hasPermissionTo(PERSMISOS.pagosCambiarFecha)}
              />
            </Grid>
          </Grid>
          <div className={classes.cuotaInteres}>
            <div>
              {prestamo.prePagoPendiente > 0 &&
              <>{Strings.pendiente}: <span>{formatPeso(prestamo.prePagoPendiente)}</span></>
              }
            </div>
            <div>
              {Strings.cuota}: <span>{formatPeso(prestamo.preMontoCuota)}</span></div>
            <div>
              {Strings.interes}: <span>{formatPeso(prestamo.preCuotaInteres)}</span></div>
          </div>
          <form onSubmit={submit} id={"formMonto"}>
            <div className={classes.montoInput}>
              <TextFieldCurrency id="pagMonto" fullWidth name="pagMonto" min={1} value={state.pagMonto}
                                 onChange={handleChange}/>
            </div>
          </form>
          <Grid container spacing={3}>
            <Grid item xs={6}>
            <TextFieldCurrency label={Strings.mora} name="pagMora" disabled value={state.pagMora} onChange={()=>{}}  fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextFieldCurrency disabled={!hasPermissionTo(PERSMISOS.pagosDescuento)}
                  label={Strings.descuento} name="pagDescuento" value={state.pagDescuento} onChange={handleChange} fullWidth/>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <SelectEnum label={Strings.formaPago} group="formaPago" name="pagFormaPago" required value={state.pagFormaPago} onChange={handleChange}/>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <SelectCaja label={Strings.caja} name="cajId" value={state.cajId === -1 ? "": state.cajId} onChange={handleChange}/>
              </div>
            </Grid>
          </Grid>
          <div className="form-group">
            <TextField label={Strings.comentario} name="pagNota" value={state.pagNota}
                       onChange={handleChange}
                       inputProps={{maxLength: 256}}
                       margin="normal" fullWidth/>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <HtmlTooltip title={Strings.totalApagar} >
            <div className={classes.totalApagar}>
              {Strings.total}: <span> {formatPeso(parseFloat(state.pagMonto || 0) -
                  parseFloat(state.pagDescuento || 0))}</span>
            </div>
          </HtmlTooltip>
          <Button color="primary" type={"submit"} form={"formMonto"} disabled={isPending}>{Strings.agregar} {Strings.pago}</Button>
        </DialogActions>
      </Dialog>
  );
}
const mapStateToprops = (state) => ({
  prestamo:state.prestamos.byId,
  defaultCajId: state.auth.user.cajId || "",
});
export default connect(mapStateToprops,{mostrarPrestamoById})(AddPagoRapido);
