import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import { transferirEntreCajas} from '../../redux/actions/cajasActions';
import {Dialog, DialogActions, DialogContent, Button, Grid, TextField} from '@mui/material';
import {connect} from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import LoadingProgress from "../../components/LoadingProgress";
import {MostrarError} from "../../components/MostrarError";
import {TextFieldCurrency} from "../../components/CustomTextField";
import Strings from "../../assets/strings";
import SelectCaja from "../../components/selects/SelectCaja";
import TopBarDialog from "../../components/topBar/TopBarDialog";
const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: "none",
        [theme.breakpoints.up('sm')]: {
            maxWidth:510,
            margin:"0px auto",
        },
    },
    content:{
        padding:theme.spacing(2),
    }
}));

const initialState = {
    monto: 0,
    cajaDestino:"",
    cajaOrigen: "",
    concepto: ""
};
function TransferenciaCajas(props) {

    const {goBack} = useHistory();
    const {state:urlState={}} = useLocation();
    const classes = useStyles();

    const {isPending,error,transferirEntreCajas} = props;
    const [open, setOpen] = useState(false);

    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlState && urlState.modalTransferenciaCaja) {
            setState(initialState);
            setOpen(true);
        }else{
            setState(initialState);
            setOpen(false);
        }
    },[urlState,setState])
    const handleChange = e => {
        const {name,value} = e.target;
        setState({...state,  [name]: value });
    };
    return (
        <Dialog className={classes.root} open={open} onClose={goBack} fullWidth maxWidth={"sm"}>
            <TopBarDialog title={Strings.transferenciaCaja} onClose={goBack} nofullScreen/>
            <DialogContent className={classes.content}>
                <MostrarError errors={error}/>
                {isPending && <LoadingProgress />}
                <form onSubmit={(e)=>{e.preventDefault();transferirEntreCajas(state)}} id="form1">
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <SelectCaja
                            name="cajaOrigen"
                            label={Strings.desde}
                            value={state.cajaOrigen}
                            fullWidth
                            required
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectCaja
                            name="cajaDestino"
                            label={Strings.hasta}
                            value={state.cajaDestino}
                            fullWidth
                            required
                            onChange={handleChange}
                            removeOption={state.cajaOrigen}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFieldCurrency
                            name="monto"
                            label={Strings.monto}
                            value={state.monto}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="concepto"
                            label={Strings.concepto}
                            value={state.concepto}
                            fullWidth
                            onChange={handleChange}
                            inputProps={{maxLength: 100}}
                        />
                    </Grid>
                  </Grid>
                </form>
            </DialogContent>
          <DialogActions>
            <Button onClick={goBack}>
                {Strings.cancelar}
            </Button>
            <Button  type="submit" color="primary" form="form1">
                {Strings.guardar}
            </Button>
          </DialogActions>

        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    error: state.cajas.error,
    isPending: state.cajas.isPending,
});
export default connect(mapStateToProps, {transferirEntreCajas })(TransferenciaCajas);



