import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarAgentesSinoEsta } from '../../redux/actions/agentesActions';
import {FormControl, InputLabel} from "@mui/material";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";

function SelectAgente({required,margin="none",value="", name="tipo",label= Strings.agentes, all, onChange, agentes, mostrarAgentesSinoEsta,...others}) {


    useEffect(() => {
            mostrarAgentesSinoEsta()
    }, [mostrarAgentesSinoEsta]);


    var options = (agentes.list && agentes.list.map(opc=>({key:opc.usuId,value: opc.usuNombres +" "+opc.usuApellidos}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})

    return (
        <FormControl fullWidth required={required} margin={margin} disabled={options.length <= 1}>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value.toString()}
                onChange={onChange}
                name={name}
                loading={agentes.isFetching }
                required={required}
                opciones={options}
                {...others}
            />
        </FormControl>
    );
}

const mapStateToProps = (state) => ({
    agentes: state.agentes,
})
export default connect(mapStateToProps, { mostrarAgentesSinoEsta })(SelectAgente);
