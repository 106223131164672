import React, {useEffect, useState,Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { mostrarPlantillas, eliminarPlantilla} from '../../redux/actions/plantillasActions';
import AppBar from "../../components/appBar";

import {
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Dialog,
    DialogContent,
    Grid,
    Checkbox,
    FormControlLabel,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import PlusFab from "../../components/PlusFab";
import {Close, FilterList, Help} from "@mui/icons-material";
import LoadingProgress from "../../components/LoadingProgress";
import {Link,useHistory, useLocation} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import {MostrarError} from "../../components/MostrarError";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS} from "../../helpers";
import {ButtonResponsive, Empty, SeacrhAppBar} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/empty.svg";
import {SelectEnum} from "../../components/selects";
import {useEnums} from "../../hooks/useEnums";
const useStyles = makeStyles(theme => ({
    root:{
        padding: 20,

    }
}));
const initialFilter={
    name: '',
    tipo:"",
    mostrarDelSistema:false
};

export default function Plantillas() {
    const classes = useStyles();
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();
    const tiposPlantilla = useEnums("tiposPlantilla")
    const [openFilter, setOpenFilter] = useState(false);
    const [filter, setFilter] = useState(initialFilter);


    const Dispatch = useDispatch();
    const {list,isFetching,error} = useSelector(state => state.plantillas)


    const handleFilter = e => {
        const {type, value,name,checked} = e.target
        setFilter({...filter,[name]: type === "checkbox" ?checked:value})
    };
    useEffect(() => {
        setOpenFilter(false);
        Dispatch(mostrarPlantillas(filter));
    },[Dispatch,filter])


    const isEmpty = !isFetching && list.length === 0;

    const ListItems = () => (
        tiposPlantilla?.map((t,index) => {
            let found = list.filter(p => p.plaTipo === parseInt(t.key));
            if (found.length) {
                return (
                    <List subheader={<Typography color={"secondary"}>{t.value}</Typography>} key={index}>
                        {found.map(item =>
                            <ListItem button key={item.plaId}
                                      onClick={() => {
                                          if (!hasPermissionTo(PERSMISOS.plantillasActualizar)) return false;
                                          history.push("/editar/plantilla/" + item.plaId)
                                      }}>
                                <ListItemText primary={item.plaNombre}
                                              secondary={
                                                  item.plaContenido.replace(/(<([^>]+)>)/gi, "")
                                                      .replaceAll("&nbsp;", "")
                                                      .substring(0, 150).concat('...')
                                              }
                                />
                                {hasPermissionTo(PERSMISOS.plantillasEliminar) && !item.registroDelSistema &&
                                <ListItemSecondaryAction>
                                    <IconButton
                                        edge="end"
                                        aria-label="comments"
                                        onClick={() => history.push(history.location.pathname, {confirmDialog: item.plaId})}
                                        size="large">
                                        <Close className="text-red" fontSize={"small"}/>
                                    </IconButton>
                                </ListItemSecondaryAction>
                                }
                            </ListItem>
                        )}
                    </List>
                );
            }else{return <Fragment key={index}></Fragment>}
        })
    )


    return (
          <>
              <AppBar title={Strings.plantillas}>
                  <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter} />
                  <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(true)}/>
                  <ButtonResponsive icon={<Help/>} text={Strings.ayuda} onClick={() => window.open("https://youtu.be/VWifzmEzhRE","_blank")}/>
              </AppBar>
              <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
                  <DialogContent>
                      <Grid container spacing={3}>
                          <Grid item xs={12}>
                              <SelectEnum all value={filter.tipo} name={"tipo"} label={Strings.tipo}
                                          onChange={handleFilter} group={"tiposPlantilla"} />
                          </Grid>
                          {list.find(p=> !p.registroDelSistema) &&
                              <FormControlLabel
                                  control={<Checkbox checked={filter.mostrarDelSistema} onChange={handleFilter} name="mostrarDelSistema" />}
                                  label={Strings.ver+" "+Strings.registrosDelSistema}
                                  labelPlacement="start"
                              />
                          }
                      </Grid>
                      <br/>
                  </DialogContent>
              </Dialog>


              <section className={"contentInner "+classes.root}>
                  {!urlState && <MostrarError errors={error}/>}
                  {isFetching  &&  <LoadingProgress vh/>}
                  {!isEmpty && <>{ListItems()}</>}


                  {isEmpty &&
                    <Empty Img={EmptySvg} title={Strings.noSeEncontraron+" "+Strings.plantillas}/>
                   }


                  {hasPermissionTo(PERSMISOS.plantillasCrear) &&
                      <PlusFab component={Link} to={"/crear/plantilla"} />
                  }


                  <ConfirmDialog onConfirm={()=>Dispatch(eliminarPlantilla(urlState && urlState.confirmDialog))}/>

              </section>
          </>
        );

}
