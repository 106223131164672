import React, {Fragment,useState, useEffect} from 'react';
import {eliminarCliente, mostrarClienteById, editarClienteRating} from "../../redux/actions/clientesActions";
import {
    Tab, Tabs, Box, MenuItem, Menu, Paper, ListSubheader,
    ListItem, IconButton, ListItemSecondaryAction, ListItemText, Dialog, List, DialogContent,
    Divider, Typography, useTheme, useMediaQuery, Button
} from '@mui/material';
import {Call, EmailOutlined, MoreVert, MapOutlined, WhatsApp} from '@mui/icons-material';
import LoadingProgress from "../../components/LoadingProgress";
import groupBy from 'lodash/groupBy';
import pdfSvg from '../../assets/img/pdf.svg';
import makeStyles from '@mui/styles/makeStyles';
import {Link, useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import Rating from "../../components/Rating";
import {
    formatDate,
    formatPeso,
    geoString,
    getUsuTipo,
    hasPermissionTo,
    openNewWin,
    PERSMISOS,
    TIPOUSUARIO
} from "../../helpers";
import {SlideLeft} from "../../components/transitions";
import ConfirmDialog from "../../components/ConfirmDialog";
import {SelectEnum, SelectPais} from "../../components/selects";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Strings from "../../assets/strings";
import DefaultImgEmp from '../../assets/img/defaultAvatar_empresa.jpg';
import NoImg from '../../assets/img/defaultAvatar.jpg';
import {useCustomFormInput} from "../../hooks/useCustomFormInput";
import EnviarPlantilla from "../../components/EnviarPlantilla";
import {isMobile} from "../../helpers/browser_detect";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import ClienteUsuario from "./ClienteUsuario";
import {getTokenInfo} from "../../services/auth";

const useStyles = makeStyles(theme => ({
    content: {
        padding: "0 !important",
    },
    contactForm: {
        "padding": "8px",
        paddingTop: 16,
        "position": "sticky",
        "bottom": "0",
        "left": "0",
        "width": "calc(100% - 16px)",
        "background": "white",
    },
    title: {
        flexGrow: 1,
        fontWeight: "normal",
    },
    imgCont: {
        width: 150,
        height: 150,
        borderRadius: "50%",
        overflow: "hidden",
        margin: "10px auto",
        "& img": {
            width: "100%",
        }
        // [theme.breakpoints.down('xs')]: {
        //     float:"left",
        //     width:50,
        //     height:50,
        //     marginRight: 12
        // },
    },
    nombre: {
        margin: 0,
        textAlign: "center",
    },
    header: {
        background: "#e6e6e6",
        padding: 10,
    },
    docs: {
        margin: 0,
        marginTop: 10,
        padding: 0,
        listStyle: "none",
        display: "flex",
        "& li": {
            width: 50,
            marginLeft: theme.spacing(2),

            "& img": {
                width: "100%",
            }
        }
    },
    ul: {
        margin: 0,
        marginTop: 10,
        padding: 0,
        listStyle: "none",
        columns: 2,
        "& li": {
            padding: "7px 15px",
            // -webkit-column-break-inside: avoid;
            // page-break-inside: avoid;
            breakInside: "avoid",

            "& h6": {
                fontWeight: "normal",
                margin: 0,
                fontSize: "0.8em",
            }
        },
        [theme.breakpoints.up('sm')]: {
            columns: 3,
        },

    },
    block: {
        borderTop: "1px solid #e6e6e6",
        padding: theme.spacing(2) + "px 0px",

        "& th": {
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            paddingBottom: "0px !important",
            fontWeight: "normal",
        },
        "& tr": {
            height: 33,
            background: "transparent !important",
            "& a": {
                color: "inherit",
                textDecoration: "none",
            }
        },
        "& h4": {
            fontWeight: "normal",
            margin: "0",
            marginLeft: theme.spacing(2),
            color: theme.palette.secondary.main
        }
    },
    contactBtn:{
        position: "sticky",
        bottom: 0,
        padding: theme.spacing(1),
        zIndex: 1,
        background: "white",
        "& button":{
            background: theme.palette.grey[100] +" !important",
        }
    },
    dialogPaper:{
        height: "100%"
    }
}));

const initialState = {
    cliEstadoCivil: "",
    cliTipoVivienda: "",
    archivos: [],
    cliReferencias: {},
    cliCalificacion: "",
};

function ContactDetails(props) {
    const {push: pushPath ,goBack} = useHistory();
    const {pathname, state} = useLocation();
    const customFormInput = useCustomFormInput();

    const [tab, setTab] = useState(1);

    const [data, setData] = useState(initialState);
    const theme = useTheme();
    const smVewport = useMediaQuery(theme.breakpoints.down('md'));

    const [showMenu, setShowMenu] = useState(null);

    const classes = useStyles();
    const {byId, isPending,isFetching, eliminarCliente, mostrarClienteById} = props;

    useEffect(() => {
        setTab(1);
        let referencias = {};

        try {
            referencias = groupBy(byId.cliReferencias, function (n) {
                return n.tipo;
            });
        } catch (e) {
        }
        setData({...byId, cliReferencias: referencias});
    }, [setTab, setData, byId]);
    const close = () => {
        mostrarClienteById(false);
        goBack();
    };
    function TabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={1}>{children}</Box>}
            </Typography>
        );
    }
    const handleRateChange = (e) => {
        const {value} = e.target;
        props.editarClienteRating({
            cliId: data.cliId,
            cliCalificacion: parseFloat(value),
        });
    };

    if(Boolean(!state || !state.cliId)) return null;

    return <>
        <Dialog
            open={true}
            {...(smVewport ? {TransitionComponent: SlideLeft} : false)}
            maxWidth={"sm"}
            fullWidth={true}
            onClose={close}
            fullScreen={smVewport}
            classes={{paper:classes.dialogPaper}}
        >
            {(isPending || isFetching) && <LoadingProgress/>}
            <TopBarDialog title={Strings.contacto} onClose={close}>
                {pathname.indexOf("prestamo") && //no mostra para evitar problema de goBack
                <IconButton size={"small"}
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={(e) => setShowMenu(e.currentTarget)}
                            color="inherit"
                >
                    <MoreVert/>
                </IconButton>
                }

                <Menu
                    id="menu-appbar"
                    anchorEl={showMenu}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(showMenu)}
                    onClose={() => setShowMenu(null)}
                >
                    {hasPermissionTo(PERSMISOS.prestamosCrear) &&
                    <MenuItem component={Link} replace to={{
                        pathname: `/crear/prestamo`,
                        state: {
                            cliente: {
                                cliId: data.cliId,
                                fullName: data.cliNombres + " " + data.cliApellidos
                            }
                        }
                    }}>
                        {Strings.crear} {Strings.prestamo}
                    </MenuItem>}
                    {data.cliEstado === 1 &&
                        hasPermissionTo(PERSMISOS.prestamos) &&
                        <MenuItem component={Link} to={`/prestamos/${data.cliId}`} replace>
                            {Strings.mostrar} {Strings.prestamos}
                        </MenuItem>
                    }
                    {hasPermissionTo(PERSMISOS.clientesEliminar) &&
                    <MenuItem
                        onClick={() => {
                            setShowMenu(null);
                            pushPath(pathname, {...state, confirmDialog: true});
                        }}
                    >{Strings.eliminar}</MenuItem>
                    }
                    {hasPermissionTo(PERSMISOS.clientesActualizar) &&
                    <MenuItem component={Link} to={`/cliente/${data.cliId}`} replace>{Strings.editar}</MenuItem>
                    }
                    <MenuItem onClick={() => {
                        setShowMenu(null);
                        openNewWin("/reports/cliente/" + data.cliId);
                        //downloadFiles({cliId: data.cliId},"cliente")
                    }}>{Strings.exportar}</MenuItem>

                </Menu>
            </TopBarDialog>
            <DialogContent className={classes.content}>
                <header className={classes.header}>
                    <div className={classes.imgCont}>
                        <img src={data.cliAvatar ? data.cliAvatar : (data.cliTipo === 2 ? DefaultImgEmp : NoImg)}
                             alt={data.cliNombres}/>
                    </div>
                    <h3 className={classes.nombre}>
                        {data.cliTipo === 2 ? (<>
                            {data.cliNombres} {data.cliApellidos} <br/>
                            <Typography variant={"body1"}>{data.cliRazonSocial}</Typography>
                        </>) : (<>
                            {data.cliNombres} {data.cliApellidos}
                        </>)}
                    </h3>
                </header>
                {/*{(data.cliEmpleo === 1 || Object.keys(data.cliReferencias).length > 0) &&*/}
                <Tabs
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant={"fullWidth"}
                    aria-label="full width tabs example"
                >
                    <Tab label={Strings.datos} value={1}/>
                    {data.cliEmpleo === 1 && <Tab label={Strings.empleo} value={0}/>}
                    {Object.keys(data.cliReferencias).length > 0 && <Tab label={Strings.referencias} value={2}/>}
                    <Tab label={Strings.plantillas} value={3}
                         style={data.cliEmpleo !== 1 || Object.keys(data.cliReferencias).length === 0 ?{}: {display:"none"}}/>

                </Tabs>
                {/*}*/}
                <TabPanel value={tab} index={1}>
                    <div>
                        <List>
                            {data.cliCelular &&
                            <ListItem>
                                <ListItemText primary={data.cliCelular} secondary={Strings.celular}/>
                                <ListItemSecondaryAction>
                                    {data?.cliCelular?.startsWith("+") &&
                                    <a href={`https://wa.me/${data.cliCelular?.replace(/[^+\d]+/g, "")}?text=${Strings.hola}, ${data.cliNombres} ${data.cliApellidos}. `}
                                       target={"_blank"} rel="noopener noreferrer">
                                        <IconButton edge="end" size="large">
                                            <WhatsApp/>
                                        </IconButton>
                                    </a>
                                    }
                                    <a href={"tel:" + data.cliCelular}>
                                        <IconButton edge="end" size="large"><Call/></IconButton>
                                    </a>
                                </ListItemSecondaryAction>
                            </ListItem>
                            }
                            {data.cliTelefono &&
                            <ListItem>
                                <ListItemText primary={data.cliTelefono} secondary={Strings.telefono}/>
                                <ListItemSecondaryAction>
                                    <a href={"tel:" + data.cliTelefono}>
                                        <IconButton edge="end" aria-label="delete" size="large">
                                            <Call/>
                                        </IconButton>
                                    </a>
                                </ListItemSecondaryAction>
                            </ListItem>
                            }

                            {data.cliEmail &&
                            <ListItem>
                                <ListItemText primary={data.cliEmail} secondary={Strings.email}/>
                                <ListItemSecondaryAction>
                                    <a href={"mailto:" + data.cliEmail}>
                                        <IconButton edge="end" size="large">
                                            <EmailOutlined/>
                                        </IconButton>
                                    </a>
                                </ListItemSecondaryAction>
                            </ListItem>
                            }
                            {!!data.cliDireccion &&
                            <>
                                <Divider/>
                                <ListItem>
                                    <ListItemText
                                        primary={data.cliDireccion + (data.cliDireccion2 && data.cliDireccion2.indexOf("http") === -1 ? " / " + data.cliDireccion2 : "")}
                                        secondary={Strings.direccion}
                                    />
                                    {!!data.cliCoordenadas &&
                                    <ListItemSecondaryAction>
                                        <a href={`https://www.google.com/maps?q=${data.cliCoordenadas}&z=17`} target={"_blank"} rel="noopener noreferrer">
                                            <IconButton edge="end" size="large">
                                                <MapOutlined/>
                                            </IconButton>
                                        </a>
                                    </ListItemSecondaryAction>
                                    }

                                </ListItem>
                            </>
                            }
                            <Divider/>
                            <ListItem>
                                <Rating onChange={handleRateChange} name={"cliCalificacion"}
                                        value={data.cliCalificacion}/>
                            </ListItem>
                            <Divider/>
                            <ul className={classes.ul}>
                                {!!data.cliIngresos &&
                                <li>
                                    <span>{formatPeso(data.cliIngresos)}</span><h6>{Strings.ingresos}</h6>
                                </li>}
                                {!!data.cliEmpleo &&
                                <li>
                                    <span><SelectEnum group="condicionesLaboral" value={data.cliEmpleo}/></span>
                                    <h6>{Strings.situacionLaboral}</h6>
                                </li>
                                }
                                {!!data.cliNacionalidad &&
                                <li>
                                    <span><SelectPais value={data.cliNacionalidad}/></span>
                                    <h6>{Strings.nacionalidad}</h6>
                                </li>
                                }
                                {!!data.cliCedula &&
                                <li>
                                    <span>{data.cliCedula}</span>
                                    <h6>{data.cliTipo === 2 ? geoString("rnc", true) : Strings.cedula}</h6>
                                </li>
                                }
                                {/*{!!data.cliFaceBook &&*/}
                                {/*<li>*/}
                                {/*    <span>{data.cliFaceBook}</span><h6>FaceBook</h6>*/}
                                {/*</li>*/}
                                {/*}*/}
                                {/*{!!data.cliInstagram &&*/}
                                {/*<li>*/}
                                {/*    <span>{data.cliInstagram}</span><h6>Instagram</h6>*/}
                                {/*</li>*/}
                                {/*}*/}
                                {!!data.cliSexo &&
                                <li>
                                    <span> {data.cliSexo}</span><h6>{Strings.genero}</h6>
                                </li>
                                }
                                {!!data.cliEstadoCivil &&
                                <li>
                                    <span><SelectEnum group="estadosCivil" value={data.cliEstadoCivil}/></span>
                                    <h6>{Strings.estadoCivil}</h6>
                                </li>
                                }
                                {!!data.cliFechaNacimiento &&
                                <li>
                                    <span> {formatDate(data.cliFechaNacimiento)}</span>
                                    <h6>{Strings.fechaNacimiento}</h6>
                                </li>
                                }
                                {!!data.cliTipoVivienda &&
                                <li>
                                    <span> <SelectEnum group="tiposVivienda" value={data.cliTipoVivienda}/></span>
                                    <h6>{Strings.vivienda}</h6>
                                </li>
                                }
                                {!!data.ruta &&
                                <li>
                                    <span> {data.ruta}</span><h6>{Strings.ruta}</h6>
                                </li>
                                }
                                {customFormInput && Object.keys(customFormInput).map((key)=><Fragment key={key}>
                                    {data[key] &&
                                    <ListItem>
                                        <ListItemText secondary={customFormInput[key].name}>
                                            {!!customFormInput[key].source?(
                                                <SelectUsoMultiples
                                                    group={customFormInput[key].source}
                                                    value={data[key] ? data[key] : ""}
                                                />
                                            ):(
                                                data[key]
                                            )}
                                        </ListItemText>
                                    </ListItem>
                                    }
                                </Fragment>)}
                            </ul>
                        </List>
                    </div>

                    {data.archivos?.length > 0 &&
                        <div className={"d-print-none " + classes.block}>
                            <h4>{Strings.documento}</h4>
                            <ul className={classes.docs}>
                                {data.archivos.map((adj, i) =>
                                    <li key={i}>
                                        <a href={adj.arcPath} target="_blank" rel="noopener noreferrer">
                                            <img src={adj.arcPath.includes(".pdf") ? pdfSvg : adj.arcPath} alt=""/></a>
                                    </li>
                                )}
                            </ul>
                        </div>
                    }
                    {(getTokenInfo()?.UsuMaster === "9ceeae24-9093-449e-ab3a-031b2a7b59f0" ||
                            getTokenInfo()?.UsuMaster === "ae4b4ad7-ce06-416e-ac31-d7d865394dc6" ||
                            getUsuTipo() === TIPOUSUARIO.admin) &&
                        <ClienteUsuario cliente={byId}/>
                    }
                    {(isMobile.anyWebView() || !!data?.cliCelular?.startsWith("+")  || !!data.cliEmail) &&
                        <div className={classes.contactBtn}>
                            <Button fullWidth onClick={()=>setTab(3)}
                                    color={"secondary"}>{Strings.enviar} {Strings.mensaje}</Button>
                        </div>
                    }
                </TabPanel>
                <TabPanel value={tab} index={0}>
                    <List>
                        {data.cliTrabajoTelefono &&
                        <ListItem>
                            <ListItemText primary={data.cliTrabajoTelefono} secondary={Strings.telefono}/>
                            <ListItemSecondaryAction>
                                <a href={"tel:" + data.cliTrabajoTelefono}>
                                    <IconButton edge="end" aria-label="delete" size="large">
                                        <Call/>
                                    </IconButton>
                                </a>
                            </ListItemSecondaryAction>
                        </ListItem>
                        }
                        {data.cliTrabajoNombre &&
                        <ListItem>
                            <ListItemText primary={data.cliTrabajoNombre} secondary={Strings.empresa}/>
                        </ListItem>
                        }
                        {data.cliTrabajoPosicion &&
                        <ListItem>
                            <ListItemText primary={data.cliTrabajoPosicion} secondary={Strings.posicion}/>
                        </ListItem>
                        }
                        {data.cliTrabajoTiempoLaborando &&
                        <ListItem>
                            <ListItemText primary={data.cliTrabajoTiempoLaborando}
                                          secondary={Strings.tiempoLaborando}/>
                        </ListItem>
                        }
                        {data.cliTrabajoDireccion &&
                        <ListItem>
                            <ListItemText primary={data.cliTrabajoDireccion} secondary={Strings.direccion}/>
                        </ListItem>
                        }
                    </List>
                </TabPanel>
                <TabPanel value={tab} index={2}>
                    <Paper elevation={0}>
                        {Object.keys(data.cliReferencias).length > 0 && Object.keys(data.cliReferencias).map((i) =>

                            <List dense key={i} subheader={
                                <ListSubheader color={"primary"}>
                                    <SelectEnum group="tiposReferencia" value={data.cliReferencias[i][0].tipo}/>
                                </ListSubheader>
                            }>

                                {data.cliReferencias[i].map((r, i) =>
                                    <ListItem key={i}>
                                        <ListItemText primary={r.nombre}
                                                      secondary={
                                                          <>
                                                              {r.telefono} <br/>
                                                              <SelectEnum group="parentescos" value={r.parentesco}/>
                                                          </>
                                                      }
                                        />
                                        <ListItemSecondaryAction>
                                            <a href={"tel:" + r.telefono}>
                                                <IconButton edge="end" size="large"><Call/></IconButton>
                                            </a>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )}
                            </List>
                        )}
                    </Paper>
                </TabPanel>
                <TabPanel value={tab} index={3}>
                    <Paper elevation={0}>
                           <EnviarPlantilla data={data}/>
                    </Paper>
                </TabPanel>
                {/*<Backdrop open={true} style={{    position: "absolute",zIndex: 0}}/>*/}
            </DialogContent>
        </Dialog>
        {pathname.indexOf("prestamo") === -1 && //no mostra para evitar problema eliminar pago en detalle de prestamos
            <ConfirmDialog onConfirm={() => eliminarCliente(data.cliId)}/>
        }
    </>;
}

const mapStateToProps = (state) => ({
    byId: state.clientes.byId,
    isPending: state.clientes.isPending,
    isFetching: state.clientes.isFetching,
    fulfilled: state.clientes.fulfilled,
});
export default connect(mapStateToProps, {eliminarCliente, mostrarClienteById, editarClienteRating})(ContactDetails);
