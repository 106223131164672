import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { mostrarRutas, eliminarRuta} from '../../redux/actions/rutasActions';
import AppBar from "../../components/appBar";
import Crear  from './crear';
import {
    ListItem,
    List,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Button,
    ListItemIcon, SvgIcon
} from "@mui/material";
import PlusFab from "../../components/PlusFab";
import {Close} from "@mui/icons-material";
import LoadingProgress from "../../components/LoadingProgress";
import {useHistory, useLocation} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import {MostrarError} from "../../components/MostrarError";
import Strings from "../../assets/strings";
import {hasPermissionTo, noTilde, PERSMISOS} from "../../helpers";
import {Empty, SeacrhAppBar} from "../../components";
import {ReactComponent as EmptySvg} from "../../assets/img/map.svg";
import {getIcon} from "../../assets/iconPath";

function Rutas(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();
    const [searchBox, setSearchBox] = useState("");

    const { mostrarRutas } = props;
     useEffect(() => {
        mostrarRutas();
     }, [mostrarRutas]);


     const { title: mainTitle, rutas: { list, isFetching, error } } = props;
     const isEmpty = !isFetching && list.length === 0;


        const filterItems = () => {
            return list.filter(item=> noTilde(item.rutDescripcion).indexOf(noTilde(searchBox)) !== -1);
        }
      return <>
          <AppBar title={Strings[mainTitle]}>
              <SeacrhAppBar name="search" value={searchBox} onChange={({target})=>setSearchBox(target.value)}/>
          </AppBar>
          <section className="contentInner" style={!isEmpty ? {maxWidth:600}:{}}>
              {!urlState && <MostrarError errors={error}/>}
              {isFetching  && !list.length && <LoadingProgress vh/>}

              <List >
                  {!isEmpty && filterItems().map(item =>
                      <ListItem role={undefined} button key={item.rutId}
                                onClick={()=>{
                                    if(!hasPermissionTo(PERSMISOS.rutasActualizar)) return false;
                                    history.push(history.location.pathname,item)}}>
                          <ListItemIcon>
                              <SvgIcon className="MuiSvgIcon-root jss67"  sx={{color:item.rutColor?item.rutColor:"auto"}}
                                   viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1"
                                   data-ga-event-action="click">
                                  <path  d={getIcon(item.rutIcon || "Place")}></path>
                              </SvgIcon>
                          </ListItemIcon>
                          <ListItemText primary={item.rutDescripcion} sx={{color:item.rutColor?item.rutColor:"auto"}}/>
                          {hasPermissionTo(PERSMISOS.rutasEliminar) && !item.registroDelSistema &&
                          <ListItemSecondaryAction>
                              <IconButton
                                  edge="end"
                                  aria-label="comments"
                                  onClick={()=>history.push(history.location.pathname,{confirmDialog:item.rutId})}
                                  size="large">
                                  <Close className="text-red" fontSize={"small"}/>
                              </IconButton>
                          </ListItemSecondaryAction>
                          }
                      </ListItem>
                  )}
              </List>
              {isEmpty &&
              <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.rutas}>
                  <p>{Strings.rutasHelpMsj}</p>
              </Empty>
               }


              {hasPermissionTo(PERSMISOS.rutasCrear) &&
                  <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{rutId:""})} />
              }
              <Crear/>


              <ConfirmDialog onConfirm={()=>props.eliminarRuta(urlState && urlState.confirmDialog)}/>

          </section>
      </>;

}

const mapStateToProps = (state) => ({
    rutas: state.rutas
});
export default connect(mapStateToProps, { mostrarRutas, eliminarRuta })(Rutas);
