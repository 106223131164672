import {generalesTypes, SET_CURRENT_USER} from './types';
import { getGenerales, editGenerales } from '../../services';
import Notifications from "react-notification-system-redux";
import {setLSLang} from "../../helpers";

export function mostrarGenerales() {
    return dispatch => {
        dispatch({
            type: generalesTypes.MOSTRAR_GENERALES,
            payload: getGenerales()
        })
    }
}
export function mostrarGeneralesSinoEsta() {
    return (dispatch, getState) => {
        const generales = getState().generales
        if (Object.keys(generales.data).length || generales.isFetching) return false;
        dispatch({
            type: generalesTypes.MOSTRAR_GENERALES,
            payload: getGenerales()
        })
    }
}
export function editarGenerales(obj) {
    return dispatch => {
        dispatch({
            type: generalesTypes.EDITAR_GENERALES,
            payload: editGenerales(obj)
        }).then(()=>{
            setLSLang(null,obj.genLocalidad)
            dispatch(Notifications.success({ title: 'Guardado con éxito', message: '', autoDismiss: 5, }));
            dispatch({
                type: SET_CURRENT_USER,
                payload: {genLocalidad: obj.genLocalidad}
            })
        })
    }
}



export const setGenerales = obj => {
    return {
        type: generalesTypes.SET_GENERALES,
        payload: obj
    }
}