import history from "../helpers/history";
import store from '../redux/store';
import Notifications from "react-notification-system-redux";
import {errorToNotifObj, getLSLang} from "../helpers";

import {getIdToken} from './auth'; //isLoggedIn
import _ from 'lodash';
import axios from "axios";
let base_url = "https://api.prestamistapp.net/";
if(window.location.hostname.indexOf("test") !== -1 || window.location.hostname.indexOf("loclhost") !== -1 ){
    base_url = "https://apitest.prestamistapp.net/";
}
export {base_url};
export const API = axios.create({
    baseURL: base_url,
    // timeout: 1000,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "es-US" }
})
API.interceptors.request.use(function (request) {
    const tokenId = getIdToken();
    request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    // const decoded = jwt_decode(localStorage._token);
    //
    // const currentTime = Date.now() / 1000;
    // if(decoded.exp < currentTime) {
    //     store.dispatch(logoutUser());
    //     window.location.href = '/login'
    // }
    //
    if (tokenId) request.headers.Authorization = `Bearer ${tokenId}`;

   // if (!window.navigator.onLine) alert("no estas conectado a internet");
    return request;
})
API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            localStorage.removeItem('_token');
            if (history.location.pathname.indexOf("/report") !== -1) {
                window.close();
            }
            history.replace("/login", { backurl: history.location.pathname })
        } else if (error.response.status === 403) {
            store.dispatch(Notifications.warning({ title: 'Acceso denegado', message: 'Necesita permisos para realizar esta acción.', autoDismiss: 5 }));
        } else {
            store.dispatch(Notifications.error(errorToNotifObj(error.response)));
        }
    }
    return Promise.reject(error);
});
export const API_PUBLIC = axios.create({
    baseURL: base_url,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "en-US" }
})
API_PUBLIC.interceptors.request.use(function (request) {
    request.headers["accept-language"] = getLSLang("l") === "en" ? "en-US" : "es-ES";
    return request;
})

export const API_PORTAL_CLIENTE = axios.create({
    baseURL: base_url,
    headers: { 'contentType': 'application/json; charset=utf-8', "accept-language": "en-US" }
})
API_PORTAL_CLIENTE.interceptors.request.use(function (request) {
    if(!request.url.toLowerCase().includes("login")) {
        let tokenId = localStorage.getItem("cliToken");
        if(tokenId) request.headers.Authorization = tokenId;
    }
    return request;
})
//Empresa
export function getGenerales() {
    return API.get('Generales');
}
export function editGenerales(obj) {
    let params = _.omitBy(obj, _.isNil);
    return API.put('Generales', params);
}
export function generarBackUp() {
    //   return API.get(`reportes/GenerarBackUp`)
    return axios({
        url: base_url + 'reportes/GenerarBackUp',
        method: 'GET',
        responseType: 'blob',
        headers: { 'Accept': 'application/vnd.ms-excel', 'Authorization': 'Bearer ' + getIdToken(), }
    })
}
export function postGenerales(obj) {
    let params = _.omitBy(obj, _.isNil);
    return API.post('Generales', params);
}

//perfil
export function getPerfil() {
    return API.get('Perfil');
}
export function ediPerfil(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Perfil', params);
}
export function postSuscripcion(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Suscripcion', params);
}
//DashBoard
export function getCxC(filter) {
    let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
    return API.get('Prestamos/CuentasxCobrar', { params })
}
export function getWidgets() {
    return API.get('/Reportes/Widgets');
}
export function getWidgetsGasIng(ciclo) {
    return API.get('/Reportes/WidgetsIngresos', { params: { ciclo } });
}
export function getResumenGrafico(anio) {
    return API.get(`/Reportes/ResumenGrafico`, { params: { anio } });
}

//Clientes
export function getClientes(filter, simpleObj) {
    if (typeof filter === 'number') {
        return API.get(`Clientes/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get(simpleObj ? "Clientes/ListadoSimple" : "Clientes", { params });
    }
}
export function addCliente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Clientes', params);
}
export function editCliente(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.put('Clientes', params);
}
export function delCliente(id) {
    return API.delete(`Clientes/${id}`);
}
export function deleteCliente(id) {
    return API.delete(`Clientes/${id}`)
}
export function rateCliente(params) {
    return API.post("/Clientes/Calificar", params);
}

//portal cliente
export function clienteLogin(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API_PORTAL_CLIENTE.post("/PortalClientes/Login",params);
}
export function clientePrestamos(obj) {
   // let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API_PORTAL_CLIENTE.get("/PortalClientes/Prestamos");
}
export function clientePrestamosById(id) {
    return API_PORTAL_CLIENTE.get(`/PortalClientes/Prestamos/${id}`);
}
export function clienteAgregarPago(obj) {
    let preId = obj.preId;
    let params = {
        pagMonto:obj.pagMonto,
        pagComentario:obj.pagComentario,
        pagAdjunto:obj.pagAdjunto
    }
    return API_PORTAL_CLIENTE.post(`/PortalClientes/Prestamos/${preId}/Pagar`,params);
}

//Agente
export function getAgentes(filter) {
    if (typeof filter === 'number') {
        return API.get(`Agentes/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("Agentes", { params });
    }
}
export function addAgente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Agentes', params);
}
export function editAgente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Agentes', params);
}
export function editPWAgente(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Agentes/ChangePassword', params);
}
export function delAgente(id) {
    return API.delete(`Agentes/${id}`);
}
export function lockToggleAgente(id) {
    return API.post(`Agentes/Lock/${id}`);
}
//Prestamo
export function getPrestamo(preId) {

    return preId ? API.get(`prestamos/${preId}`) : API.get(`prestamos`);
}
export function getPrestamos(filter) {
    if (typeof filter == 'number') {
        return API.get(`Prestamos/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("prestamos", { params });
    }
}
export function calCuotasPrestamo(id, noCuotas) {
    return API.get(`Prestamos/GetMontoCuota/${id}`, { params: { noCuotas } })
}
export function addPrestamo(obj) {
    let params = _.omitBy(obj, (v) => !v);

    if(params.preIdReeganche) return API.post('Prestamos/Reenganche', params);

    return API.post('Prestamos', params);
}
export function editPrestamo(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Prestamos', params);
}
export function addPrestamoNota(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Prestamos/Notas', params);
}
export function deletePrestamoNota(prnId) {
    return API.delete(`Prestamos/Notas/${prnId}`);
}
export function cancelarPrestamo(id, preComentario = "", anularPagos = false) {
    return API.delete(`Prestamos/${id}`, { params: { preComentario, anularPagos } });
}
export function ajustarCapitalPrestamo(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Prestamos/AjustarCapital', params);
}
export function getContrato(id, plantilla) {
    return API.get(`Prestamos/VerContrato/${id}`, { params: { plantilla } });
}
export function setIncobrablePrestamo(id) {
    return API.post(`/Prestamos/${id}/MarcarIncobrable`);
}
//pagos
export function getPagos(filter) {
    // if(preId)
    let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
    return API.get('Pagos/', { params });
}
export function getPagoById(pagId) {
    return API.get('Pagos/' + pagId);
}
export function postPago(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post("Pagos", params);
}
export function postPagoInicial(obj){
    let params = _.omitBy(obj, (v) => !v);
    return API.post("Pagos/PagoInicial", params);
}
export function postPagoRapido(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post("Pagos/PagoRapido", params);
}
export function deletePago(preId, pagId) {
    // if(preId)
    return API.delete(`Pagos/${preId}`, { params: { pagId } });
}
export function getBancos() {
    return API.get("bancos")
}
export function postEditBanco(name, bankId) {
    if (bankId) {
        return API.put(`bancos/${bankId}`, { nombre: name, banId: bankId })
    } else {
        return API.post("bancos", { nombre: name })
    }
}
export function deleteBanco(id) {
    if (id)
        return API.delete(`bancos/${id}`)
}
//Solicitudes
export function getSolicitudes(filter) {
    if (typeof filter == 'number') {
        return API.get(`Solicitudes/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("Solicitudes", { params });
    }
}
export function addSolicitud(obj) {
    return API.post('Solicitudes', obj);
}
export function rechazarSolicitud(id) {
    return API.post(`Solicitudes/Rechazar/${id}`);
}
export function aprobarSolicitud(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post(`Solicitudes/Aprobar`, params);
}
export function editSolicitud(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('Solicitudes', params);
}
export function delSolicitud(id) {
    return API.delete(`Solicitudes/${id}`);
}

//Rutas
export function getRutas(name) {
    return API.get('Rutas', { params: { name } });
}
export function addRuta(obj) {
    return API.post('Rutas', obj);
}
export function editRuta(obj) {
    return API.put('Rutas', obj);
}
export function delRuta(id) {
    return API.delete(`Rutas/${id}`);
}
//Carteras
export function getCarteras(filter) {
    if (typeof filter == 'number') {
        return API.get(`Carteras/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
        return API.get("Carteras", { params });
    }
}
export function addCartera(obj) {
    return API.post('Carteras', obj);
}
export function editCartera(obj) {
    return API.put('Carteras', obj);
}
export function delCartera(id) {
    return API.delete(`Carteras/${id}`);
}
//plantillas
export function getPlantillas(filter) {
    if (typeof filter == 'number') {
        return API.get(`Plantillas/${filter}`)
    } else {
        let params = _.omitBy(filter, (v) => !v);
        return API.get("Plantillas", { params });
    }
}
export function addPlantilla(obj) {
    return API.post('Plantillas', obj);
}
export function editPlantilla(obj) {
    return API.put('Plantillas', obj);
}
export function delPlantilla(id) {
    return API.delete(`Plantillas/${id}`);
}
export function getPlantillaConverted(id, params) {
    return API.get(`Plantillas/Contrato/${id}`, { params });
}
//Cajas
export function getCajas(name) {
    if (typeof name == 'number') {
        return API.get(`Cajas/${name}`)
    } else {
        return API.get('Cajas', { params: { name } });
    }
}
export function addCaja(obj) {
    return API.post('Cajas', obj);
}
export function editCaja(obj) {
    return API.put('Cajas', obj);
}
export function delCaja(id) {
    return API.delete(`Cajas/${id}`);
}
export function delCajaCierre(id) {
    return API.delete(`Cajas/Cierres/${id}`);
}
export function closeCaja(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('cajas/Cierres', params);
}
export function transferBetweenCajas(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('cajas/Transferencia', params);
}
export function getCajasMovimientos(id, obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get(`cajas/Movimientos/${id}`, { params });
}
export function getCajasCierres(id, params) {
    return API.get(`Cajas/Cierres/${id}`, { params });
}
//gastos
export function getGastos(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Gastos', { params });
}
export function addGasto(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('Gastos', params);
}
export function delGasto(id) {
    return API.delete(`Gastos/${id}`);
}





/*-------------Otros Mantenimientos--------------------*/
//getUsosMultiples
export function getUsosMultiples(usoGrupo, name) {
    return API.get('UsosMultiples', { params: { usoGrupo, name } });
}
export function addUsosMultiples(obj) {
    return API.post('UsosMultiples', obj);
}
export function editUsosMultiples(obj) {
    return API.put('UsosMultiples', obj);
}
export function delUsosMultiples(usoGrupo, usoCodigo) {
    return API.delete('UsosMultiples', { params: { usoGrupo, usoCodigo } });
}
//Autorizaciones
export function getAutorizaciones(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('Autorizaciones', { params });
}
export function addAutorizaciones(obj) {
    return API.post('Autorizaciones', obj);
}
export function delAutorizaciones(id) {
    return API.delete('Autorizaciones/'+id);
}

//Roles
export function getRoles(id) {

    if (typeof id == 'number') {
        return API.get(`Roles/${id}`);
    }

    return API.get('Roles');

}
export function addRol(params) {
    let obj = _.omitBy(params, (v) => !v);
    return API.post('Roles', obj);
}
export function editRol(params) {
    let obj = _.omitBy(params, (v) => !v);
    return API.put('Roles', obj);
}
export function delRol(id) {
    return API.delete(`Roles/${id}`);
}
//Estados
export function getEstados() {
    return API.get('Estados');
}
//permisos
export function getPermisos() {
    return API.get('Permisos');
}
//Paises
export function getPaises() {
    return API.get('comunes/Paises');
}
//Paises
export function getZonasHorarias() {
    return API.get('/Comunes/TimeZones');
}
//Enums
export function getEnums() {
    return API.get('comunes/Enums');
}
//FeedBack
export function postFeedBack(FeedBack) {
    return API.post('Comunes/EvaluarApp',{FeedBack});
}
export function savePushToken(params) {
    let obj = _.omitBy(params, (v) => !v);
    return API.post('/Comunes/SesionToken', obj);
}
//notificaciones
export function getNotificaciones() {
    return API.get('Notificaciones');
}
//amort
export function getAmortizacion(monto, tipoAmortizacion, cuotas, fechaPrimerPago) {
    return API.get('comunes/Amortizacion', { monto, tipoAmortizacion, cuotas, fechaPrimerPago });
}
//admin
export function getAppUsers(obj) {
    let params = _.omitBy(obj, (v) => v === '' || _.isNil(v));
    return API.get('admin', { params });
}
export function getAppUsersById(id) {
    return API.get('admin/GetById/' + id);
}
export function putAppUser(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.put('admin', params);
}
export function getAdminEnums() {
    return API.get('Admin/GetEnums');
}


//auth
export function PostRegister(obj, validate) {
    let params = _.omitBy(obj, (v) => !v);
    return API_PUBLIC.post('Register' + (validate ? "?validar=true" : ""), params);
}
export function changePassword(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ChangePassword', params);
}
export function forgotPassword(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ForgotPassword', params);
}
export function forgotPasswordConfirm(obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API.post('ForgotPasswordConfirm', params);
}

//piblic
export function getPublicUser(user) {
    return API_PUBLIC.get('Public/' + user);
}
export function postSolicitud(user, obj) {
    let params = _.omitBy(obj, (v) => !v);
    return API_PUBLIC.post('Solicitudes/' + user, params);
}
export function getGeo() {
    return axios.get("https://ipapi.co/json");
}
