import React, {Fragment, useEffect, useState} from 'react';
import Strings from "../../assets/strings";

import {DialogActions, Dialog, Button, Grid, TextField, useMediaQuery} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {buildAmort, formatDate, openNewWin} from '../../helpers';
import TopBarDialog from "../topBar/TopBarDialog";
import {SelectEnum} from "../selects";
import {DateInput, TextFieldCurrency} from "../CustomTextField";
import {API} from "../../services";
import {HtmlTooltip} from "../index";
import AmortList from "./AmortList";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  inputCont:{
    padding: "12px 16px",
    "& .MuiInput-input":{
      padding: ".25rem .5rem",
      [theme.breakpoints.down('md')]: {
        padding: ".25rem 0",
      }
    }
  },
  inputDataCont:{
    paddingBottom: theme.spacing(1),
  },
  btn:{
    marginRight: 5,
    color:theme.palette.common.white,
    fontWeight:"normal",
  }
}));

const initialState={
  fecha: formatDate(new Date(),"YYYY-MM-DD"),
  capital: "",
  interes: "",
  cuotas: "",
  ciclosPago:"30",
  tipoAmortizacian:"1",
  montoCuota:"",
}

export default function AmortCal({onClose,onUseVal,inicialState:inicialStateProp}){
  const smVewport = useMediaQuery(theme=>theme.breakpoints.down('md'));
  const classes = useStyles();

  const [list, setList] = useState([]);
  const [state, setState] = useState(inicialStateProp?inicialStateProp:initialState);


  // Reset fecha si se cambia a modalidad quincenalFijo
  const {ciclosPago} = state
  useEffect(()=>{
    if(parseInt(ciclosPago) === 16) setState(prevState => ({...prevState, ciclosPago, fecha: ""}));
  },[ciclosPago]);

  const calcular = (e)=>{
    e.preventDefault();
    getAmortList(state).then(res=> {
      setState(prevState => ({...prevState,interes: res.interes}))
      setList(res.list)
    })
  }
  const handleChange =  e => {
    const {value,name} = e.target
    if(name === "cuotas" && value > 800) return false;
    setState({...state,[name]:value});
  };

  const formId = Math.random();
  return (
      <Fragment>

        <Dialog
          open={true}
          onClose={onClose}
          fullScreen={smVewport}
          maxWidth={"sm"}
          fullWidth
        >
          <TopBarDialog title={Strings.calculadora} onClose={onClose}>
            {!!list.length &&
            <Button className={classes.btn} size={"small"}
                        // onClick={()=>downloadFiles({
                        //   Monto:state.capital,
                        //   TipoAmortizacion:state.tipoAmortizacian,
                        //   CicloPago: state.ciclosPago,
                        //   Tasa:state.interes,
                        //   Cuotas:state.cuotas,
                        //   FechaPrimerPago:state.fecha
                        // },"rptAmortizacion")}
              onClick={()=>openNewWin("/reports/amortizacion",state)}
            >
              {Strings.exportar}
            </Button>
            }
          </TopBarDialog>
            <form onSubmit={calcular} id={formId} className={classes.inputCont}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextFieldCurrency
                      name="capital"
                      required
                      value={state.capital}
                      onChange={handleChange}
                      fullWidth
                      label={Strings.monto}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                      name="interes"
                      required={!state.montoCuota}
                      value={state.interes}
                      onChange={handleChange}
                      type="number"
                      inputProps={{step:"any",inputMode:"decimal"}}
                      fullWidth
                      disabled={state.montoCuota>0}
                      label={Strings.interes}
                      margin={"none"}
                      size="small"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                      name="cuotas"
                      required
                      value={state.cuotas}
                      onChange={handleChange}
                      type="number"
                      inputProps={{step:1,inputMode:"numeric"}}
                      fullWidth
                      label={Strings.cuotas}
                      margin={"none"}
                      size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectEnum
                      fullWidth
                      label={Strings.amortizacion}
                      required
                      group="tipoAmortizacion"
                      name='tipoAmortizacian'
                      value={state.tipoAmortizacian}
                      onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <HtmlTooltip title={
                      <em> {Strings.interesDesconocidoMsj}</em>
                  } disableHoverListener>
                    <div>
                      <TextFieldCurrency
                          name="montoCuota"
                          value={state.montoCuota}
                          onChange={handleChange}
                          fullWidth
                          disabled={!state.tipoAmortizacian}
                          placeholder={"Opcional"}
                          label={Strings.monto+" "+Strings.cuotas}
                      />
                    </div>
                  </HtmlTooltip>
                </Grid>
                <Grid item xs={6}>
                  <SelectEnum
                      fullWidth
                      required={!!state.fecha}
                      label={Strings.modalidad}
                      group="ciclosPago"
                      name= 'ciclosPago'
                      value={state.ciclosPago}
                      onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DateInput
                      name="fecha"
                      value={state.fecha}
                      label={Strings.primerPago}
                      margin={"none"}
                      quincenalFijo={state.ciclosPago === "16"}
                      required
                      onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </form>
            <AmortList list={list}/>
          <DialogActions>
            {onUseVal &&
              <Button onClick={()=>{onClose(); onUseVal(state)}} color="primary" sx={{marginRight: "auto"}}>
                {Strings.usar} {Strings.valores}
              </Button>
            }
            <Button type={"submit"} form={formId} color="primary">{Strings.calcular}</Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );

}

export async function getAmortList ({capital,interes:stInteres,cuotas,ciclosPago,tipoAmortizacian,fecha,montoCuota}){
  var interes = stInteres??0;
  if(montoCuota && capital && cuotas && tipoAmortizacian ){
    switch (tipoAmortizacian?.toString()) {
      case "1":
        await API.get("Comunes/GetTasaCuotaFija",{params:{montoCuota,cuotas,capital:capital}})
            .then(({data})=> interes=data)
        break
        //value*(  (1-Math.pow(1 + i,-5) )/ 0.1)
      case "2": //disminuir cuota
        interes = (montoCuota-capital/cuotas)/capital*100
        break
      case "3": //interes fijo -- //mismo resultado de arriba ;)
        interes = (1 / cuotas) * (montoCuota * cuotas / capital - 1) * 100;
        break
      case "4": //capital al final
        interes = (capital/capital) * 100;
        break
      default: //capital al final
        interes = 0
    }
    //setState({...state,interes})
  }


  if(capital && !isNaN(interes) && cuotas){
    return {
      interes,
      list:buildAmort(capital, interes, cuotas, ciclosPago, parseInt(tipoAmortizacian), fecha)
    };
  }
  return {interes,list:[]};
}