import React, {useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, IconButton,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    TextField
} from '@mui/material';
import {Close} from "@mui/icons-material";
import Strings from "../assets/strings";
import SelectEnum from "./selects/SelectEnum";
import {TextFieldPhone} from "./CustomTextField";
import Typography from "@mui/material/Typography";
import TopBarDialog from "./topBar/TopBarDialog";
import Box from "@mui/material/Box";
import {MostrarError} from "./MostrarError";



const initialState={
    tipo:"",
    nombre:"",
    telefono:"",
    parentesco:""
};
export default  function ReferenciasCliente({referencias=[], onChange}){

    const [state, setState] = useState(initialState);
    const [error, setError] = useState(null);

    
    const [openNewRef, setOpenNewRef] = useState(false);
    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const deleteRef = (refIndex) =>{
        let refs = [...referencias];
        refs.splice(refIndex, 1);
        onChange(refs);


        // for (var i = 0; i < refs.length; i++) {
        //     if (refs[i].id === refId) {
        //         refs.splice(i, 1);
        //         onChange(refs);
        //     }
        // }
    };
    const addRef = (e)=>{
        e.preventDefault();
        setError(null);
        let refs = [...referencias];

        if(state.tipo && state.nombre && state.telefono && state.parentesco){
            refs.unshift(state);
            onChange(refs);
            setOpenNewRef(false);
            setState(initialState)
        }else {
            setError(Strings.completeCamposRequeridosMsj)
        }
    };

    return <>
        <div>
            <Box display='flex' alignItems="center" bgcolor={"#ececec"} p={"0 5px"}>
                <Typography sx={{flex: '1 1 100%'}} variant="body1" color={"secondary"}>
                    {Strings.referencias}
                </Typography>
                <Button color={"primary"} onClick={()=>setOpenNewRef(!openNewRef)}>
                    {Strings.agregar}
                </Button>
            </Box>
            {!!referencias.length &&
                <TableContainer>
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{Strings.tipo}</TableCell>
                                <TableCell>{Strings.nombreCompleto}</TableCell>
                                <TableCell>{Strings.telefono}</TableCell>
                                <TableCell>{Strings.parentesco}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {referencias.map((ref,i)=>{
                                return (
                                    <TableRow key={i}>
                                        <TableCell className="actionCell">
                                            <IconButton onClick={()=>deleteRef(i)} size="large">
                                                <Close className="text-red"/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell><SelectEnum value={ref.tipo} group={"tiposReferencia"}/></TableCell>
                                        <TableCell>{ref.nombre}</TableCell>
                                        <TableCell>{ref.telefono}</TableCell>
                                        <TableCell><SelectEnum value={ref.parentesco} group={"parentescos"}/></TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </div>
        <Dialog maxWidth="xs" onClose={()=>setOpenNewRef(false)} open={!!openNewRef}>
            <TopBarDialog title={Strings.agregar+" "+Strings.referencia} onClose={()=>setOpenNewRef(false)} nofullScreen/>
            <DialogContent>
                    <MostrarError errors={error} onClose={()=>setError(null)}/>
                    <SelectEnum value={state.tipo}
                                name={"tipo"}
                                label={Strings.tipo}
                                onChange={handleChange}
                                group={"tiposReferencia"}
                                margin="dense"
                                required
                    />
                    <TextField
                        name="nombre"
                        label={Strings.nombreCompleto}
                        value={state.nombre}
                        onChange={handleChange}
                        fullWidth
                        margin="dense"
                        required
                    />
                    <TextFieldPhone
                        name="telefono"
                        label={Strings.telefono}
                        value={state.telefono}
                        onChange={handleChange}
                        required
                        margin="dense"
                        fullWidth
                    />
                    <SelectEnum
                        value={state.parentesco}
                        name={"parentesco"}
                        label={Strings.parentesco}
                        onChange={handleChange}
                        group={"parentescos"}
                        margin="dense"
                        required
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={()=>setOpenNewRef(false)} color="primary">{Strings.cancelar}</Button>
                    <Button color="primary" onClick={addRef}>{Strings.agregar}</Button>
                </DialogActions>
        </Dialog>
    </>;
}
