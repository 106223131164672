import {themeTypes } from '../actions/types';

const initialState = {
    drawerOpen: false,
    viewTableList: false,
    paypalPlanOpen:false,
    installBannerEvent: null,
}

export default function index(state = initialState, action={}) {
    switch(action.type) {
        case themeTypes.OPEN_DRAWER:
            return {
                ...state,
                drawerOpen: true,
            }
        case themeTypes.CLOSE_DRAWER:
            return {
                ...state,
                drawerOpen: false,
            }
        case themeTypes.TOGGLE_VIEW_TABLE:
            return {
                ...state,
                viewTableList: !state.viewTableList,
            }
        case themeTypes.OPEN_PAYPAL_PLAN:
            return {
                ...state,
                paypalPlanOpen: true,
            }
        case themeTypes.CLOSE_PAYPAL_PLAN:
            return {
                ...state,
                paypalPlanOpen: false,
            }
        case themeTypes.SET_INSTALL_BANNER_EVENT:
            return {
                ...state,
                installBannerEvent: action.payload,
            }
        default: 
            return state;
    }
}