import React, {useState, useEffect} from 'react';
import { Button,Dialog,DialogContent,TextField,DialogActions} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {connect} from "react-redux";
import {agregarEditarCaja, cerrarCaja} from "../../redux/actions/cajasActions";
import {nullToEmptyString} from "../../helpers";
import {MostrarError} from "../../components/MostrarError";
import LoadingProgress from "../../components/LoadingProgress";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import Strings from "../../assets/strings";

export const ACTIONS = {
    AJUSTAR:1,
    CERRAR:2
};

const initialState = {
    cajId: null,
    cajDescripcion: "",
    cajBalance:"",
    concepto:""
};

function Crear(props) {
    let {goBack} = useHistory();
    const {state:urlState} = useLocation();

    const [state, setState] = useState(initialState);
    const {isPending,error} = props;

    useEffect(() => {
        if(!!urlState && urlState.cajId) {
            let cajBalance = urlState.action === ACTIONS.CERRAR ?"":urlState.cajBalance; //balance inicial en 0 para cerrar
            setState(state => ({...state, ...nullToEmptyString({...urlState,cajBalance})}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = e => {
        e.preventDefault();

        switch ((urlState && urlState.action) || "") {
            case ACTIONS.CERRAR:
                props.cerrarCaja({cajId: state.cajId, nota:state.concepto,monto:state.cajBalance})
                break
            case ACTIONS.AJUSTAR:
                props.agregarEditarCaja(state)
                break
            default:
                props.agregarEditarCaja({cajDescripcion:state.cajDescripcion,cajBalance:state.cajBalance});
                break
        }
    };

    const title = ()=>{
        let title = ""
        switch ((urlState && urlState.action) || "") {
            case ACTIONS.CERRAR:
                title = Strings.cerrar+" "+Strings.caja
                break
            case ACTIONS.AJUSTAR:
                title = Strings.ajustar+" "+Strings.caja
                break
            default:
                title = Strings.agregar+" "+Strings.caja
                break
        }
        return title;
    }
    if(!urlState) return false;
    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("cajId"))} onClose={goBack} fullWidth maxWidth={"xs"} >
                <TopBarDialog title={title()} onClose={goBack} nofullScreen/>
                <DialogContent>
                    <MostrarError errors={error}/>
                    {isPending && <LoadingProgress />}
                    <form onSubmit={handleSubmit} id="form1">
                        {parseInt(urlState.action) !== parseInt(ACTIONS.CERRAR) &&
                            <TextField
                                value={state.cajDescripcion}
                                onChange={(e) => setState({ ...state, cajDescripcion: e.target.value })}
                                inputProps={{maxLength: 50}}
                                autoFocus
                                autoComplete={"off"}
                                required
                                label={Strings.descripcion}
                                fullWidth
                                margin={"normal"}

                            />
                        }

                        <TextField
                            value={state.cajBalance}
                            onChange={(e) => setState({ ...state, cajBalance: e.target.value })}
                            required
                            label={Strings.balanceInicial}
                            type="number"
                            inputProps={{step:"any",inputMode:"decimal"}}
                            fullWidth
                            margin={"normal"}
                        />
                        {(urlState.action === ACTIONS.CERRAR || (urlState.action === ACTIONS.AJUSTAR
                            && parseFloat(urlState.cajBalance) !== parseFloat(state.cajBalance))) &&
                            <TextField
                                value={state.concepto}
                                onChange={(e) => setState({ ...state, concepto: e.target.value })}
                                label={Strings.comentario}
                                fullWidth
                                margin={"normal"}
                            />
                        }

                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={goBack} color="primary">{Strings.cancelar}</Button>
                    <Button type="submit" form={"form1"} color="primary" disabled={isPending}>
                        {!!state.rutId ? Strings.actualizar : Strings.guardar}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}
const mapStateToProps = (state) => ({
    error: state.cajas.error,
    isPending: state.cajas.isPending,
});
export default connect(mapStateToProps, { agregarEditarCaja,cerrarCaja })(Crear);