import { enumsTypes, _FULFILLED, _PENDING, _REJECTED } from '../actions/types';
const initialState = {
    list: [],
    isFetching: false,
}

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case enumsTypes.MOSTRAR_ENUMS + _PENDING: {
            return {
                ...state,
                isFetching: true,
            }
        }
        case enumsTypes.MOSTRAR_ENUMS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
            }
        }
        case enumsTypes.MOSTRAR_ENUMS + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        case enumsTypes.SET_ENUMS: {
            return {
                ...state,
                list: action.payload
            }
        }
    default:
      return state;
  }
}
