import React, {useState} from 'react';
import {connect} from "react-redux";
import {useHistory} from 'react-router-dom';
import { mostrarPrestamoById} from '../../redux/actions/prestamoActions';
import {ajustarCapitalPrestamo} from "../../services";
import { Dialog, DialogActions, DialogContent, Button, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {LoadingProgress,MostrarError} from "../../components";
import {DateInput, TextFieldCurrency} from "../../components/CustomTextField";
import Strings from "../../assets/strings";
import SelectCaja from "../../components/selects/SelectCaja";
import {formatPeso} from "../../helpers";
import {SelectEnum} from "../../components/selects";
const useStyles = makeStyles(theme => ({
    root: {
        boxShadow: "none",
        [theme.breakpoints.up('sm')]: {
            maxWidth:510,
            margin:"0px auto",
        },
    },
    content:{
        padding:theme.spacing(2),
    }
}));


function AjustarCapitalDialog(props) {
    const classes = useStyles();
    const {goBack} = useHistory();

    const {prestamo} = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [state, setState] = useState({
        monto: 0,
        fecha: prestamo.currentDate,
        cajId: props.defaultCajId,
        concepto: "",
        pagFormaPago:"",
    });
    const handleChange = e => {
        const {name,value} = e.target;
        if(name === "fecha") return setState({...state,[name]: value+""+prestamo.currentDate?.substr(10)});

        setState({...state,  [name]: value });
    };
    const submit = (e) => {
        e.preventDefault();
        setLoading(true);
        ajustarCapitalPrestamo({...state,preId:prestamo.preId}).then(()=>{
            setLoading(false);
            props.mostrarPrestamoById(prestamo.preId);
            goBack()
        }).catch(error=>{
            setError(error.response);
            setLoading(false)
        })
    };

    return (
        <Dialog className={classes.root} open={true} onClose={goBack} fullWidth maxWidth={"sm"}>
            <TopBarDialog title="Ajustar Capital" onClose={goBack} nofullScreen/>
            <MostrarError errors={error}/>
            <form onSubmit={submit}>
              <DialogContent className={classes.content}>
                  {loading && <LoadingProgress vh={false} />}
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextFieldCurrency
                            name="monto"
                            label={Strings.monto}
                            value={state.monto}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label={Strings.capitalActual}
                            defaultValue={ formatPeso(prestamo.preCapitalActual)}
                            inputProps={{readOnly:true}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <DateInput
                            name={"fecha"}
                            value={state.fecha}
                            label={Strings.fecha}
                            onChange={handleChange}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectCaja
                            name="cajId"
                            label={Strings.caja}
                            value={state.cajId}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            name="concepto"
                            label={Strings.concepto}
                            value={state.concepto}
                            fullWidth
                            onChange={handleChange}
                            inputProps={{maxLength: 100}}

                        />
                    </Grid>
                      <Grid item xs={12}>
                          <SelectEnum label={Strings.formaPago} group="formaPago" name="pagFormaPago" required value={state.pagFormaPago} onChange={handleChange}/>
                      </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={goBack}>{Strings.cancelar}</Button>
                <Button type="submit" color="secondary">{Strings.guardar}</Button>
              </DialogActions>
            </form>
        </Dialog>
    );
}

const mapStateToProps = (state) => ({
    prestamo: state.prestamos.byId,
    defaultCajId: state.auth.user.cajId || "",
});
export default connect(mapStateToProps, { mostrarPrestamoById })(AjustarCapitalDialog);



