import React, {useEffect, useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Strings from "../assets/strings";

import {Button, List, ListSubheader, ListItem, ListItemText,} from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import {mostrarPlantillas} from "../redux/actions/plantillasActions";
import LoadingProgress from "./LoadingProgress";
import {isMobile} from "../helpers/browser_detect";
const useStyles = makeStyles(() => ({
    shareBtn:{
        position: "absolute",
        left:0,
        background: "rgba(225,225,225,0.60)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        "& button, & a":{
            height:30,
            padding:"5px 10px",
            marginLeft:5
        }
    },
}));

export default function EnviarPlantilla({data={}}){
  const classes = useStyles();
  const [selectedPlantilla, selectPlantilla] = useState(-1);
    const [plantillas, setPlantillas] = useState([]);

    const Dispatch = useDispatch();
    const {list,isFetching} = useSelector(state => state.plantillas)


    useEffect(() => {
        Dispatch(mostrarPlantillas({tipo:1,mostrarDelSistema:true}));
    }, [Dispatch]);
    useEffect(() => {
        if(list?.length){
            let converted = list.filter(p=>p.plaTipo === 1);
            converted = converted.map((obj)=>{
                let plaContenido = obj.plaContenido.replace("$NombreCliente",data.cliNombres+" "+data.cliApellidos)
                plaContenido = plaContenido.replace("$CedulaCliente",data.cliCedula)
                plaContenido = plaContenido.replace("$DireccionCliente",data.cliDireccion)
                return  {...obj,plaContenido}
            })
            setPlantillas(converted)
        }
    }, [list,setPlantillas,data]);


  return <>
      {isFetching && <LoadingProgress />}
      <List dense  subheader={
          <ListSubheader color={"primary"}>
              {Strings.plantillas}
          </ListSubheader>
      }>
          {plantillas.map((p)=>
              <ListItem key={p.plaId} button={selectedPlantilla !== p.plaId} onClick={()=>selectPlantilla(selectedPlantilla === p.plaId?-1:p.plaId)}>
                  <ListItemText primary={p.plaNombre} secondary={p.plaContenido.substring(0, 150).concat('...')}/>
                  {selectedPlantilla === p.plaId &&
                  <div className={classes.shareBtn} >
                      <div onClick={(e)=> e.stopPropagation()}>
                          {data?.cliCelular?.startsWith("+") && <>

                              <Button color={"primary"} variant={"contained"} component={"a"}
                                      target={"_blank"} rel="noopener noreferrer"
                                      href={`https://wa.me/${data.cliCelular?.replace(/[^+\d]+/g, "")}?text=${p.plaContenido}`}>WhatsApp</Button>

                              <Button color={"primary"} variant={"contained"} component={"a"}
                                      target={"_blank"} rel="noopener noreferrer"
                                      href={`sms://${data.cliCelular?.replace(/[^+\d]+/g, "")};?&body=${encodeURI(p.plaContenido)}`}>SMS</Button>
                          </>}
                          {data.cliEmail &&
                          <Button color={"primary"} variant={"contained"} component={"a"}
                                  target={"_blank"} rel="noopener noreferrer"
                                  href={`mailto:${data.cliEmail}?subject=${p.plaNombre}&body=${p.plaContenido}`}>E-mail</Button>
                          }
                          {isMobile.anyWebView() &&
                            <Button color={"primary"} variant={"contained"}
                                    onClick={()=>{
                                        if(isMobile.AndroidWebView())
                                            window.Android.shareLink(p.plaContenido);
                                        else if(isMobile.IosWebView())
                                            window.webkit.messageHandlers.shareLink.postMessage(p.plaContenido)
                                    }}
                            >{Strings.otros}</Button>
                          }
                      </div>
                  </div>
                  }
              </ListItem>

          )}
      </List>
  </>;
}
