import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom'
import {connect} from "react-redux";
import {mostrarPagos} from "../../redux/actions/pagosActions";

import {
    useMediaQuery,
    TextField,
    Grid,
    TableCell,
    TableBody,
    TableFooter,
    TableRow,
    Button,
    Dialog,
    DialogContent,
    Checkbox,
    Typography,
    FormControlLabel,
    DialogActions,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import {formatPeso, formatDate} from '../../helpers';
import downloadFiles from '../../helpers/downloadFiles';
import ReciboPago from "../../components/recibo";
import {SelectRuta,SelectAgente,SelectCaja,SelectEnum} from "../../components/selects";
import {
    Pagination, TableSort, ButtonResponsive, SeacrhAppBar, LoadingProgress, CustomListItem, ListViewMode, AppBar,
    MostrarError, FilteredAlert} from "../../components";
import {AttachFile, CloudDownloadOutlined, FilterList} from "@mui/icons-material";
import Strings from '../../assets/strings'

const useStyles = makeStyles(theme => ({
  pagAnulado:{
    background: "#fff1f1",
    "& td, th":{
      color:theme.palette.error.light,
    }
  },
    row:{
        "&.pagTipo4":{
            background:"#d6f4ff !important"
        },
        "&.pagTipo8":{
            background:"#f8eaca !important"
        },
    },
    cliente:{
        "& svg":{
            fontSize: 14,
            marginLeft: -4,
            marginBottom: -1,
            color:theme.palette.secondary.main
        }
    }
}));
const initialState={
    search:'',
    fechaDesde: '',
    fechaHasta: '',
    estado: '',
    creadoPor: '',
    agenteComision:'',
    cajId:'',
    rutId:'',
    tipo:'',
    verAnulados:false,
    formaPago:"-1",
    //pagination
    page: 0,
    pageSize:15,
    orderBy:"",
    orderByIsDescending:""
}
function PagosRealizados(props) {
    let history = useHistory();
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const classes = useStyles();

    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(initialState);

    const { title:mainTitle, mostrarPagos,pagos: { list, isFetching,error,filtered} } = props;
    const isEmpty = (!list.results || list.results.length === 0);

    useEffect(() => {
        mostrarPagos();
    }, [mostrarPagos]);
    //set filtered params
    useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


    const handleFilter = e => {
        const {name, value,checked} = e.target;
        if(name === "search" || name === "page" || name === "pageSize") return mostrarPagos({...filter,page:null,[name]:value});

        if(name === "verAnulados")
            setFilter({...filter,page:null,[name]:checked});
        else
            setFilter({...filter,page:null,[name]:value});
    };
    const resetFilter = () => {
        setOpenFilter(false);
        mostrarPagos({});
    };
    const searchFilter = () => {
        setOpenFilter(false);
        mostrarPagos(filter);
    };
    const tableSortProps = orderBy => {
        return {
            active:filter.orderBy === orderBy,
            direction: filter.orderByIsDescending ? "desc" : 'asc',
            onClick:()=> mostrarPagos({...filter, orderBy,page:null, orderByIsDescending: !filter.orderByIsDescending})
        }
    }

    return (
      <>
        <AppBar title={Strings[mainTitle]}>
            <SeacrhAppBar name="search" value={filter.search} onChange={handleFilter}/>
            <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
            <ButtonResponsive icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"pagos")}/>
        </AppBar>
          <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} maxWidth={"xs"}>
              <DialogContent>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                          <TextField
                              label={Strings.fechaDesde}
                              type="date"
                              name="fechaDesde"
                              value={filter.fechaDesde}
                              onChange={handleFilter}
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </Grid>
                      <Grid item xs={12} md={6} >
                          <TextField
                              label={Strings.fechaHasta}
                              type="date"
                              name="fechaHasta"
                              value={filter.fechaHasta}
                              onChange={handleFilter}
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                          />
                      </Grid>
                      <Grid item xs={12} md={6} >
                          <SelectAgente
                              label={Strings.creadoPor}
                              value={filter.creadoPor}
                              onChange={handleFilter}
                              name="creadoPor"
                          />
                      </Grid>
                      <Grid item xs={12} md={6}>
                          <SelectAgente
                              all
                              label={Strings.agenteAcomisionar}
                              value={filter.agenteComision}
                              onChange={handleFilter}
                              name="agenteComision"
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <SelectCaja
                              fullWidth
                              name= 'cajid'
                              allowAll
                              value={filter.cajid}
                              onChange={handleFilter}
                          />
                      </Grid>
                      <Grid item xs={6}>
                          <SelectEnum value={filter.tipo} name={"tipo"} label={Strings.pago}
                                      onChange={handleFilter} group={"tiposPago"} />
                      </Grid>
                      <Grid item xs={6}>
                          <SelectEnum value={filter.formaPago} name={"formaPago"} label={Strings.formaPago}
                                      onChange={handleFilter} group={"formaPago"} />
                      </Grid>
                      <Grid item xs={12}>
                          <SelectRuta value={filter.rutId} onChange={handleFilter} />
                      </Grid>
                      <FormControlLabel
                          control={<Checkbox checked={filter.verAnulados} onChange={handleFilter} name="verAnulados" />}
                          label={Strings.ver+" "+Strings.anulado}
                          labelPlacement="start"
                      />
                  </Grid>
                  <br/>
              </DialogContent>
              <DialogActions>
                  <Button onClick={resetFilter}>{Strings.cancelar}</Button>
                  <Button color="primary" onClick={searchFilter}>oK</Button>
              </DialogActions>
          </Dialog>

          <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount}>
              <FilteredAlert onClose={resetFilter} filter={filtered}/>
          </ListViewMode>
          <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
              {isFetching && isEmpty && <LoadingProgress vh/>}
              {error && <MostrarError errors={error}/>}


              {(!props.viewTableList && smVewport)?(
                  !isEmpty && list.results.map(pag =>
                      <CustomListItem key={pag.pagId}
                                      onClick={()=> history.push(history.location.pathname,{recibo:pag})}
                                      lt={pag.cliente}
                                      lb={<Typography variant={"caption"}><SelectEnum value={pag.pagTipo} group={"tiposPago"} /></Typography>}
                                      rt={<Typography color={"primary"}>{formatPeso(pag.pagTotal)}</Typography>}
                                      rb={<Typography variant="caption">{formatDate(pag.pagFecha)}</Typography>}
                      />
                  )
              ):(
                  <TableSort data={[
                      {pro:"cliente",text:Strings.prestatario},
                      {pro:"pagFecha",text:Strings.fecha},
                      {pro:"caja",text:Strings.caja},
                      {pro:"agente",text:Strings.agente},
                      {pro:"pagConcepto",text:Strings.concepto},
                      {pro:"pagCapital",text:Strings.capital},
                      {pro:"pagInteres",text:Strings.interes},
                      {pro:"pagMora",text:Strings.mora},
                      {pro:"pagDescuento",text:Strings.descuento},
                      {pro:"pagComision",text:Strings.comision},
                      {pro:"pagSeguro",text:Strings.seguro},
                      {pro:"pagTotal",text:Strings.totalPagado},
                  ]} onSort={tableSortProps} lessVh={205}>
                      <TableBody>
                      {!isEmpty && list.results.map(pag =>
                              <TableRow key={pag.pagId}
                                        className={classes.row + (pag.pagAnulado?" "+classes.pagAnulado:" pagTipo"+pag.pagTipo)}
                                        onClick={()=> history.push(history.location.pathname,{recibo:pag})}>
                                  <TableCell className={classes.cliente}>
                                      {!!pag.pagAdjunto && <AttachFile />}
                                      {pag.cliente}
                                  </TableCell>
                                  <TableCell>{formatDate(pag.pagFecha)}</TableCell>
                                  <TableCell>{pag.caja}</TableCell>
                                  <TableCell>{pag.agente}</TableCell>
                                  <TableCell>{pag.pagConcepto}</TableCell>

                                  {(pag.pagTipo === 4 || pag.pagTipo === 8)?(
                                      <>
                                          <TableCell colSpan={6}/>
                                      </>
                                  ):(
                                      <>
                                          <TableCell>{formatPeso(pag.pagCapital)}</TableCell>
                                          <TableCell>{formatPeso(pag.pagInteres)}</TableCell>
                                          <TableCell>{formatPeso(pag.pagMora)}</TableCell>
                                          <TableCell>{formatPeso(pag.pagDescuento)}</TableCell>
                                          <TableCell>{formatPeso(pag.pagComision)}</TableCell>
                                          <TableCell>{formatPeso(pag.pagSeguro)}</TableCell>

                                      </>
                                  )}
                                  <TableCell>{formatPeso(pag.pagTotal)}</TableCell>
                              </TableRow>
                      )}
                      </TableBody>
                      {!isEmpty &&
                          <TableFooter>
                              <TableRow>
                                  <TableCell colSpan={5}><Typography align={"right"}>Total:</Typography> </TableCell>
                                  <TableCell>{formatPeso(list.footerTotals.pagCapital)}</TableCell>
                                  <TableCell>{formatPeso(list.footerTotals.pagInteres)}</TableCell>
                                  <TableCell>{formatPeso(list.footerTotals.pagMora)}</TableCell>
                                  <TableCell>{formatPeso(list.footerTotals.pagDescuento)}</TableCell>
                                  <TableCell>{formatPeso(list.footerTotals.pagComision)}</TableCell>
                                  <TableCell>{formatPeso(list.footerTotals.pagSeguro)}</TableCell>
                                  <TableCell>{formatPeso(list.footerTotals.pagTotal)}</TableCell>
                              </TableRow>
                          </TableFooter>
                      }
                  </TableSort>

              )}

              <Pagination
                  empty={Boolean(isEmpty && !isFetching)}
                  count={list.totalRecordCount || 0}
                  rowsPerPage={filter.pageSize}
                  page={list.pageNo -1}
                  onPageChange={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
                  onRowsPerPageChange={handleFilter}
              />
          </section>
          <ReciboPago toPreIcon/>
      </>
    );
}
const mapStateToProps = (state) => ({
    pagos: state.pagos,
    viewTableList: state.theme.viewTableList
})
export default connect(mapStateToProps, { mostrarPagos})(PagosRealizados);
