import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './redux/store';
import App from './App';
//import registerServiceWorker from './registerServiceWorker';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';


//auth -----------------------
import {mostrarPerfil } from './redux/actions/authentication';
import {setInstallBannerEvent } from './redux/actions/themeActions';
import Strings from "./assets/strings";
import {getLSLang} from "./helpers";
//end auth -----------------------


if(localStorage._token) {
    store.dispatch(mostrarPerfil()); // to get the color en currency
}
//setear idioma
let lang = getLSLang("l");
Strings.setLanguage(lang);

if (!Object.entries) {
    Object.entries = function( obj ){
        var ownProps = Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];

        return resArray;
    };
}


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <Provider store={store}><App /></Provider>
    // </React.StrictMode>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();


//registerServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();


window.addEventListener('beforeinstallprompt', function (e) {
    e.preventDefault();
    store.dispatch(setInstallBannerEvent(e));
});


