import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {PayPalButton} from "react-paypal-button-v2";

import {mostrarPerfil, setCurrentUser} from '../../redux/actions/authentication';
import {
    AlertTitle,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Strings from "../../assets/strings";
import {getLSLang, setLSLang} from "../../helpers";
import {getGeo, PostRegister} from "../../services";
import {connect} from "react-redux";
import styles from "../../assets/jss/login";
import Alert from '@mui/material/Alert';
import {MostrarError} from "../../components";
import {Instagram, WhatsApp, YouTube} from "@mui/icons-material";
import {TextFieldPhone} from "../../components/CustomTextField";
import LoadingProgress from "../../components/LoadingProgress";
import OtherAppPromo from "../../components/OtherAppPromo";
import {isMobile} from "../../helpers/browser_detect";

const useStyles = makeStyles(styles);



function Register({mostrarPerfil,setCurrentUser }){
    const classes = useStyles();
    const {noPayment} = useParams();

    const [otherApp, setOtherApp] = useState(false)

    const [passFocus, setPassFocus] = useState(false)

    const [error, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [step, setStep] = useState(1); //-1

    const [objetivo, setObjetivo] = useState("");


    const [state, setState] = useState({
        lang:getLSLang("l"),
        usuInicioSesion: "",
        usuClave: "",
        usuNombres: "",
        usuApellidos: "",
        usuEmail: "",
        usuTelefono: "",
        cuentaDestino:"deibisoto12@hotmail.com",
        os:'web'
        //  facturacion: []
    });


    useEffect(()=>{
        getGeo().then(r=>{
            setCurrentUser({genLocalidad: r?.data?.country_code ?? "DO"})
        }).catch(()=>setCurrentUser({genLocalidad: "DO"}))
    },[setCurrentUser])

    const handleChange = (e)=>{
        const {name, value} = e.target
        setState({...state, [name]: value});

        if(error) setError(false)
    }
    const submitForm = (obj,validate ) =>{
        setLoading(true)
        PostRegister({...state,...obj,lang:null},validate)
            .then((response) =>{
                if(validate){
                    setStep(2);
                }else {
                    setStep(3);
                    localStorage.setItem("_token", response.data.tocken);
                    //sen tocken to webview
                    if (isMobile?.AndroidWebView()) {
                        if(window?.Android?.logged){
                            window.Android.logged(response.data.tocken);
                        }
                    }else if(isMobile?.IosWebView()){
                        //window?.webkit?.messageHandlers?.shareFromBase64?.logged(response.data.tocken);
                    }
                    mostrarPerfil(response.data);
                }
            })
            .catch((e) => {
                alert(e?.response?.data ?? "Error, Contactanos para más información");
                setError(true);
            }).finally(()=>setLoading(false));
    }

    return (
        <div className={classes.root} style={{height:(step === 1 ?"auto":"100hv"), paddingTop: "2%", paddingBottom: 17}}>
    {/*        {step === -1 ?(*/}
    {/*            <div className={classes.video}>*/}
    {/*                <iframe width="100%" height="100%" title={"video Promo"}  src="https://www.youtube.com/embed/DJ1SIg8fKaM" frameBorder="0"*/}
    {/*allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
    {/*allowFullScreen/>*/}
    {/*                <div>*/}
    {/*                    <Button component={Link} color={"secondary"} to={"/login"}>{Strings.cancelar}</Button>*/}
    {/*                    <Button color={"primary"} variant={"contained"} disableElevation onClick={()=>setStep(1)}>{Strings.registrarme}</Button>*/}
    {/*                </div>*/}
    {/*            </div>*/}
    {/*            ):(*/}
                    <>
                        <div className={classes.logo}>
                            <Typography variant="h1"  color="primary">PrestamistAPP</Typography>
                            <Typography variant="h3" >{Strings.slogan}</Typography>
                        </div>

                        <div className={classes.form}>
                            <div style={!!objetivo ?{
                                borderBottom: "1px solid #666", marginBottom: 15, paddingBottom: 12
                            }:{textAlign:"center"}}>

                                {/*enviar a la version 3*/}
                                <Typography variant="h6" >Ya esta disponible PrestamitApp 3.0</Typography>
                                <Typography>Te recomendamos usar siempre la versión más reciente.</Typography>

                                 <br/>
                                 <Button
                                     fullWidth
                                     href={"https://prestamistapp.com/"}
                                     variant={"contained"}
                                     target={"_blank"}
                                     rel="noreferrer"
                                     sx={{
                                         mt: 1,
                                         mb:2
                                     }}
                                 >Descargar PrestamistApp 3.0</Button>



                                <Button
                                    fullWidth
                                    href={"https://apps.apple.com/do/app/prestamistapp-3-0/id6471681320"}
                                    variant={"text"}
                                    size={"small"}
                                    target={"_blank"}
                                    rel="noreferrer"
                                >Desde AppStore</Button>
                                <Button
                                    fullWidth
                                    href={"https://play.google.com/store/apps/details?id=com.dsegura.prestamistapp3"}
                                    variant={"text"}
                                    size={"small"}
                                    target={"_blank"}
                                    rel="noreferrer"
                                    sx={{
                                        mt: 1,
                                    }}
                                >Desde Google Play</Button>

                               {/*<Typography variant="h6">¿Que deseas hacer?</Typography>*/}
                               {/* <FormControl>*/}
                               {/*     <RadioGroup*/}
                               {/*         value={objetivo}*/}
                               {/*         onChange={({target})=>setObjetivo(target.value)}*/}
                               {/*     >*/}
                               {/*         <FormControlLabel value="1" control={<Radio />} label="Solicitar Préstamo" />*/}
                               {/*         {objetivo !== "1" &&  <FormControlLabel value="2" control={<Radio />} label="Soy prestamista/financiera" />}*/}
                               {/*     </RadioGroup>*/}
                               {/* </FormControl>*/}
                               {/* <br/>*/}
                               {/* <Button*/}
                               {/*     fullWidth*/}
                               {/*     href={"https://www.youtube.com/channel/UC169EbwYwjpoCOC_SXkCMKg"}*/}
                               {/*     target={"_blank"}*/}
                               {/*     rel="noreferrer"*/}
                               {/*     sx={{*/}
                               {/*         mt: 1,*/}
                               {/*         mb: -2,*/}
                               {/*     }}*/}
                               {/* >Videos tutoriales</Button>*/}
                            </div>



                            {objetivo === "1" &&
                                <div>
                                    <p style={{textAlign: "center", background: "#f5f5f5", padding: 7}}><span
                                        style={{fontSize: 22, color: "#ff6666", display: "block"}}>Nosotros NO prestamos dinero,</span>
                                        esto es una app solo para prestamistas/financieras. Favor no registrase si usted
                                        quiere solicitar un préstamo.</p>
                                    <a  target={"_blank"} rel="noreferrer" href={"https://www.instagram.com/dsegura_srl/"}>Más Información...</a>


                                </div>
                            }
                            {objetivo === "2" &&
                                <>
                                    {!!error &&
                                        <MostrarError errors={error}/>
                                    }
                                    {isLoading && <LoadingProgress/>}
                                    {step === 3 &&
                                        <div>
                                            <Alert severity="success">Registro finalizado con éxito.</Alert>
                                            <p>Hola {state.usuNombres+" "+state.usuApellidos}, a continuación le dejo algunos enlaces que pueden ser de su interés. Cualquier información adicional, por favor comunicase con nosotros.</p>
                                            <Box mt={1} mb={1}><Divider/></Box>
                                            <Button
                                                color="primary"
                                                size="small"
                                                className={classes.button}
                                                startIcon={<YouTube />}
                                                target={"_blank"}
                                                rel="noreferrer"
                                                href={"https://www.youtube.com/channel/UC169EbwYwjpoCOC_SXkCMKg"}
                                                fullWidth
                                            >
                                                Video Tutoriales
                                            </Button>
                                            <Button
                                                color="primary"
                                                size="small"
                                                className={classes.button}
                                                startIcon={<WhatsApp />}
                                                target={"_blank"}
                                                rel="noreferrer"
                                                href={"https://wa.me/18098538622"}
                                                fullWidth
                                            >
                                                Chat vía WhatApp
                                            </Button>
                                            <Box mt={1}>
                                                <Button variant="contained" color="primary" disableElevation fullWidth component={Link} to="/inicio">
                                                    {Strings.iniciarSesion}
                                                </Button>
                                            </Box>

                                        </div>
                                    }
                                    {step === 1 &&
                                        <form id="form" onSubmit={e=>{e.preventDefault();submitForm({},noPayment !== "demo")}}>
                                            {step !== 3 && <Typography mb={2} className={classes.headline}>{Strings.crearNuevaCuenta}</Typography>}

                                            <Grid container spacing={1}>
                                                <Grid item xs={12}>
                                                    <Box  color="text.secondary">{Strings.datosPersonales}</Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        variant={"outlined"}
                                                        label={Strings.primerNombre}
                                                        required
                                                        margin="dense"
                                                        name="usuNombres"
                                                        value={state.usuNombres}
                                                        onChange={handleChange}
                                                        inputProps={{maxLength: 50}}
                                                        autoFocus={true}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        variant={"outlined"}
                                                        label={Strings.apellidos}
                                                        required
                                                        margin="dense"
                                                        name="usuApellidos"
                                                        value={state.usuApellidos}
                                                        onChange={handleChange}
                                                        inputProps={{maxLength: 50}}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        variant={"outlined"}
                                                        label={Strings.email}
                                                        type="email"
                                                        required
                                                        margin="dense"
                                                        name="usuEmail"
                                                        value={state.usuEmail}
                                                        onChange={handleChange}
                                                        inputProps={{maxLength: 50}}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextFieldPhone
                                                        flag
                                                        variant={"outlined"}
                                                        label={Strings.telefono}
                                                        required
                                                        margin="dense"
                                                        name="usuTelefono"
                                                        value={state.usuTelefono}
                                                        onChange={handleChange}
                                                        fullWidth
                                                        // helperText={"Enviaremos un código de verificación a este número"}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box color="text.secondary" width={"100%"}>{Strings.datosParaIniciarSesion}</Box>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        variant={"outlined"}
                                                        label={Strings.nombreDeUsuario}
                                                        autoFocus={false}
                                                        required
                                                        margin="dense"
                                                        name="usuInicioSesion"
                                                        value={state.usuInicioSesion}
                                                        onChange={handleChange}
                                                        inputProps={{maxLength: 50}}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        variant={"outlined"}
                                                        label={Strings.contrasena}
                                                        required
                                                        margin="dense"
                                                        name="usuClave"
                                                        onFocus={()=>setPassFocus(true)}
                                                        onBlur={()=>setPassFocus(false)}
                                                        type={passFocus?"text":"password"}
                                                        value={state.usuClave}
                                                        onChange={handleChange}
                                                        inputProps={{maxLength: 50}}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControlLabel
                                                        control={<Checkbox required color="primary"/>}
                                                        label={<span style={{fontSize:15}}>Estoy consciente de que esta app incluye pagos por suscripción.</span>}
                                                    />
                                                    <FormControlLabel
                                                        control={<Checkbox required color="primary"/>}
                                                        label={<span style={{fontSize:15}}>Acepto los <a target={"_blank"} rel="noreferrer" href={"https://prestamistapp.com/TerminosYcondiciones"}>términos y condiciones</a></span>}
                                                    />

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button type="submit" form="form" fullWidth variant="contained" color="primary" disableElevation disabled={isLoading}>
                                                        {isLoading? (
                                                            <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                                        ):(
                                                            <>Probar aplicación</>
                                                        )}
                                                    </Button>
                                                </Grid>

                                            </Grid>
                                        </form>
                                    }

                                    {step === 2 &&
                                        <>
                                            {/*<PlanSubscription onApprove={obj=>submitForm(obj)}/>*/}
                                            {error &&
                                                <>
                                                    <Alert severity="error" onClose={()=>setError(false)}>
                                                        <AlertTitle>Se ha producido un error al intentar hacer el pago, Favor escribenos para ayudarte.</AlertTitle>
                                                        Contáctanos vía <a  rel="noreferrer" href={"https://api.whatsapp.com/send/?phone=18498641680&text=Hola mi nombre es "+state.usuNombres+" "+state.usuApellidos+", desde PrestamistAPP, la app me dio error al intentar hacer el pago"} target={"_blank"}>WhatsApp</a>.
                                                    </Alert><br/>
                                                </>
                                            }

                                            <Typography variant={"body2"} align={"center"} sx={{mb:1}}>Pruébalo hoy por solo <span style={{fontSize: "20px", color: "#1aa2b8"}}>1 dólar</span> o escríbenos para más información.</Typography>

                                            <PayPalButton
                                                amount={1}
                                                shippingPreference={"NO_SHIPPING"}
                                                onSuccess={(details) => {
                                                    // let facturacion = [{
                                                    //     usuNombres: details?.payer?.name?.given_name,
                                                    //     usuApellidos: details?.payer?.name?.surname,
                                                    //     usuEmail: details?.payer?.email_address,
                                                    //     usuOrderId: details?.purchase_units[0]?.payments?.captures[0]?.id
                                                    // }];

                                                    let usuOrderId = details?.purchase_units[0]?.payments?.captures[0]?.id

                                                    return submitForm({usuOrderId});

                                                }}
                                                onError={()=>setError(true)}
                                                options={{
                                                    //gmail
                                                    //clientId: "AWBEotIk0ZckSFeHQQLDYKVF7yDY8YUq5wBNJJ3E40SZ1lkx9in0Fjmnrzme59aPR_zlwIEgxES5_H7d"
                                                    //hotmail
                                                    clientId: "AQmh-AVJifRjxrsvuvRcph8oso0ZZB9qk8yNBe_A6jnf2eiE9meItEUl6TGbXN2DKTDqVDm6CyzY_Y6t",
                                                }}
                                                style={{
                                                    // tagline:true,
                                                    shape: 'rect',
                                                    color: 'blue',
                                                    layout: 'vertical',
                                                    label: 'pay',
                                                }}
                                            />
                                        </>
                                    }
                                </>
                            }


                        </div>
                        <Typography className={classes.bmLink}>{Strings.yaCuenta} <Link to="/login">{Strings.iniciarSesion}</Link></Typography>
                        {/*<Button  onClick={()=>setOtherApp(true)}>Otras aplicaciones</Button>*/}
                        <div className={classes.lang}>
                            <select value={state.lang} onChange={(e)=>{
                                let lang = e.target.value;
                                setLSLang(lang);
                                Strings.setLanguage(lang);
                                setState({...state,lang});
                            }}>
                                {Strings.getAvailableLanguages().map(l=>
                                    <option key={l} value={l}>{Strings[l]}</option>
                                )}
                            </select>
                            {/*<label>{Strings.idioma}</label>*/}
                        </div>
                        {!!error &&
                            <Button
                                className={classes.moreInfo}
                                component={"a"}
                                variant="contained"
                                disableElevation
                                href={"http://prestamistapp.com/?p=true"}>
                                Información del Sistema {!!error.toString()}
                            </Button>
                        }

                        <a className={classes.inBtn}  target={"_blank"} rel="noreferrer" href={"https://www.instagram.com/dsegura_srl/"}><Instagram sx={{marginRight:1}} /> Siguenos  @dsegura_srl</a>
                    </>
                {/*)}*/}
            {otherApp &&
                <OtherAppPromo open={otherApp} handleClose={()=>setOtherApp(false)}/>
            }
        </div>
    );
}
export default connect(null, {mostrarPerfil,setCurrentUser })(Register);
