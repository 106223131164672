import React, {useEffect, useState} from 'react';
import {useLocation,useHistory } from "react-router-dom";
import {connect} from "react-redux";
import {editarGenerales, mostrarGenerales} from "../../redux/actions/generalesActions";

import {
    Tabs,
    Tab,
    Button,
    CircularProgress,
    Grid,
    Typography,
    TextField,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Switch,
    List,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {MostrarError,HtmlTooltip,AppBar} from "../../components";
import Strings from "../../assets/strings";
import {TextFieldPhone} from "../../components/CustomTextField";
import {SelectPais,SelectEnum, SelectFiles} from "../../components/selects";
import LoadingProgress from "../../components/LoadingProgress";
import {geoString, nullToEmptyString} from "../../helpers";

import CamposFormularios from "./CamposFormularios";
const useStyles = makeStyles(theme => ({
    root:{
    },
    tabs:{
        marginBottom: theme.spacing(3)
    },
    form:{
        margin: theme.spacing(2)
    },
    subtitle1:{
        margin: theme.spacing(2)+" auto"
    },
    avatar:{
        "& img":{
            maxWidth: 150
        },
        [theme.breakpoints.down('md')]: {
            "& img":{
                //   borderRadius: "50%",
            }
        }

    },

}));



function Ajustes(props) {
    let history = useHistory();
    const { hash = null} = useLocation();

    const classes = useStyles();

    const [activedTab, setTab] = React.useState('empresa');
    const [state, setState] = useState({
        genId: "",
        genEmpresaNombre: "",
        genEmpresaDireccion: "",
        genEmpresaTelefono: "",
        genEmpresaTelefono2: "",
        genEmpresaEmail:"",
        genEmpresaRNC: "",
        genEmpresaLogo: "",
        genDiasMora: "",
        genPorcientoMora: "",
        genTipoMora: "",
        genLocalidad: "",
        genIdioma: "",
        genFooterRecibo:"",
        genFormatoRecibo: new Set(),
        genConfCampos:{},
        parametros: []
    });
    const {mostrarGenerales, editarGenerales,generales: {data, isFetching, isPending, error}} = props

    useEffect(()=>{
        if(!!hash && ["#empresa","#recibo","#otros","#camposFormularios"].includes(hash)){
            setTab(hash.replace("#",""));
        }
    },[hash,setTab])
    useEffect(()=> {
        mostrarGenerales();
    },[mostrarGenerales]);
    useEffect(()=>{
        let datos = {...nullToEmptyString(data)};
        try {datos = {...data,genFormatoRecibo: new Set(data.genFormatoRecibo.split(","))};}
        catch (e) {datos = {...data,genFormatoRecibo: new Set()};}

        setState((prev)=>({...prev,...datos}));
    },[setState,data])
    const handleChange = e => {
        const {value,name} = e.target
        setState({...state,[name]:value});
    };
    const handleReciboCheck  = e => {
        const {name, checked} = e.target
        let genFormatoRecibo = state.genFormatoRecibo;

        if(checked)
            genFormatoRecibo.add(name)
        else
            genFormatoRecibo.delete(name)

        setState({...state,genFormatoRecibo});
    };
    const handleParamCheck  = e => {
        const {name:key, checked} = e.target
        let parametros = state.parametros
        let targetObject =  props.parametros.find(param => param.key === key);

        if(checked)
            parametros.push(targetObject)
        else
            parametros = parametros.filter(param => param.key !== key)

        setState({...state,parametros:[...new Set(parametros)]});
    };
    const onImgChange = (baseb4)=> {
        setState({...state, genEmpresaLogo: baseb4});
    }
    const submit = (e) =>{
        e.preventDefault();
        let datos = {...state,genFormatoRecibo: [...state.genFormatoRecibo].join(",")};
        if(datos.genEmpresaLogo === data.genEmpresaLogo)
            datos.genEmpresaLogo = "";

        editarGenerales(datos);
    }

    return (
        <>
            <AppBar title={Strings.ajustes}>
                <Button disabled={isPending} type="submit" form="form1">
                    {isPending? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>
            <div className="contentInner">

                {isFetching && <LoadingProgress/>}
                <MostrarError errors={error}/>
                <Tabs value={activedTab}
                      onChange={(e,v)=>history.push("ajustes#"+v)}
                      variant={"scrollable"}
                      className={classes.tabs}
                >
                    <Tab value="empresa" label={Strings.empresa} />
                    <Tab value="recibo" label={Strings.recibo}/>
                    <Tab value="camposFormularios" label={Strings.formularios} />
                    <Tab value="otros" label={Strings.otros} />
                </Tabs>

                <form id="form1" onSubmit={submit}  className={classes.form} style={activedTab !== "empresa"?{display:"none"}:{}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={2}>
                            <div className={classes.avatar}>
                                <SelectFiles src={state.genEmpresaLogo ?state.genEmpresaLogo:null} onChange={onImgChange}/>
                            </div>
                        </Grid>
                        <Grid item md={10} >
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={4} md={3}>
                                    <TextField
                                        name="genEmpresaNombre"
                                        label={Strings.empresa}
                                        value={state.genEmpresaNombre}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextFieldPhone
                                        name="genEmpresaTelefono"
                                        label={Strings.telefono}
                                        value={state.genEmpresaTelefono}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextFieldPhone
                                        name="genEmpresaTelefono2"
                                        label={Strings.celular}
                                        value={state.genEmpresaTelefono2}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6} sm={4} md={3}>
                                    <TextField
                                        name="genEmpresaRNC"
                                        label={geoString("rnc",true)}
                                        placeholder={geoString("rnc")}
                                        value={state.genEmpresaRNC}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={6} sm={4} md={3}>
                                    <TextField
                                        name="genEmpresaEmail"
                                        label={Strings.email}
                                        value={state.genEmpresaEmail}
                                        type={"email"}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={9} md={6}>
                                    <TextField
                                        name="genEmpresaDireccion"
                                        label={Strings.direccion}
                                        value={state.genEmpresaDireccion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 100}}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SelectPais
                                        name="genLocalidad"
                                        label={Strings.moneda}
                                        value={state.genLocalidad}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Typography color={"secondary"} variant={"subtitle1"} className={classes.subtitle1}>{Strings.mora}</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6} sm={4} md={3}>
                            <HtmlTooltip title={Strings.diasMoraMsj} disableHoverListener>
                                <TextField
                                    name="genDiasMora"
                                    label={Strings.diasDeGracia}
                                    type="number"
                                    inputProps={{step:1,inputMode:"numeric"}}
                                    value={state.genDiasMora}
                                    autoComplete="off"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </HtmlTooltip>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <HtmlTooltip title={Strings.porcientoMoraMsj} disableHoverListener>
                                <TextField
                                    name="genPorcientoMora"
                                    label={Strings.porcentaje}
                                    type="number"
                                    inputProps={{step:"any",inputMode:"decimal"}}
                                    value={state.genPorcientoMora}
                                    autoComplete="off"
                                    onChange={handleChange}
                                    fullWidth
                                />
                            </HtmlTooltip>
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <SelectEnum group={"tiposMora"}
                                        name={"genTipoMora"}
                                        label={Strings.aplicarMoraA}
                                        value={state.genTipoMora}
                                        onChange={handleChange}/>
                        </Grid>

                    </Grid>
                </form>

                {/*<BusinessCard state={state}/>*/}

                {activedTab === "camposFormularios" &&
                <CamposFormularios values={state.genConfCampos} onChange={(genConfCampos)=>setState({...state,genConfCampos})}/>
                }
                {activedTab === "recibo" &&
                <div className="reciboCont" style={{maxWidth:600}}>
                    <List  className={classes.root}>
                        {
                            [
                                {id:"copia",text:Strings.copia},
                                {id:"capital",text:Strings.capital},
                                {id:"mora",text:Strings.mora},
                                {id:"interes",text:Strings.interes},
                                {id:"seguro",text:Strings.seguro},
                                {id:"descuento",text:Strings.descuento},
                                {id:"capitalRestante",text:Strings.capitalPendiente},
                                {id:"balancePendiente",text:`${Strings.balancePendiente} (${Strings.capital} + ${Strings.interes})`},
                                {id:"proximoPago",text:`${Strings.fecha} ${Strings.proximo} ${Strings.pago}`},
                                {id:"formaPago",text:Strings.formaPago},
                                {id:"agente",text:Strings.nombres+" "+Strings.cobrador},
                                {id:"firma",text:Strings.firma},

                            ].map(s=>
                                <ListItem key={s.id}>
                                    <ListItemText id={s.id} primary={s.text} />
                                    <ListItemSecondaryAction>
                                        <Switch
                                            edge="end"
                                            name={s.id}
                                            onChange={handleReciboCheck}
                                            checked={state.genFormatoRecibo.has(s.id)}
                                            inputProps={{ 'aria-labelledby': s.id }}
                                        />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            )
                        }
                        <ListItem>
                            <TextField
                                name="genFooterRecibo"
                                label={Strings.piePagina}
                                value={state.genFooterRecibo}
                                onChange={handleChange}
                                inputProps={{maxLength: 100}}
                                fullWidth
                            />
                        </ListItem>
                    </List>

                        <br/>
                        <br/>
                </div>
                }
                {activedTab === "otros" &&
                <div className="otrosCont" style={{maxWidth:600}}>
                    <span style={{display: "none"}}><SelectEnum group="parametros" value={100} /></span>
                    <List  className={classes.root} component="nav">
                        {
                            props.parametros.map((s)=> {

                                let checked = Boolean(state.parametros.find(param => param?.key === s?.key));
                                return(
                                    <ListItem key={s.key} button onClick={()=>handleParamCheck({target:{name:s.key,checked:!checked}})}>

                                        <ListItemText id={s.key} primary={Strings[s.value]} />
                                        <ListItemSecondaryAction>
                                            <Switch
                                                edge="end"
                                                name={s.key}
                                                onChange={handleParamCheck}
                                                checked={checked}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                        }

                    </List>
                </div>
                }
            </div>

        </>
    );
}


const mapStateToprops = (state) => ({
    generales: state.generales,
    parametros: state.enums.list.parametros||[],
})
export default connect(mapStateToprops,{mostrarGenerales, editarGenerales})(Ajustes);

