import React, {useEffect, Fragment, useState} from 'react';
import { GoogleMap,Marker,InfoWindow } from '@react-google-maps/api';
import {mostrarClienteById} from "../../redux/actions/clientesActions";
import * as signalR from "@microsoft/signalr";

import {
    Divider,
    List,
    ListItem,
    ListItemText,
    Typography,
    Backdrop,
    Snackbar,
    useMediaQuery,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import Strings from "../../assets/strings";
import {getAgentes, getClientes} from "../../services";
import ContactDetails from "../clientes/ContactDetails";
import {useHistory} from "react-router-dom";
import {useDispatch} from 'react-redux'
import {AppBar, ButtonResponsive} from "../../components";
import {FilterList,Help} from "@mui/icons-material";
import {getUsuTipo, noTilde, TIPOUSUARIO} from "../../helpers";
import {getIcon} from "../../assets/iconPath";
import {getIdToken,getTokenInfo} from "../../services/auth";

const initialCenter = {
    lat: 0,
    lng: 0
};

const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: 'transparent',
    },
    searchBox:{
        top: "60px",
        right: "10px",
        width: "229px",
        maxHeight: "50%",
        position: "absolute",
        background: "white",
        overflow: "auto",
        zIndex: 1101,
        "&>div":{
            marginBottom: -10,
        },
        "& input":{
            padding: "10px 15px",
            width: "calc(100% - 4px)",
            boxSizing: "border-box",
            margin: "2px",
            border: "none",
            outline: "none",
            fontSize: "1em",
            background: "#eaeaea",
        }
    },
}));
function pinSymbol(proColor,proIcon) {
    let color = proColor ? proColor : "#b81717";
    let icon = proIcon ? proIcon : "Place";

    return {
        path:getIcon(icon),
        scale: 2, // > 1 to make it bigger
        fillColor: color, // Use RGBa function
        background:color,
        fillOpacity: 1,
        strokeColor: 'hsl(0,0%,100%)', // Use HSLa function
        anchor: { x: 12, y: 24 },
    };
}
function Map() {


    const {UsuMaster:usuMaster} = getTokenInfo()
    //const geolocation = useGeolocation()

    let history = useHistory();
    const dispatch = useDispatch()
    const smVewport = useMediaQuery((theme) => theme.breakpoints.down('md'));

    const classes = useStyles();

    const [infoWin , setInfoWin ] = React.useState(null);
    const [clientes, setClientes ] = React.useState([]);
    const [clientesFilter, setClientesFilter ] = React.useState([]);

    const [openFilter, setOpenFilter] = useState(false);
    const [snackbar, setSnackbar] = useState(null);

    const [center, setCenter ] = React.useState(null);
    const [allAgents, setAllAgents] = React.useState([]);
    const [agentsCoor, setAgentsCoor] = React.useState([]);
    const [currentPoint, setCurrentPoint ] = React.useState(null);


    useEffect(() => {

        if(getUsuTipo() === TIPOUSUARIO.titular || getUsuTipo() === TIPOUSUARIO.admin){
            // Builds the SignalR connection, mapping it to /chat
            const hubConnection = new signalR.HubConnectionBuilder()
                .withUrl("https://api.prestamistapp.net/MapHub",{
                    accessTokenFactory: getIdToken
                })
                //.configureLogging(signalR.LogLevel.Trace)
                .build();

            //reveive locaction
            hubConnection.on("ReceiveLocation", (usuId, lat, long) => {
                console.log(`usuId: ${usuId}, Lat: ${lat} Long: ${long}`);

                if(usuId === usuMaster){
                    setCurrentPoint({lat,long})
                }else{
                    let estaAg = allAgents.find(a=>a.usuId === usuId);

                    if(estaAg){
                        estaAg.usuCoordenadas = {
                            lat,
                            lng:long
                        }
                        setAgentsCoor(prevState => ([...(prevState.filter(a => a.usuId !== usuId)),estaAg]));
                        //console.log(estaAg)
                    }
                }
            })

            // Starts the SignalR connection
            hubConnection.start().then(res => {
                hubConnection.invoke("JoinGroup", usuMaster)
                    .catch(err => {
                        console.log(err);
                    });
            }).catch(err => {
                console.log(err);
            })
            return ()=>{
                if(hubConnection.state === "Connected"){
                    hubConnection.stop();
                }
            }

        }
    },[usuMaster,setCurrentPoint,setCenter,allAgents,setAgentsCoor]);
    useEffect(()=>{
        if(getUsuTipo() === TIPOUSUARIO.titular || getUsuTipo() === TIPOUSUARIO.admin){
            getAgentes()
                .then(res=>{
                    let data = res?.data ?? [];
                    data = data.map((c)=>{
                        let usuCoordenadas = null;
                        if(c.usuCoordenadas && c.usuCoordenadas.indexOf(",") !== -1){
                            let latLng = c.usuCoordenadas.split(",");
                            usuCoordenadas = {
                                lat: parseFloat(latLng[0]),
                                lng: parseFloat(latLng[1]),
                            };
                        }

                        return {...c,usuCoordenadas}

                    })
                    setAllAgents(data);
                    setAgentsCoor(data.filter(a=>!!a.usuCoordenadas))

                    //console.log(data)
                })
        }
        getClientes(null,true)
            .then(res=>{
                let data = res?.data ?? [];
                data = data.map((c)=>{
                    let cliCoordenadas = null;
                    if(c.cliCoordenadas && c.cliCoordenadas.indexOf(",") !== -1){
                        let latLng = c.cliCoordenadas.split(",");
                        cliCoordenadas = {
                            lat: parseFloat(latLng[0]),
                            lng: parseFloat(latLng[1]),
                        };
                    }

                    return {...c,cliCoordenadas}

                })
                setClientes(data)
            })
    },[setClientes,setAllAgents,setAgentsCoor])
    useEffect(()=>{
        if(clientes.length){
            if(!center?.lat) setCenter(clientes.find(e=>e.cliCoordenadas !== null)?.cliCoordenadas);
        }

    },[center,clientes, setCenter])
    const handleFilterChange = (e)=>{
        const {value} = e.target
        let filtro = clientes.filter((c)=>noTilde(c.cliente).indexOf(noTilde(value)) !== -1);
        return setClientesFilter(filtro.slice(0,20));
    }
    const showClientModal = cliId => {
        dispatch(mostrarClienteById(cliId));
        history.push(history.location.pathname, {cliId});
    };



    return window?.google?.maps ? <>
        <AppBar title={Strings.mapa}>
            <ButtonResponsive icon={<Help/>} text={Strings.ayuda} onClick={() => window.open("https://youtu.be/82YgTriT5So","_blank")}/>
            <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
        </AppBar>
        <section className="contentInner">
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: `calc(100vh - ${smVewport ? "50" : "145"}px)`
                }}
                center={center || initialCenter}
                zoom={13}
            >
                {clientes?.filter(e=> !!e.cliCoordenadas).map(c =>
                    <Marker
                        key={c.cliId}
                        position={{
                            lat: c.cliCoordenadas?.lat,
                            lng: c.cliCoordenadas?.lng
                        }}
                        title={c.cliente}
                        onClick={()=>setInfoWin(infoWin === c.cliId ? null:c.cliId)}
                        animation={infoWin === c.cliId && window.google.maps.Animation.ep}
                        icon={pinSymbol(c.rutColor,c.rutIcon)}
                    >
                        {(infoWin === c.cliId || infoWin === -1) &&
                        <InfoWindow onCloseClick={()=>setInfoWin(null)}>
                            <div onClick={()=>showClientModal(c.cliId)}>
                                <Typography>{c.cliente}</Typography>
                                <Typography color={"primary"} variant={"body2"}>Mas información</Typography>
                            </div>
                        </InfoWindow>}
                    </Marker>
                )}
                  {currentPoint &&
                    <Marker
                        position={currentPoint}
                        title={"Yo"}
                        onClick={()=>setInfoWin(infoWin === -2 ? null: -2)}
                        icon={pinSymbol("#e83527","Navigation")}

                    >
                        {(infoWin === -2 || infoWin === -1) &&
                        <InfoWindow onCloseClick={()=>setInfoWin(null)}>
                            <>
                                <Typography color={"primary"}>{Strings.ubicacionActual}</Typography>
                                <p>{Strings.ubicacionActualMjs}</p>
                            </>
                        </InfoWindow>}
                    </Marker>
                }
                {agentsCoor?.map((ag)=>
                    <Marker
                        key={ag.usuId}
                        position={ag.usuCoordenadas}
                        title={ag.usuInicioSesion}
                        onClick={()=>setInfoWin(infoWin === ag.usuId ? null: ag.usuId)}
                        icon={pinSymbol("#17a2b8","motorbike")}

                    >
                        {(infoWin === ag.usuId || infoWin === -1) &&
                        <InfoWindow onCloseClick={()=>setInfoWin(null)}>
                            <>
                                <Typography color={"primary"}>{ag.usuInicioSesion}</Typography>
                                <p>{ag.usuNombres} {ag.UsuApellidos}</p>
                            </>
                        </InfoWindow>}
                    </Marker>
                )}
            </GoogleMap>
            <Backdrop className={classes.backdrop} open={openFilter} onClick={()=>setOpenFilter(false)}>

                <div className={classes.searchBox} onClick={(e)=>e.stopPropagation()}>
                    <div onClick={(e)=>e.stopPropagation()}>
                        <input type="text" onChange={handleFilterChange}
                               placeholder={Strings.buscar} autoFocus/>
                    </div>
                    <List>
                        {clientesFilter?.map(c =><Fragment key={c.cliId}>
                            <ListItem button onClick={()=>{
                                if(c.cliCoordenadas){
                                    setInfoWin(c.cliId);
                                    setCenter(c.cliCoordenadas);
                                    setOpenFilter(false)
                                }else{
                                    setSnackbar(Strings.noMapMjs)
                                }
                            }} >
                                <ListItemText>
                                    <Typography noWrap>
                                        {c.cliente}
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <Divider component="li" />
                        </Fragment>)}
                        <ListItem>
                            <ListItemText>
                                <Typography color={"primary"} variant={"body2"} noWrap>
                                    {Strings.agentes}
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        {allAgents?.map(a =>
                            <Fragment key={a.usuId}>
                                <ListItem  button onClick={()=>{
                                    if(a?.usuCoordenadas){
                                        setInfoWin(a.usuId);
                                        setCenter(a.usuCoordenadas);
                                        setOpenFilter(false)
                                    }else{
                                        setSnackbar(Strings.noMapMjs)
                                    }
                                }}>
                                    <ListItemText>
                                        <Typography noWrap>
                                            {a.usuNombres} {a.usuApellidos}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                                <Divider component="li" />
                            </Fragment>)}
                    </List>
                </div>
            </Backdrop>
            <ContactDetails />
            <Snackbar open={!!snackbar} autoHideDuration={2000}
                      anchorOrigin={{ vertical:'top', horizontal:'center' }}
                      onClose={()=>setSnackbar(null)}>
                <Alert severity="warning">
                    {snackbar}
                </Alert>
            </Snackbar>
        </section>

    </> :<></>
}

export default React.memo(Map)
