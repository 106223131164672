import React, {useState} from 'react';
import { Dialog, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {AspectRatioSharp, AttachFile, ImageAspectRatioSharp} from '@mui/icons-material';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import {Close} from '@mui/icons-material'
import defaultImg from '../../assets/img/defaultAvatar.jpg';

import pdfIcon from '../../assets/img/pdf.svg';
import Strings from "../../assets/strings";
const useStyles = makeStyles(theme => ({
    root:{
        textAlign:"center",
        position: "relative",
        "& img":{
            cursor:"pointer"
        }
    },
    delIcon:{
        position: "absolute",
        bottom: 10,
        left: "calc(50% - 14px)",
        color: theme.palette.error.main,
        opacity: .9,
        background:"rgba(0, 0, 0, 0.3) !important",
        padding: 3,
        "&.showImg":{
            bottom: 5,
            left: "calc(100% - 36px)",
            background:"rgba(0, 0, 0, 0.06) !important",

        },
        "& svg":{
            width: "0.8em",
            height: "0.8em",
        }
    },
    inputFlex:{
        background: "white",
        marginTop: 7,
        display:"flex",
        alignItems:"center",
        border: "1px solid #e6e6e6",
        "& img":{
            cursor: "pointer",
        },
        "& label":{
            padding: "8px 8px",
            textAlign: "left",
            width: "100%",
            cursor:"pointer",

        }
    },
    dialogContent:{
        padding: "3px !important",

    },
    dialogActions:{
        display:"flex",
        "& button:first-child":{
            marginRight: "auto",
        }
    }
}));


export default function SelectFiles({src, onChange, changeRadio = true, showImg = true,accept="image/gif, image/jpeg, image/png",output="jpeg",defaultImgProp}){
    const classes = useStyles();
    const id = Math.random().toString();

    const [deleted, setDeleted] = useState(null);


    const [img, setImg] = useState(null);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [showAddForm, setShowAddForm] = useState(false);
    const [aspectRatioSq, setAspectRatioSq] = useState(true);
    const [cropper, setCropper] = useState(React.createRef(null));

    const showModal = () => {
        setShowAddForm(true)
    }
    const closeModal = () => {
        setLoading(false)
        setShowAddForm(false)
    }
    const handleChange = e => {
        e.preventDefault();
        setError(null);
        let file;
        if (e.dataTransfer) {
          file = e.dataTransfer.files[0];
        } else if (e.target) {
          file = e.target.files[0];
        }
        if (!file) return false;
        if(file.type.indexOf("image") === -1 && file.type.indexOf("pdf") === -1) return setError(Strings.archivoNoPermitidoMsj)

        if(file.type.indexOf("pdf") !== -1 && file.size > 2000000) return setError(Strings.archivoExcedeMsj+ ", 2MB!");


        const reader = new FileReader();
        reader.onload = () => {
            if(file.type.indexOf("image") !== -1){
                setImg(reader.result);
                showModal()
            }else{
                onChange(reader.result);
            }
        //    e.target.files = [];
        };
        reader.readAsDataURL(file);

      }
    const cropImage = e => {
        e.preventDefault();
        if(loading) return false;

        setLoading(true)

        if (typeof cropper.getCroppedCanvas() === 'undefined') {
          return;
        }

        onChange(cropper.getCroppedCanvas().toDataURL('image/'+output, 0.8));
        closeModal();
      }
    const onDelClick = () =>{
        if(src && src.toString().indexOf("https://")  !== -1 && src !== -1){
            setDeleted(src);
            onChange(-1)
        }else{
            onChange(deleted)
        }
    }
    const viewFileClick = (src) =>{
        if(src.indexOf("base64") === -1)
            window.open(src);
    }

    return <>
        <div className={classes.root}>
            {(src || deleted) &&
                <IconButton
                    className={classes.delIcon + (!showImg?" showImg":"")}
                    onClick={onDelClick}
                    size="large">
                    <Close/>
                </IconButton>
            }
            <div className="label">
                {showImg ?(
                    <label htmlFor={id}>
                        <img style={{width: "100%"}} src={src && src !== -1 ? src : defaultImgProp ? defaultImgProp: defaultImg} alt="avatar"/>
                    </label>
                ):(
                    <div className={classes.inputFlex}>
                        {(src && src !== -1) ?(
                            <img height={36} src={accept.indexOf("image") !== -1? src: pdfIcon}
                                 onClick={()=>viewFileClick(src)} alt={"img"}/>
                        ) :(
                            <AttachFile fontSize={"small"} />
                        )}
                        <label htmlFor={id}>{Strings.adjuntar}</label>
                    </div>
                )}
                {error && <Typography component={"div"} color={"error"} variant={"caption"} align={"left"}>{error}</Typography>}
                <input type="file" id={id} style={{display:"none"}} onChange={handleChange} accept={accept}  />
            </div>
        </div>
        <Dialog open={Boolean(showAddForm)} onClose={closeModal}>
            <DialogContent className={classes.dialogContent}>
                <Cropper
                    fillColor='#fff'
                    style={{ height: 400, width: '100%' }}
                    aspectRatio={aspectRatioSq ? 1 : null}
                    guides={false}
                    viewMode={0}
                    src={img}
                    ref={cropper => setCropper(cropper)}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {changeRadio &&
                <Button color="primary" onClick={() => setAspectRatioSq(!aspectRatioSq)}>
                    {aspectRatioSq ?(<AspectRatioSharp/>):(<ImageAspectRatioSharp color={"secondary"}/>)}
                </Button>
                }
                <Button color="primary" onClick={closeModal}>{Strings.cerrar}</Button>
                <Button
                    color="primary"
                    disabled={loading}
                    onClick={!loading ? cropImage : null} >
                    {loading ? Strings.cargando : Strings.agregar}
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}
