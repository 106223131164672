import {PERSMISOS,PERSMISOS_USUARIOS,TIPOUSUARIO,hasPermissionTo,getUsuTipo} from "../helpers";
import Clientes from '../views/clientes';
import CrearCliente from '../views/clientes/crearCliente';
import Prestamos from '../views/prestamos';
import Pagos from '../views/pagos';
import DetallesPrestamo from '../views/prestamos/detallesPrestamo';
import CrearPrestamo from '../views/prestamos/crearPrestamo';
import Ajustes from '../views/ajustes';
import Perfil from '../views/cuenta/perfil';
import Solicitudes from '../views/solicitud';
import DetallesSolicitud from '../views/solicitud/DetallesSolicitud';
import Rutas from '../views/rutas';
import Carteras from '../views/carteras';
import DetallesCartera from '../views/carteras/detalleCartera';

import Inicio from '../views/inicio';
import Inicio2 from '../views/inicio/inicio2';
import Ayuda from '../views/ayuda';
import Map from '../views/Map';

import Cajas from '../views/cajas';
import CajaDetails from '../views/cajas/CajaDetails';

import Gastos from '../views/gastos';
import Agentes from '../views/Agentes';
import Roles from '../views/roles';
import Plantillas from '../views/Plantillas';
import PlantillaCrear from '../views/Plantillas/crear';

import Autorizaciones from '../views/autorizaciones';

//,HowToRegOutlined
import {MapOutlined,PersonOutline,SettingsOutlined,AttachMoney,DescriptionOutlined,
    Dashboard,PeopleAltOutlined,LocalAtmOutlined,Tune, MessageOutlined,MoneyOff,AccountBalanceWalletOutlined, CallToActionOutlined} from "@mui/icons-material"
import {AccountLockOutline,MapMarkerOutline} from "mdi-material-ui";

//admin
import {lazy} from "react";
const AppUsers = lazy(() => import('../views/appUsers'));
const UsosMultiples = lazy(() => import('../views/usosMultiples'));
const Enums = lazy(() => import('../views/enums'));
const Paises = lazy(() => import('../views/paises'));

const getRoutes = ()=>{
    return (
        [
            {
                path: "/perfil",
                navbarName: "Mi perfil",
                icon: PersonOutline,
                component: Perfil
            },
            {
                path: "/ayuda",
                navbarName: "Ayuda",
                icon: PersonOutline,
                component: Ayuda
            },
            {
                path: "/inicio",
                sidebarName: "inicio",
                navbarName: "inicio",
                icon: Dashboard,
                component: hasPermissionTo(PERSMISOS.dashboard) ? Inicio:Inicio2,
            },
            {
                path: "/solicitudes", //listado de solicitud de prestamo
                sidebarName: "solicitudes",
                navbarName: "solicitudes",
                icon: MessageOutlined,
                hasBadge:true,
                component: Solicitudes,
                restricted: (!hasPermissionTo(PERSMISOS.solicitudes) || !hasPermissionTo(PERSMISOS_USUARIOS.solicitudes)),
                nestedRoutes: [
                    {
                        path: "/solicitud/:solId",
                        sidebarName: "solicitudes",
                        navbarName: "solicitudes",
                        component: DetallesSolicitud
                    },
                ]
            },
            {
                path: "/clientes", //lista de clientes
                sidebarName: "prestatarios",
                navbarName: "prestatarios",
                icon: PeopleAltOutlined,
                component: Clientes,
                restricted: !hasPermissionTo(PERSMISOS.clientes),
                nestedRoutes: [
                    {
                        path: "/cliente/:cliId", //detalles de clientes / actualizar
                        sidebarName: "detalles de clientes",
                        navbarName: "prestatario",
                        component: CrearCliente
                    },
                    {
                        path: "/crear/cliente", //Crear cliente
                        sidebarName: "Crear Cliente",
                        navbarName: "Crear Cliente",
                        component: CrearCliente
                    },
                ]
            },
            {
                path: "/prestamos",
                sidebarName: "prestamos",
                navbarName: "prestamos",
                component: Prestamos,
                icon:DescriptionOutlined,
                restricted: !hasPermissionTo(PERSMISOS.prestamos),
                nestedRoutes: [
                    {
                        path: "/prestamos/:cliId",
                        sidebarName: "prestamos",
                        navbarName: "prestamos",
                        component: Prestamos
                    },
                    {
                        path: "/prestamo/:preId", //detalles de prestamo
                        sidebarName: "Table List",
                        navbarName: "Detalles de Prestamo",
                        component: DetallesPrestamo
                    },
                    {
                        path: "/crear/prestamo",
                        sidebarName: "Typography",
                        navbarName: "Crear Prestamo",
                        component: CrearPrestamo
                    },
                    {
                        path: "/reenganche/:preId",
                        sidebarName: "Typography",
                        navbarName: "Reenganche Prestamo",
                        component: CrearPrestamo
                    },
                    {
                        path: "/editar/prestamo/:preId", //Editar un prestamo
                        sidebarName: "Typography",
                        navbarName: "Editar Prestamo",
                        component: CrearPrestamo
                    }
                ]
            },
            {
                path: "/pagos", //lista de pagos
                sidebarName: "pagos",
                navbarName: "pagos",
                icon: AttachMoney,
                restricted: !hasPermissionTo(PERSMISOS.pagos),
                component: Pagos
            },
            {
                path: "/cajas",
                sidebarName: "cajas",
                icon: LocalAtmOutlined,
                navbarName: "cajas",
                restricted: (!hasPermissionTo(PERSMISOS.cajas) || !hasPermissionTo(PERSMISOS_USUARIOS.cajas)),
                component: Cajas,
                nestedRoutes: [
                    {
                        path: "/caja/:cajId",
                        navbarName: "CajaDetails",
                        component: CajaDetails
                    },
                ]

            },
            {
                path: "/gastos",
                sidebarName: "gastos",
                icon: MoneyOff,
                navbarName: "gastos",
                restricted: (!hasPermissionTo(PERSMISOS.gastos) || !hasPermissionTo(PERSMISOS_USUARIOS.gastos)),
                component: Gastos
            },
            {
                path: "/rutas",
                sidebarName: "rutas",
                navbarName: "rutas",
                icon: MapMarkerOutline,
                restricted: (!hasPermissionTo(PERSMISOS.rutas) || !hasPermissionTo(PERSMISOS_USUARIOS.rutas)),
                component: Rutas
            },
            {
                path: "/carteras",
               // nuevo: true,
                sidebarName: "carteras",
                navbarName: "carteras",
                icon: AccountBalanceWalletOutlined,
                restricted: (!hasPermissionTo(PERSMISOS.carteras) || !hasPermissionTo(PERSMISOS_USUARIOS.carteras)),
                component: Carteras,
                nestedRoutes: [
                    {
                        path: "/cartera/:carId", //detalles de carteras
                        sidebarName: "Table List",
                        navbarName: "Detalles de carteras",
                        component: DetallesCartera
                    }
                ]
            },
            {
                path: "/mapa",
                sidebarName: "mapa",
                navbarName: "mapa",
                restricted: (!hasPermissionTo(PERSMISOS.map) || !hasPermissionTo(PERSMISOS_USUARIOS.geoMapa)),
                icon: MapOutlined,
                component: Map
            },
            ...(getUsuTipo() === TIPOUSUARIO.titular || getUsuTipo() === TIPOUSUARIO.admin ?
                    [{
                        sidebarName: "seguridad",
                        navbarName: "seguridad",
                        icon: AccountLockOutline,
                        nestedRoutes: [
                            {
                                path: "/agentes",
                                sidebarName: "agentes",
                                navbarName: "agentes",
                                component: Agentes
                            },
                            {
                                path: "/roles",
                                sidebarName: "rolesPermisos",
                                navbarName: "rolesPermisos",
                                component: Roles
                            },
                            {
                                path: "/autorizaciones",
                                sidebarName: "autorizaciones",
                                navbarName: "autorizaciones",
                                nuevo: true,
                                component: Autorizaciones
                            }

                        ]
                    },
                        {
                            path: "/ajustes",
                            sidebarName: "ajustes",
                            navbarName: "ajustes",
                            icon: SettingsOutlined,
                            component: Ajustes
                        }] : []
            ),
            {
                path: "/plantillas",
                sidebarName: "plantillas",
                navbarName: "plantillas",
                icon: CallToActionOutlined,
                component: Plantillas,
                //  nuevo: true,
                restricted: (!hasPermissionTo(PERSMISOS.plantillas) || !hasPermissionTo(PERSMISOS_USUARIOS.plantillas)),
                nestedRoutes: [
                    {
                        path: "/crear/plantilla",
                        component: PlantillaCrear
                    },
                    {
                        path: "/editar/plantilla/:plaId",
                        component: PlantillaCrear
                    }
                ]
            },
            ...(getUsuTipo() === TIPOUSUARIO.admin ?
                    [{
                        sidebarName: "admin",
                        navbarName: "admin",
                        icon: Tune,
                        nestedRoutes: [
                            {
                                path: "/appUsers",
                                sidebarName: "usuarios",
                                navbarName: "usuarios",
                                component: AppUsers
                            },
                            {
                                path: ["/appUsers/:usuId","/appUser/:usuId"],
                                sidebarName: "usuario",
                                navbarName: "usuario",
                                component: AppUsers
                            },
                            {
                                path: "/UsosMultiples",
                                sidebarName: "usosMultiples",
                                navbarName: "usosMultiples",
                                component: UsosMultiples
                            },
                            {
                                path: "/Enums",
                                sidebarName: "enums",
                                navbarName: "enums",
                                component: Enums
                            },
                            {
                                path: "/Paises",
                                sidebarName: "paises",
                                navbarName: "paises",
                                component: Paises
                            },
                        ]
                    }]:[]
            ),
            { redirect: true, path: "/", to: "/inicio", navbarName: "Redirect" }
        ]
    )
}
export default getRoutes;
