import React, {useState} from 'react';
//import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'


import {Divider, MenuItem, NativeSelect, Select} from "@mui/material";
//import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {useMediaQuery, useTheme} from "@mui/material";
import {checkDateInput, formatDate, formatPeso} from "../helpers";
import moment from "moment";
import HtmlTooltip from "./HtmlTooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Strings from "../assets/strings";
import TextFieldPhone from "./TextFieldPhone";
// function TextMaskCustomCurrency({ maskOptions, ...inputProps }) {
//     const currencyMask = createNumberMask({
//         prefix: "",
//         includeThousandsSeparator: true,
//         allowDecimal: true,
//         decimalLimit: 4,
//         allowNegative: false,
//         allowLeadingZeroes: false,
//         ...maskOptions,
//     })
//
//     return <MaskedInput mask={currencyMask} {...inputProps} />
// }
export function TextFieldCurrency({min,value,...props}){
    //const { name, onChange, ...other } = props;
    const [isFocused,setIsFocused] = useState(false);
    return (
        <TextField
            {...props}
            value={isFocused?value:(value?formatPeso(value):"")}
            onFocus={()=>setIsFocused(true)}
            onBlur={()=>setIsFocused(false)}
            {...(isFocused?{
                type:"number",
                inputProps:{step:"any",inputMode:"decimal",min}
            }:{})}
        />

        // <TextField
        //     {...other}
        //     {...((name && onChange) && {
        //         onChange: ({target:{value}})=>props.onChange({target:{value: value.replace(/,/g,""),name: props.name}})
        //     })}
        //    type={"tel"}
        //     InputProps={{
        //         inputComponent: TextMaskCustomCurrency,
        //     }}
        // />

        // <FormControl>
        //     <InputLabel htmlFor="formatted-text-mask-input">react-text-mask</InputLabel>
        //     <Input
        //         {...other}
        //         {...((name && onChange) && {
        //             onChange: ({target:{value}})=>props.onChange({target:{value: parseInt(value),name: props.name}})
        //         })}
        //         type={"tel"}
        //         id="formatted-text-mask-input"
        //         inputComponent={TextMaskCustomCurrency}
        //     />
        // </FormControl>
    )
}

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            decimalSeparator
            isNumericString
            prefix="$"
        />
    );
}
export function TextFieldCurrency2(props) {
    const { name, onChange, ...other } = props;
    return (
            <TextField
                {...other}
                {...((name && onChange) && {
                    onChange: ({target:{value}})=>props.onChange({target:{value: parseInt(value),name: props.name}})
                })}
                type={"tel"}
                InputProps={{
                    inputComponent: NumberFormatCustom,
                }}
            />
    );
}
export function DateInput({name,value, onChange,native,clearable,openTo,views,quincenalFijo,...other}) {

    const [openTp, setOpenTp] = useState(false)


    const handleChange = (e) => {
        const {value:val} = e.target;
        setOpenTp(false);
        let fecha = moment(val);
        if(quincenalFijo){
            if(fecha.date() === 15 || fecha.date() === fecha.daysInMonth()){
                return onChange(e);
            }
            setOpenTp(true)
            onChange({target:{name,value:""}})
        }else {
            return onChange(e);
        }
    }

    // if(smVewport || native)
        return (
            <ClickAwayListener onClickAway={()=>setOpenTp(false)}>
            <div>
                <HtmlTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    arrow
                    onClose={()=>setOpenTp(false)}
                    open={openTp}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={Strings.inputQuincenalMsj }
                >
                    <div>
                        {checkDateInput() ?(
                            <TextField
                                name={name}
                                onChange={handleChange}
                                value={value ? formatDate(value,"YYYY-MM-DD"):""}
                                placeholder={"YYYY-MM-DD"}
                                {...other}
                                type="date"
                                fullWidth
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        ):(
                            <LocalizationProvider  dateAdapter={AdapterMoment}>
                                <DatePicker
                                    autoOk
                                    format="DD MMM, YYYY"
                                    todayLabel={Strings.hoy}
                                    cancelLabel={Strings.cancelar}
                                    clearLabel={Strings.sinFecha}
                                    value={value?value:null}
                                    showTodayButton={!clearable}

                                    {...{clearable,openTo,views}}

                                    onChange={(value)=>handleChange({target:{value:value && value.format('YYYY-MM-DD'),name}})}
                                    renderInput={(params) => <TextField {...params} {...other} fullWidth />}

                                />
                                <input type="text" value={value} onChange={()=>{}} required={other.required} style={{height: "0", padding: "0", margin: "0 auto", width: "0", opacity: "0"}}/>
                            </LocalizationProvider>
                        )}
                    </div>

                </HtmlTooltip>
            </div>
            </ClickAwayListener>
        )
    // else


}
export function SelectSmart({opciones, loading, value, ...props}) {
    const theme = useTheme();
    const smVewport = useMediaQuery(theme.breakpoints.down('sm'));

    let validValue = opciones?.find(o=>o.key.toString() === value.toString()) ? value : "";


    if(smVewport)
        return (
            <NativeSelect value={validValue} {...props}>
                {loading && <option value={validValue} disabled>{Strings.cargando}...</option>}
                {/*{all ? (*/}
                {/*    <option value="">Todos</option>) :(<option value="" disabled={props.required}>Seleccione</option>*/}
                {/*)}*/}
                {opciones.length > 0 && opciones.map(opt=> {
                    if(opt.key?.toString().indexOf("--") === 0){
                        return <option key={opt.key} value={opt.key} disabled>-------</option>
                    }
                    return <option key={opt.key} value={opt.key} disabled={opt.disabled}>{opt.value}</option>
                })}
            </NativeSelect>
        )
    else
        return (
            <>
            <Select displayEmpty value={validValue} {...props}>
                {loading && <MenuItem value={validValue} disabled>{Strings.cargando}...</MenuItem>}
                {/*{all ? (*/}
                {/*    <MenuItem value={opciones.length <= 0 ? value :""}>Todos</MenuItem>) :(*/}
                {/*    <MenuItem value={opciones.length <= 0 ? value :""} disabled={props.required}>Seleccione</MenuItem>*/}
                {/*)}*/}
                {opciones.length > 0 && opciones.map(opt=> {
                    if(opt.key?.toString().indexOf("--") === 0){
                        return <Divider key={opt.key}/>
                    }
                    return <MenuItem key={opt.key} value={opt.key} disabled={opt.disabled}>{opt.value}</MenuItem>
                })}
            </Select>
                <input type="text" value={validValue} onChange={()=>{}} required={props.required} style={{height: "0", padding: "0", margin: "0 auto", width: "0", opacity: "0"}}/>
            </>
        )


}

export {TextFieldPhone}
