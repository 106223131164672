import React from 'react';
import { Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Add} from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex:2
    },
}));
export default  function PlusFab(props){
    const classes = useStyles();
    return (
        <Fab color="primary" className={classes.root} aria-label="add" {...props}>
            <Add />
        </Fab>
    );
}
