import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    Grid,
    TextField,
    Button,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    Typography,
} from '@mui/material';


import makeStyles from '@mui/styles/makeStyles';


import AppBar from '../../components/appBar';
import Strings from "../../assets/strings";

import {colors} from "../../assets/colors";
import {TextFieldPhone} from "../../components/CustomTextField";
import {editarPerfil} from "../../redux/actions/authentication";
import {nullToEmptyString} from "../../helpers";
import {MostrarError} from "../../components/MostrarError";
import {useHistory} from "react-router-dom";
import {SelectCaja, SelectFiles} from "../../components/selects";
import defaultImgFirma from '../../assets/img/firma.jpg';

import ChangePassword from "./ChangePassword";

const useStyles = makeStyles(theme => ({

    root:{
        padding:theme.spacing(2),
    },
    firma:{
        "& img":{
            maxWidth: 150,
            boxShadow: "0px 0px 5px #c4b9b9"
        }
    },

    divColor:{
        width:"100%",
        padding: "1px 7px",
        color: "white",
    }
}));

function MiPerfil(props){
    let history = useHistory();
    const classes = useStyles();
    const [state, setState] = useState({
        usuId:"",
        usuInicioSesion: "",
        usuClave: "",
        usuNombres: "",
        usuApellidos: "",
        usuEmail: "",
        usuTelefono: "",
        usuUIColor: "",
        usuFirma:"",
        cajId: props.defaultCajId,
    })
    const {editarPerfil, perfil} = props
    useEffect(()=>{
            setState(nullToEmptyString(perfil))
    },[setState,perfil])
    const handleChange = (e)=>{
        const {name, value} = e.target
        setState({...state, [name]: value});
    }
    const {errors,isPending} = props;

    return(
        <>
            <AppBar title={Strings.perfil}>
                <Button color="inherit" disabled={isPending} type="submit" form="form1">{Strings.guardar}</Button>
            </AppBar>
            <div className={"contentInner "+classes.root}>
                <MostrarError errors={errors}/>
                <form id="form1" onSubmit={(e)=>{e.preventDefault();editarPerfil(state)}}>
                    <Grid container spacing={3} className={classes.form}>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="usuNombres"
                                label={Strings.primerNombre}
                                value={state.usuNombres}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="usuApellidos"
                                label={Strings.apellidos}
                                value={state.usuApellidos}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="usuEmail"
                                label={Strings.email}
                                type="email"
                                value={state.usuEmail}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextFieldPhone
                                name="usuTelefono"
                                label={Strings.telefono}
                                value={state.usuTelefono}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormControl fullWidth>
                                <InputLabel id="usuUIColor">UI Color</InputLabel>
                                <Select
                                    labelId="usuUIColor"
                                    name="usuUIColor"
                                    value={state.usuUIColor}
                                    onChange={handleChange}
                                >
                                    {colors.map(c=>

                                        <MenuItem key={c.primary.main} value={c.primary.main}>
                                            <div className={classes.divColor} style={{background: c.primary.main}}>{c.primary.main}</div>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <SelectCaja label={Strings.caja+" "+Strings.porDefecto} name="cajId" value={state.cajId} onChange={handleChange}/>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                name="usuInicioSesion"
                                label={Strings.nombreDeUsuario}
                                value={state.usuInicioSesion}
                                onChange={handleChange}
                                inputProps={{readOnly: true,}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                label={Strings.cambiar+" "+Strings.contrasena}
                                type="password"
                                defaultValue={"45654"}
                                inputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                                onClick={()=> history.push(history.location.pathname,{modalChangePassword:state.usuInicioSesion})}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <Typography align={"center"} color={"secondary"}>{Strings.firma}</Typography>
                            <div className={classes.firma}>
                                <SelectFiles output={"png"} defaultImgProp={defaultImgFirma} src={state.usuFirma ?state.usuFirma:null} onChange={(baseb4)=> {
                                    setState({...state, usuFirma: baseb4});
                                }}/>
                            </div>

                            {/*<TextField*/}
                            {/*    name="usuInicioSesion"*/}
                            {/*    label={Strings.nombreDeUsuario}*/}
                            {/*    value={state.usuInicioSesion}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    inputProps={{readOnly: true,}}*/}
                            {/*    fullWidth*/}
                            {/*/>*/}
                        </Grid>
                    </Grid>
                </form>
            </div>
            <ChangePassword/>
        </>
    );
}

const mapStateToProps = (state) => ({
    perfil: state.auth.user,
    isPending: state.auth.isPending,
    isFetching: state.auth.isFetching,
    errors: state.auth.error,
    defaultCajId: state.auth.user.cajId || "",
})
export default connect(mapStateToProps, {editarPerfil })(MiPerfil);
