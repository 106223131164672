import React, {useEffect} from 'react';
import {
    Grid, IconButton, CircularProgress, Hidden, Backdrop, BottomNavigation, BottomNavigationAction, useMediaQuery,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {togglePaypalPlan} from "../../redux/actions/themeActions";
import AppBar from '../../components/appBar';
import HomeCounter from '../../components/homeWidget/HomeCounter';
import Strings from '../../assets/strings';
import IngresosChart from "../../components/homeWidget/IngresosChart"
import InComeOut from "../../components/homeWidget/inComeOut";
import CxC from "../../components/homeWidget/CxC";
import {
    Add, DescriptionOutlined, SupervisorAccountOutlined, BarChart, PeopleAltOutlined, Refresh,
} from "@mui/icons-material"
import {useDispatch, useSelector} from "react-redux";
import {mostrarDashBoardData} from "../../redux/actions/dashBoardActions";
import {hasPermissionTo, moment, PERSMISOS, TIPOPLAN} from "../../helpers";
import { SpeedDial, Alert } from '@mui/material';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
const useStyles = makeStyles(theme => ({
    root:{
        paddingBottom: 0,
        [theme.breakpoints.down('md')]: {
            overflow: "hidden",
            margin: theme.spacing(1),
            "& .cxcContent":{
                margin: -theme.spacing(1),
                height: "calc(100vh - 107px)",
                "&>.content":{
                    paddingBottom:0,
                    height: "calc(100vh - 191px)",
                    "& .total":{
                        bottom: 0,
                    }
                }
            },
            '& .chart':{
                marginTop: -24
            }

        }
    },
    tab:{
        position: 'fixed',
        bottom: 0,
        width:"100%",
        borderTop: "1px solid "+theme.palette.grey[300],
    },
    speedDial:{
        //display:"none",
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            "&>button":{
                opacity: 0,
            }
        }
    },
    sDAction:{
        "& .MuiSpeedDialAction-staticTooltipLabel":{
            color: theme.palette.secondary.main,
            border: "1px solid " + theme.palette.secondary.main,
            boxShadow: "none",
            padding: "0px 10px",
            whiteSpace: "nowrap",
            fontSize: ".9rem",
        },
        "& svg":{
            color:theme.palette.primary.main,
        }

    },
    drawerTop:{
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        height:50,
        paddingLeft:theme.spacing(2),
        background:theme.palette.primary.main,
        color:theme.palette.common.white,
    },
    listRoot:{
        minWidth:255,
    },
    alert:{
        [theme.breakpoints.down('md')]: {
            "& .MuiAlert-icon":{
                display: "none",
            },
            flexDirection: "column",
        }
    },

}));
const isIOS = ()=>{
    if(window?.webkit?.messageHandlers?.pagar)
        return true
    else
        return false
}
export default function DashBoard() {
    const classes = useStyles();
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));

    const {usuInicioSesion,usuTipoPlan,usuFechaCreacion} = useSelector(state => state.auth?.user);
    const isFetching = useSelector(state => state.dashBoard.isFetching);
    const [tab, setTab] = React.useState("cxc");


    const Dispatch = useDispatch();
    const [openSpeedDial, setOpenSpeedDial] = React.useState(false);
    // const [openSetting, setOpenSetting] = React.useState(false);

    useEffect(()=>{
        Dispatch(mostrarDashBoardData());
    },[Dispatch]);
    const pay = ()=>{
        if(isIOS()){
            window.webkit.messageHandlers.pagar.postMessage("Pagar");
        }else {
            Dispatch(togglePaypalPlan())
          //  window.open("https://prestamistapp.com/checkout?user="+usuInicioSesion,"_Blank")
        }
    }
    const contactar = () =>{
        window.open("https://api.whatsapp.com/send/?phone=18498641680&text=Hola, desde PrestamistAPP, mi usuario es "+usuInicioSesion,"_Blank")
    }
    return <>
        <AppBar title={Strings.inicio}>
            <IconButton
                onClick={()=>Dispatch(mostrarDashBoardData())}
                disabled={isFetching}
                size="large">{isFetching?<CircularProgress size={20} />:<Refresh/>}</IconButton>
            {/*<IconButton onClick={()=>setOpenSetting(true)}><Settings/></IconButton>*/}
        </AppBar>

        <div className={"contentInner " + classes.root}>
            {usuTipoPlan === TIPOPLAN.gratis &&
                <>
                    <Alert className={classes.alert} severity="warning"  variant={"outlined"} action={
                        <div>
                            <Button color="inherit" size="small" component={"a"} href={"https://www.youtube.com/channel/UC169EbwYwjpoCOC_SXkCMKg"} target={"_blank"}>
                                Videos
                            </Button>
                            <Button color="inherit" size="small" onClick={contactar}>
                                {Strings.contacto}
                            </Button>
                            <Button color="primary" onClick={pay} size="small">
                                {Strings.comprar}
                            </Button>
                        </div>
                    }>
                        {Strings.formatString(Strings.preriodoPruebaMsj,5 - moment().diff(moment(usuFechaCreacion??""), 'days'))}
                    </Alert>
                    <br/>
                </>
            }
            {(!smVewport || tab === "chart") &&
                <HomeCounter/>
            }
            <Grid container spacing={3}>

                <Grid item xs={12} md={5}>
                    {(!smVewport || tab === "chart") &&
                        <InComeOut/>
                    }
                    {(!smVewport || tab === "cxc") && <>
                        {hasPermissionTo(PERSMISOS.prestamos) &&
                            <CxC />
                        }
                    </>}

                </Grid>
                {(!smVewport || tab === "chart") &&
                    <Grid item xs={12} md={7} className={"chart d-print-none"}>
                        <IngresosChart/>
                    </Grid>
                }
            </Grid>
        </div>
       <Hidden mdUp>
           <Backdrop open={openSpeedDial} sx={{opacity: .4, zIndex: 2}}  onClick={()=>setOpenSpeedDial(false)}/>
       </Hidden>
        <SpeedDial
            ariaLabel="Short Cut"
            className={classes.speedDial}
            icon={<Add/>}
            onClose={()=>setOpenSpeedDial(false)}
            onOpen={()=>setOpenSpeedDial(true)}
            open={openSpeedDial}
        >
            {hasPermissionTo(PERSMISOS.prestamosCrear) &&
            <SpeedDialAction
                classes={{staticTooltip:classes.sDAction}}
                icon={<DescriptionOutlined/>}
                tooltipTitle={Strings.agregar +" "+Strings.prestamo}
                tooltipOpen
                FabProps={{
                    component: Link,
                    to: "/crear/prestamo"
                }}
            />}
            {hasPermissionTo(PERSMISOS.clientesCrear) &&
                <SpeedDialAction
                    classes={{staticTooltip:classes.sDAction}}
                    icon={<PeopleAltOutlined/>}
                    tooltipTitle={Strings.agregar +" "+Strings.prestatario}
                    tooltipOpen
                    FabProps={{
                        component: Link,
                        to: "/crear/cliente"
                    }}
                />
            }
        </SpeedDial>
        {!!smVewport &&
        <BottomNavigation
            showLabels
            value={tab}
            onChange={(event, v) => (v === "new") ? setOpenSpeedDial(true): setTab(v)}
            className={classes.tab} >
            <BottomNavigationAction label="CxC" value="cxc" icon={<SupervisorAccountOutlined />} />
            <BottomNavigationAction label={Strings.estadisticas} value="chart" icon={<BarChart />} />
            {(hasPermissionTo(PERSMISOS.prestamosCrear) || hasPermissionTo(PERSMISOS.clientesCrear)) &&
                <BottomNavigationAction label={Strings.nuevo} value="new" icon={<Add />}/>
            }
        </BottomNavigation>}
        {/*<Drawer*/}
        {/*    anchor={"right"}*/}
        {/*    open={openSetting}*/}
        {/*    onClose={()=>setOpenSetting(false)}*/}
        {/*>*/}
        {/*    <div>*/}
        {/*        <div className={classes.drawerTop}>*/}
        {/*            <div>{Strings.mostrar.toLocaleUpperCase()}</div>*/}
        {/*            <Button color={"inherit"}><Check/></Button>*/}
        {/*        </div>*/}
        {/*        <List  className={classes.listRoot}>*/}
        {/*            {*/}
        {/*                [*/}
        {/*                    {id:"copia",text:Strings.prestamosActivos},*/}
        {/*                    {id:"capital",text:Strings["total"] +" "+ Strings["prestado"]},*/}
        {/*                    {id:"mora",text:Strings["total"] +" "+ Strings["interes"]},*/}
        {/*                    {id:"interes",text:Strings.total +" "+Strings.capital+" + "+Strings.interes},*/}
        {/*                    {id:"descuento",text:Strings.ingresos},*/}
        {/*                    {id:"capitalRestante",text:Strings.egresos},*/}
        {/*                    {id:"proximoPago",text:Strings.cuentasXcobrar},*/}
        {/*                    {id:"balancePendiente",text:`${Strings.ingresos} ${Strings.grafico}`}*/}
        {/*                ].map(s=>*/}
        {/*                    <ListItem key={s.id}>*/}
        {/*                        <ListItemText id={s.id} primary={s.text}/>*/}
        {/*                        <ListItemSecondaryAction>*/}
        {/*                            <Switch*/}
        {/*                                edge="end"*/}
        {/*                                name={s.id}*/}
        {/*                                inputProps={{ 'aria-labelledby': s.id }}*/}
        {/*                                size={"small"}*/}
        {/*                            />*/}
        {/*                        </ListItemSecondaryAction>*/}
        {/*                    </ListItem>*/}
        {/*                )*/}
        {/*            }*/}

        {/*        </List>*/}
        {/*    </div>*/}
        {/*</Drawer>*/}

    </>;
}


