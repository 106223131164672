import {cajaTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    byId:{},
    isFetching: false,
    isPending: false,
    error: null,
}

export default function index(state = initialState, action={}) {
    switch (action.type) {
        case cajaTypes.MOSTRAR_CAJAS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case cajaTypes.MOSTRAR_CAJAS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case cajaTypes.MOSTRAR_CAJAS + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //mostrar por id
        case cajaTypes.MOSTRAR_CAJAS_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case cajaTypes.MOSTRAR_CAJAS_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case cajaTypes.MOSTRAR_CAJAS_POR_ID + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //agregar  o editar
        
        case cajaTypes.AGREGAR_EDITAR_CAJA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case cajaTypes.AGREGAR_EDITAR_CAJA + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list.filter(e => e.cajId !== data.cajId)
            list.unshift(data)
            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case cajaTypes.AGREGAR_EDITAR_CAJA + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //Cerrar caja

        case cajaTypes.CERRAR_CAJA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case cajaTypes.CERRAR_CAJA + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list.filter(e => e.cajId !== data.cajId)
            list.unshift(data)

            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case cajaTypes.CERRAR_CAJA + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }

        //transferencia

        case cajaTypes.TRANSFERENCIA_CAJA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case cajaTypes.TRANSFERENCIA_CAJA + _FULFILLED: {
            return {
                ...state,
                isPending: false,
                error: {}
            }
        }
        case cajaTypes.TRANSFERENCIA_CAJA + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }

        //Eliminar
        case cajaTypes.ELIMINAR_CAJA + _FULFILLED: {
            const { data: id  } = action.payload;
            let list = state.list.filter(e => e.cajId !== id)
            return {
                ...state,
                list,
                isPending: false,
                error: {}
            }
        }
        case cajaTypes.ELIMINAR_CAJA + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
    default:
      return state;
  }
}
