import React, {useEffect, useState} from 'react';
import {Autocomplete, GoogleMap,Marker, } from '@react-google-maps/api';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent, FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel, TextField, useMediaQuery
} from "@mui/material";
import TopBarDialog from "./topBar/TopBarDialog";
import {MostrarError} from "./MostrarError";
import Strings from "../assets/strings";
import {AddLocationAltOutlined, WhereToVoteOutlined} from "@mui/icons-material";
import {HtmlTooltip} from "./index";
import {getLSLang} from "../helpers";
const containerStyle = {
    width: '100%',
    minHeight: 400,
    height: '100%'
};
let defaultCenter = {
    lat: 0,
    lng: 0
};

if(!defaultCenter.lat){
    navigator.geolocation.getCurrentPosition((pos)=>{
        const crd = pos.coords;

        defaultCenter.lat = crd.latitude;
        defaultCenter.lng = crd.longitude;

    }, (err)=>{
        console.log(JSON.stringify(err))
        console.log(err)
    }, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    });
}




const inputStyle = {
    boxSizing: "border-box",
    border: "1px solid transparent",
    width: "calc(100% - 20px)",
    height: "40px",
    padding: "0px 12px",
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 2px 6px",
    fontSize: "14px",
    outline: "none",
    position: "absolute",
    top: "10px",
    left: "10px"
}
function SearchBox({handleChange,cliDireccion,cliCoordenadas}) {
    const smVewport = useMediaQuery(theme=>theme.breakpoints.down('md'));

    const [autocomplete ] = React.useState(null); //setAutocomplete
    const [autocompleteIn, setAutocompleteIn ] = React.useState(null);
    const [openMap, setOpenMap] = useState(false);

    const [error, setError] = React.useState(null);
    const [latLng, setLatLng] = React.useState();

    useEffect(()=>{
        if(cliCoordenadas){
            let coordenadas = cliCoordenadas.split(',');
            setLatLng({lat:parseFloat(coordenadas[0]), lng:parseFloat(coordenadas[1])});
        }
    },[cliCoordenadas])
    const  handledLatLng = async (obj)=>{
        let lat = obj.latLng.lat();
        let lng = obj.latLng.lng();

        let toAdd = {
            cliCoordenadas: lat+","+lng
        };

        handleChange(toAdd);
        if(obj.formatted_address){
            toAdd.cliDireccion = obj.formatted_address;
            handleChange(toAdd);

        } else{
            coordenateToName(obj.latLng).then(res=>{
                toAdd.cliDireccion = res;

                handleChange(toAdd);
            });
        }

    }
    const onPlaceChanged = (inModal) =>{
        let input = inModal ? autocompleteIn : autocomplete;

        if (input !== null) {
             let place = input.getPlace();
             const {formatted_address,geometry } = place;

             handledLatLng({latLng:geometry.location, formatted_address},true);
        }

    }
    const currentLocation = ()=>{
        navigator.geolocation.getCurrentPosition((pos)=>{
            const crd = pos.coords;


            let latlng = new window.google.maps.LatLng(crd.latitude, crd.longitude);

            handledLatLng({'latLng': latlng});

            //para eliminar apiPlace
            // handleChange({
            //     cliCoordenadas: crd.latitude+","+crd.longitude
            // })

        }, (err)=>{
            console.log(JSON.stringify(err))
            console.log(err)
            setError(Strings.ubicacionDenegada);
        }, {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0
        });
    }
    const handleInputChange = (e)=>{
        const {value} = e.target
        if(!value){
           return  handleChange({cliCoordenadas: "",cliDireccion: ""});
        }
        handleChange({cliDireccion: value,cliCoordenadas});
    }

    const country = getLSLang("c");
    // const [link, setLink] = useState("");

    return window?.google?.maps ? <>
        {/*<Autocomplete*/}
        {/*    onLoad={(v)=>setAutocomplete(v)}*/}
        {/*    onPlaceChanged={onPlaceChanged}*/}
        {/*    {...(country && {restrictions:{country}})}*/}
        {/*>*/}
            <FormControl fullWidth>
                <InputLabel htmlFor="cliDireccion">{Strings.direccion}</InputLabel>
                <Input
                    id="cliDireccion"
                    value={cliDireccion}
                    onChange={handleInputChange}
                    autoComplete={"new-password"}
                 //   onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                    endAdornment={
                        <InputAdornment position="end">
                            <HtmlTooltip title={"Geolocalización"}>
                                <IconButton color={"primary"} onClick={()=>setOpenMap(true)} size="large">
                                    {latLng?.lat ?<WhereToVoteOutlined/>: <AddLocationAltOutlined/>}
                                </IconButton>
                            </HtmlTooltip>
                        </InputAdornment>
                    }
                />
            </FormControl>
        {/*</Autocomplete>*/}

        <Dialog open={openMap}  onClose={()=>setOpenMap(false)} fullScreen={smVewport} fullWidth maxWidth={"md"} >
            <TopBarDialog title={Strings.direccion} onClose={()=>setOpenMap(false)} nofullScreen/>
            <DialogContent sx={{ padding: 0}} >
                <MostrarError errors={error} onClose={()=>setError(null)}/>
                {/*<Box>*/}
                {/*    <TextField fullWidth placeholder={"https://maps.google.com/?q=18.560032,-69.945679"} value={link}*/}


                {/*               onPaste={(e)=>{*/}
                {/*        let paste = (e.clipboardData || window.clipboardData).getData("text");*/}
                {/*        let arr = paste.split("?q=");*/}
                {/*        if(arr.length === 2){*/}
                {/*            let arrC = arr[1].split(",");*/}
                {/*            if(arrC.length === 2){*/}
                {/*                setLatLng({lat:+arrC[0], lng: +arrC[1] })*/}
                {/*            }*/}
                {/*        }*/}

                {/*    }}*/}
                {/*           onChange={({target})=> setLink(target.value)}*/}
                {/*               {...(!!link ?{*/}
                {/*                   InputProps:{*/}
                {/*                       endAdornment:  <InputAdornment position="end" style={{position: "absolute",right: 0}}>*/}
                {/*                           <IconButton onClick={()=>{*/}
                {/*                               let arr = link.split("?q=");*/}
                {/*                               if(arr.length === 2){*/}
                {/*                                   let arrC = arr[1].split(",");*/}
                {/*                                   if(arrC.length === 2){*/}
                {/*                                       setLatLng({lat:+arrC[0], lng: +arrC[1] })*/}
                {/*                                   }*/}
                {/*                               }*/}
                {/*                           }}> <Search fontSize="inherit" /> </IconButton>*/}
                {/*                       </InputAdornment>,*/}
                {/*                   }*/}
                {/*               }:{})}*/}
                {/*    />*/}
                {/*    <br/>*/}




                {/*</Box>*/}
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={latLng??defaultCenter}
                    zoom={15}
                    disableDefaultUI={true}
                    options={{
                        disableDefaultUI:true
                    }}
                >

                    <Autocomplete
                        onLoad={(v)=>setAutocompleteIn(v)}
                        onPlaceChanged={()=>onPlaceChanged(true)}
                        {...(country && {restrictions:{country}})}
                    >
                        <input
                            type='text'
                            defaultValue={cliDireccion}
                          //  onChange={handleInputChange}
                            placeholder={"Escriba o pegue una dirección de whatsapp."}
                            style={inputStyle}
                            onPaste={(e)=>{
                                let paste = (e.clipboardData || window.clipboardData).getData("text");
                                let arr = paste.split("?q=");
                                if(arr.length === 2){
                                    let arrC = arr[1].split(",");
                                    if(arrC.length === 2){

                                        let toAdd = {
                                            cliCoordenadas: arrC[0]+","+arrC[1]
                                        };

                                        coordenateToName({lat:+arrC[0], lng: +arrC[1] }).then(res=>{
                                            toAdd.cliDireccion = res;

                                            handleChange(toAdd);
                                        });

                                      //  setLatLng({lat:+arrC[0], lng: +arrC[1] })
                                    }
                                }

                            }}
                        />
                    </Autocomplete>
                    <Marker
                        draggable
                        position={latLng??defaultCenter}
                        //   icon = "https://drive.google.com/uc?id=0B3RD6FDNxXbdVXRhZHFnV2xaS1E"
                        onDragEnd={handledLatLng}

                        // onRightClick={() => props.onMarkerRightClick(marker)}
                    />
                </GoogleMap>
            </DialogContent>
            {/*<Box sx={{*/}
            {/*    display:"flex",*/}
            {/*    padding: "5px",*/}
            {/*    background: "white",*/}
            {/*    width: "100%",*/}
            {/*    boxSizing: "border-box"*/}
            {/*}}>*/}
            {/*    <Box mr={1}>*/}
            {/*        Lat: <input type="number" value={latLng?.lat ?? ""} onChange={({target})=>setLatLng(prev=>({lng:prev?.lng??0, lat: +target.value}))}/>*/}
            {/*    </Box>*/}
            {/*    <Box>*/}
            {/*        Lng: <input type="number" value={latLng?.lng ?? ""} onChange={({target})=>setLatLng(prev=>({lat:prev?.lat??0, lng: +target.value}))}/>*/}
            {/*    </Box>*/}
            {/*</Box>*/}
            <DialogActions>
                <HtmlTooltip title={Strings.ubicacionActualMjs}>
                    <Button color="primary" onClick={currentLocation}>{Strings.ubicacionActual}</Button>
                </HtmlTooltip>
                <Button color="primary" onClick={()=>setOpenMap(false)}>Ok</Button>
            </DialogActions>
        </Dialog>
    </>:<>
        <TextField
            value={cliDireccion}
            onChange={handleInputChange}
            label={Strings.direccion}
            fullWidth
        />
    </>;
}
export default React.memo(SearchBox)

const coordenateToName = latLng => {
    return new Promise((resolve, reject) => {
        if(typeof(window.google) !== "object") reject("no map window")

        let geocoder = new window.google.maps.Geocoder();

        geocoder?.geocode( { latLng }, function ( result, status ) {
            if (status === 'OK') {
                resolve(result[0].formatted_address);
            } else {
                reject(status);
            }
        });
    });
};

