import React, {useEffect, useState} from 'react';

import {
    Button,
    TextField,
    Box,
    Menu,
    MenuItem,
    Typography,
    Tab,
    Tabs,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Dialog,
    DialogContent,
    Grid,
    DialogActions,
    Switch,
    FormControlLabel,
    IconButton,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {FilterList, MoreVert} from '@mui/icons-material';
import {connect} from "react-redux";
import {eliminarCaja,mostrarCajaById} from '../../redux/actions/cajasActions';
import {useHistory,useLocation,useParams} from "react-router-dom";
import {delCajaCierre, getCajasCierres, getCajasMovimientos} from "../../services";
import {formatDate, formatPeso, getMinMaxDate, hasPermissionTo, PERSMISOS} from "../../helpers";
import ConfirmDialog from "../../components/ConfirmDialog";
import Crear, {ACTIONS} from "./crear";
import {LoadingProgress,MostrarError,AppBar,ButtonResponsive} from "../../components";
import Alert from '@mui/material/Alert';
import Strings from "../../assets/strings";
import {Close} from "@mui/icons-material";
import SelectEnum from "../../components/selects/SelectEnum";

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    tabs: {
        marginBottom: theme.spacing(1),
    },

    content: {
        padding: "0 !important",
    },
    title: {
        flexGrow: 1,
        fontWeight: "normal",
    },
    tapTop:{
        [theme.breakpoints.up('md')]: {
            display: "flex",
            justifyContent: "space-between",
            "&>div:last-child":{
                width: 200,
            }
        }
    },
    top:{
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px dashed #a1a1a1",

    },
    tHead:{
        background:"rgb(237, 247, 237)"
    }
  }));
const initialFilter = {fechaDesde:"",fechaHasta:"",cmoTipo:"",cciId:""}
function CajaDetails(props) {
    const classes = useStyles();
    const history = useHistory();
    const {state:urlState} = useLocation();
    const [showAnulado, setShowAnulado] = useState(false);


    const {cajId} = useParams()
    const [activeTab,setActiveTab] = useState(0);
    const [movFiltered,setMovFiltered] = useState(null);
    const [cieFiltered,setCieFiltered] = useState(null);


    const [loading,setLoading] = useState(false);
    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(initialFilter);

    const [showMenu, setShowMenu] = useState(null);

    const {mostrarCajaById, caja, isFetching, error} = props
    useEffect(() => {
        mostrarCajaById(cajId)
    }, [cajId,mostrarCajaById]);

    // const addFilter = (obj)=>{
    //     return setState(prev=>({...prev,obj}));
    // }

    const filterMov = obj => {
        setLoading(true);
        setFilter(prev=>({...prev,...obj}))
        setOpenFilter(false);
        getCajasMovimientos(cajId,obj).then(res=>{
            setActiveTab(0);
            setMovFiltered(res.data)
        }).finally(()=>setLoading(false))
    }
    const filterCie = obj => {
        setLoading(true);
        setOpenFilter(false);
        let cleanedObj = {...obj};
        delete cleanedObj.cmoTipo;

        getCajasCierres(cajId,cleanedObj).then(res=>{
            setActiveTab(1);
            setCieFiltered(res.data)
        }).finally(()=>setLoading(false))
    }
    const movimientos = () =>{
        let mov = [];
        if(movFiltered){
            mov =  movFiltered;
        }else if(caja && caja.movimientos){
            mov = caja.movimientos
        }
        return mov
    }
    const cierres = () =>{
        let cie = [];
        if(cieFiltered){
            cie =  cieFiltered;
        }else if(caja && caja.cierres){
            cie = caja.cierres
        }
        return cie
    }
    const eliminarCierre = (id) =>{
        delCajaCierre(id).then(()=> mostrarCajaById(cajId))
    }
    function TabPanel(props) {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && <Box p={1}>{children}</Box>}
            </Typography>
        );
    }
    return <>
        <AppBar title={caja.cajDescripcion || "Detalles caja"} >
            <ButtonResponsive icon={<FilterList/>} text={Strings.filtrar} onClick={() => setOpenFilter(!openFilter)}/>
            <ButtonResponsive icon={<MoreVert/>} text={Strings.opciones} onClick={(e)=>setShowMenu(e.currentTarget)}/>
            <Menu anchorEl={showMenu} open={Boolean(showMenu)} onClose={()=>setShowMenu(null)}>
                {hasPermissionTo(PERSMISOS.cajasActualizar) &&
                    <MenuItem onClick={()=> {
                        setShowMenu(null);
                        history.push(history.location.pathname,{action:ACTIONS.AJUSTAR,...caja,movimientos:null,cierres:null})}} >{Strings.ajustar}</MenuItem>
                }
                {hasPermissionTo(PERSMISOS.cajasRealizarCierres) &&
                <MenuItem onClick={()=>{
                    setShowMenu(null);
                    history.push(history.location.pathname,{action:ACTIONS.CERRAR,...caja,movimientos:null,cierres:null})}} >{Strings.cerrar} {Strings.caja}</MenuItem>
                }
                {hasPermissionTo(PERSMISOS.cajasEliminar) &&
                    <MenuItem onClick={() => {
                        setShowMenu(null);
                        history.push(history.location.pathname, {confirmDialog: caja.cajId})
                    }}>{Strings.eliminar}</MenuItem>
                }
            </Menu>
        </AppBar>
        <Dialog open={openFilter} onClose={()=>setOpenFilter(false)} fullWidth maxWidth={"xs"}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <TextField
                            label={Strings.fechaDesde}
                            type="date"
                            value={filter.fechaDesde}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            onChange={({target})=>setFilter({...filter,fechaDesde:target.value})}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={Strings.fechaHasta}
                            type="date"
                            value={filter.fechaHasta}
                            fullWidth
                            InputLabelProps={{shrink: true}}
                            onChange={({target})=>setFilter({...filter, fechaHasta:target.value})}
                        />
                    </Grid>
                    {activeTab === 0 &&
                    <Grid item xs={12}>
                        <SelectEnum value={filter.cmoTipo}
                                    group={"tiposMovimientoCaja"}
                                    label={Strings.tipo}
                                    all
                                    fullWidth
                                    onChange={({target})=>setFilter({...filter, cmoTipo:target.value})}
                        />
                    </Grid>
                    }

                </Grid>
                <br/>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e)=>setOpenFilter(false)}>
                    {Strings.cancelar}
                </Button>
                <Button color="primary" onClick={()=>activeTab === 0 ? filterMov(filter) : filterCie(filter)}>
                    {Strings.filtrar}
                </Button>
            </DialogActions>
        </Dialog>

        <div className="contentInner">
            {(isFetching || loading) && <LoadingProgress vh/>}
            <MostrarError errors={error}/>

            <div className={classes.tapTop}>
                {caja.cierres && caja.cierres.length > 0 ?(
                    <Tabs
                        value={activeTab}
                        onChange={(e,v)=>setActiveTab(v)}
                        className={classes.tabs}
                        //  variant="fullWidth"
                    >
                        <Tab  value={0} label={Strings.movimientos} />
                        <Tab  value={1} label={Strings.cierres}  />
                    </Tabs>
                ):(<div/>)

                }
                <div className={classes.top}>
                    <span>{Strings.balance}: </span>
                    <span>{formatPeso(caja.cajBalance)}</span>
                </div>
            </div>

            <TabPanel value={activeTab} index={0}>
                {!!movFiltered &&
                    <Alert icon={false} onClose={() => {
                        setMovFiltered(null);
                        setFilter(initialFilter)
                    }}>
                        {Strings.movimientos}: {getMinMaxDate(movFiltered)}
                    </Alert>
                }

                <TableContainer>
                    <Table sx={{minWidth: 743}} size={"small"} aria-label="simple table">
                        <TableHead className={!!movFiltered ? classes.tHead : ""}>
                            <TableRow>
                                <TableCell>{Strings.tipo} {Strings.movimientos}</TableCell>
                                <TableCell>{Strings.fecha}</TableCell>
                                <TableCell>{Strings.creadoPor}</TableCell>
                                <TableCell>{Strings.monto}</TableCell>
                                <TableCell className="comment">{Strings.comentario}</TableCell>
                                <TableCell sx={{maxWidth:100,textAlign:"right"}}>
                                    <FormControlLabel className="d-print-none" sx={{margin: 0}}
                                                      control={
                                                          <Switch
                                                              size={"small"}
                                                              checked={showAnulado}
                                                              onChange={()=>setShowAnulado(!showAnulado)}
                                                              value="checkedB"
                                                          />
                                                      }
                                                      label={Strings.anulado}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {movimientos().map((row,i) => {
                                if(!row.fechaAnulacion || showAnulado ){
                                    return ( <React.Fragment key={i}>
                                    <TableRow className={row.fechaAnulacion ? "text-red-deep" : ""}>
                                        <TableCell>{Strings[row.tipoString]}</TableCell>
                                        <TableCell>{formatDate(row.fecha)}</TableCell>
                                        <TableCell>{row.creadoPor}</TableCell>
                                        <TableCell>{formatPeso(row.monto)}</TableCell>
                                        <TableCell className="comment" colSpan={!row.fechaAnulacion ? 2 : 1}>{row.concepto}</TableCell>
                                        {row.fechaAnulacion && <TableCell className={"alignRight"}> {formatDate(row.fechaAnulacion)}</TableCell>}
                                    </TableRow>
                                </React.Fragment>);
                                }else{return false}
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
                {!!cieFiltered &&
                <Alert icon={false} onClose={() => setCieFiltered(null)}>
                    Cierres: {getMinMaxDate(cieFiltered)}
                </Alert>
                }

                <TableContainer>
                    <Table sx={{minWidth: 743}} size={"small"} className="hasOnclick">
                        <TableHead className={!!cieFiltered ? classes.tHead : ""}>
                            <TableRow>
                                <TableCell/>
                                <TableCell>{Strings.fecha}</TableCell>
                                <TableCell>{Strings.creadoPor}</TableCell>
                                <TableCell>{Strings.monto}</TableCell>
                                <TableCell className="comment">{Strings.comentario}</TableCell>
                                <TableCell sx={{maxWidth:100,textAlign:"right"}}>
                                    <FormControlLabel className="d-print-none" sx={{margin: 0}}
                                                      control={
                                                          <Switch
                                                              size={"small"}
                                                              checked={showAnulado}
                                                              onChange={()=>setShowAnulado(!showAnulado)}
                                                              value="checkedB"
                                                          />
                                                      }
                                                      label={Strings.anulado}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>

                            {cierres().map((row, i) => {
                                if(!row.fechaAnulacion || showAnulado ){
                                    return (
                                        <TableRow key={i} className={row.fechaAnulacion ? "text-red-deep" : ""}
                                                  onClick={() => filterMov({cciId: row.cciId})}>

                                            <TableCell className={"actionCell"}>
                                                <IconButton
                                                    onClick={(e)=>{e.stopPropagation(); eliminarCierre(row.cciId)}}
                                                    size="large">
                                                    <Close className={"text-red"}/>
                                                </IconButton>
                                            </TableCell>

                                            <TableCell>{formatDate(row.fecha)}</TableCell>
                                            <TableCell>{row.creadoPor}</TableCell>
                                            <TableCell>{formatPeso(row.monto)}</TableCell>
                                            <TableCell className="comment" colSpan={!row.fechaAnulacion ? 2 : 1}>{row.nota}</TableCell>
                                            {row.fechaAnulacion && <TableCell className={"alignRight"}> {formatDate(row.fechaAnulacion)}</TableCell>}
                                        </TableRow>
                                    );
                                        }else{return false}
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </TabPanel>
        </div>
        <Crear/>
        <ConfirmDialog onConfirm={()=>props.eliminarCaja(urlState && urlState.confirmDialog)}/>

    </>;
}
const mapStateToProps = (state) => ({
    caja: state.cajas.byId,
    error: state.cajas.error,
    isFetching: state.cajas.isFetching,
})
export default connect(mapStateToProps, {eliminarCaja,mostrarCajaById})(CajaDetails);
