import * as React from 'react';
import {useSelector} from 'react-redux';
import { TextField, Grid, Button, CircularProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Alert from '@mui/material/Alert';

import {WhatsApp, YouTube,Email,Instagram} from "@mui/icons-material";
import {useState} from "react";
import Strings from "../../assets/strings";
import {API} from "../../services";
import {isMobile} from "../../helpers/browser_detect";

const useStyles = makeStyles(theme => ({
    root:{
        padding:theme.spacing(2),
        maxWidth: 1100,
        margin: "0 auto",
        marginTop: 25,
    },
    contForm:{
        borderRight: "1px solid #dedddd",
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('md')]: {
            borderRight: "none",
            paddingRight: 0,
            borderBottom: "1px solid #dedddd",
        },
    },
    head:{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        textAlign:"center",
        boxShadow: "0px 2px 5px rgb(0 0 0 / 10%) inset",
        "& h2":{
            margin:0,
            marginBottom: 4,
            paddingTop:theme.spacing(2)
        },
        "& p":{
            margin: 0,
            paddingBottom:theme.spacing(2)
        }
    },
    links:{
        "& svg":{
          marginRight:5,
        },
        "& a":{
            marginBottom:8,
            color: theme.palette.primary.main,
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
        }
    },
    table:{
        "& tr":{
            height: 30,
            "& .tick":{
                color: "#2CA01C",
            },
            "& .text-danger": {
                color: "#dc3545",
            }
        },
        "& tbody th":{
            textAlign: "left",
            fontWeight: "normal",
            paddingLeft: 10,
        },
        "& td":{
            textAlign: "center",
        }
    }
}));

export default function Index() {
    const classes = useStyles();
    const [state, setState] = useState({uscConcepto:"",uscContacto:"",uscComentario:""});
    const [msjSent, setMsjSent] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const {user} = useSelector(state => state.auth);

    const handleChange = (e)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = (e)=>{
        e.preventDefault();
        setLoading(true);
        setError(false);
        API.post("ayuda",state).then(()=>{
            setMsjSent(true);
        }).catch(()=>{
            setError(true)
        }).finally(()=>{
            setLoading(false);
        })
    }

    return (
        <>
            <div className={classes.head}>
                <h2>Ayuda / FeedBack</h2>
                <p>Tomamos muy en serio las necesidades de nuestro cliente, danos tu opinión y ayúdanos a mejorar.</p>
            </div>
            <section className={classes.root}>
              <div>
                      <Grid container spacing={3}>
                          <Grid item xs={12} md={6} className={classes.contForm}>
                              <p>Formulario de contacto:</p>
                              {!!error && <Alert onClose={()=>setError(false)} sx={{marginBottom:16}} severity={"error"}>
                                  Se ha prodicido un error, intentelo mas tarde.</Alert>}
                              {!!msjSent?(
                                  <Alert severity="success">
                                      Mensaje enviado, Gracias.
                                  </Alert>
                              ):(
                                  <form onSubmit={submit}>
                                      <Grid container spacing={3}>
                                          <Grid item xs={12} sm={6}>
                                              <TextField fullWidth
                                                         onChange={handleChange}
                                                         name="uscConcepto"
                                                         required
                                                         value={state.uscConcepto}
                                                         label={!user.usuInicioSesion ? Strings.usuario :Strings.concepto}
                                              />
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                              <TextField fullWidth
                                                         label={"Tel. / E-mail"}
                                                         value={state.uscContacto}
                                                         onChange={handleChange}
                                                         name={"uscContacto"}
                                                         required
                                              />
                                          </Grid>

                                          <Grid item xs={12}>
                                              <TextField
                                                  fullWidth
                                                  label={Strings.comentario}
                                                  multiline
                                                  rows={4}
                                                  value={state.uscComentario}
                                                  onChange={handleChange}
                                                  name={"uscComentario"}
                                                  required
                                              />
                                          </Grid>
                                      </Grid>
                                      <Button type={"submit"} disabled={loading} color={"primary"}>
                                          {loading? (
                                              <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                                          ):(
                                              <span>{Strings.enviar}</span>
                                          )}
                                      </Button>
                                  </form>
                              )}
                          </Grid>
                          <Grid item xs={12} md={6}>
                              <div className={classes.links}>
                                  <Grid container spacing={3}>
                                      <Grid item xs={6} >
                                          <p>Enlaces de interés</p>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"http://instagram.com/dsegura_srl/"}
                                          >
                                              <Instagram />
                                              Instagram
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://www.youtube.com/channel/UC169EbwYwjpoCOC_SXkCMKg"}
                                          >
                                              <YouTube />
                                              Video Tutoriales
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={`https://wa.me/18098538622?text=${Strings.hola}, soy ${user.usuNombres} ${user.usuApellidos} mi usuario es ${user.usuInicioSesion}. `}
                                          >
                                              <WhatsApp />
                                              Chat vía WhatApp
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"mailto:segura12s@gmail.com,deibisoto12@gmail.com"}
                                          >
                                              <Email />
                                              E-mail
                                          </a>
                                          {/*<a href={"/"} onClick={(e)=>{setOpenDGPlan(true); e.preventDefault()}}>*/}
                                          {/*      <AccountBalanceWalletOutlined/>*/}
                                          {/*    {Strings.ver} {Strings.precios}</a>*/}

                                      </Grid>
                                      {!isMobile.iOS() &&
                                      <Grid item xs={6} >

                                          <p>Disponible desde:</p>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://app.prestamistapp.com/"}
                                          >
                                              Web
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://play.google.com/store/apps/details?id=com.dsegura.prestamistapp2"}
                                          >
                                              Google play / Android
                                          </a>
                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://apps.apple.com/app/id1526690162"}
                                          >
                                              App store / IOS
                                          </a>

                                          <a
                                              target={"_blank"}
                                              rel="noopener noreferrer"
                                              href={"https://prestamistapp.com/"}
                                          >
                                              Más información...
                                          </a>
                                      </Grid>
                                      }
                                  </Grid>
                              </div>
                          </Grid>
                      </Grid>
              </div>
            </section>
        </>
    );
}

