import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { mostrarAgentes} from '../../redux/actions/agentesActions';
import {
    Table, TableBody, TableCell, TableHead, TableContainer, TableRow, Button, useMediaQuery, List,
    ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Divider
} from "@mui/material";
import Strings from "../../assets/strings"
import {LoadingProgress, Empty, PlusFab, AppBar, SeacrhAppBar} from "../../components";
import Crear from "./crear";
import {useHistory} from "react-router-dom";
import {formatDate, noTilde} from "../../helpers";
import {ReactComponent as EmptySvg} from "../../assets/img/collaborators.svg";

function  Agentes(props) {
    let history = useHistory();
    const [searchBox, setSearchBox] = useState("");

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const { mostrarAgentes } = props
    useEffect(() => {
        mostrarAgentes();
    }, [mostrarAgentes])
    const { title: mainTitle, agentes: { list, isFetching } } = props;
    const isEmpty = list.length === 0;
    const filterItems = () => {
        return list.filter(item=> noTilde(item.usuNombres+" "+item.usuApellidos).indexOf(noTilde(searchBox)) !== -1);
    }
    return(
        <>
            <AppBar title={Strings[mainTitle]}>
                <SeacrhAppBar name="search" value={searchBox} onChange={({target})=>setSearchBox(target.value)}/>
            </AppBar>
            <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
                {isFetching && isEmpty && <LoadingProgress vh/>}

                {isEmpty && !isFetching ? (
                    <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.agentes}>
                        <p>{Strings.agentesHelpMsj}</p>
                    </Empty>
                ):(
                    smVewport ? (
                        <List>
                            {filterItems().map(agente => (
                                <React.Fragment key={agente.usuId}>
                                    <ListItem button alignItems="flex-start" onClick={()=>history.push(history.location.pathname,agente)} >
                                        <ListItemAvatar>
                                            <Avatar>{agente.usuNombres?.charAt(0)?.toUpperCase()}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText secondary={agente.rol}>
                                            <Typography noWrap className={(agente.usuEstatus !== 1) ? "text-red":""}>
                                                {agente.usuNombres + " " + agente.usuApellidos}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider variant="inset" component="li"/>
                                </React.Fragment>
                            ))}
                        </List>
                    ) : (
                        <TableContainer>
                            <Table aria-label="simple table" className={"hasOnclick"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{Strings.nombreDeUsuario}</TableCell>
                                        <TableCell>{Strings.primerNombre}</TableCell>
                                        <TableCell>{Strings.apellidos}</TableCell>
                                        <TableCell>{Strings.email}</TableCell>
                                        <TableCell>{Strings.telefono}</TableCell>
                                        <TableCell>{Strings.caja}</TableCell>
                                        <TableCell>{Strings.rol}</TableCell>
                                        <TableCell>{Strings.actualización}</TableCell>
                                        <TableCell>{Strings.estado}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filterItems().map(item =>
                                        <TableRow key={item.usuId} className={(item.usuEstatus !== 1) ? "text-red-deep":""}
                                                  onClick={()=>history.push(history.location.pathname,item)}>
                                            <TableCell> {item.usuInicioSesion }</TableCell>
                                            <TableCell> {item.usuNombres}</TableCell>
                                            <TableCell> {item.usuApellidos}</TableCell>
                                            <TableCell> {item.usuEmail}</TableCell>
                                            <TableCell> {item.usuTelefono}</TableCell>
                                            <TableCell> {item.caja}</TableCell>
                                            <TableCell> {item.rol}</TableCell>
                                            <TableCell> {formatDate(item.usuFechaActualizacion)}</TableCell>
                                            <TableCell> {Strings[item.usuEstatusString.toLowerCase()]}</TableCell>
                                        </TableRow>
                                    )}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    )
                )}
                <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{})} />
                <Crear/>
            </section>
        </>
    )


}


const mapStateToProps = (state) => ({
    agentes: state.agentes
})
export default connect(mapStateToProps, { mostrarAgentes })(Agentes);
