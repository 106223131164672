import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {mostrarPrestamoById} from "../../redux/actions/prestamoActions"
import {useHistory, useLocation} from "react-router-dom";
import {postPagoInicial} from "../../services"
import {Button, Dialog, DialogActions, DialogContent, Grid, TextField, useMediaQuery} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {DateInput, TextFieldCurrency} from "../../components/CustomTextField"
import {getUsuTipo, hasPermissionTo, PERSMISOS, TIPOUSUARIO} from "../../helpers";
import {LoadingProgress, MostrarError, TopBarDialog} from "../../components";
import {SelectCaja, SelectEnum, SelectFiles} from "../../components/selects";
import Strings from "../../assets/strings";
import defaultImg from "../../assets/img/reciboImg.jpg";


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    [theme.breakpoints.up('sm')]: {
      maxWidth:540,
      margin:"0px auto",
    },
    [theme.breakpoints.only('xs')]: {
      "& .MuiInput-input":{
        position: "relative",
        padding: 7,
        border: "1px solid #e6e6e6",
      },
      "& .MuiInputLabel-formControl:not(.MuiInputLabel-shrink)":{
        left:theme.spacing(1),

      }
    }
  },
  content:{
    padding:theme.spacing(2),
  },
  montoInput:{
    background: "#f3f3f3",
    padding: theme.spacing(2),
    textAlign: "center",
    marginBottom: theme.spacing(2),
    "& .MuiInput-root":{
      margin:"0px !important",
    },
    "& input": {
      fontSize: "1em",
      color:theme.palette.primary.main,
      textAlign: "center",
      background: theme.palette.common.white,

      "&:disabled":{
        background: theme.palette.grey[100]
      }
    }
  },
  alertText:{
    background:theme.palette.secondary.light,
    padding: "5px "+ theme.spacing(2),
    textAlign: "center",
    fontSize: ".9rem",
    color:theme.palette.secondary.contrastText,
  },
  dialogActions:{
    justifyContent: "space-between",
    background: "#f8f8f8",
  },
  authMask:{ position: "absolute", height: "calc(100% - 16px)", width: "calc(100% - 16px)", zIndex: 1, cursor: "pointer" }
}));
let initialState = {
  preId:0,
  pagMonto: 0,
  pagNota: "",
  pagFecha: "",
  cajId: "",
  autPin:"",
  pagAdjunto:""
};
function AddPagoInicial({prestamo,mostrarPrestamoById,defaultCajId}){
  let history = useHistory();
  const {state:hState={}} = useLocation();

  const classes = useStyles();
  const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));

  const [state, setState] = useState(initialState);

  const [isPending, setIsPending] = useState(false);

  const [error, setError] = useState(null);

  useEffect(()=>{
    if (prestamo.preId) {
      let obj={
         preId:prestamo.preId,
         pagMonto:prestamo.preInicial,
         pagFecha:prestamo.currentDate
      };

      //setear la caja por defecto al pagar, si tiene permiso
      let dCajId = "";
      if (getUsuTipo() !== TIPOUSUARIO.agente){ //titutar o admin
        dCajId = defaultCajId || prestamo.cajId || ""
      }else{
          if(defaultCajId === -1 || defaultCajId === prestamo.cajId){
            dCajId = prestamo.cajId;
          }else{
            dCajId = defaultCajId
          }
      }

      setState(prevState => ({...prevState,cajId: dCajId, ...obj}));
    }
  },[prestamo, setState,defaultCajId]);

  const handleChange = e => {
    const {value,name} = e.target;
    if(name === "pagFecha") return setState({...state,pagFecha: value+""+prestamo.currentDate?.substring(10)});

    setState({...state,[name]:value});
  };

  const submit = (e) => {
    e.preventDefault();
    setIsPending(true);
    setError(null);
    let data = {
      preId: state.preId,
      pagFecha: state.pagFecha,
      pagMonto: state.pagMonto,
      cajId: state.cajId,
      autPin: state.autPin,
      pagNota: state.pagNota,
      pagAdjunto: state.pagAdjunto
    };

    //no enviar adjunto si es el mismo
    if(hState?.modalPagoInicial.pagAdjunto === data.pagAdjunto){
      delete data.pagAdjunto
    }

    postPagoInicial(data)
    .then(({data})=>{
      setIsPending(false);
      mostrarPrestamoById(prestamo.preId);
      history.replace(history.location.pathname,{recibo:data})
    }).catch(({response})=>{
      setError(response.data);
      setIsPending(false)
    })
  };
  return (
      <Dialog className={classes.root} fullWidth={true} maxWidth={"sm"} fullScreen={smVewport} open={true} onClose={history.goBack}>
        <TopBarDialog title={Strings.pago+" "+Strings.inicial} nofullScreen onClose={history.goBack}></TopBarDialog>

        <MostrarError errors={error}/>

        <DialogContent className={classes.content}>
          {isPending && <LoadingProgress vh={false} />}
          <Grid container spacing={3} style={{position:"relative"}}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} sx={{mb:2}}>
              <DateInput
                  name="pagFecha"
                  value={state.pagFecha}
                  margin={"none"}
                  onChange={handleChange}
                  disabled={!hasPermissionTo(PERSMISOS.pagosCambiarFecha)}
              />
            </Grid>
          </Grid>
          <form onSubmit={submit} id={"formMonto"}>
            <div className={classes.montoInput}>
              <TextFieldCurrency id="pagMonto" fullWidth name="pagMonto" min={1} value={state.pagMonto}
                                 onChange={handleChange}/>
            </div>
          </form>
          <Grid container spacing={3}>
            {/*{solAuthModal &&*/}
            {/*<Grid item xs={12}>*/}
            {/*  <TextField label={Strings.autorizacion} placeholder={"ID"} name="autPin" value={state.autPin} onChange={handleChange} fullWidth/>*/}
            {/*</Grid>}*/}
            <Grid item xs={6}>
              <div className="form-group">
                <SelectEnum label={Strings.formaPago} group="formaPago" name="pagFormaPago" required value={state.pagFormaPago} onChange={handleChange}/>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="form-group">
                <SelectCaja label={Strings.caja} name="cajId" value={state.cajId === -1 ? "": state.cajId} onChange={handleChange}/>
              </div>
            </Grid>
          </Grid>
          <div className="form-group">
            <TextField label={Strings.comentario} name="pagNota" value={state.pagNota}
                       onChange={handleChange}
                       inputProps={{maxLength: 256}}
                       margin="normal" fullWidth/>
          </div>
              <Grid item xs={12} >
                <SelectFiles showImg={false} src={state.pagAdjunto??null} onChange={(baseb4)=>setState({...state, pagAdjunto: baseb4})} defaultImgProp={defaultImg}/>
              </Grid>
        </DialogContent>
        {/*{state.pagMonto >= prestamo.preCapitalActual &&*/}
        {/*  <div className={classes.alertText}>{Strings.pagoCancelaMsj}</div>*/}
        {/*}*/}

        <DialogActions className={classes.dialogActions}>
          <Button color="primary" sx={{ml:"auto"}} type={"submit"} form={"formMonto"} disabled={isPending}>{Strings.agregar} {Strings.pago}</Button>
        </DialogActions>
      </Dialog>
  );
}
const mapStateToprops = (state) => ({
  prestamo:state.prestamos.byId,
  defaultCajId: state.auth.user.cajId || "",
  rolId: state.auth.user.rolId || "",
});
export default connect(mapStateToprops,{mostrarPrestamoById})(AddPagoInicial);
