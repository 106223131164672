import React from 'react';
import {ButtonBase, Paper} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
  table:{
    width:"100%",
    "& tr td:nth-child(2)":{
      textAlign: "right",
    },
    "& tr":{
      background: "transparent !important",
    }
  },
  root: {
    margin:theme.spacing(1),
    display: "block",
    "& .content":{
      padding:theme.spacing(1)
    }
  },
  rootNoGap: {
    boxShadow: "none",
    display: "block",
    borderBottom: "1px solid" +theme.palette.grey[300],
    "&:hover":{
      background: theme.palette.grey[100],
    },
    "& .content":{
      padding:theme.spacing(1)
    }
  },
  divider:{
    margin: theme.spacing(1) +" 0px",
  },


}));
export default function CustomListItem({lt,lb,rt,rb,onClick,noGap,bg,children}) {
  const classes = useStyles();
  return (
      <>
            <ButtonBase component={Paper} className={noGap?classes.rootNoGap:classes.root} onClick={onClick}
              {...((bg) && {
                style: {background:bg}
              })}
            >
              <div className="content" >
                {children}

                <table className={classes.table +" wrap"}>
                  <tbody>
                    <tr>
                      <td>
                        {lt && <div>{lt}</div>}
                        {lb && <div>{lb}</div>}
                      </td>
                      <td>
                        {rt && <div>{rt}</div>}
                        {rb && <div>{rb}</div>}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ButtonBase>

      </>
  )
}
