import React, {useState, useEffect, useCallback} from 'react';
import {Button, Dialog, DialogContent, TextField, DialogActions, Grid, useMediaQuery, IconButton, Menu, MenuItem
} from "@mui/material";
import {connect} from "react-redux";
import {agregarEditarAgente,toggleLock,eliminarAgente,editarContrasena} from "../../redux/actions/agentesActions";
import {resetRedux} from "../../redux/actions/sharedActions";
import Strings from "../../assets/strings"
import {SelectCaja,SelectRutas,SelectRol} from "../../components/selects";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {SlideLeft} from "../../components/transitions";
import {MoreVert} from "@mui/icons-material";
import {useHistory,useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {MostrarError,LoadingProgress,ConfirmDialog} from "../../components";
import {isMobile} from "../../helpers/browser_detect";
import {TextFieldPhone} from "../../components/CustomTextField";

const initialState={
    usuInicioSesion: "",
    usuClave: "",
    usuClave2:"",
    usuNombres: "",
    usuApellidos: "",
    usuEmail: "",
    usuTelefono: "",
    rolId: "",
    usuUIColor: "",
    cajId: "",
    usuRutas: "",
};
function Crear(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const [showMenu, setShowMenu] = useState(null);
    const [showFormPass, setShowFormPass] = useState(false);
    const [errorPass, setErrorPass] = useState("");

    const {fulfilled,resetRedux,eliminarAgente} = props;

    const [state, setState] = useState(initialState);
    const resetBack = useCallback(
        () => {
            setErrorPass("");
            setShowFormPass(false);
            resetRedux();
            goBack();
        },
        [setShowFormPass,goBack,resetRedux],
    );
    useEffect(() => {
        if(urlState)
            setState(state=>({...state,...nullToEmptyString(urlState)}));
        else
            setState(initialState);

        if(fulfilled) resetBack() ;

    }, [setState, urlState,fulfilled,resetBack]);
    const eliminar  = () =>{
        eliminarAgente(state.usuId)
    };
    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const handleSubmit = e => {
        e.preventDefault();
        setErrorPass("");
        let usuClave = null;
        if(showFormPass || !state.usuId){
            usuClave = state.usuClave;
            if(usuClave !== state.usuClave2){
                return setErrorPass(Strings.contrasenaNoMatchMjs)
            }
        }
        if(showFormPass)
            props.editarContrasena({usuId:state.usuId, usuPassword:usuClave, usuName:state.usuNombres});
        else
            props.agregarEditarAgente({...state,usuClave, usuClave2:null,});

    };

    const url = `https://prestamistapp.com/solicitar/${state.usuInicioSesion}`;

    return (
        <>
            <Dialog open={Boolean(urlState)} onClose={resetBack}
                    {...(smVewport ? {TransitionComponent: SlideLeft}:{})}
                    fullScreen={smVewport}>

                <TopBarDialog title={Strings.agente} onClose={resetBack}>
                    {!showFormPass && !!state.usuId &&
                        <>
                    <IconButton size={"small"}
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={(e)=>setShowMenu(e.currentTarget)}
                                color="inherit"
                    >
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={showMenu}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(showMenu)}
                        onClose={()=>setShowMenu(null)}
                    >
                        <MenuItem onClick={()=>{setShowMenu(null);props.toggleLock(state.usuId)}}>{state.usuEstatus === 1 ? Strings.bloquear:Strings.desbloquear}</MenuItem>
                        <MenuItem onClick={()=>{setShowMenu(null);setShowFormPass(true)}}>{Strings.contrasena}</MenuItem>
                        <MenuItem onClick={()=>{
                            setShowMenu(null);
                            history.push(history.location.pathname,{...state,confirmDialog:true})}}>{Strings.eliminar}</MenuItem>
                        <MenuItem type={"button"}
                                  onClick={()=>{
                                      if(isMobile.AndroidWebView())
                                          window.Android.shareLink(url);
                                      else if(isMobile.IosWebView())
                                          window.webkit.messageHandlers.shareLink.postMessage(url)
                                      else
                                          window.open(url,'_blank');
                                  }
                                  }>
                            {Strings.enlace} {Strings.solicitud}
                        </MenuItem>
                    </Menu></>}
                </TopBarDialog>
                    <DialogContent>
                        <MostrarError errors={errorPass?errorPass:props.error}/>
                        {props.isPending && <LoadingProgress/>}
                        <br/>
                        <form id="form1" onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                {!showFormPass && <>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuInicioSesion"
                                        value={state.usuInicioSesion}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        label={Strings.nombreDeUsuario}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuNombres"
                                        value={state.usuNombres}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        label={Strings.primerNombre}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuApellidos"
                                        value={state.usuApellidos}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        required
                                        label={Strings.apellidos}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        name="usuEmail"
                                        value={state.usuEmail}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 50}}
                                        label={Strings.email}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldPhone
                                        name="usuTelefono"
                                        value={state.usuTelefono}
                                        onChange={handleChange}
                                        inputProps={{maxLength: 20}}
                                        required
                                        label={Strings.telefono}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectCaja
                                        name="cajId"
                                        value={state.cajId}
                                        onChange={handleChange}
                                      //  allowNone={false}
                                        allowAll
                                        label={Strings.caja}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectRol
                                        name="rolId"
                                        value={state.rolId}
                                        required
                                        onChange={handleChange}
                                        label={Strings.rol}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectRutas
                                        name="usuRutas"
                                        value={state.usuRutas}
                                        onChange={handleChange}
                                    />
                                </Grid></>}
                                {(showFormPass || !state.usuId) &&
                                    <>
                                    <Grid item xs={6}>
                                        <TextField
                                            name="usuClave"
                                            value={state.usuClave}
                                            onChange={handleChange}
                                            inputProps={{maxLength: 50}}
                                            required
                                            label={Strings.contrasena}
                                            type="password"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            name="usuClave2"
                                            value={state.usuClave2}
                                            onChange={handleChange}
                                            required
                                            label={Strings.confirmar+" "+Strings.contrasena}
                                            type="password"
                                            fullWidth
                                        />
                                    </Grid>
                                    </>
                                }
                            </Grid>
                        </form>
                        <br/>
                    </DialogContent>
                    <DialogActions>
                        {showFormPass && <Button onClick={()=>setShowFormPass(false)} color="primary">{Strings.cancelar}</Button>}

                        <Button type={"submit"} form={"form1"}  color="primary" disabled={props.isPending}>
                            {!!state.usuId ? Strings.actualizar : Strings.guardar}
                        </Button>
                    </DialogActions>

            </Dialog>
            <ConfirmDialog onConfirm={eliminar}/>

        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.agentes.isPending,
    error: state.agentes.error,
    fulfilled: state.agentes.fulfilled
});
export default connect(mapStateToProps, { agregarEditarAgente,toggleLock,eliminarAgente,editarContrasena,resetRedux })(Crear);
