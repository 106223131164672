import React, {useState, useEffect} from 'react';
import {agregarEditarRuta} from "../../redux/actions/rutasActions";
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid, SvgIcon,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {useHistory, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {connect} from "react-redux";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"
import {colors} from "../../assets/colors";
import {iconPath} from "../../assets/iconPath";
const useStyles = makeStyles(theme => ({

    root:{
        padding:theme.spacing(2),
    },
    divColor:{
        width:"100%",
        padding: "1px 7px",
        color: "white",
    },
    divIcon:{
        "&>div":{
            padding: "4px 5px !important",
            paddingRight: "16px !important",
            textAlign: "center !important",
            height: "29px  !important",
            [theme.breakpoints.down('md')]: {
                height: "26px  !important",
            },
        }
    }
}));

const initialState = {
    rutId: null, rutDescripcion: "", rutColor: "", rutIcon:""
};
function Crear(props) {
    const classes = useStyles();

    const {goBack} = useHistory();
    const {state:urlState={}} = useLocation();


    const [state, setState] = useState(initialState);
    const {isPending} = props;

    useEffect(() => {
        if(!!urlState && urlState.rutId) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = e => {
        e.preventDefault();
        props.agregarEditarRuta(state)
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("rutId"))} onClose={goBack} maxWidth={"xs"} fullWidth={true}>
                    <DialogContent>
                        {isPending && <LoadingProgress />}
                        <form onSubmit={handleSubmit} id="form1">
                            <TextField
                                value={state.rutDescripcion}
                                onChange={(e) => setState({ ...state, rutDescripcion: e.target.value })}
                                inputProps={{maxLength: 50}}
                                autoFocus
                                autoComplete={"off"}
                                required
                                label={Strings.descripcion}
                                type="text"
                                fullWidth
                            />
                            <Grid container spacing={1}>
                                <Grid item xs={9}>
                                    <FormControl fullWidth margin={"normal"}>
                                        <InputLabel id="usuUIColor">Color</InputLabel>
                                        <Select
                                            labelId="usuUIColor"
                                            name="usuUIColor"
                                            value={state.rutColor}
                                            onChange={(e) => setState({ ...state, rutColor: e.target.value })}

                                        >
                                            {colors.map(c=>

                                                <MenuItem key={c.primary.main} value={c.primary.main}>
                                                    <div className={classes.divColor} style={{background: c.primary.main}}>{c.primary.main}</div>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth margin={"normal"} >
                                        <InputLabel id="rutIcon">&nbsp;</InputLabel>
                                        <Select
                                            labelId="rutIcon"
                                            name="rutIcon"
                                            value={state.rutIcon}
                                            onChange={(e) => setState({ ...state, rutIcon: e.target.value })}
                                            className={classes.divIcon}
                                        >
                                            {iconPath.map(i=>

                                                <MenuItem key={i.name} value={i.name}>
                                                        <SvgIcon className="MuiSvgIcon-root jss67"
                                                                 sx={{ color: state.rutColor }}
                                                             viewBox="0 0 24 24" aria-hidden="true" tabIndex="-1"
                                                             data-ga-event-action="click">
                                                            <path
                                                                d={i.path}></path>
                                                        </SvgIcon>
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>



                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={goBack} color="primary">{Strings.cancelar}</Button>
                        <Button type="submit" form={"form1"} color="primary" disabled={isPending}>
                            {!!state.rutId ? Strings.actualizar : Strings.guardar}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.rutas.isPending,
});
export default connect(mapStateToProps, { agregarEditarRuta })(Crear);
