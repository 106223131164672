import {pagoTypes, prestamoTypes} from './types';
import {getPagos, deletePago, getPrestamos} from '../../services';

export function mostrarPagos(filter) {
    return (dispatch,getState) => {
        const pagos = getState().pagos;
        let paramsFilter = filter || pagos.filtered;

        dispatch({
            type: pagoTypes.MOSTRAR_PAGOS,
            payload: getPagos(paramsFilter)
        })
    }
}
export function anularPago(preId,pagId) {
    return (dispatch) => {
        dispatch({
            type: pagoTypes.ANULAR_PAGO,
            payload: deletePago(preId,pagId)
        }).then(() => {
            dispatch({
                type: prestamoTypes.MOSTRAR_PRESTAMO_POR_ID,
                payload: getPrestamos(parseInt(preId))
            })
        })
    }
}
