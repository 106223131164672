import React from 'react';
import { connect } from 'react-redux';
import {Button, Hidden, Typography, Box, ButtonGroup} from '@mui/material';
import {toggleViewTable} from "../redux/actions/themeActions";
import {FormatListBulleted, ListAlt} from "@mui/icons-material";
import Strings from "../assets/strings";
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
    root:{
        width: "100%",
        height: "33px",
        margin: "0",
        display: "flex",
        padding: "0px 16px",
        boxSizing: "border-box",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#f6f6f6",
        [theme.breakpoints.up('md')]: {
            marginTop: "65px",
        }
    }
}));


function ListViewMode({lg,noView,viewTableList,toggleViewTable,pageRows,pageTotalRows,children}){
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <Typography variant={"body2"}>{!!pageRows && <>{pageRows} {Strings.de} {pageTotalRows}</>}</Typography>
           <Box alignItems={"center"} display={"flex"}>
               {children}
               {!noView &&
                   <Hidden mdUp={!lg}>
                       <ButtonGroup size="small" aria-label="small outlined button group">
                           <Button sx={{marginLeft: 5}} color={viewTableList ? "primary":"inherit"}  onClick={()=> !viewTableList && toggleViewTable()}><FormatListBulleted fontSize={"small"}/></Button>
                           <Button color={!viewTableList ? "primary":"inherit"}  onClick={()=>viewTableList && toggleViewTable()} ><ListAlt fontSize={"small"}/></Button>
                       </ButtonGroup>
                   </Hidden>
               }

           </Box>


        </div>)
}
const mapStateToprops = (state) => ({
  viewTableList: state.theme.viewTableList
})
export default connect(mapStateToprops,{toggleViewTable})(ListViewMode);

