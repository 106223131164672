import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {formatPeso} from "../../helpers";
import {useDispatch,useSelector} from "react-redux";
import Strings from "../../assets/strings";
import {mostrarWidgetsGasIng} from "../../redux/actions/dashBoardActions"
import {useEnums} from "../../hooks/useEnums";
import HtmlTooltip from "../HtmlTooltip";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "14px 5px",
        background: "white",
        border: "1px solid" + theme.palette.grey[300],
        marginBottom: "10px",
        textAlign: "center",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& > div": {
            position: "relative",
            flexGrow: 3,
            //width: "50%",
            // "&:first-child:after":{
            //     content:"''",
            //     position: "absolute",
            //     top: "0",
            //     right: "0",
            //     height: "100%",
            //     borderRight: "1px solid" + theme.palette.grey[300],
            // },
            "& label":{
                color:theme.palette.text.secondary,
                display:"block"
            },
            "&:nth-child(2)":{
                flexGrow: 1,
                "& label":{
                    border: "1px solid" + theme.palette.grey[300],
                    borderTop: "none",
                    borderBottom: "none",
                    position: "relative",

                    "& span":{
                        background: theme.palette.common.white,
                        padding: "1px 3px",
                        textTransform: "capitalize",
                    },
                    "& select":{
                        cursor: "pointer",
                        position: "absolute",
                        left: "0",
                        width: "100%",
                        opacity: 0
                    }
                },
                "&:before":{
                    top: "9px",
                    right: "0",
                    height: "1px",
                    width: "100%",
                    content: "''",
                    position: "absolute",
                    background: theme.palette.grey[300],
                },
            },
        },
        // "& select":{
        //     WebkitAppearance: "none",
        //     MozAppearance: "none",
        //     appearance: "none",
        //     position: "absolute",
        //     top: "-11px",
        //     left: "0",
        //     border: "none",
        //     outline: "none",
        //     cursor: "pointer",
        //     color:theme.palette.text.secondary,
        //
        // },
        [theme.breakpoints.down('md')]: {
            fontSize: 14
        },
    },
}));
export default function InComeOut() {
    const classes = useStyles();
    const ciclos = useEnums("ciclos");
    const [date, setDate] = useState("4");
    const Dispatch = useDispatch();
    const dashBoard = useSelector((state)=>state.dashBoard);

    const handleFilter = e => {
        const {value} = e.target;
        setDate(value);
        Dispatch(mostrarWidgetsGasIng(value));
    };

    let ciclosName = ciclos?.find(opt=>opt.key === date)?.value;
    return (
        <HtmlTooltip title={Strings.formatString(Strings.totalInOutMsj)}>
            <div className={"d-print-none  "+classes.root}>
                <div>
                    {formatPeso(dashBoard.ingresos)}
                    <label>{Strings.ingreso}</label>
                </div>
                <div>
                    <label>
                        <span>
                            {ciclosName}
                        </span>
                        <select onChange={handleFilter} value={date}>
                            {ciclos?.map((opt)=>
                                <option key={opt.key} value={opt.key}>{opt.value}</option>
                            )}
                        </select>
                    </label>

                </div>
                <div>
                    {formatPeso(dashBoard.egresos)}
                    <label>{Strings.egresos}</label>
                </div>
            </div>
        </HtmlTooltip>
    )
}
