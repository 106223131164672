import { clienteTypes } from './types';
import history from "../../helpers/history"
import {getClientes,addCliente ,editCliente, delCliente,rateCliente} from '../../services';

export function mostrarClientes(filter) {
    return (dispatch,getState) => {
        const pagos = getState().clientes;
        let paramsFilter = filter || pagos.filtered;
        dispatch({
            type: clienteTypes.MOSTRAR_CLIENTES,
            payload: getClientes(paramsFilter)
        })
    }
}
export function editarClienteRating(obj) {
    return (dispatch) => {
        dispatch({
            type: clienteTypes.EDIT_CLIENTE_RATING,
            payload: rateCliente(obj)
        })
    }
}
export function mostrarClienteById(id) {
    if(!id)
        return dispatch => { dispatch({ type: clienteTypes.LIMPIAR_CLIENTE_POR_ID}) }
    return (dispatch, getState) => {
        try {
            let byId = getState().clientes.list.results.find(c=>c.cliId === id);
            if(byId)
               dispatch({ type: clienteTypes.MOSTRAR_CLIENTE_POR_ID_ENCONTRADO, payload:{byId}}) ;        }
        catch(err) {}

        dispatch({
            type: clienteTypes.MOSTRAR_CLIENTE_POR_ID,
            payload: getClientes(parseInt(id))
        })
    }
}
export function agregarEditarCliente(obj) {
    return (dispatch) => {
        dispatch({
            type: clienteTypes.AGREGAR_EDITAR_CLIENTE,
            payload: obj.cliId ? editCliente(obj) : addCliente(obj)
        }).then(({action})=>{
            if(obj.cliId){
               return history.replace("/clientes",null);
            }
            //enviar a crear prestamo si es nuevo
            let data = action.payload?.data;
            history.replace(`/crear/prestamo`,{
                cliente: {
                    cliId: data.cliId,
                    fullName: data.cliNombres + " " + data.cliApellidos
                }
            });
        })
    }
}
export function eliminarCliente(id) {
    return dispatch => {
        dispatch({
            type: clienteTypes.ELIMINAR_CLIENTE,
            payload: delCliente(id)
        }).then(()=>{
            history.replace("/clientes",null);
        })
    }
}