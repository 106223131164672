import React, {useEffect,useState} from 'react';
import {Box, Button, Dialog, DialogContent, Typography, useMediaQuery} from '@mui/material';
import {connect} from "react-redux";
import {mostrarGenerales} from "../../redux/actions/generalesActions";
import {formatDate, formatPeso, geoString, moment} from "../../helpers";
import {isMobile} from "../../helpers/browser_detect";
import makeStyles from '@mui/styles/makeStyles';
import {MoreVert} from "@mui/icons-material";
import {useHistory,useLocation} from "react-router-dom";
import TopBarDialog from "../topBar/TopBarDialog";
//import SignatureCanvas from 'react-signature-canvas'
import Strings from "../../assets/strings";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import {downloadBase64} from "../../helpers/downloadFiles"
import ButtonSelect from "../ButtonSelect";
const useStyles = makeStyles(theme => ({
    pagoPediente:{
        "&>div":{
            display:"flex",
            padding:theme.spacing(1),
            borderBottom: "1px solid #e6e6e6",
            "&>p:first-child":{
                fontWeight: 500,
                marginRight:theme.spacing(1)
            }
        }
    },
    sigCanvas:{
        touchAction: "none",
        position: "absolute",
        bottom: "-14px",
        right: "-18px"
    },
    close:{
        marginRight: "auto",
    },
    reciboPago: {
        minWidth: 1040,
        margin: "0 auto",
        padding: "20px 25px",
        fontFamily: "arial",
        fontSize: "14px",
        color: "#000",
        position: "relative",
        outline: "2px solid",
        border: "2px solid",
        outlineColor:theme.palette.primary.main,
        borderColor:theme.palette.primary.light,
        "&.aju":{
            outlineColor:theme.palette.secondary.main,
            borderColor:theme.palette.secondary.light,
            "& h1": {
                color:theme.palette.secondary.main +"!important"
            },
        },
        "&:nth-child(2) ": {
            display:"none",
            position: "relative",
            marginTop: "70px",
            borderColor: "rgb(209, 209, 209)",
            outlineColor: "#aaaaaa",
            "&>:before": {
                content: '""',
                position: "absolute",
                top: "-37px",
                left: "0",
                width: "100%",
                borderBottom: "1px dashed"
            },
            '@media print': {
                display:"block",
            }
        },
        "& :not(i) span": {
            //paddingRight: "7px",
            fontWeight: "normal"
        },
        "&.small":{
            // border: "none",
            // outline: "none",
            // padding: 0,
            minWidth: 300,
            '& .topHead':{
                textAlign: "center",
                display: "block",
                height: "auto",
                '& .logo':{
                    textAlign: "center",
                    margin: "0 auto",
                    "& img":{
                        maxWidth: "73px",
                        maxHeight: "73px",
                    },
                },

                "& .info":{
                    "& h1":{
                        marginTop:4
                    },
                    "& *:not(h1)":{
                        fontSize: ".8rem",
                    }
                }
            },
            '& .header':{
                flexDirection: "column",
                "& h1":{
                    order: 1,
                    textAlign: "center",
                    fontSize: "1.5em",
                    marginBottom: 14,
                    fontWeight: "normal",
                },
                "& .numero":{
                    order: 2,
                    marginBottom: 8,
                },
                "& .fecha":{
                    order: 3,
                    "& div.top":{
                        display: "initial",
                        borderBottom: "1px solid",
                        padding: "0px 10px",
                        "& span": {
                            padding: 3,
                            borderBottom: "none",

                        }
                    },

                    "& div.bottom":{
                        display:"none",
                    },

                },
            },
            '& section':{
              "& p":{
                  margin: "8px 0px",
              }
            },
            '& .desglose':{
                display: "block",
                "&>div":{
                    padding: 0,
                    border: "none",
                    marginBottom: 8,
                    "&>div":{
                        display: "inline-block",
                        marginRight: 13,
                        textTransform: "uppercase",
                    },
                }
            },
            '& .footer':{
                flexDirection: "column",
                alignItems: "baseline",
                "& .firma":{
                    margin: "0 auto",
                }
            },
        },
        "& .d-print-none":{
            opacity: 0.5,
        }
    },
    desglose:{
        display:"flex",
        "&>div":{
            position: "relative",
            marginRight: "18px",
            paddingLeft: "5px",
            borderLeft: "1px solid"
        }
    },
    topHead: {
        display: "flex",
        justifyContent: "space-between",
        textAlign: "right",
        height: "103px",
        "& h1":{
            color:theme.palette.primary.main,
            fontWeight: "normal",
            marginBottom: "0",
            fontSize: "1.3rem",
        },
        "& .logo":{
            maxWidth: 180,
            textAlign: "left",
            "& img":{
                maxWidth: "100%",
                maxHeight: "100%",
            },
        },
        "& span":{
            padding: 0,
            "&.tel":{
                fontSize:".8rem",
            },
            "&.dir":{
                fontSize:".7rem",
            }
        }
    },
    header:{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "20px",
        "& .numero": {
            fontWeight: "bold",
            "& span": { borderBottom: "1px solid", padding: "0px 7px", fontWeight: "normal" }
        },
        "& h1": { margin: "0", fontSize: "1.8em", color:theme.palette.primary.main },
        "& div.fecha": {
            "& div.top": {
                "& span":{
                    borderBottom: "1px solid",
                    padding: "0 7px",
                }
            },
            "& div.bottom": {
                fontSize: ".8em",
                fontStyle: "italic",
                "& span":{
                    padding: "0 9px",
                }
            },

        }
    },
    section: {
        marginBottom: 1,
        "& p": {
            fontSize: 14,
            display: "flex",
            "& span": {
                fontWeight: "bold",

                "&:last-child, &.suma": {
                    fontWeight: "normal",
                    borderBottom: "1px solid #ccc",
                    padding: "0px 7px",
                    flex: 12,
                },

                "&.suma": {
                    flex: 70,
                }
            },
            "&.cRestante": {
                textAlign: "right",

                "& span": {
                    width: 134,
                    textAlign: "left",
                }
            }
        }
    },
    content:{
        background:"white",
        padding:3,
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .firma": {
            marginTop: -14,//53,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

            "& .imgCont": {
                textAlign: "center",
                height: 69,
                "& img": {
                    height:"100%"
                },
            },
            "& span": {
                fontWeight: "bold",
                padding: "3px 90px",
                border: "none",
                borderTop: "1px solid"
            }
        }
    },
    '@media print': {
        root:{
            "&>div":{
                width:"1020px",
                background:"white",
            }
        },
        content:{
            overflow: 'visible',
            margin:0,
        },
        appBar:{
            display:"none",
        },
        "&.small":{

        }
    },
}));

function ReciboPago({firmaImg,mostrarGenerales,generales = {},toPreIcon}){
    let history = useHistory();
    const {state:urlState,pathname} = useLocation();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const classes = useStyles();

    const [dLarge,setDLarge] = useState(!isMobile.anyWebView())
    const [data,setData] = useState({
        pagId: 0,
        preId: 0,
        cliente:"",
        pagFecha: new Date(),
        pagInteres: 0,
        pagCapital: 0,
        pagDescuento: 0,
        pagMora: 0,
        pagNuevoCapital: 0,
        pagBalance: 0,
        pagComentario: "",
        pagTotalLetra: "",
        pagAnulado: true,
        pagTipo: 0,
        pagFormaPago: 0,
        pagConcepto: "",
        pagCuotaPagadas: 0,
        pagBalancePendiente: 0,
        pagCapitalPendiente: 0,
        pagInteresPendiente: 0,
        pagFechaCreacion: new Date(),
        pagFechaAnulacion: new Date(),
    });

    useEffect(()=>{
        if(!Object.keys(generales).length){
            mostrarGenerales();
        }
        if(urlState?.recibo){
            setData(urlState.recibo);
            // if(urlState?.recibo.pagTipo === 8) setDLarge(false);
        }else{
            setData(null);
            // setDLarge(!smVewport);
        }
    },[mostrarGenerales,urlState,generales])

    if(!data) return null;
    var formatoRecibo;
    try {
        formatoRecibo = generales.genFormatoRecibo.split(",");
    }
    catch(error) {
        formatoRecibo = []
    }

    const convertHtml=()=>{
      //  window.scrollTo(0,0);
        html2canvas(document.querySelector(".reciboCont>div"),{scrollY:0, letterRendering: 1, allowTaint : true,logging:true,useCORS:true}).then(canvas => {
            let base64 = canvas.toDataURL('image/jpeg', 1.0);
            let fileName = "pago_"+data.cliente.replace(/\s/g,"-")+"_"+formatDate(data.pagFecha,"DD,MMMM,YYYY")+"_#"+data.pagId+"-"+data.preId+".jpg";

            try {
                if (isMobile.AndroidWebView()) {
                    if(window.Android.shareFromBase64){
                        window.Android.shareFromBase64(base64.split(',')[1],fileName);
                    }
                }else if(isMobile.IosWebView()){
                    window.webkit.messageHandlers.shareFromBase64.postMessage(base64.split(',')[1],fileName);
                }else{
                    downloadBase64(base64,fileName)
                }
            }catch (e) {
                console.log(e);
            }
        }, 'image/jpeg', 1);
    }
    const convertPDF= (size)=>{

        let docWidth = 78.063 + size//136.063;
        let docHeight = 370;
        // if(generales.genEmpresaLogo){
        //     docWidth= 285;
        // }
        // if(generales.pagComentario){
        //     docWidth += 15;
        // }


        let doc = new jsPDF('p', 'pt', [docWidth,docHeight]);
        let splitText = "";
        let yPosition = 10;

        if(generales.genEmpresaLogo){
            doc.addImage(generales.genEmpresaLogo, 'JPEG', (docWidth/2)-15, yPosition, 30, 30)
            yPosition += 40;
        }
        doc.setFont("Helvetica");
        doc.setFontSize(8)
        doc.text( generales.genEmpresaNombre,docWidth/2,yPosition,{align:"center"})
        yPosition += 10;

        if(generales.genEmpresaTelefono || generales.genEmpresaTelefono2){
            let tel = "Tel: ";
            if(generales.genEmpresaTelefono){
                tel += generales.genEmpresaTelefono;
                if(generales.genEmpresaTelefono2) tel += " / "+generales.genEmpresaTelefono2;
            }else{
                tel += generales.genEmpresaTelefono2;
            }
            doc.setFontSize(7)
            doc.text( tel,docWidth/2,yPosition,{align:"center"});
            yPosition += 10;

        }
        if(generales.genEmpresaRNC){
            doc.setFontSize(7)
            doc.text(Strings.rnc+": "+generales.genEmpresaRNC,docWidth/2,yPosition,{align:"center"});
            yPosition += 10
        }
        if(generales.genEmpresaDireccion){
            splitText = doc.splitTextToSize(Strings.direccion+": "+generales.genEmpresaDireccion, docWidth);
            doc.setFontSize(7)
            doc.text(splitText ,docWidth/2,yPosition ,{align:"center"})
            yPosition += (10 * splitText.length);
        }

        yPosition += 10;
        doc.setFontSize(8)
        doc.text( (data.pagTipo===4?Strings.ajusteCapital+"/"+Strings.reenganche:Strings.reciboDePago).toUpperCase(),docWidth/2,yPosition, {align:"center"} )
        yPosition += 18;

        doc.text("NO.: "+data.pagId+"-"+data.preId+"\n"+Strings.fecha+": "+formatDate(data.pagFecha)+" "+moment(data.pagFecha).format("hh:mm a"),0,yPosition)
        yPosition += 30;

        doc.setLineDashPattern([1,1]);
        doc.line(0, yPosition-13, docWidth, yPosition-13);

        splitText = doc.splitTextToSize((data.pagTipo===4?Strings.entregadoA:Strings.recibido+" "+Strings.de ).toLocaleUpperCase()+": "+data.cliente, docWidth);
        doc.text(splitText,0,yPosition, )
        yPosition += (10 * splitText.length);

        doc.text(Strings.laSumaDe.toUpperCase()+" "+formatPeso(data.pagTotal),0,yPosition)
        yPosition += 10;

        splitText = doc.splitTextToSize(Strings.concepto.toUpperCase()+": "+data.pagConcepto, docWidth);
        doc.text(splitText,0,yPosition)
        yPosition += (10 * splitText.length + 10);

        doc.setLineDashPattern([1,1]);
        doc.line(0, yPosition-13, docWidth, yPosition-13);

        if(!!data.pagComentario){
            splitText = doc.splitTextToSize(data.pagComentario, docWidth);
            doc.setFontSize(7)
            doc.text(splitText,docWidth/2,yPosition-3,{align:"center"});
            yPosition += (10 * splitText.length);
            doc.setLineDashPattern([1,1]);
            doc.line(0, yPosition-10, docWidth, yPosition-10);
        }


        doc.setFontSize(7)
        if(formatoRecibo.includes("formaPago") && !!data.pagFormaPagoString){
            doc.text(Strings.formaPago+": "+Strings[data.pagFormaPagoString],0,yPosition);
            yPosition += 10;
        }
        if(formatoRecibo.includes("agente") && !!data.agente){
            doc.text(Strings.cobrador+": "+data.agente,0,yPosition);
            yPosition += 10;
        }

        if(data.pagTipo !== 4){
            if(formatoRecibo.includes("capital")){
                doc.text(Strings.capital+": "+formatPeso(data.pagCapital),0,yPosition);
                yPosition += 10;
            }
            if(formatoRecibo.includes("interes")){
                doc.text(Strings.interes+": "+formatPeso(data.pagInteres),0,yPosition);
                yPosition += 10;
            }

            if(formatoRecibo.includes("descuento")) {
                doc.text(Strings.descuento + ": " + formatPeso(data.pagDescuento), 0, yPosition);
                yPosition += 10;
            }
            if(formatoRecibo.includes("mora")){
                doc.text(Strings.mora+": "+formatPeso(data.pagMora),0,yPosition)
                yPosition += 10;
            }
        }
        if(formatoRecibo.includes("capitalRestante") ){
            doc.text(Strings.capitalPendiente+": "+formatPeso(data.pagNuevoCapital),0,yPosition);
            yPosition += 10;
        }
        if(formatoRecibo.includes("balancePendiente")){
            doc.text(Strings.balancePendiente+": "+formatPeso(data.pagBalance),0,yPosition)
            yPosition += 10;
        }
        if(formatoRecibo.includes("proximoPago") ){
            doc.text(Strings.proximo+" "+Strings.pago+": "+formatDate(data.pagFechaProximoPago),0,yPosition)
            yPosition += 10;
        }

        yPosition += 30;
        doc.setLineDashPattern([0,0],0);
        doc.line(10, yPosition-8, docWidth-10, yPosition-8,);
        doc.setFontSize(8)
        doc.text( Strings.firmaAutorizada.toUpperCase(),docWidth/2,yPosition,{align:"center"} )

        if(generales.genFooterRecibo){
            yPosition += 16;

            splitText = doc.splitTextToSize(generales.genFooterRecibo, docWidth);
            doc.setFontSize(5)
            doc.text(splitText,docWidth/2,yPosition-3,{align:"center"});
           // yPosition += (10 * splitText.length);
        }

        doc.autoPrint();
        doc.output("dataurlnewwindow",{filename:Strings.pago+"-"+data.cliente+"-"+formatDate(data.pagFecha)});
    }
    const imprimir = () => {
        let {genEmpresaNombre,genEmpresaRNC, genEmpresaLogo,genEmpresaDireccion, genEmpresaTelefono, genEmpresaTelefono2, genEmpresaEmail,genFormatoRecibo,genFooterRecibo} = generales;
        let obj = {
            ...data,
            genEmpresaLogo,genEmpresaRNC,genEmpresaNombre, genEmpresaDireccion, genEmpresaTelefono, genEmpresaTelefono2, genFormatoRecibo,genEmpresaEmail,genFooterRecibo};
        let c_obj = {
            ...obj,
            pagFecha: formatDate(obj.pagFecha),
            pagFechaCreacion: formatDate(obj.pagFechaCreacion),
            pagFechaAnulacion: formatDate(obj.pagFechaAnulacion),
            pagFechaProximoPago: formatDate(obj.pagFechaProximoPago),
            pagBalance: formatPeso(obj.pagBalance),
            pagBalancePendiente: formatPeso(obj.pagBalancePendiente),
            pagCapital: formatPeso(obj.pagCapital),
            pagCapitalPendiente: formatPeso(obj.pagCapitalPendiente),
            pagComision: formatPeso(obj.pagComision),
            pagDescuento: formatPeso(obj.pagDescuento),
            pagInteres: formatPeso(obj.pagInteres),
            pagSeguro: formatPeso(obj.pagSeguro),
            pagInteresPendiente: formatPeso(obj.pagInteresPendiente),
            pagMora: formatPeso(obj.pagMora),
            pagNuevoCapital: formatPeso(obj.pagNuevoCapital),
            pagTotal: formatPeso(obj.pagTotal),
        }

            if (isMobile.AndroidWebView()) {
               return  window.Android?.printAPP(JSON.stringify(c_obj));
            }else if(isMobile.IosWebView()){
               return window.webkit?.messageHandlers?.printAPP?.postMessage(c_obj);
            }

            window.print()

    };
    return(
        <>
            <Dialog //Boolean(data.cliId > 0)
                open={Boolean(data)}
                maxWidth={dLarge?"lg":"sm"}
                fullWidth={true}
                onClose={history.goBack}
                fullScreen={smVewport}
                className="dialogRecibo"
                classes={{root:classes.root}}
            >
                <TopBarDialog title={Strings.recibo} onClose={()=> history.replace(pathname)} >
                    {!!data.pagAdjunto &&
                    <Button sx={{color:"white"}} component={"a"} href={data.pagAdjunto} target={"_blank"} >
                        {/*eliminar plural hardcode*/}
                        {Strings.adjuntos.slice(0, -1)}
                    </Button>}
                    {data.pagTipo !== 8 &&
                        <ButtonSelect smIcon={<MoreVert/>}
                                      opcions={[
                                          {key: "", value: Strings.opciones, disabled: true},
                                          ...(isMobile.anyWebView() || dLarge ? [{
                                              key: "1",
                                              value: Strings.imprimir
                                          }] : []),
                                          ...(!isMobile.anyWebView() ? [
                                              {key: "58", value: Strings.imprimir + " (58mm)"},
                                              {key: "80", value: Strings.imprimir + " (80mm)"},
                                              {key: "--1", value: "-"},
                                          ] : []),
                                          {
                                              key: "2",
                                              value: isMobile.anyWebView() ? Strings.compartir : Strings.descargar + " jpg"
                                          },
                                          {key: "3", value: dLarge ? Strings.vertical : Strings.horizontal},

                                          ...(toPreIcon ? [{
                                              key: "4",
                                              value: Strings.ver + " " + Strings.prestamo
                                          }] : []),
                                          {key: "5", value: Strings.ajustes}]}
                                      onChange={(v) => {
                                          switch (v) {
                                              case "1":
                                                  imprimir()
                                                  break
                                              case "58":
                                                  convertPDF(58)
                                                  break
                                              case "80":
                                                  convertPDF(80)
                                                  break
                                              case "2":
                                                  convertHtml()
                                                  break
                                              case "3":
                                                  setDLarge(!dLarge)
                                                  break
                                              case "4":
                                                  history.push(`/prestamo/${data.preId}`)
                                                  break
                                              case "5":
                                                  history.push("/ajustes#recibo")
                                                  break
                                              default:
                                                  return false
                                          }
                                      }}
                        />
                    }

                </TopBarDialog>
                <DialogContent className={classes.content}>
                    {data.pagTipo === 8?(
                            <div className={classes.pagoPediente}>
                                <Box>
                                    <Typography>{Strings.prestamo} ID:</Typography>
                                    <Typography>{data.preReferencia}</Typography>
                                </Box>
                                <Box>
                                    <Typography>{Strings.cliente}:</Typography>
                                    <Typography>{data.cliente}</Typography>
                                </Box>
                                <Box>
                                    <Typography>{Strings.fecha}</Typography>
                                    <Typography>{formatDate(data.pagFecha)}</Typography>
                                </Box>
                                <Box sx={{flexDirection: "column"}}>
                                    <Typography>{Strings.comentario}</Typography>
                                    <Typography>{data.pagComentario}</Typography>
                                </Box>
                                <Box>
                                    <Typography>{Strings.total}</Typography>
                                    <Typography>{formatPeso(data.pagTotal)}</Typography>
                                </Box>

                                <Button fullWidth onClick={()=>history.push(`/prestamo/${data.preId}`)}>{Strings.ver} {Strings.prestamo}</Button>
                            </div>
                    ):(
                        <div className={"reciboCont"}>
                        {[...Array(formatoRecibo.includes("copia") && dLarge ?2:1).keys()].map(i=>
                            <div key={i} className={classes.reciboPago + (!dLarge?" small ":" ")+(data.pagTipo===4?"aju":"")}>
                                <div className={"topHead " +classes.topHead}>
                                    <div className="logo">
                                        {generales.genEmpresaLogo &&
                                            <img alt="logo" src={generales.genEmpresaLogo} />
                                        }
                                    </div>
                                    <div className="info">
                                        <h1>{generales.genEmpresaNombre}</h1>
                                        <span className="tel">
                                            <i>
                                                {!!generales.genEmpresaRNC && <><span>{geoString("rnc",true)}: {generales.genEmpresaRNC}</span> <br/></>}
                                                {!!generales.genEmpresaTelefono && <span> Tel: {generales.genEmpresaTelefono}</span>}
                                                {!!generales.genEmpresaTelefono2 && <span> &nbsp;/ &nbsp; {generales.genEmpresaTelefono2}</span>}
                                            </i>
                                        </span>
                                        <br/>
                                        <span className="dir">{generales.genEmpresaDireccion}</span>

                                    </div>
                                </div>
                                <br />
                                <div className={"header " +classes.header}>
                                    <div className="numero">{!dLarge && <>Recibo </>} No. <span>{data.pagId} - {data.preId} </span></div>
                                    <h1>{(data.pagTipo===4?Strings.ajusteCapital+"/"+Strings.reenganche:Strings.reciboDePago).toLocaleUpperCase()}</h1>
                                    <div className="fecha">{!dLarge && <strong>{Strings.fecha}: &nbsp;</strong>}
                                        <div className={"top"}>
                                            <span>{formatDate(data.pagFecha,"DD")}</span> /
                                            <span>{formatDate(data.pagFecha,"MM")}</span> /
                                            <span>{formatDate(data.pagFecha,"YYYY")}</span>
                                            <span><small> {moment(data.pagFecha).format("hh:mm a")}</small></span>

                                        </div>
                                       <div className={"bottom"}>
                                           <span>{Strings.dia}</span>
                                           <span>{Strings.mes}</span>
                                           <span>{Strings.anio}</span>
                                       </div>
                                    </div>
                                </div>
                                <section className={classes.section}>
                                    <p><span>{(data.pagTipo===4?Strings.entregadoA:Strings.recibido+" "+Strings.de ).toLocaleUpperCase()}:</span> <span><b>{data.cliente}</b></span></p>
                                    <p><span>{Strings.laSumaDe.toLocaleUpperCase()}</span>
                                    {dLarge && <><span className="suma">{data.pagTotalLetra}</span><span>{Strings.monto.toLocaleUpperCase()}:</span></> }
                                    <span> {formatPeso(data.pagTotal)}</span></p>
                                    <p><span>{Strings.porConceptoDe.toLocaleUpperCase()}</span> <span style={{width: "82.6%", display: "inline-block"}}>{data.pagConcepto}</span></p>
                                    {!!data.pagComentario &&
                                        <p><span>{Strings.comentario.toLocaleUpperCase()}:</span> <span>{data.pagComentario}</span></p>
                                    }
                                </section>
                                <div className={"footer " +classes.footer}>
                                        <div className={"desglose " + classes.desglose}>

                                            {formatoRecibo.includes("formaPago") && !!data.pagFormaPagoString &&
                                                <div>
                                                    <Box>{Strings.formaPago}</Box>
                                                    <span className="c">{Strings[data.pagFormaPagoString]}</span>
                                                </div>}
                                            {formatoRecibo.includes("agente") && !!data.agente &&
                                                <div>
                                                    <Box>{Strings.cobrador}</Box>
                                                    <span className="c">{data.agente}</span>
                                                </div>
                                            }
                                            {data.pagTipo !== 10 && <>
                                                {data.pagTipo !== 4 && <>
                                                    {formatoRecibo.includes("capital") &&
                                                        <div>
                                                            <Box>{Strings.capital}</Box>
                                                            <span className="c">{formatPeso(data.pagCapital)}</span>
                                                        </div>}

                                                    {formatoRecibo.includes("interes") &&
                                                        <div>
                                                            <Box>{Strings.interes}</Box>
                                                            <span className="c">{formatPeso(data.pagInteres)}</span>
                                                        </div>}
                                                    {formatoRecibo.includes("seguro") && !!data.pagSeguro &&
                                                        <div>
                                                            <Box>{Strings.seguro}</Box>
                                                            <span className="c">{formatPeso(data.pagSeguro)}</span>
                                                        </div>}
                                                    {formatoRecibo.includes("descuento") &&
                                                        <div>
                                                            <Box>{Strings.descuento}</Box>
                                                            <span className="c">{formatPeso(data.pagDescuento)}</span>
                                                        </div>}
                                                    {formatoRecibo.includes("mora") &&
                                                        <div style={{color: "#de5c5c"}}>
                                                            <Box>{Strings.mora}</Box>
                                                            <span className="c">{formatPeso(data.pagMora)}</span>
                                                        </div>}
                                                </>}
                                                {formatoRecibo.includes("capitalRestante") &&
                                                    <div>
                                                        <Box>{Strings.capitalPendiente}</Box>
                                                        <span className="c">{formatPeso(data.pagNuevoCapital)}</span>
                                                    </div>}
                                                {formatoRecibo.includes("balancePendiente") &&
                                                    <div>
                                                        <Box>{Strings.balancePendiente}</Box>
                                                        <span className="c">{formatPeso(data.pagBalance)}</span>
                                                    </div>}
                                                {formatoRecibo.includes("proximoPago") &&
                                                    <div>
                                                        <Box>{Strings.proximo} {Strings.pago}</Box>
                                                        <span
                                                            className="c">{formatDate(data.pagFechaProximoPago)}</span>
                                                    </div>}
                                            </>
                                            }
                                        </div>
                                    {formatoRecibo.includes("firma") &&
                                    <div className="firma">
                                        <div className="imgCont">
                                            {firmaImg &&
                                                <img src={firmaImg} alt=""/>
                                            }
                                        </div>
                                        {/*<SignatureCanvas penColor='black'*/}
                                        {/*                 canvasProps={{width: 310, height: 100, className: classes.sigCanvas}} />*/}
                                        <span>{Strings.firma.toLocaleUpperCase()}</span>
                                    </div>}
                                    {generales.genFooterRecibo && !dLarge &&
                                        <p style={{textAlign:"center",width: "100%"}}>{generales.genFooterRecibo}</p>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    )
}


const mapStateToprops = (data) => ({
    generales: data.generales.data,
    firmaImg: data.auth.user?.usuFirma ?? ""
})
export default connect(mapStateToprops,{mostrarGenerales})(ReciboPago);
