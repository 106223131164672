import {carteraTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    byId:{},
    isFetching: false,
    isPending: false,
    error: null,
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case carteraTypes.MOSTRAR_CARTERAS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case carteraTypes.MOSTRAR_CARTERAS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
            }
        }
        case carteraTypes.MOSTRAR_CARTERAS + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //agregar  o editar
        
        case carteraTypes.AGREGAR_EDITAR_CARTERA + _PENDING: {
            return {
                ...state,
                isPending: true,
                error: {}
            }
        }
        case carteraTypes.AGREGAR_EDITAR_CARTERA + _FULFILLED: {

            const { data } = action.payload;
            let list = {...state.list};
            list.results?
                list.results = list.results.filter(e => e.carId !== data.carId): list.results = [];
            list.results.unshift(data);

            let byId = {...state.byId}
            if(byId?.carId === data.carId){
                byId = {...byId,...data}
            }
            return {
                ...state,
                list,
                isPending: false,
                byId,
                error: {}
            }
        }
        case carteraTypes.AGREGAR_EDITAR_CARTERA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //mostrar por id
        case carteraTypes.MOSTRAR_CARTERA_POR_ID + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case carteraTypes.MOSTRAR_CARTERA_POR_ID + _FULFILLED: {
            return {
                ...state,
                byId: action.payload.data,
                isFetching: false,
            }
        }
        case carteraTypes.MOSTRAR_CARTERA_POR_ID + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }

        case carteraTypes.MOSTRAR_CARTERA_POR_ID_ENCONTRADO:  {
            const { byId } = action.payload;
            return {
                ...state,
                byId
            }
        }
        //Eliminar

        case carteraTypes.ELIMINAR_CARTERA + _FULFILLED: {
            const { data: id } = action.payload;
            let list = {...state.list}
            list.results = list.results.filter(e => e.carId !== id)
            --list.totalRecordCount
            return {
                ...state,
                list,
                isPending: false,
                byId:{},
                error: {}
            }
        }
        case carteraTypes.ELIMINAR_CARTERA + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }

    default:
      return state;
  }
}
