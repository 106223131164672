import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Rating from '@mui/material/Rating';
const labels = [0,0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

const useStyles = makeStyles((theme)=>({
    select:{
        border: "none",
        marginLeft: 14,
        background: theme.palette.grey[200],
        padding: "2px 1px",
    },
    rating: {
        width: 200,
        display: 'flex',
        alignItems: 'center',
    },
}));

export default function HoverRating({onChange,value, name, ...props}) {
    const classes = useStyles();

    return (
        <div className={classes.rating}>
            <Rating
                onChange={onChange}
                {...props}
                name={name}
                value={parseFloat(value)}
                precision={0.5}
                // onChange={(event, newValue) => {
                //     setValue(newValue);
                // }}
            />
            {/*onChange={(e)=>setValue(parseFloat(e.target.value))}*/}
            <select className={classes.select} name={name} value={value && value.toString()} onChange={onChange}>
                {labels.map(l=>
                    <option key={l} value={l}>{l}</option>
                )}
            </select>
        </div>
    );
}
