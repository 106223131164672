import {appUserTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    filtered:{},
    byId: {},
    isFetching: false,
    isPending: false,
    error: null,
}
 
export default function index(state = initialState, action={}) {

    switch (action.type) {
        case appUserTypes.MOSTRAR_APPUSERS + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            }
        }
        case appUserTypes.MOSTRAR_APPUSERS + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                filtered: action.payload?.config?.params ?? {},
                isFetching: false,
                error: null
            }
        }
        case appUserTypes.MOSTRAR_APPUSERS + _REJECTED: {
            return {
                ...state,
                isFetching: false,
            }
        }
        //editar

        case appUserTypes.UPDATE_APPUSER: {
            let data = action.payload;
            let list = state.list;
            list.results?
                list.results = list.results.filter(e => e.usuId !== data.usuId): list.results = [];
            data.new = true
            list.results.unshift(data)
            return {
                ...state,
                isPending: false,
                showDetailsModal: false,
                byId:{},
                error: {}
            }
        }
    default:
      return state;
  }
}
