import React, {useEffect, useState} from 'react';
import {Select,Input,InputLabel,MenuItem,FormControl,ListItemText,Hidden,FormHelperText} from '@mui/material';
import {moment} from "../../helpers"
import StyledCheckbox from "../StyledCheckbox";
import Strings from "../../assets/strings";

export default function SelectDay({name,value,onChange}) {
    const [showHelp, setShowHelp] = useState(false)

    const [selected, setSelected] = useState([])

    useEffect(()=>{
        let val = value.split(",").filter(Boolean)
        setSelected(val.map(v=>parseInt(v)))
    },[setSelected,value])
    const handleChange = e => {
        setShowHelp(false)
        const {value: tValue} = e.target
        if(onChange && tValue.length <= 3){
            onChange({target:{value: tValue.join(','),name}})
        }else{
            setShowHelp(true)
        }
    };
    const handleChangeNative = (event) => {
        setShowHelp(false)

        const { options } = event.target;
        const value = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        if(onChange && value.length <= 3){
            onChange({target:{value: value.join(','),name}})
        }else{
            setShowHelp(true)
        }
    };
    return (
        <>
            <Hidden only={"xs"}>
                <FormControl fullWidth>
                    <InputLabel>{Strings.excluir} {Strings.dias}.</InputLabel>
                    <Select
                        multiple
                        value={selected}
                        onChange={handleChange}
                        renderValue={(selected) => selected.map(s=>moment().day(s).format("dddd")).join(', ')}
                        input={<Input />}
                    >
                      {[...Array(7).keys()].map(i => (

                            <MenuItem key={i} value={i}>
                                <StyledCheckbox checked={selected.indexOf(i) > -1} xIsCheck/>
                                <ListItemText primary={moment().day(i).format("dddd")} />
                            </MenuItem>
                        ))}
                    </Select>
                    {showHelp &&
                    <FormHelperText>{Strings.excluir} 3 {Strings.dias} {Strings.maximo}.</FormHelperText>}
                </FormControl>
            </Hidden>
            <Hidden smUp>
                <FormControl fullWidth>
                    <InputLabel shrink>{Strings.excluir} {Strings.dias}.</InputLabel>
                    <Select
                        multiple
                        native
                        value={selected}
                        onChange={handleChangeNative}
                    >
                        {[...Array(7).keys()].map(i => (
                            <option key={i} value={i}>
                                {moment().day(i).format("dddd")}
                            </option>
                        ))}
                    </Select>
                    {showHelp &&
                    <FormHelperText>{Strings.excluir} 3 {Strings.dias} {Strings.maximo}.</FormHelperText>}
                </FormControl>
            </Hidden>
        </>
    );
}
