import { zonasHorariasTypes } from './types';
import { getZonasHorarias } from '../../services';


export function mostrarZonasHorarias() {
    return dispatch => {
        dispatch({
            type: zonasHorariasTypes.MOSTRAR_ZONAS_HORARIAS,
            payload: getZonasHorarias()
        })
    }
}
export function setZonasHorarias(payload) {
    return (dispatch, getState) => {
        const zonasHorarias = getState().zonasHorarias
        if (Object.keys(zonasHorarias.list).length || zonasHorarias.isFetching) return false;
        dispatch({
            type: zonasHorariasTypes.SET_ZONAS_HORARIAS,
            payload
        })
    }
}
export function mostrarZonasHorariasSinoEsta() {
    return (dispatch, getState) => {
        const zonasHorarias = getState().zonasHorarias
        if (Object.keys(zonasHorarias.list).length || zonasHorarias.isFetching) return false;
        dispatch({
            type: zonasHorariasTypes.MOSTRAR_ZONAS_HORARIAS,
            payload: getZonasHorarias()
        })
    }
}
