import React, {useState, useEffect} from 'react';
import {agregarEditarCartera} from "../../redux/actions/carterasActions";
import {
    Button,
    Dialog,
    DialogContent,
    TextField,
    DialogActions, Grid
} from "@mui/material";
import {useHistory, useLocation} from "react-router-dom";
import {nullToEmptyString} from "../../helpers";
import {connect} from "react-redux";
import LoadingProgress from "../../components/LoadingProgress";
import Strings from "../../assets/strings"


const initialState = {
    carId: null, carDescripcion: "", carCapital: ""
};



function Crear(props) {
    const {goBack} = useHistory();
    const {state:urlState={}} = useLocation();


    const [state, setState] = useState(initialState);
    const {isPending} = props;

    useEffect(() => {
        if(!!urlState && urlState.carId) {
            setState(state => ({...state, ...nullToEmptyString(urlState)}));
        }else {
            setState(initialState);
        }
    }, [setState, urlState]);

    const handleSubmit = e => {
        e.preventDefault();
        props.agregarEditarCartera(state)
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("carId"))} onClose={goBack} maxWidth={"xs"} fullWidth={true}>
                    <DialogContent>
                        {isPending && <LoadingProgress />}
                        <form onSubmit={handleSubmit} id="form1">
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        value={state.carDescripcion}
                                        onChange={(e) => setState({ ...state, carDescripcion: e.target.value })}
                                        inputProps={{maxLength: 50}}
                                        autoFocus
                                        autoComplete={"off"}
                                        required
                                        label={Strings.descripcion}
                                        type="text"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>

                                    <TextField
                                        value={state.carCapital}
                                        onChange={(e) => setState({ ...state, carCapital: e.target.value })}
                                        required
                                        label={Strings.capital}
                                        type="number"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>



                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={goBack} color="primary">{Strings.cancelar}</Button>
                        <Button type="submit" form={"form1"} color="primary" disabled={isPending}>
                            {!!state.carId ? Strings.actualizar : Strings.guardar}
                        </Button>
                    </DialogActions>
            </Dialog>
        </>
    );

}
const mapStateToProps = (state) => ({
    isPending: state.carteras.isPending,
});
export default connect(mapStateToProps, { agregarEditarCartera })(Crear);
