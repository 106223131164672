import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogContent, TextField, DialogActions, Grid, useMediaQuery} from "@mui/material";
import {connect} from "react-redux";
import {agregarGasto} from "../../redux/actions/gastosActions";

import SelectCaja from "../../components/selects/SelectCaja";
import TopBarDialog from "../../components/topBar/TopBarDialog";
import {SlideLeft} from "../../components/transitions";
import {useHistory, useLocation} from "react-router-dom";
import {formatDate, nullToEmptyString} from "../../helpers";
import {MostrarError} from "../../components/MostrarError";
import LoadingProgress from "../../components/LoadingProgress";
import {DateInput, TextFieldCurrency} from "../../components/CustomTextField";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
import Strings from "../../assets/strings"

let initialState={
    gasFecha: formatDate(new Date(),"YYYY-MM-DD"),
    categoriaId: "",
    gasConcepto: "",
    gasMonto: "",
    cajId: "",
    gasComentario: "",

};
function Crear({isPending,error,defaultCajId,agregarGasto}) {
    initialState.cajId = defaultCajId;
    const {goBack} = useHistory();
    const {state:urlState} = useLocation();

    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const [state, setState] = useState(initialState);

    useEffect(() => {
        if(urlState)
            setState(state=>({...state,...nullToEmptyString(urlState)}));
        else
            setState(initialState);
    }, [setState, urlState]);

    const handleChange = e => {
        const {name, value} = e.target;
        setState({...state,[name]:value})
    };
    const handleSubmit = e => {
        e.preventDefault();
        agregarGasto(state)
    };

    return (
        <>
            <Dialog open={Boolean(urlState && urlState.hasOwnProperty("gasId"))} onClose={goBack}
                    {...(smVewport ? {TransitionComponent: SlideLeft}:{})}
                    fullScreen={smVewport} maxWidth={"xs"}>

                <TopBarDialog title={Strings.nuevo} onClose={goBack}>
                </TopBarDialog>
                    <DialogContent>
                        <MostrarError errors={error}/>
                        {isPending && <LoadingProgress/>}
                        <br/>
                        <form id="form1" onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <DateInput
                                        name="gasFecha"
                                        value={state.gasFecha}
                                        onChange={handleChange}
                                        required
                                        label={Strings.fecha}
                                        InputProps={{readOnly: !!state.gasId}}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectUsoMultiples
                                        emptyOption={{key:"",value:Strings.ninguna}}
                                        group="CATEGORIAGASTOS"
                                        name="categoriaId"
                                        value={state.categoriaId}
                                        onChange={handleChange}
                                        label={Strings.categoria}
                                        fullWidth
                                        disabled={!!state.gasId}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="gasConcepto"
                                        value={state.gasConcepto}
                                        onChange={handleChange}
                                        required
                                        label={Strings.concepto}
                                        fullWidth
                                        inputProps={{maxLength: 100}}
                                        InputProps={{readOnly: !!state.gasId}}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextFieldCurrency
                                        name="gasMonto"
                                        value={state.gasMonto}
                                        onChange={handleChange}
                                        required
                                        label={Strings.monto}
                                        fullWidth
                                        InputProps={{readOnly: !!state.gasId}}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectCaja
                                        name="cajId"
                                        value={state.cajId}
                                        onChange={handleChange}
                                        InputProps={{readOnly: !!state.gasId}}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        name="gasComentario"
                                        value={state.gasComentario}
                                        onChange={handleChange}
                                        label={Strings.comentario}
                                        fullWidth
                                        multiline
                                        inputProps={{maxLength: 150}}
                                        InputProps={{readOnly: !!state.gasId}}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        <br/>
                    </DialogContent>
                    {!state.gasId &&
                        <DialogActions>
                            <Button type={"submit"} form={"form1"}  color="primary" disabled={isPending}>
                                {!!state.gasId ? Strings.actualizar : Strings.agregar}
                            </Button>
                        </DialogActions>
                    }
            </Dialog>

        </>
    );

}

const mapStateToProps = (state) => ({
    isPending: state.gastos.isPending,
    error: state.gastos.error,
    defaultCajId: state.auth.user.cajId || "",

});
export default connect(mapStateToProps, { agregarGasto })(Crear);
