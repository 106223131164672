import React, {Fragment, useState} from 'react';
import Chart from "react-apexcharts";
import Strings from "../../assets/strings";

import {
  DialogContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Tab,
  Tabs,
  Divider,
  Box,
  TableContainer,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import {formatPeso} from '../../helpers';

const useStyles = makeStyles(theme => ({
  chart:{
    width: 211,
    margin: "0 auto",
  },
  tabs:{
    marginBottom: 3,
    padding: "0px "+ theme.spacing(1),
    background: theme.palette.grey[100],
    "& button, & .MuiTabs-root":{
      minHeight: "40px",
      height: "40px"
    },
    "& .MuiTabs-scroller":{
      minHeight: "40px",
      height: "40px",
      "& .MuiTab-root":{
        width: "auto",
        minWidth: "auto",
        fontSize: "0.8125rem",
        padding: "4px 8px",
      },
    },

  },
  series:{
    display:"flex",
    justifyContent: "center",
    "& span:before":{
      content: "''",
      background: theme.palette.secondary.main,
      display: "inline-block",
      width: "10px",
      height: "10px",
      marginRight: "3px",
      borderRadius: "2px",
    },
    "& span:last-child:before":{
      background: theme.palette.primary.main,
      marginLeft: 81,
    },
  },
  table:{
    "& th":{
      fontWeight: "normal",
    },
    "& td":{
      fontSize: "0.9em",
    },
    "& .hl":{
      background: "rgba(211, 232, 255, 0.5)",
    }
  },
}));
const barOpcion = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
     // stackType: '100%',
      toolbar: {show: false},
      zoom: {
        type: "x",
        enabled: false,
        autoScaleYaxis: true
      },
    },

    // plotOptions: {
    //   bar: {
    //     horizontal: true,
    //   },
    // },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        endingShape: 'flat'
      },
    },
    stroke: {
      show: false,
      width: 0,
      colors: ['#fff']
    },
    xaxis: {
      show: false,
    },
    yaxis: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      y: {
        formatter: (value) => formatPeso(value),
      }
    },
    fill: {
      opacity: 1

    },
    legend: {
      show: false
    }
  };
const pieOpcion = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: [Strings.interes,Strings.capital],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'center',
      offsetX: 40
    },
    tooltip: {
      y: {
        formatter: (value) => formatPeso(value),
      }
    },

};

export default function AmortList({list}){
  const primaryColor = useTheme().palette.primary.main;
  const secondaryColor = useTheme().palette.secondary.main;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(1);

  const capital =  list.map(a=>a.capitalApagar)
  const interes =  list.map(a=>a.interes)
  return (
      <Fragment>
          {!!list.length &&
          <div className={classes.tabs}>
            <Tabs value={activeTab} onChange={(e,v)=>setActiveTab(v)} indicatorColor={"secondary"}>
              <Tab value={1} label={Strings.amortizacion} />
              <Tab value={2} label={Strings.grafico} />
            </Tabs>
          </div>
          }
          {!!list.length &&
          <DialogContent >
            {activeTab === 2 &&
            <>
              <div className={classes.series}><span> {Strings.capital}</span> <span>{Strings.interes}</span></div>
              <div className={classes.chart}>
                <Chart options={{...pieOpcion,colors: [ primaryColor, secondaryColor]}} series={[
                  interes.reduce((a, b) => a + b,0),
                  capital.reduce((a, b) => a + b,0)]} type="pie" width={200} />
              </div>
              <Chart options={{...barOpcion,colors: [ primaryColor, secondaryColor]}} series={[{ name: 'Interés', data:interes }, { name: 'Capital', data: capital }]} type="bar" height={250} />
            </>}

            { activeTab === 1 && list &&
            <>
                <TableContainer className={classes.table}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>No - {Strings.fecha}</TableCell>
                      <TableCell className="hl">{Strings.totalApagar}</TableCell>
                      <TableCell>{Strings.capitalPendiente}</TableCell>
                      <TableCell>{Strings.abonoCapital}</TableCell>
                      <TableCell>{Strings.interes}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list && list.map(n => {
                      return (
                          <TableRow key={n.cuota}>
                            <TableCell>{n.cuota} - {n.fecha}</TableCell>
                            <TableCell className="hl">{formatPeso(n.totalaPagar)}</TableCell>
                            <TableCell>{formatPeso(n.balanceRestante)}</TableCell>
                            <TableCell>{formatPeso(n.capitalApagar)}</TableCell>
                            <TableCell>{formatPeso(n.interes)}</TableCell>
                          </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                </TableContainer>
            </>}

            <div>
              <Box p={1} display="flex" justifyContent="space-between">
                <span>{Strings.total+" "+Strings.interes}:</span>
                <span>{formatPeso(interes.reduce((a, b) => a + b,0))}</span>
              </Box>
              <Divider/>
              <Box p={1} display="flex" justifyContent="space-between">
                <span>{Strings.interes} + {Strings.capital}:</span>
                <span>{formatPeso(interes.reduce((a, b) => a + b,0) + capital.reduce((a, b) => a + b,0))}</span>
              </Box>
            </div>
          </DialogContent>
          }
      </Fragment>
    );

}