import {prestamoTypes} from './types';
import {
    addPrestamo,
    editPrestamo,
    getPrestamos,
    cancelarPrestamo,
    postPago,
    addPrestamoNota,
    deletePrestamoNota, getCxC, setIncobrablePrestamo
} from '../../services';
import history from "../../helpers/history"

export function mostrarPrestamos(filter) {
    return (dispatch,getState) => {
        const prestamos = getState().prestamos;

        let paramsFilter = filter || prestamos.filtered;

        dispatch({
            type: prestamoTypes.MOSTRAR_PRESTAMOS,
            payload: getPrestamos(paramsFilter),
        })
    }
}
export function mostrarCxC() {
    return dispatch => {
        dispatch({
            type: prestamoTypes.MOSTRAR_CxC,
            payload: getCxC()
        })
    }
}

export function mostrarPrestamoById(preId) {
    return (dispatch, getState) => {
        try {
            let byId = getState().prestamos.list.results.find(p=>p.preId === preId);
            if(byId)
                dispatch({ type: prestamoTypes.MOSTRAR_PRESTAMO_POR_ID_ENCONTRADO, payload:{...byId, preEstado:byId.estado??""}}) ;
        }catch(err) {}

        dispatch({
            type: prestamoTypes.MOSTRAR_PRESTAMO_POR_ID,
            payload: getPrestamos(parseInt(preId))
        })
    }
}
export function agregarEditarPrestamo(obj) {
    return dispatch => {
        dispatch({
            type: prestamoTypes.AGREGAR_EDITAR_PRESTAMO,
            payload: obj.preId ? editPrestamo(obj) : addPrestamo(obj)
        }).then(({value})=>{
            history.push('/prestamo/'+value.data.preId)
        })
    }
}
export function agregarNota(obj) {
    return dispatch => {
        dispatch({
            type: prestamoTypes.AGREGAR_PRE_NOTA,
            payload: addPrestamoNota(obj)
        })
    }
}
export function eliminarNota(id) {
    return dispatch => {
        dispatch({
            type: prestamoTypes.ELIMINAR_PRE_NOTA,
            payload: deletePrestamoNota(id)
        })
    }
}
export function agregarPago(obj) {
    return dispatch => {
        dispatch({
            type: prestamoTypes.AGREGAR_PAGO,
            payload: postPago(obj)
        }).then(() => {
            dispatch({
                type: prestamoTypes.MOSTRAR_PRESTAMO_POR_ID,
                payload: getPrestamos(parseInt(obj.preId))
            })
        })
    }
}
export function cancelPrestamo(id,preComentario,anularPagos) {
    if(!id) return false;
    return dispatch => {
        dispatch({
            type: prestamoTypes.CANCELAR_PRESTAMO,
            payload: cancelarPrestamo(id,preComentario,anularPagos)
        })
    }
}
export function marcarPrestamoIncobrable(id) {
    if(!id) return false;
    return dispatch => {
        dispatch({
            type: prestamoTypes.MARCAR_PRESTAMOS_IMCOBRABLE,
            payload: setIncobrablePrestamo(id)
        })
    }
}
