import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { mostrarRoles, eliminarRol} from '../../redux/actions/rolesActions';
import Crear from './crear';
import Strings from "../../assets/strings";
import {Button, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import AppBar from "../../components/appBar";
import PlusFab from "../../components/PlusFab";
import {Close} from "@mui/icons-material";
import LoadingProgress from "../../components/LoadingProgress";
import {useHistory, useLocation} from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import Empty from "../../components/Empty";
import {SeacrhAppBar} from "../../components";
import {noTilde} from "../../helpers";

function Roles(props) {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();
    const [searchBox, setSearchBox] = useState("");


    const { mostrarRoles } = props
    useEffect(() => {
        mostrarRoles();
    }, [mostrarRoles]);

    const { title: mainTitle, roles: { list, isFetching } } = props;
    const isEmpty = list.results && list.results.length === 0;
    const filterItems = () => {
        return list.filter(item=> noTilde(item.rolDescripcion).indexOf(noTilde(searchBox)) !== -1);
    }
    return <>
        <AppBar title={Strings[mainTitle]}>
            <SeacrhAppBar name="search" value={searchBox} onChange={({target})=>setSearchBox(target.value)}/>
        </AppBar>
        <section className="contentInner" style={{maxWidth:600}}>
            {isFetching  && !list.length && <LoadingProgress vh/>}
            <List >
                {!isEmpty && filterItems().map(item =>
                    <ListItem role={undefined} button key={item.rolId} onClick={()=>history.push(history.location.pathname,item)}>
                        <ListItemText primary={item.rolDescripcion}  />
                        {!item.registroDelSistema &&
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="comments"
                                onClick={()=>history.push(history.location.pathname,{confirmDialog:item.rolId})}
                                size="large">
                                <Close className="text-red" fontSize={"small"}/>
                            </IconButton>
                        </ListItemSecondaryAction>
                        }
                    </ListItem>
                )}
            </List>
            {isEmpty && <Empty/>}
            <PlusFab component={Button} onClick={()=>history.push(history.location.pathname,{rolId:""})} />
        </section>
        <Crear/>
        <ConfirmDialog onConfirm={()=>props.eliminarRol(urlState && urlState.confirmDialog)}/>

    </>;
}


const mapStateToProps = (state) => ({
    roles: state.roles
});
export default connect(mapStateToProps, { mostrarRoles, eliminarRol })(Roles);
