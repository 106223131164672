import React from 'react';
import { Typography, IconButton } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {formatDate, formatPeso} from "../../helpers";
import {DeleteSweepOutlined} from "@mui/icons-material";
import Drawer from "@mui/material/Drawer";
import {useHistory} from "react-router-dom";
import Strings from "../../assets/strings";

const useStyles = makeStyles(theme => ({
  header:{
    textAlign: "center",
    padding: "20px 8px",
    borderBottom: "1px solid #eaeaea",
    "& *":{
      fontWeight: "normal"
    },
    "& .deleteIcon":{
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "#f8f8f8"
    }
  },
  root:{
   // zIndex:theme.zIndex.modal + 1,
   //  background: "white",
   //  position: "absolute",
   //  bottom: 0,
   //  width: "100%",
  //  textAlign: "center",
  },
  comment:{
    padding: "8px 16px",
    fontSize: ".8rem",
  },
  ul:{
    margin: 0,
    marginTop: 10,
    padding: 0,
    listStyle: "none",
    columns: 2,
    "& li":{
      padding: "7px 15px",
      // -webkit-column-break-inside: avoid;
      // page-break-inside: avoid;
      breakInside: "avoid",

      "& h6":{
        fontWeight: 'normal',
        color:theme.palette.text.secondary,
        margin: 0,
        fontSize: "0.9em",
      }
    },
    [theme.breakpoints.up('sm')]: {
      columns: 3,
    },

  }
}));
export default function DetallesGastos({item,...props}) {
  const classes = useStyles();
  let history = useHistory();

  return <>
    <Drawer
        anchor={"bottom"}
        open={!!item}
        {...props}
    >
        {!!item &&
        <div className={classes.root}>
          <div className={classes.header}>
            <Typography variant={"caption"}>{formatDate(item.gasFecha,"LL")}</Typography>
            <Typography color={"primary"} variant={"h6"}>
              {formatPeso(item.gasMonto)}
            </Typography>
            <IconButton
              className="deleteIcon"
              onClick={()=>{props.onClose(); history.push(history.location.pathname,{confirmDialog:item.gasId})}}
              size="large">
              <DeleteSweepOutlined fontSize="small" />
            </IconButton>
          </div>

          <ul className={classes.ul}>
            <li>
              <h6>{Strings.categoria}</h6>
              <span>{item.categoriaString}</span>
            </li>
            <li>
              <h6>{Strings.concepto}</h6>
              <span>{item.gasConcepto}</span>
            </li>
            <li>
              <h6>{Strings.caja}</h6>
              <span>{item.caja}</span>
            </li>
            <li>
              <h6>{Strings.creadoPor}</h6>
              <span>{item.creadoPor}</span>
            </li>
          </ul>
          {item.gasComentario &&
          <div className={classes.comment}>
            {item.gasComentario}
          </div>
          }
        </div>
        }
    </Drawer>
  </>;
}
