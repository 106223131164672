import { USER_LOGOUT} from '../actions/types';

import { combineReducers } from 'redux';
import {reducer as notifications} from 'react-notification-system-redux';
import authReducer from './authReducer';
import dashBoard from './dashBoardReducers';
import theme from './themeReducer';
import pagos from './pagosReducers';
import prestamos from './prestamoReducers';
import clientes from './clientesReducer';
import rutas from './rutasReducers';
import carteras from './carterasReducers';
import plantillas from './plantillaReducers';
import usosMultiples from './usosMultiplesReducer';
import roles from './rolesReducers';
import cajas from './cajasReducers';
import agentes from './agentesReducers';
import gastos from  './gastosReducers'
import paises from './paisesReducers';
import enums from './enumsReducers';
import zonasHorarias from './zonasHorariasReducers';
import solicitudes from './solicitudesReducers';
import generales from './generalesReducers';
import appUsers from './appUsersReducers';
const appReducer = combineReducers({
    auth: authReducer,
    theme,
    dashBoard,
    pagos,
    prestamos,
    clientes,
    rutas,
    carteras,
    plantillas,
    cajas,
    usosMultiples,
    roles,
    agentes,
    gastos,
    solicitudes,
    paises,
    enums,
    zonasHorarias,
    generales,
    appUsers,
    notifications

});

//export default
export default function index(state, action) {
    if (action.type === USER_LOGOUT) {
        state = undefined;
    }

    return appReducer(state, action);
}
