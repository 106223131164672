import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarCarterasSinoEsta } from '../../redux/actions/carterasActions';
import {FormControl, InputLabel} from "@mui/material";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";
import {hasPermissionTo, PERSMISOS, PERSMISOS_USUARIOS} from "../../helpers";

function SelectCartera({ value = "", name = "carId",label=Strings.carteras, onChange, all, carteras,mostrarCarterasSinoEsta}) {

    useEffect(() => {
        if(hasPermissionTo(PERSMISOS.carteras) && hasPermissionTo(PERSMISOS_USUARIOS.carteras)){
            mostrarCarterasSinoEsta();
        }
    }, [mostrarCarterasSinoEsta]);


    var options = (carteras.list?.results && carteras.list.results.map(opc=>({key:opc.carId,value:opc.carDescripcion}))) || [];
    options.unshift({key:"",value:all?Strings.todo:Strings.seleccione})

    return (

        <FormControl fullWidth>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value}
                onChange={onChange}
                name={name}
                loading={carteras.isFetching }
                opciones={options}
            />
        </FormControl>

    );
}

const mapStateToProps = (state) => ({
    carteras: state.carteras,
})
export default connect(mapStateToProps, { mostrarCarterasSinoEsta })(SelectCartera);
