import React,{useEffect,useState} from 'react';
import {useHistory} from 'react-router-dom'
import {isMobile} from "../../helpers/browser_detect";
import Strings from '../../assets/strings'
import { FormControl, TableRow, TableCell, TableBody, Fab } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {connect} from "react-redux";
import {mostrarSolicitudes} from "../../redux/actions/solicitudActions";
import {formatDate, formatPeso} from "../../helpers";
import {
  HtmlTooltip,
  Pagination,
  TableSort,
  AppBar,
  SeacrhAppBar,
  FilteredAlert,
  MostrarError,
  LoadingProgress,
  ListViewMode, ButtonResponsive
} from "../../components";
import {Link, EditOutlined, Share, CloudDownloadOutlined} from "@mui/icons-material";
import { SpeedDialAction, SpeedDial } from '@mui/material';
import downloadFiles from "../../helpers/downloadFiles";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 13,
    overflowX: 'auto',
  },
  selectEstado:{
    background: "transparent",
    border: "none",
    borderLeft: "1px solid #bdbdbd",
    padding: "3px 0px 0px 6px"
  },
  fb: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  speedDial:{
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  sDAction:{
    "& .MuiSpeedDialAction-staticTooltipLabel":{
      color: theme.palette.secondary.main,
      border: "1px solid " + theme.palette.secondary.main,
      boxShadow: "none",
      padding: "0px 10px",
      whiteSpace: "nowrap",
      fontSize: ".9rem",
    },
    "& svg":{
      color:theme.palette.primary.main,
    }

  }
}));

const initialState = {
  name:"",
  estado: "",
  page: 1,
  pageSize: 15,
  orderBy: "",
  orderByIsDescending:"",
}
function Solicitudes(props){

  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [filter, setFilter] = useState(initialState);

  const {mostrarSolicitudes,solicitudes: { list, isFetching,error,filtered} } = props;
  const isEmpty = !list.results || list.results.length === 0;

  useEffect(() => {
    mostrarSolicitudes();
  }, [mostrarSolicitudes]);
  //set filtered params
  useEffect(()=>setFilter({...initialState,...filtered}),[setFilter,filtered])


  const handleFilter = e => {
    const {name, value} = e.target;
    mostrarSolicitudes({...filter,page:null,[name]:value})
  };
  const tableSortProps = orderBy => {
    return {
      active:filter.orderBy === orderBy,
      direction: filter.orderByIsDescending ? "desc" : 'asc',
      onClick:()=>mostrarSolicitudes({...filter,page:null,orderBy,orderByIsDescending:!filter.orderByIsDescending})
    }
  };

  const url = `https://prestamistapp.com/solicitar/${props.userName}`;
  return (

      <>
        <AppBar title={Strings.solicitudes}>
          <SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>
          <FormControl>
            <select
                value={filter.estado}
                onChange={handleFilter}
                name = 'estado'
                className={classes.selectEstado}
            >
              <option value="1">{Strings.pendiente}</option>
              <option value="2">{Strings.aprobada}</option>
              <option value="3">{Strings.rechazada}</option>
            </select>
          </FormControl>
          <ButtonResponsive icon={<CloudDownloadOutlined/>} text={Strings.exportar} disabled={isEmpty} onClick={()=>downloadFiles(filter,"solicitudes")}/>

        </AppBar>
        <ListViewMode pageRows={list.pageSize} pageTotalRows={list.totalRecordCount} noView>
          <FilteredAlert onClose={()=>mostrarSolicitudes({})} filter={filtered}/>
        </ListViewMode>

        <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
          {isFetching && isEmpty && <LoadingProgress vh/>}

          <MostrarError errors={error}/>

          <TableSort data={[
            {pro:"solEstado",text:Strings.estado},
            {pro:"solFechaCreacion",text:Strings.fecha},
            {pro:"solNombreCompleto",text:Strings.solicitante},
            {pro:"solMontoPrestamo",text:Strings.monto},
            {pro:"solCuotas",text:Strings.cuotas},
            {pro:"solCiclosPago",text:Strings.modalidad},
            {pro:"solCedula",text:Strings.cedula},
            {pro:"solTelefono",text:Strings.telefono},
            ...(filter.estado !== "1" ?
              [{pro:"solFechaAprobacion",text:(filter.estado === "2"?Strings.aprobada:Strings.rechazada)}]:[]
            ),
            {pro:"agente",text:Strings.agentes},
          ]} onSort={tableSortProps} lessVh={205}>
            <TableBody>
            {!isEmpty && list.results.map(sol =>
                <TableRow key={sol.solId} className={classes.tRow} onClick={()=>history.push(`/solicitud/${sol.solId}`)}>
                  <TableCell>{Strings[sol.solEstadoString]}</TableCell>
                  <TableCell>{formatDate(sol.solFechaCreacion)}</TableCell>
                  <TableCell>{sol.solNombreCompleto}</TableCell>
                  <TableCell>{formatPeso(sol.solMontoPrestamo)}</TableCell>
                  <TableCell>{sol.solCuotas}</TableCell>
                  <TableCell>{Strings[sol.solCiclosPagoString]}</TableCell>
                  <TableCell>{sol.solCedula}</TableCell>
                  <TableCell>{sol.solTelefono}</TableCell>
                  {filter.estado !== "1" &&
                    <TableCell>{formatDate(sol.solFechaAprobacion)}</TableCell>
                  }
                  <TableCell>{sol.agente}</TableCell>
                </TableRow>
            )}
            </TableBody>
          </TableSort>
          <Pagination
              empty={Boolean(isEmpty && !isFetching)}
              count={list.totalRecordCount || 0}
              rowsPerPage={filter.pageSize}
              page={list.pageNo -1}
              onPageChange={(e,page)=>handleFilter({target:{name:"page",value:page+1}})}
              onRowsPerPageChange={handleFilter}

            //  onPageChange={(e,page)=> setFilter({...filter,page:page+1})}
            //  onRowsPerPageChange={(e)=>  setFilter({...filter,pageSize:parseInt(e.target.value)})}
          />
        </section>
        {isMobile.anyWebView()?(
            <SpeedDial
                ariaLabel="Short Cut"
                className={classes.speedDial}
                icon={<Link/>}
                onClose={()=>setOpen(false)}
                onOpen={()=>setOpen(true)}
                open={open}
            >
              <SpeedDialAction
                  classes={{staticTooltip:classes.sDAction}}
                  icon={<Share/>}
                  tooltipTitle={"Compartir"}
                  tooltipOpen
                  FabProps={{
                    onClick: ()=>{
                      isMobile.AndroidWebView()? window.Android.shareLink(url): window.webkit.messageHandlers.shareLink.postMessage(url);
                    }
                  }}
              />
              <SpeedDialAction
                  classes={{staticTooltip:classes.sDAction}}
                  icon={<EditOutlined/>}
                  tooltipTitle={"Abrir"}
                  tooltipOpen
                  FabProps={{
                    component: "a",
                    target:"_blank",
                    rel:"noopener noreferrer",
                    href: url
                  }}
              />
            </SpeedDial>

        ):(
            <HtmlTooltip
                title={Strings.solicitudMsj}
            >
              <Fab color="primary" className={classes.fb} href={url} target={"_blank"} rel="noopener noreferrer">
                <Link />
              </Fab>
            </HtmlTooltip>
        )}



      </>
  );
}

const mapStateToProps = (state) => ({
  solicitudes: state.solicitudes,
  userName: state.auth.user.usuInicioSesion,

});
export default connect(mapStateToProps, { mostrarSolicitudes })(Solicitudes);
