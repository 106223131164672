import React, {useEffect, useState} from 'react';
import {
    AppBar,
    ConfirmDialog, Empty,
    ListViewMode,
    LoadingProgress,
    MostrarError,
    PlusFab,
} from "../../components";
import {
    Button,
    FormControlLabel,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";
import Strings from "../../assets/strings"
import {useHistory, useLocation} from "react-router-dom";
import {formatDate} from "../../helpers";
import {Close} from "@mui/icons-material";
import {addAutorizaciones, delAutorizaciones, getAutorizaciones} from "../../services";
import {ReactComponent as EmptySvg} from "../../assets/img/password.svg";

const initialState = {
    soloActivas: true,
    search:""
}
export default function  Index() {
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();
    const [autorizaciones, setAutorizaciones] = useState([]);
    const [isFetching, setIsFetching] = useState(false);


    const [filter, setFilter] = useState(initialState);

    let error = "";

    useEffect(() => {
        setIsFetching(true)
        getAutorizaciones(filter).then(r=>{
            setAutorizaciones(r.data)
        }).finally(()=>{
            setIsFetching(false)
        })
    }, [setAutorizaciones,filter]);

    //set filtered params
    const handleFilter = e => {
        const {name, value,checked} = e.target;
        if(name === "soloActivas")
            setFilter({...filter,page:null,[name]:checked});
        else
            setFilter({...filter,page:null,[name]:value});
    };

    const eliminarAut = () => {
        let id = urlState?.confirmDialog;
        if(id){
            delAutorizaciones(id).then(()=>{
                setAutorizaciones(autorizaciones.filter(a=>a.autId !== id))
            })
        }
    }
    const addAut = () => {
        setIsFetching(true)
        addAutorizaciones().then((res)=>{
            let list = [...autorizaciones]
            if(res.data) list.push(res.data);
            setAutorizaciones(list)

        }).finally(()=>{
            setIsFetching(false)
        })
    }
    return <>
        <AppBar title={Strings.autorizaciones}>
            {/*<SeacrhAppBar name="name" value={filter.name} onChange={handleFilter}/>*/}
            <FormControlLabel className="d-print-none"
                              control={
                                  <Switch
                                      size={"small"}
                                      color={"secondary"}
                                      name={"soloActivas"}
                                      checked={filter.soloActivas}
                                      onChange={handleFilter}
                                  />
                              }
                              label={Strings.soloActivas}
            />
        </AppBar>
        <ListViewMode pageRows={autorizaciones.length} pageTotalRows={autorizaciones.length}/>
        <section className="contentInner" style={isFetching?{opacity:.8}:{opacity:1}}>
            {isFetching && <LoadingProgress vh/>}
            {!urlState && <MostrarError errors={error}/>}

            {autorizaciones.length ?(
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell>{Strings.fecha}</TableCell>
                        <TableCell>PIN</TableCell>
                        <TableCell>{Strings.usado}</TableCell>
                        <TableCell>{Strings.usado} {Strings.por}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {autorizaciones.map(item =>
                        <TableRow key={item.autId} className={!!item.usadoPor ? "text-red-deep":""}>
                            <TableCell className="actionCell">
                                <IconButton
                                    onClick={()=>history.push(history.location.pathname,{confirmDialog:item.autId})}
                                    size="large">
                                    <Close className={"text-red"}/>
                                </IconButton>
                            </TableCell>

                            <TableCell> {formatDate(item.autFecha,'MMMM DD YYYY, h:mm:ss a')}</TableCell>
                            <TableCell> {item.autPin}</TableCell>
                            <TableCell> {formatDate(item.autFechaUso,'MMMM DD YYYY, h:mm:ss a')}</TableCell>
                            <TableCell> {item.usadoPor}</TableCell>

                        </TableRow>
                    )}
                </TableBody>
            </Table>):(
                <Empty Img={EmptySvg} title={Strings.aunNoTienes+" "+Strings.autorizaciones}>
                    <p>{Strings.autorizacionHelpMsj}</p>
                </Empty>
            )}

            <PlusFab component={Button} onClick={addAut} />
            <ConfirmDialog onConfirm={eliminarAut}/>
        </section>
    </>;


}


