import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { mostrarUsosMultiplesSinoEsta } from '../../redux/actions/usosMultiplesActions';
import {FormControl, InputLabel} from "@mui/material";
import {SelectSmart} from "../CustomTextField";
import _ from "lodash";
import Strings from "../../assets/strings";

function SelectUsoMultiples({emptyOption,required,disabled, margin, value="", name="",label="", onChange, group, mostrarUsosMultiplesSinoEsta,usosMultiples}) {

    const {isFetching,list} = usosMultiples

    //cargar usos multiples
    useEffect(() => {
        mostrarUsosMultiplesSinoEsta(group)
    }, [group, mostrarUsosMultiplesSinoEsta]);


    const lista = list[group] ? list[group] : [];
    var options = lista.map(opc=>({key:opc.usoCodigo,value: Strings[_.camelCase(opc.usoDescripcion)] || opc.usoDescripcion}))
    if(emptyOption) options.unshift(emptyOption)

    if(!onChange){
        let opcion = options.find(o=>o.key.toString() === value.toString())
        if(opcion) return  <>{opcion.value}</>

        return <>-</>
    }
    return (
        <FormControl fullWidth required={required} margin={margin} >
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value.toString()}
                onChange={onChange}
                name={name}
                loading={isFetching}
                required={required}
                disabled={disabled}
                opciones={options}
            />
        </FormControl>
    );
}

const mapStateToProps = (state) => ({
    usosMultiples: state.usosMultiples,
})
export default connect(mapStateToProps, { mostrarUsosMultiplesSinoEsta })(SelectUsoMultiples);
