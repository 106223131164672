import {solicitudTypes} from './types';
import {getSolicitudes,aprobarSolicitud as aprobar, rechazarSolicitud as rechazar} from '../../services';
import historyHelper from "../../helpers/history";


export function mostrarSolicitudes(filter) {
    return (dispatch,getState) => {
        const solicitudes = getState().solicitudes;
        let paramsFilter = filter || solicitudes.filtered;

        dispatch({
            type: solicitudTypes.MOSTRAR_SOLICITUDES,
            payload: getSolicitudes(paramsFilter)
        })
    }
}
export function mostrarSolicitudById(preId) {
    return dispatch => {
        dispatch({
            type: solicitudTypes.MOSTRAR_SOLICITUD_POR_ID,
            payload: getSolicitudes(parseInt(preId))
        })
    }
}

export function aprobarSolicitud(obj) {
    return dispatch => {
        dispatch({
            type: solicitudTypes.APROBAR_SOLICITUD,
            payload: aprobar(obj)
        }).then((res)=>{
            historyHelper.push("/prestamos")
        })
    }
}
export function rechazarSolicitud(id) {
    return dispatch => {
        dispatch({
            type: solicitudTypes.RECHAZAR_SOLICITUD,
            payload: rechazar(id)
        }).then(()=>{
           historyHelper.push("/solicitudes")
        })
    }
}

