import React, {useEffect, useState} from 'react';
import { Typography, Button, useMediaQuery } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import Chart from "react-apexcharts";
import {formatPeso, moment} from "../../helpers";
import {connect} from "react-redux";
import {getResumenGrafico} from "../../services";
import LoadingProgress from "../LoadingProgress";
import {CloudDownloadOutlined} from "@mui/icons-material";
import downloadFiles from "../../helpers/downloadFiles";
import {SelectSmart} from "../CustomTextField";
import Strings from "../../assets/strings";

const useStyles = makeStyles(theme => ({
    root:{
        background: theme.palette.common.white,
        border: "1px solid " +theme.palette.grey[300],
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(5)
        },
    },
    filterButton: {
        //margin: theme.spacing.unit,
        padding: "2px 5px",
        minHeight: "auto",
        minWidth: 104,
        marginLeft: 4,
        "& svg":{
            width:18,
        }
    },
    head:{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 "+theme.spacing(1),
        height: 47,
        borderBottom: "1px solid "+theme.palette.grey[300],
        marginBottom: theme.spacing(1),
        "&> p":{
            marginRight: "auto"
        },
        "& button":{
            textTransform: "capitalize",
            fontWeight: "normal",
            fontSize: ".9rem",
            background: "none !important",
            "&:after":{
                content: "''",
                borderRight: "1px solid",
                height: "58%",
                position: "absolute",
                right: -4,
                zIndex: 1,
                opacity: .4
            }
        }
    },
    yearSelect:{
        textAlign:"right",
        width:82,
        "& .MuiInput-root":{
            border: "none !important",
            outline: "none",
            margin: "0 !important",
            "&:after":{
                display: "none !important",
            }
        },
        "& .MuiSelect-select":{
            marginRight:theme.spacing(1),
            background: "none",
        }

    },
    chart:{
        [theme.breakpoints.down('md')]: {
            overflow: "auto",
            overflowY: "hidden",

            "& .graph":{
                minWidth:500,
            },
        },
        "& .total":{
            position: "absolute",
            right: theme.spacing(2),
        },
        "& .apexcharts-menu":{
            width: "39px", overflow: "hidden",  minWidth: 0,
            "&>div":{
                whiteSpace: "nowrap",
                marginLeft: "-77px",
                wordSpacing: "23px"
            }
        },

    },
    tableDetail:{
        width:"100%",
        minWidth: 800,
        textAlign: "left",
        fontSize: 12,
        background: theme.palette.grey[50],
        padding: "8px 3px",
        "& tr":{
            background: "transparent !important",

        },
        "& th":{
            fontWeight: 500,
            padding: 5,
        },
        "& td":{
            padding: 5,
        }
    }
}));

const CURRENT_YEAR = new Date().getFullYear().toString();
function years() {
    var years = [];
    var year = parseInt(CURRENT_YEAR);
    for(let i=0;i<=7;i++){
        years.push(year--);
        year = year--
    }
    return years;
}
function IngresosChart({ingresos}) {
    const xsVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));
    const smVewport = useMediaQuery((theme)=>theme.breakpoints.down('md'));

    const classes = useStyles();
    const [loading, seLoading] = useState(false);
    const [anio, setAnio] = useState(CURRENT_YEAR);
    const [data, seData] = useState();
    const [dataPointIndex, setDataPointIndex] = useState(null);


    const [clearData, setClearData] = useState([]);

    const handleChange = e => {
        const {value} = e.target;
        setAnio(value);
        if(value === CURRENT_YEAR) return seData(null); //para traer los datos ya cargados en redux
        seLoading(true);
        getResumenGrafico(value).then(res=>{
            seData(res.data)
        }).finally(()=>seLoading(false));
    };

    useEffect(()=>{
        let datos = data || ingresos || [];
        let converted = [];

        if(datos.length)
            converted = datos.map(e=>(
                {
                    name:Strings[e.name],
                    data: dataPointIndex !== null ? [e.data[dataPointIndex]] : e.data}
            ));

        setClearData(converted);
    },[ingresos,data,dataPointIndex,setClearData]);

    const optionsChart = {
        chart: {
            type: 'bar',
            height: xsVewport?300:473,
            stacked: Boolean(dataPointIndex === null),
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: true,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false ,
                },
            },
            events: {
                dataPointSelection: function (e, chart, opts) {
                    setDataPointIndex(dataPointIndex === null? opts.dataPointIndex:null);
                }
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
              //  columnWidth: '65%',
                endingShape: 'flat'
            },
        },
        // grid: {
        //     borderColor: '#ccc',
        //     strokeDashArray: 7,
        //     xaxis: {
        //         lines: {
        //             show: true
        //         }
        //     }
        // },
        dataLabels: {
            enabled: Boolean(dataPointIndex !== null),
            formatter: function (val) {
                return formatPeso(val);
            },
           // offsetY: -20,
            style: {
              //  fontSize: '12px',
                colors: ["#304758"]
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['transparent']
        },
        xaxis: {
            categories: dataPointIndex !== null ? [dataPointIndex]:[...Array(12).keys()],
            labels: {
                formatter: (number) => moment().month(number).format(dataPointIndex !== null ?"MMMM" :"MMM"),
            }
        },
        yaxis: {
            labels: {
                formatter: (value) => value.toFixed(),
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            enabled: Boolean(dataPointIndex === null),
            shared: true,
            intersect:false,
            followCursor:true,
            y: {
                formatter: (value) => formatPeso(value),
            }
        }

    };
    const calHeight = ()=>{
        let vh = window.innerHeight - 396;
        if(vh < 273) vh = 273;
        return smVewport ? vh :450
    }

    return (
        <div className={classes.root}>
            <div className={classes.head}>
                <Typography>{Strings.ingresos} {dataPointIndex !== null && moment().month(dataPointIndex).format("MMMM")}</Typography>
                <Button variant="text" startIcon={<CloudDownloadOutlined/>}
                        size={"small"} onClick={()=>downloadFiles({anio},"resumenGrafico")}>
                    {Strings.exportar}
                </Button>
                <div className={classes.yearSelect}>
                        <SelectSmart
                            value={anio}
                            onChange={handleChange}
                            opciones={years().map((year) =>({key:year,value:year}))}
                        />
                </div>
            </div>

            <div className={classes.chart}>
                {dataPointIndex !== null &&
                    <div className="total">
                        {Strings.total} = {formatPeso(clearData.reduce((a, b) => +a + +b.data[0], 0))}
                    </div>
                }
                {loading && <LoadingProgress/>}
                <div className={(dataPointIndex === null)?"graph":""}>
                    <Chart options={optionsChart} series={clearData} type="bar" height={calHeight()} />
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    ingresos: state.dashBoard.ingresosChart || []
});
export default connect(mapStateToProps)(IngresosChart);