import React from 'react';
import { Box, ButtonBase, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {formatDate, formatPeso} from "../../helpers";
import Strings from "../../assets/strings";
import {HtmlTooltip} from "../index";
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    display: "block",
    margin:2,
    borderBottom: "1px solid" +theme.palette.grey[300],
    "&.i":{
      background:"rgba(21,216,193,0.13) !important",
      "& .estado":{
        color: "#15d2d8",
      }
    },

    "&.c":{
      background:"rgba(216, 67, 21, 0.13) !important",
      "& .estado":{
        color: "#d84315",
      }
    },
    "&.m":{
      background:"rgba(255, 111, 0, 0.05) !important",
      "& .estado":{
        color: "#ff6f00",
      }
    },
    "&.p":{
      background:"rgba(25, 118, 210, 0.07)",
      "& .estado":{
        color: "#1976d2",
      }
    },
    "& .content":{
      padding:theme.spacing(1),
      // [theme.breakpoints.only('xs')]: {
      //   overflow: "auto",
      // },
    }
  },
  divider:{
    margin: theme.spacing(1) +" 0px",
  },
  details:{
    "&>div":{
      display:"flex",
      justifyContent:"space-between",
      [theme.breakpoints.down(undefined)]: {
        "&:first-child":{
          flexDirection:"column-reverse",
        },
        flexDirection: "column"
      }

    }
  }

}));
export default function ItemPrestamo({ onClick,item:pre }) {
  const classes = useStyles();
  return (
      <>
        {pre &&
            <ButtonBase component={Paper} className={classes.root +" "+pre.estado?.charAt(0) ?? ""} onClick={onClick}>
              <div className="content" >
                <Box display="flex" justifyContent="space-between">
                  <Typography component={"span"} noWrap>{pre.cliente}</Typography>
                  <HtmlTooltip title={Strings.monto+" "+Strings.cuotas}>
                  <Typography align={"right"}>
                      {/*<Typography variant="caption">Cuotas:</Typography>*/}
                      {formatPeso(pre.preMontoCuota)}
                  </Typography>
                  </HtmlTooltip>

                </Box>
                <div style={{margin:"8px 0",borderBottom:"1px dashed #cacaca"}}/>
                <div className={classes.details}>
                  <div>
                    <Typography variant="caption">{Strings.balance} {Strings.pendiente}: {formatPeso(pre.preBalancePendiente)}</Typography>
                    <HtmlTooltip title={Strings.proximo+" "+Strings.pago}>
                      <Typography variant="body2">{
                        formatDate(pre.preFechaProximoPago,"dd, DD MMMM, YYYY")
                           .replace(", "+new Date().getFullYear(),"")
                      }
                      </Typography>
                    </HtmlTooltip>
                  </div>
                  <div>
                    <div>
                      <Typography variant="caption" noWrap>
                        {Strings.cuotas} {Strings.pagada}: {pre.prePlazoString}
                      </Typography>
                      <Typography variant="caption" component={"div"} noWrap>
                        {Strings.c_pendientes}: {pre.cuotasAtrasadas}
                        {/*{pre.cuotasAtrasadas>1 && pre.cuotasAtrasadas}  {Strings[pre.estado]}*/}
                      </Typography>
                    </div>



                    <Typography variant="caption" className="estado">
                      {(pre.estado === "mora" || pre.estado === "c_pendientes") ?(
                          <>{Strings.mora} : {formatPeso(pre.mora)}</>
                      ):(
                          <>{Strings[pre.estado]}</>
                      )}
                      {/*{Strings[pre.estado]}*/}


                    </Typography>
                  </div>
                </div>
              </div>
            </ButtonBase>
        }
      </>
  )
}
