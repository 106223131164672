import React, {useEffect, useState} from 'react';
import { agregarEditarPlantilla, mostrarPlantillaById } from '../../redux/actions/plantillasActions';
import LoadingProgress from "../../components/LoadingProgress";
import {nullToEmptyString} from "../../helpers";
import Strings from "../../assets/strings";
import {useSelector,useDispatch} from "react-redux";
import AppBar from "../../components/appBar";
import { CircularProgress, Grid, MenuItem, Menu, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Button from "@mui/material/Button";
import {useParams} from "react-router-dom";
import {MostrarError} from "../../components";
import ContratoEditor from "./ContratoEditor";
import SelectEnum from "../../components/selects/SelectEnum";
import { Alert } from '@mui/material';
const useStyles = makeStyles(theme => ({
    root:{
        padding: 20,

    },
    topMenu:{
        background:theme.palette.grey[200],
        "&>button":{
            textTransform: "capitalize",
        }
    }
}));
let initialState = {
    plaId: null,
    plaNombre: "",
    plaContenido: "",
    plaTipo: ""
}
export default function CrearPlantilla(){
    const classes = useStyles();
    let { plaId:urlPlaId} = useParams()
    const Dispatch = useDispatch();
    const {byId:platilla,isFetching,isPending,error} = useSelector(state => state.plantillas)
    const [state, setState] = useState(initialState);

    useEffect(()=>{
        if(urlPlaId) Dispatch(mostrarPlantillaById(urlPlaId));
    },[urlPlaId,Dispatch])
    useEffect(()=>{
        if(platilla?.plaId === parseInt(urlPlaId)) {
            setState({...initialState, ...nullToEmptyString(platilla)});
        }else{
            setState(initialState)
        }
    },[urlPlaId,platilla])

    const handleChange = (e)=>{
        const {name,value} = e.target
        if (name === "plaTipo"){
            return setState({...state,[name]: value,plaContenido:""});
        }
        setState({...state,[name]: value});
    }
    const submit = (e) =>{
        e.preventDefault();
        let obj = {...state}

        Dispatch(agregarEditarPlantilla(obj));

    }
    const [anchorEl, setAnchorEl] = useState(null);
    const addParam = (p) =>{
        setAnchorEl(null)
        return setState({...state,plaContenido: state.plaContenido+" "+p});
    }
    const duplicar = () =>{
        let copyState = {...state,plaNombre: state.plaNombre + " - "+Strings.copia, registroDelSistema: false, plaId:null }
        return setState(copyState);
    }
    return(
        <>
            <AppBar title={(state.plaId ? Strings.editar:Strings.crear )+" "+Strings.plantilla }>
                {!state.registroDelSistema &&
                    <Button color="inherit" disabled={isPending} type="submit" form="form1" value="Submit">
                        {isPending? (
                            <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                        ):(
                            <span>{Strings.guardar}</span>
                        )}
                    </Button>
                }
            </AppBar>
            <section className={"contentInner "+classes.root} >
                <MostrarError errors={error}/>
                {(isFetching || isPending) && <LoadingProgress vh/>}
                {state.registroDelSistema &&
                    <>
                        <Alert icon={false}
                            action={
                                <Button color="inherit" size="small" onClick={duplicar}>
                                    {Strings.duplicar}
                                </Button>
                            }
                        >
                            {Strings.registroDelSistemaMsj}
                        </Alert>
                        <br/>
                    </>
                }

                <form id="form1" onSubmit={submit}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <TextField
                                name={"plaNombre"}
                                label={Strings.descripcion}
                                value={state.plaNombre}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                required
                                disabled={state.registroDelSistema}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectEnum
                            group="tiposPlantilla"
                            name="plaTipo"
                            value={state.plaTipo}
                            onChange={handleChange}
                            label={Strings.tipo}
                            required
                            fullWidth
                            disabled={!!state.plaId || state.hasOwnProperty("registroDelSistema")}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        {parseInt(state.plaTipo) === 2 ?(
                                <ContratoEditor
                                    disabled={state.registroDelSistema}
                                    name={"plaContenido"}
                                    value={state.plaContenido}
                                    onChange={handleChange}/>
                        ):(
                            <>
                                {!state.registroDelSistema &&
                                <div className={classes.topMenu}>
                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={(e) => setAnchorEl(e.currentTarget)}>
                                        {Strings.parametros}
                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={() => setAnchorEl(null)}
                                    >
                                        <MenuItem onClick={()=>addParam("$NombreCliente")}>{Strings.nombreCompleto}</MenuItem>
                                        <MenuItem onClick={()=>addParam("$CedulaCliente")}>{Strings.cedula}</MenuItem>
                                        <MenuItem onClick={()=>addParam("$DireccionCliente")}>{Strings.direccion}</MenuItem>
                                    </Menu>
                                </div>}
                                <TextField
                                    rows={10}
                                    multiline
                                    name={"plaContenido"}
                                    placeholder={Strings.descripcion}
                                    value={state.plaContenido}
                                    onChange={handleChange}
                                    required
                                    disabled={state.registroDelSistema}
                                    fullWidth
                                />
                            </>
                        )}
                        </Grid>

                    </Grid>
                </form>
            </section>
        </>
    )
}

