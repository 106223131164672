import React from 'react';
import Strings from "../../assets/strings";
import {
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow, TextField,
    Typography
} from "@mui/material";
import { Alert } from '@mui/material';
export default function ContratoEditor(props){
    const {values,onChange} = props;
    const handleChange = e => {
        let genConfCampos = {...values};

        const {type, value,name,checked} = e.target
        let campo = {
            name:"",
            //source:"",
            isRequired:false
        }
        if(genConfCampos[name]) campo = genConfCampos[name];

        if(type === "text") campo.name = value; else campo.isRequired = checked;

        campo.name ? genConfCampos[name] = campo: delete genConfCampos[name];

        onChange(genConfCampos);
    };
    return(
        <div>
            <Alert severity="info" icon={false}>{Strings.customFormInputMjs}</Alert>

            <TableContainer>
                <Table size={"small"}>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>{Strings.descripcion}</TableCell>
                            <TableCell>{Strings.requerido}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={6}><Typography variant={"body1"} color={"primary"}>{Strings.formulario} {Strings.cliente}</Typography> </TableCell>
                        </TableRow>
                        {[1,2,3,4].map((e)=>
                            <TableRow key={e}>
                                <TableCell>cliCampo{e}</TableCell>
                                <TableCell>
                                    <TextField name={"cliCampo"+e} size={"small"}  inputProps={{maxLength:20}} value={values["cliCampo"+e]?.name ?? ""} onChange={handleChange}/>
                                </TableCell>
                                <TableCell padding="checkbox" align={"center"}>
                                    <Checkbox name={"cliCampo"+e} size={"small"} checked={values["cliCampo"+e]?.isRequired ?? false} onChange={handleChange} />
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell colSpan={6}><Typography variant={"body1"} color={"primary"}>{Strings.formulario} {Strings.prestamo}</Typography> </TableCell>
                        </TableRow>
                        {[1,2,3,4].map((e)=>
                            <TableRow key={e}>
                                <TableCell>preCampo{e}</TableCell>
                                <TableCell>
                                    <TextField name={"preCampo"+e} size={"small"}  inputProps={{maxLength:20}} value={values["preCampo"+e]?.name ?? ""} onChange={handleChange}/>
                                </TableCell>
                                <TableCell padding="checkbox" align={"center"}>
                                    <Checkbox name={"preCampo"+e} size={"small"} checked={values["preCampo"+e]?.isRequired ?? false} onChange={handleChange} />
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
