import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { mostrarCajasSinoEsta } from '../../redux/actions/cajasActions';
import {FormControl, InputLabel} from "@mui/material";
import {SelectSmart} from "../CustomTextField";
import {formatPeso, hasPermissionTo, PERSMISOS, PERSMISOS_USUARIOS} from "../../helpers";
import Strings from "../../assets/strings"

function SelectCaja(
    { value = "", name = "cajId",label=Strings.caja, onChange,disabled,required, cajas,
        allowNone=true,
        allowAll,
        removeOption,mostrarCajasSinoEsta}) {

    useEffect(() => {
        if((hasPermissionTo(PERSMISOS.cajas) && hasPermissionTo(PERSMISOS_USUARIOS.cajas))){
            mostrarCajasSinoEsta();
        }
    }, [mostrarCajasSinoEsta]);

    let { list = [] } = cajas;

    if(!onChange){
        let selected = list.find(e=>e.cajId === value);
        if(selected) return selected.cajDescripcion;

        return "-"
    }

    list = list.map(opc=>({
        key:opc.cajId,
        value:opc.cajDescripcion +(hasPermissionTo(PERSMISOS.cajas) ?" - "+ formatPeso(opc.cajBalance):"")
    }));
    if(allowAll) list.unshift({key:"-1",value:Strings.todo});
    if(allowNone) list.unshift({key:"",value:Strings.ninguna});
    if(removeOption){
        list = list.filter(opt=>opt.key !== parseInt(removeOption))
    }

    return (
        <FormControl fullWidth required={required} disabled={disabled}>
            <InputLabel shrink>{label}</InputLabel>
            <SelectSmart
                value={value && value.toString()}
                onChange={onChange}
                name={name}
                disabled={disabled}
                required={required}
                loading={cajas.isFetching }
                opciones={list}
            />
        </FormControl>

    );
}

const mapStateToProps = (state) => ({
    cajas: state.cajas,
});
export default connect(mapStateToProps, { mostrarCajasSinoEsta })(SelectCaja);
