import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles(theme => ({
    root: {
        padding: "4px 0px",
        background: "white",
        border: "1px solid " +theme.palette.grey[300],
        marginBottom: "10px",
        textAlign: "right",
        "& .title": {
            fontSize: "1em",
            padding: "6px 21px 9px"
        },
        "& .number": {
            fontSize: "1.2em",
            position: "relative",
            padding: "10px 20px 3px",
            borderBottom: "1px solid #eee"
        },
        "& .number:before": {
            content: '""',
            border: "1px solid #eee",
            background: "white",
            fontSize: "2em",
            borderRadius: "50%",
            width: "55px",
            height: "55px",
            position: "absolute",
            bottom: "-27.4px",
            left: "18.7px"
        },

        "& .number svg": {
            left: "29px",
            width: "38px",
            bottom: "-24px",
            height: "52px",
            position: "absolute"
        },
        [theme.breakpoints.down('md')]: {
            textAlign: "center",
            borderBottom: "none",
            boxShadow:theme.shadows[1],
            marginBottom: 0,

            "& .number:before, & .number svg":{
                display:"none"
            },
            "& .title": {
                color: theme.palette.primary.main,
                padding: "3px 6px 6px",
                fontSize: ".8em",
            },
            "& .number": {
                padding: "5px 6px 0px",
                borderBottom: "none",
                fontSize: "1em",

            },
        },
    },
}));
export default function ClientListItem(props) {

    const classes = useStyles();
    const { title, subTitle, Icon, color = "#d9534f" } = props;
    return (

        <div className={"counterItem "+classes.root}>
            <div className="number">
                {subTitle}
                <Icon sx={{fill:color}}/>
            </div>
            <div className="title">
                {title}
            </div>
        </div>
    )
}
