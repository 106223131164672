import React, {Fragment, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux';
import { agregarEditarCliente, mostrarClienteById } from '../../redux/actions/clientesActions';
import {getLSLang, nullToEmptyString,geoString} from '../../helpers';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Typography,
    Input,
    CircularProgress,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {SelectEnum,SelectRuta,SelectFiles,SelectPais} from "../../components/selects"
import {AppBar,MostrarError,LoadingProgress,ReferenciasCliente} from "../../components";
import {TextFieldCurrency, TextFieldPhone, DateInput} from "../../components/CustomTextField";
import Strings from "../../assets/strings";
import AddAdjuntos from "../../components/AddAdjuntos";
import {useCustomFormInput} from "../../hooks/useCustomFormInput";
import MapSearch from "../../components/MapSearch";
import SelectUsoMultiples from "../../components/selects/SelectUsoMultiples";
const useStyles = makeStyles(theme => ({
    root:{
        padding:"20px",

    },
    avatar:{
        "& img":{
            maxWidth: 150
        },
        [theme.breakpoints.down('md')]: {
            "& img":{
                borderRadius: "50%",
            }
        }

    },
    submitBtn:{
        margin: 20, 
    },
}));
let initialState = {
    cliId: "",
    cliTipo: "1",
    cliRazonSocial:"",
    cliNombres: "",
    cliApellidos: "",
    cliCedula: "",
    cliNacionalidad: getLSLang("c"),

    cliEmail: "",
    cliEstadoCivil: "",
    cliSexo: "",
    cliFechaNacimiento: "",
    cliCelular: "",
    cliTelefono: "",
    cliAvatar: "",
    cliTipoVivienda: "",
    cliDireccion: "",
    cliCoordenadas:"",
    cliDireccion2: "",
    cliEmpleo: "",
    cliTrabajoNombre: "",
    cliTrabajoDireccion: "",
    cliTrabajoTelefono: "",
    cliTrabajoPosicion: "",
    cliTrabajoIntervaloCobro: "",
    cliDiasCobro: "",
    cliIngresos: "",
    cliTiempoLaborando: "",
    referenciaPersonal: "",
    cliTelefonoRefPersonal: "",
    userId: null,
    archivos:[],
    //----------
    cliCodigoPostal: "",
    cliReferencias: [],
    cliDependientes: "",
    ruta: "",
    rutId: ""
}
function CrearCliente(props){
    const classes = useStyles();
    let { cliId:urlCliId} = useParams()
    const {agregarEditarCliente,mostrarClienteById, cliente, isFetching,isPending, error} = props;
    const customFormInput = useCustomFormInput();

    const [state, setState] = useState(initialState);



    useEffect(()=>{
        if(urlCliId) mostrarClienteById(urlCliId);
    },[urlCliId,mostrarClienteById])
    useEffect(()=>{
        if(!!cliente.cliId && cliente.cliId === parseInt(urlCliId)) {
            setState({...initialState, ...nullToEmptyString(cliente)});
        }else{
            setState(initialState)
        }
    },[urlCliId,cliente])
    const handleChange = (e)=>{
        const {name,value} = e.target
        return setState({...state,[name]: value});
    }
    const submit = (e) =>{
        e.preventDefault();
        let obj = {...state}

        if(obj.cliAvatar === cliente.cliAvatar )
            obj.cliAvatar = "";
        if(obj.cliDocumento1 === cliente.cliDocumento1 )
            obj.cliDocumento1 = "";
        if(obj.cliDocumento2 === cliente.cliDocumento2 )
            obj.cliDocumento2 = "";
        agregarEditarCliente(obj);
    }

    let cliTipo = parseInt(state.cliTipo);

    return(
        <Fragment>
            <AppBar title={state.cliId ? Strings.editar+" "+Strings.prestatario : Strings.crear+" "+Strings.prestatario } >
                <Button color="inherit" disabled={isPending} type="submit" form="form1" value="Submit">
                    {isPending? (
                        <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                    ):(
                        <span>{Strings.guardar}</span>
                    )}
                </Button>
            </AppBar>
            <div className={"contentInner " + classes.root}>
                <MostrarError errors={error}/>
                {(isFetching || isPending) && <LoadingProgress vh/>}

                <form id="form1" onSubmit={submit} autoComplete={"off"}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                        <div className={classes.avatar}>
                            <SelectFiles src={state.cliAvatar ? state.cliAvatar : null} emp={cliTipo === 2}
                                         onChange={(baseb4)=>setState({ ...state, cliAvatar: baseb4 } )}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} md={3}>
                                <SelectEnum value={cliTipo} name={"cliTipo"} label={Strings.tipo} required
                                            onChange={handleChange} group={"tiposCliente"} />
                            </Grid>
                            {cliTipo === 2 &&
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    name={"cliRazonSocial"}
                                    label={Strings.razonSocial}
                                    value={state.cliRazonSocial}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    required
                                    fullWidth
                                />
                            </Grid>}
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    name={"cliNombres"}
                                    label={Strings.primerNombre}
                                    placeholder={cliTipo === 2 ? Strings.representante:""}
                                    value={state.cliNombres}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextField
                                    name="cliApellidos"
                                    label={Strings.apellidos}
                                    placeholder={cliTipo === 2 ? Strings.representante:""}
                                    value={state.cliApellidos}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    required
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6} sm={4} md={3}>
                                <TextField
                                    name="cliCedula"
                                    label={cliTipo === 2 ? geoString("rnc",true):Strings.cedula}
                                    placeholder={cliTipo === 2 ? geoString("rnc",true):""}
                                    value={state.cliCedula}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 20}}
                                    fullWidth
                                    required
                                />
                            </Grid>
                            {cliTipo !== 2 &&
                                <Grid item xs={6} sm={4} md={3}>
                                    <FormControl fullWidth >
                                        <InputLabel>{Strings.genero}</InputLabel>
                                        <Select
                                            value={state.cliSexo}
                                            onChange={handleChange}
                                            input={<Input name="cliSexo" />}
                                        >
                                            <MenuItem value="M">{Strings.masculino}</MenuItem>
                                            <MenuItem value="F">{Strings.femenino}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            }
                            <Grid item xs={12} sm={4} md={3}>
                                <TextFieldPhone
                                    flag
                                    name="cliCelular"
                                    label={Strings.celular}
                                    value={state.cliCelular}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 20}}
                                    required={!state.cliTelefono}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <TextFieldPhone
                                    flag
                                    name="cliTelefono"
                                    label={Strings.telefono}
                                    value={state.cliTelefono}
                                    onChange={handleChange}
                                    required={!state.cliCelular}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={4} md={3}>
                                <SelectPais
                                    name="cliNacionalidad"
                                    display="paiNacionalidad"
                                    label={Strings.nacionalidad}
                                    value={state.cliNacionalidad}
                                    onChange={handleChange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    {cliTipo !== 2 && <>
                        <Grid item xs={6} sm={3} md={3}>
                            <SelectEnum value={state.cliTipoVivienda} name={"cliTipoVivienda"} label={Strings.vivienda}
                                        onChange={handleChange} group={"tiposVivienda"} />
                        </Grid>
                        <Grid item xs={6} sm={3} md={4} lg={2}>
                            <SelectEnum value={state.cliEmpleo} name={"cliEmpleo"} label={Strings.situacionLaboral}
                                            onChange={handleChange} group={"condicionesLaboral"} />
                        </Grid>
                        <Grid item xs={6} sm={3} md={3} lg={2}>
                            <TextFieldCurrency
                                name="cliIngresos"
                                label={Strings.ingreso}
                                value={state.cliIngresos}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <SelectEnum value={state.cliEstadoCivil} name={"cliEstadoCivil"} label={Strings.estadoCivil}
                                        onChange={handleChange} group={"estadosCivil"} />
                        </Grid>
                        <Grid item xs={6} sm={3} md={2}>
                            <TextField

                                name="cliDependientes"
                                label={Strings.dependientes}
                                value={state.cliDependientes}
                                type="number"
                                inputProps={{step:1,inputMode:"numeric"}}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                    </>}

                    <Grid item xs={12} sm={cliTipo === 2?6:9} md={cliTipo === 2?6:5} lg={5}>
                        <MapSearch handleChange={(obj)=>setState({...state,...obj})}
                                   cliDireccion = {state.cliDireccion} cliCoordenadas={state.cliCoordenadas}/>
                    </Grid>

                    <Grid item xs={12}  sm={cliTipo === 2?6:5} md={cliTipo === 2?6:5} lg={5}>
                        <TextField
                            name="cliDireccion2"
                            label={Strings.direccion + " 2"}
                            value={state.cliDireccion2}
                            placeholder={Strings.referenciaDireccion}
                            onChange={handleChange}
                            inputProps={{maxLength: 100}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} lg={2}>
                        <TextField
                            name="cliCodigoPostal"
                            label={Strings.codigoPostal}
                            value={state.cliCodigoPostal}
                            onChange={handleChange}
                            inputProps={{maxLength: 20}}
                            fullWidth
                        />
                    </Grid>
                    {cliTipo !== 2 &&
                    <Grid item xs={12} sm={4} lg={3}>
                            <DateInput
                                openTo={"year"}
                                clearable
                                value={state.cliFechaNacimiento}
                                name="cliFechaNacimiento"
                                label={Strings.fechaNacimiento}
                                onChange={handleChange}
                                fullWidth
                            />
                    </Grid>}
                    {/*<Grid item xs={6} sm={4} md={4} lg={2}>*/}
                    {/*    <TextField*/}
                    {/*        name="cliFaceBook"*/}
                    {/*        label="FaceBook"*/}
                    {/*        value={state.cliFaceBook}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        inputProps={{maxLength: 50}}*/}
                    {/*        fullWidth*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    {/*<Grid item xs={6} sm={4} md={4} lg={2}>*/}
                    {/*    <TextField*/}
                    {/*        name="cliInstagram"*/}
                    {/*        label="Instagram"*/}
                    {/*        value={state.cliInstagram}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        inputProps={{maxLength: 50}}*/}
                    {/*        fullWidth*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={4} lg={2}>
                        <TextField
                            name="cliEmail"
                            type={"email"}
                            label="Email"
                            value={state.cliEmail}
                            onChange={handleChange}
                            inputProps={{maxLength: 50}}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={4} lg={3}>
                        <SelectRuta value={state.rutId}
                                    name={"rutId"}
                                    label={Strings.ruta}
                                    onChange={handleChange}  />
                    </Grid>


                    {customFormInput && Object.keys(customFormInput).map((key)=>
                        <Grid item xs={12} sm={4} lg={2} key={key}>

                            {!!customFormInput[key].source?(
                                <SelectUsoMultiples
                                    emptyOption={{key:"",value:Strings.ninguna}}
                                    group={customFormInput[key].source}
                                    name={key}
                                    label={customFormInput[key].name}
                                    required={customFormInput[key].isRequired}
                                    value={state[key] ? state[key] : ""}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />

                            ):(
                                <TextField
                                    name={key}
                                    label={customFormInput[key].name}
                                    required={customFormInput[key].isRequired}
                                    value={state[key] ? state[key] : ""}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 50}}
                                    fullWidth
                                />
                            )}

                        </Grid>
                    )}
                </Grid>
                {state.cliEmpleo.toString() === "1" && cliTipo !== 2 &&
                    <>
                        <br/>
                    <Typography color={"secondary"}>{Strings.empresa}</Typography>
                        <br/>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                            name="cliTrabajoNombre"
                            label={Strings.empresa}
                            value={state.cliTrabajoNombre}
                            onChange={handleChange}
                            inputProps={{maxLength: 50}}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <SelectEnum value={state.cliTrabajoIntervaloCobro} name={"cliTrabajoIntervaloCobro"}
                                        label={Strings.intervaloCobro} onChange={handleChange} group={"ciclosPago"} />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                name="cliDiasCobro"
                                label={Strings.diasCobro}
                                value={state.cliDiasCobro}
                                onChange={handleChange}
                                inputProps={{maxLength: 50}}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                            name="cliTrabajoPosicion"
                            label={Strings.posicion}
                            value={state.cliTrabajoPosicion}
                            onChange={handleChange}
                            inputProps={{maxLength: 50}}
                            fullWidth
                            required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                            name="cliTiempoLaborando"
                            label={Strings.tiempoLaborando}
                            value={state.cliTiempoLaborando}
                            inputProps={{maxLength: 50}}
                            onChange={handleChange}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextFieldPhone
                            name="cliTrabajoTelefono"
                            label={Strings.telefono}
                            value={state.cliTrabajoTelefono}
                            onChange={handleChange}
                            fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                            name="cliTrabajoDireccion"
                            label={Strings.direccion}
                            value={state.cliTrabajoDireccion}
                            onChange={handleChange}
                            inputProps={{maxLength: 100}}
                            fullWidth
                            />
                        </Grid>
                    </Grid>
                    </>
                }
                    <br/>

                    <AddAdjuntos adjuntos={state.archivos} adjlimit={7}
                                 onChange={(archivos)=>setState({...state,archivos})} />
                    <br/>
                    <ReferenciasCliente referencias={state.cliReferencias}
                                        onChange={(cliReferencias)=>setState({...state,cliReferencias})} />
            </form>
            <br/>
            </div>
        </Fragment>
    );

}

const mapStateToProps = (state) => ({
    cliente: state.clientes.byId,
    isPending: state.clientes.isPending,
    isFetching: state.clientes.isFetching,
    error: state.clientes.error
})
export default connect(mapStateToProps, { agregarEditarCliente, mostrarClienteById })(CrearCliente);
