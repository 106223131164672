import {dashBoardTypes, prestamoTypes} from './types';
import {getWidgets, getResumenGrafico, getCxC, getNotificaciones, getWidgetsGasIng} from '../../services';
import _ from "lodash";

export function mostrarDashBoardData() {
    return dispatch => {
        dispatch({
            type: dashBoardTypes.MOSTRAR_DASHBOARD_DATA,
            payload: Promise.all([getWidgets(),getResumenGrafico()])
        });
        dispatch({
            type: prestamoTypes.MOSTRAR_CxC,
            payload: getCxC()
        })
    }
}
export function mostrarCxC(filter) {
    return dispatch => {
        dispatch({
            type: prestamoTypes.MOSTRAR_CxC,
            payload: getCxC(filter)
        })
    }
}
export function mostrarWidgetsGasIng(filter) {
    return dispatch => {
        dispatch({
            type: dashBoardTypes.MOSTRAR_WIDGETS_GASING,
            payload: getWidgetsGasIng(filter)
        })
    }
}
export function mostrarGetNotificaciones() {
    return dispatch => {
        dispatch({
            type: dashBoardTypes.MOSTRAR_NOTIFICACIONES,
            payload: getNotificaciones()
        })
    }
}
export function setDashBoardFilter(filter = {}) {
    let params = _.omitBy(filter, (v) => v === '' || _.isNil(v));
    if(params.estado && params.estado === "incluirCxcAldia"){
        mostrarCxC({incluirCxcAldia:true})
    }
    return dispatch => {
        dispatch({
            type: dashBoardTypes.SET_FILTER,
            payload: {cxc:params}
        })
    }
}

// export function mostrarIngresosGrafico(year) {
//     return dispatch => {
//         dispatch({
//             type: dashBoardTypes.MOSTRAR_DASHBOARD_DATA,,
//             payload: getResumenGrafico(year)
//         }).catch(({response})=>{
//             dispatch(Notifications.error(errorToNotifObj(response)));
//         })
//     }
// }