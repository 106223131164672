import React, {useEffect} from 'react';
import { Editor } from '@tinymce/tinymce-react';
import LoadingProgress from "../../components/LoadingProgress";
import {geoString, getLSLang} from "../../helpers";
import Strings from "../../assets/strings";
import {useCustomFormInput} from "../../hooks/useCustomFormInput";
import {useSelector} from "react-redux";
export default function ContratoEditor({name,value,onChange,disabled}){
    const [loading, setLoading] = React.useState(true);
    const customFormInputCli = useCustomFormInput();
    const customFormInputPre = useCustomFormInput("pre");
    const generales = useSelector(state => state.generales)

    const language = getLSLang("l")

    useEffect(()=>{
        setLoading(generales.isFetching)
    },[generales,setLoading])


    const setting = {
        language,
        menubar: false,
        height: 500,
        branding: false,
        inline_boundaries: false,
        inline_boundaries_selector: 'em',
        // statusbar: false,
        //
        plugins: 'code  template table searchreplace directionality insertdatetime', //insert
        toolbar: 'undo redo | template | contrato | parametros | table | formatselect  | bold italic underline strikethrough  | alignleft aligncenter alignright alignjustify  | code |  searchreplace removeformat',
        image_advtab: true,
        templates: [
            { title: Strings.contrato, description:"", url: "/contratoTemplate/"+language+"/contrato.html" },
            { title: Strings.pagareNotarial, description:"", url: "/contratoTemplate/"+language+"/pagare.html" }
        ],
        mobile: {
            theme: 'silver',
        },
        setup: function (editor) {
            editor.on('init', function () {
                this.getDoc().body.style.fontSize = '16px';
                this.getDoc().body.style.fontFamily = 'Georgia';
            });
            editor.ui.registry.addMenuButton('parametros', {
                text: Strings.parametros,
                fetch: function (callback) {
                    let items = [
                        {
                            type: 'nestedmenuitem',
                            text: Strings.empresa,
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: Strings.nombreCompleto,
                                        onAction: function (_) {
                                            editor.insertContent(' $PrestadorNombre');
                                        }
                                    },{
                                        type: 'menuitem',
                                        text: Strings.empresa+" "+Strings.nombres,
                                        onAction: function (_) {
                                            editor.insertContent(' $EmpresaNombre');
                                        }
                                    },{
                                        type: 'menuitem',
                                        text: Strings.telefono,
                                        onAction: function (_) {
                                            editor.insertContent(' $EmpresaTelefono');
                                        }
                                    },{
                                        type: 'menuitem',
                                        text: Strings.celular,
                                        onAction: function (_) {
                                            editor.insertContent(' $EmpresaCelular');
                                        }
                                    },{
                                        type: 'menuitem',
                                        text: Strings.email,
                                        onAction: function (_) {
                                            editor.insertContent(' $EmpresaEmail');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.direccion,
                                        onAction: function (_) {
                                            editor.insertContent(' $PrestadorDireccion');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.interes,
                                        onAction: function (_) {
                                            editor.insertContent(' $MoraInteres');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: geoString("rnc",true),
                                        onAction: function (_) {
                                            editor.insertContent(' $RNC');
                                        }
                                    }
                                ]
                            }
                        },
                        {
                            type: 'nestedmenuitem',
                            text: Strings.prestatario,
                            getSubmenuItems: function () {
                                return [...[
                                    {
                                        type: 'menuitem',
                                        text: Strings.infoCompleta,
                                        onAction: function (_) {
                                            editor.insertContent(' $ClienteInfo');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.nombreCompleto,
                                        onAction: function (_) {
                                            editor.insertContent(' $NombreCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.apellidos,
                                        onAction: function (_) {
                                            editor.insertContent(' $ApellidoCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.cedula,
                                        onAction: function (_) {
                                            editor.insertContent(' $CedulaCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.direccion,
                                        onAction: function (_) {
                                            editor.insertContent(' $DireccionCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.telefono,
                                        onAction: function (_) {
                                            editor.insertContent(' $TelefonoCliente');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.nacionalidad,
                                        onAction: function (_) {
                                            editor.insertContent(' $Nacionalidad');
                                        }
                                    }
                                ],
                                    ...(Object.keys(customFormInputCli || {}).map(e=>({
                                        type: 'menuitem',
                                        text: customFormInputCli[e]?.name,
                                        onAction: function (_) {
                                            editor.insertContent(' $'+e.charAt(0).toUpperCase() + e.slice(1));
                                        }
                                    })))
                                ]
                            }
                        }, {
                            type: 'nestedmenuitem',
                            text: Strings.codeudor,
                            getSubmenuItems: function () {
                                return [
                                    {
                                        type: 'menuitem',
                                        text: Strings.nombreCompleto,
                                        onAction: function (_) {
                                            editor.insertContent(' $CodeudorNombre');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.cedula,
                                        onAction: function (_) {
                                            editor.insertContent(' $CodeudorCedula');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.direccion,
                                        onAction: function (_) {
                                            editor.insertContent(' $CodeudorDireccion');
                                        }
                                    }
                                ]
                            }
                        }, {
                            type: 'nestedmenuitem',
                            text: Strings.prestamo,
                            getSubmenuItems: function () {
                                return [...[
                                    {
                                        type: 'menuitem',
                                        text: Strings.fecha,
                                        getSubmenuItems: () => [
                                            {
                                                type: 'menuitem',
                                                text: Strings.fechaApertura,
                                                onAction: function (_) {
                                                    editor.insertContent(' $FechaPrestamo');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: Strings.fechaTermino,
                                                onAction: function (_) {
                                                    editor.insertContent(' $FechaTermino');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: Strings.anio+" ("+Strings.letra+")",
                                                onAction: function (_) {
                                                    editor.insertContent(' $LAnoPrestamo');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: Strings.mes+" ("+Strings.letra+")",
                                                onAction: function (_) {
                                                    editor.insertContent(' $LMesPrestamo');
                                                }
                                            },
                                            {
                                                type: 'menuitem',
                                                text: Strings.dia+" ("+Strings.letra+")",
                                                onAction: function (_) {
                                                    editor.insertContent(' $LDiaPrestamo ');
                                                }
                                            }
                                        ]
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.interes,
                                        onAction: function (_) {
                                            editor.insertContent(' $Interes');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.plazo,
                                        onAction: function (_) {
                                            editor.insertContent(' $PlazoPrestamo');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: `${Strings.capital} ${Strings.prestado}`,
                                        onAction: function (_) {
                                            editor.insertContent(' $MontoPrestamo');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.balancePendiente,
                                        onAction: function (_) {
                                            editor.insertContent(' $BalancePendiente ');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.capitalActual,
                                        onAction: function (_) {
                                            editor.insertContent(' $CapitalActual');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.monto+" "+Strings.cuota,
                                        onAction: function (_) {
                                            editor.insertContent(' $MontoCuota');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.totalApagar,
                                        onAction: function (_) {
                                            editor.insertContent(' $TotalPagos');
                                        }
                                    }, {
                                        type: 'menuitem',
                                        text: Strings.codigoRef,
                                        onAction: function (_) {
                                            editor.insertContent(' $RefPrestamo');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: Strings.total+" "+Strings.interes,
                                        onAction: function (_) {
                                            editor.insertContent(' $TotalInteres');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: Strings.total+" "+Strings.interes+" ("+Strings.letra+")",
                                        onAction: function (_) {
                                            editor.insertContent(' $LTotalInteres');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: Strings.monto+" "+Strings.prestamo+" ("+Strings.letra+")",
                                        onAction: function (_) {
                                            editor.insertContent(' $LMontoPrestamo');
                                        }
                                    },
                                    {
                                        type: 'menuitem',
                                        text: Strings.totalPagado+" ("+Strings.letra+")",
                                        onAction: function (_) {
                                            editor.insertContent(' $LTotalPagos');
                                        }
                                    }

                                ],
                                    ...(Object.keys(customFormInputPre || {}).map(e=>({
                                        type: 'menuitem',
                                        text: customFormInputPre[e]?.name,
                                        onAction: function (_) {
                                            editor.insertContent(' $'+e.charAt(0).toUpperCase() + e.slice(1));
                                        }
                                    })))
                                ]
                            }
                        },
                        {
                            type: 'menuitem',
                            text: Strings.fecha,
                            onAction: function (_) {
                                editor.insertContent(' $FechaActual');
                            }
                        }
                    ];
                    callback(items);
                }
            });
        }
    }


    if(loading) return <LoadingProgress/>;

    return(
        <div className={"contPagare"} >
            <Editor init={setting}
                    disabled={disabled}
                    apiKey='wz24bwywc9e9y0hrtovishcbc4en66fzp25qnun8qjlxzkcj'
                    value={value}
                {...(onChange && {
                    onEditorChange : (content)=> onChange({target:{name, value: content}})
                })}
            />
        </div>
    )
}

