import React, {useState, useEffect} from 'react';
import { Alert } from '@mui/material';
import Collapse from "@mui/material/Collapse";

// import PropTypes from 'prop-types';

export function MostrarError({ errors, action,onClose, fixed=true}) {
    const [mensaje, setMensaje] = useState("");
    const [errorsList, setErrorsList] = useState([]);
    useEffect(() => {
        setMensaje("");
        setErrorsList([]);
        if(errors && typeof errors === "string"){
            setMensaje(errors)
        }else if (errors && errors.data) {
            let tipo = typeof errors.data;

            if (tipo === "string"){
                setMensaje(errors.data)
            }else if (tipo === "object"){
                let { title, errors: errores={}} = errors.data;
                if (title){
                    setMensaje(title)
                    setErrorsList(errores)
                }

            }
        }
    }, [setErrorsList, setMensaje, errors]);
    const handleClose = () => {
        if(onClose) {
          return  onClose();
        }
        setMensaje("");
        setErrorsList([]);
    };

        return (
            <Collapse in={Boolean(mensaje.length)}>
                <Alert severity="warning" action={action} onClose={handleClose}  className={fixed?"fixed":""}>
                    {mensaje}
                    {Object.keys(errorsList).length > 0 &&
                        <ul>
                            {Object.keys(errorsList).map(key=>
                                <li key={key}>{key} : {(typeof errorsList[key] === "object") ? errorsList[key].join(" / ") : errorsList[key]}</li>
                            )}
                        </ul>
                    }
                </Alert>
            </Collapse>
        );
}

// MostrarError.propTypes = {
//     errors: PropTypes.ojb,
// }