import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {KeyboardArrowRight,KeyboardArrowLeft,Close} from '@mui/icons-material';
import {IconButton} from "@mui/material";


const tutorialSteps = [
    {
        logo: 'https://dsegura.net/img/gallery/mobileApp/loanCal.png',
        name: 'LoanCal - Gratis',
        link: 'https://play.google.com/store/apps/details?id=com.dsegura.loancal',
        desc: 'Esta aplicación te permite calcular los pagos de tus préstamos para que puedas tener un cálculo antes de solicitarlos.',
    },
    {
        logo: 'https://dsegura.net/img/gallery/mobileApp/gestinmueble.png',
        name: 'GestInmueble',
        link: 'https://gestinmueble.com/',
        desc: 'Sistema de administración de inmuebles diseñado para inmobiliaria o toda persona relacionada con la gestión de inmuebles.',
    },
    {
        logo: 'https://dsegura.net/img/gallery/mobileApp/prestamistapp.png',
        name: 'Prestamistapp',
        link: 'Prestamistapp',
        desc: 'Sistema de administración de préstamos diseñado para financieras y prestamistas que deseen automatizar su negocio.',
    },
    {
        logo: 'https://dsegura.net/img/gallery/mobileApp/gasting.png',
        name: 'Gasting',
        link: 'https://play.google.com/store/apps/details?id=net.dsgura.gasting',
        desc: 'Esta aplicación le permite realizar un seguimiento fácil de sus ingresos y gastos.',
    },

];
const useStyles = makeStyles((theme)=>({
    root: {
        maxWidth: 400,
        flexGrow: 1,
        textAlign: "center",
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        display: "inline-block",
        width: "130px",
        background: "white",
        marginBottom: "8px",
        boxShadow:
            "0px 2px 1px -1px #c7c7c7, 0px 1px 1px 0px #ababab, 0px 1px 3px 0px #bdbdbd",
        borderRadius: "5px"
    },
    closeBtn:{
        width: "50px",
        height: "50px",
        position: "absolute",
        right: "0",
        top: "0px"
    }
}));
export default function OtherAppPromo({handleClose=()=>{},open=false}) {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = tutorialSteps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <IconButton
                    className={classes.closeBtn}
                    color={"secondary"}
                    onClick={handleClose}
                    size="large"><Close/></IconButton>
                <div className={classes.root}>
                    <a href={tutorialSteps[activeStep].desc}>
                        <img
                            className={classes.img}
                            src={tutorialSteps[activeStep].logo}
                            alt={tutorialSteps[activeStep].name}
                        />
                    </a>
                    <Typography variant={"h6"}>{tutorialSteps[activeStep].name}</Typography>
                    <Typography>{tutorialSteps[activeStep].desc}</Typography>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            </Button>
                        }
                    />
                </div>
            </DialogContent>
        </Dialog>
    );
}
