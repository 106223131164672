import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {Button} from '@mui/material';
import {SelectSmart} from "./CustomTextField";
import Box from "@mui/material/Box";

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: "100%",
    boxShadow: "none",
  },
  btn: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 39,
    }
  },
  label:{
    position: "relative",
    "& .MuiInputBase-root":{
      position: "absolute",
      left: "0",
      width: "100%",
      marginTop: "0 !important",
      paddingRight: 0,
      opacity: "0"
    }
  }
}));
export default function ButtonSelect({smIcon,text,opcions,disabled,onChange}){
  const classes = useStyles();
  const [value] = useState("");


  const handleChange = (e)=>{
    const {value} = e.target
    onChange(value)
  }

  return (
    <label className={classes.label}>
      <Button  color="inherit" className={classes.btn} disabled={disabled}>
        {(text && smIcon)?(
            <>
              <Box component="span" sx={{ display: { xs: 'none', md: 'block' } }} >{text}</Box>
              <Box component="span" sx={{ display: { sm: 'block', md: 'none', } }} >{smIcon}</Box>
            </>
            ):(smIcon || smIcon)
        }
      </Button >
      <SelectSmart
          disabled={disabled}
          value={value}
          onChange={handleChange}
          opciones={opcions}
      />
    </label>
  );
}

