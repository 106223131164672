export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const USER_LOGOUT = 'USER_LOGOUT';


export const themeTypes = {
    CLOSE_DRAWER: 'CLOSE_DRAWER',
    OPEN_DRAWER: 'OPEN_DRAWER',
    CLOSE_PAYPAL_PLAN: 'CLOSE_PAYPAL_PLAN',
    OPEN_PAYPAL_PLAN:"OPEN_PAYPAL_PLAN",
    TOGGLE_VIEW_TABLE: 'TOGGLE_VIEW_TABLE',
    SET_INSTALL_BANNER_EVENT: 'SET_INSTALL_BANNER_EVENT'
};
export const dashBoardTypes = {
    MOSTRAR_DASHBOARD_DATA: 'MOSTRAR_DASHBOARD_DATA',
    MOSTRAR_WIDGETS_GASING: 'MOSTRAR_WIDGETS_GASING',
    MOSTRAR_NOTIFICACIONES: 'MOSTRAR_NOTIFICACIONES',
    SET_FILTER: 'SET_FILTER',
};
export const usuarioTypes = {
    MOSTRAR_PERFIL: 'MOSTRAR_PERFIL',
    EDITAR_PERFIL: 'EDITAR_PERFIL',
};
export const pagoTypes = {
    MOSTRAR_PAGOS: 'MOSTRAR_PAGOS',
    MOSTRAR_PAGOS_DE_CONTRATO: 'MOSTRAR_PAGOS_DE_CONTRATO',
    ANULAR_PAGO: 'ANULAR_PAGO',
};
export const appUserTypes = {
    MOSTRAR_APPUSERS: 'MOSTRAR_APPUSERS',
    UPDATE_APPUSER: 'UPDATE_APPUSER',
};
export const gastoTypes = {
    MOSTRAR_GASTOS: 'MOSTRAR_GASTOS',
    AGREGAR_GASTO: 'AGREGAR_GASTO',
    ELIMINAR_GASTO: 'ELIMINAR_GASTO'
};
export const prestamoTypes = {
    MOSTRAR_CxC: 'MOSTRAR_CxC',
    MOSTRAR_PRESTAMOS: 'MOSTRAR_PRESTAMO',
    MOSTRAR_PRESTAMO_POR_ID: 'MOSTRAR_PRESTAMO_POR_ID',
    MOSTRAR_PRESTAMO_POR_ID_ENCONTRADO:"MOSTRAR_PRESTAMO_POR_ID_ENCONTRADO",
    AGREGAR_EDITAR_PRESTAMO: 'AGREGAR_EDITAR_PRESTAMO',
    AGREGAR_PAGO: 'AGREGAR_PAGO',
    CANCELAR_PRESTAMO: 'CANCELAR_PRESTAMO',
    AGREGAR_PRE_NOTA: 'AGREGAR_PRE_NOTA',
    ELIMINAR_PRE_NOTA: 'ELIMINAR_PRE_NOTA',
    TOGGLE_MODAL_PAGO_PRESTAMO: 'TOGGLE_MODAL_PAGO_PRESTAMO',
    MARCAR_PRESTAMOS_IMCOBRABLE: 'MARCAR_PRESTAMOS_IMCOBRABLE',

};
export const solicitudTypes = {
    MOSTRAR_SOLICITUDES: 'MOSTRAR_SOLICITUDES',
    MOSTRAR_SOLICITUD_POR_ID: 'MOSTRAR_SOLICITUD_POR_ID',
    AGREGAR_EDITAR_SOLICITUD: 'AGREGAR_EDITAR_SOLICITUD',
    APROBAR_SOLICITUD: 'APROBAR_SOLICITUD',
    RECHAZAR_SOLICITUD: 'RECHAZAR_SOLICITUD',
};
export const clienteTypes = {
    MOSTRAR_CLIENTES: 'MOSTRAR_CLIENTES',
    MOSTRAR_CLIENTE_POR_ID: 'MOSTRAR_CLIENTE_POR_ID',
    MOSTRAR_CLIENTE_POR_ID_ENCONTRADO: 'MOSTRAR_CLIENTE_POR_ID_ENCONTRADO',
    AGREGAR_EDITAR_CLIENTE: 'AGREGAR_EDITAR_CLIENTE',
    EDIT_CLIENTE_RATING: 'EDIT_CLIENTE_RATING',

    ELIMINAR_CLIENTE: 'ELIMINAR_CLIENTE',
    LIMPIAR_CLIENTE_POR_ID: 'LIMPIAR_CLIENTE_POR_ID'
};
export const rutaTypes = {
    MOSTRAR_RUTAS: 'MOSTRAR_RUTAS',
    AGREGAR_EDITAR_RUTA: 'AGREGAR_EDITAR_RUTA',
    ELIMINAR_RUTA: 'ELIMINAR_RUTA',
};
export const carteraTypes = {
    MOSTRAR_CARTERAS: 'MOSTRAR_CARTERAS',
    AGREGAR_EDITAR_CARTERA: 'AGREGAR_EDITAR_CARTERA',
    ELIMINAR_CARTERA: 'ELIMINAR_CARTERA',
    MOSTRAR_CARTERA_POR_ID:"MOSTRAR_CARTERA_POR_ID",
    MOSTRAR_CARTERA_POR_ID_ENCONTRADO:"MOSTRAR_CARTERA_POR_ID_ENCONTRADO"
};
export const plantillaTypes = {
    MOSTRAR_PLANTILLAS: 'MOSTRAR_PLANTILLAS',
    MOSTRAR_PLANTILLA_POR_ID: 'MOSTRAR_PLANTILLA_POR_ID',
    MOSTRAR_PLANTILLA_POR_ID_ENCONTRADO: 'MOSTRAR_PLANTILLA_POR_ID_ENCONTRADO',

    AGREGAR_EDITAR_PLANTILLA: 'AGREGAR_EDITAR_PLANTILLA',
    ELIMINAR_PLANTILLA: 'ELIMINAR_PLANTILLA',
};
export const generalesTypes = {
    MOSTRAR_GENERALES: 'MOSTRAR_GENERALES',
    EDITAR_GENERALES: 'EDITAR_GENERALES',
    SET_GENERALES: 'SET_GENERALES'
};
export const cajaTypes = {
    MOSTRAR_CAJAS: 'MOSTRAR_CAJAS',
    MOSTRAR_CAJAS_POR_ID: 'MOSTRAR_CAJAS_POR_ID',
    AGREGAR_EDITAR_CAJA: 'AGREGAR_EDITAR_CAJA',
    AJUSTAR_CAJA: 'AJUSTAR_CAJA',
    CERRAR_CAJA: 'CERRAR_CAJA',
    ELIMINAR_CAJA: 'ELIMINAR_CAJA',
    ELIMINAR_CAJA_CIERRE: 'ELIMINAR_CAJA_CIERRE',
    TRANSFERENCIA_CAJA: 'TRANSFERENCIA_CAJA'
};
export const usosMultiplesTypes = {
    MOSTRAR_USOS_MULTIPLES: 'MOSTRAR_USOS_MULTIPLES',
    AGREGAR_EDITAR_USO_MULTIPLE: 'AGREGAR_EDITAR_USO_MULTIPLE',
    ELIMINAR_USO_MULTIPLE: 'ELIMINAR_USO_MULTIPLE'
};
export const rolTypes = {
    MOSTRAR_ROLES: 'MOSTRAR_ROLES',
    AGREGAR_EDITAR_ROL: 'AGREGAR_EDITAR_ROL',
    ELIMINAR_ROL: 'ELIMINAR_ROL',
};
export const paisTypes = {
    MOSTRAR_PAISES: 'MOSTRAR_PAISES',
    SET_PAISES: 'SET_PAISES',
};
export const zonasHorariasTypes = {
    MOSTRAR_ZONAS_HORARIAS: 'MOSTRAR_ZONAS_HORARIAS',
    SET_ZONAS_HORARIAS: 'SET_ZONAS_HORARIAS',
};
export const agenteTypes = {
    MOSTRAR_AGENTES: 'MOSTRAR_AGENTES',
    AGREGAR_EDITAR_AGENTE:"AGREGAR_EDITAR_AGENTE",
    EDITAR_CONTRASENA_AGENTE:"EDITAR_CONTRASENA_AGENTE",
    LOCK_TOGGLE_AGENTE:"LOCK_TOGGLE_AGENTE",
    ELIMINAR_AGENTE:"ELIMINAR_AGENTE",
};
export const enumsTypes = {
    MOSTRAR_ENUMS: 'MOSTRAR_ENUMS',
    SET_ENUMS: 'SET_ENUMS',
};
//COMPARTIDOS
export const _PENDING = '_PENDING';
export const _REJECTED = '_REJECTED';
export const _FULFILLED = '_FULFILLED';
