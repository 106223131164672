import React, {useState} from 'react';
import {API_PUBLIC} from '../../services';
import {Link, Redirect, useHistory, useLocation} from 'react-router-dom';
import { connect } from 'react-redux';
import {mostrarPerfil} from '../../redux/actions/authentication';
import {
    TextField,
    Button,
    Typography,
    CircularProgress,
    IconButton,
    InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import styles from "../../assets/jss/login";
import Strings from "../../assets/strings";
import {getLSLang, setLSLang} from "../../helpers";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {isMobile} from "../../helpers/browser_detect";
const useStyles = makeStyles(styles);

function Login(props){
    const {goBack,...history} = useHistory();
    const {state:urlState} = useLocation();

    const backurl = (urlState && urlState.backurl) ? urlState.backurl:"/inicio";
    const [showPass, setShowPass] = useState(false);

    const [error, setError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const [state, setState] = useState({
        lang:getLSLang("l"),
        user:"",
        password:"",
        recordar:false,
    });

    const handleChange = (e)=>{
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };
    // const handleChangeBox = name => event => {
    //     setState({...state, [name]: event.target.checked });
    // };
    const submitForm = (e) =>{
        e.preventDefault();
        setLoading(true);
        setError("");
        API_PUBLIC.post('LogIn',{
            userName: state.user,
            userPassword: state.password,
           // recordar: state.recordar,
        })
        .then((response) =>{
            localStorage.setItem("_token", response.data.tocken);

            //sen tocken to webview
            if (isMobile?.AndroidWebView()) {
                if(window?.Android?.logged){
                    window.Android.logged(response.data.tocken);
                }
            }else if(isMobile?.IosWebView()){
                //window?.webkit?.messageHandlers?.shareFromBase64?.logged(response.data.tocken);
            }

            // to get the color en currency
            props.mostrarPerfil(response.data);
            history.replace(backurl)
        })
        .catch(({response}) => {
            if(response && response.data && typeof response.data === "string")
                setError(response.data);
            else
                setError("Error");
            setLoading(false)
        });
    };

    const classes = useStyles();

    const {auth } = props;
    if ((auth.isAuthenticated && localStorage.getItem("_token"))) {
        return <Redirect to={backurl}/>;
    }
    return (
        <div className={classes.root}>
            <div className={classes.logo}>
                <Typography variant="h1"  color="primary">PrestamistAPP 2.0</Typography>
                <Typography variant="h3" >{Strings.slogan}</Typography>
            </div>
            <form  onSubmit={submitForm} className={classes.form}>
                <Typography  className={classes.headline}>{Strings.iniciarSesion.toUpperCase()}</Typography>
                {!!error &&
                <Typography color="error" variant="caption">
                    {error}
                </Typography>
                }
                <div>
                    <TextField
                        label={Strings.nombreDeUsuario}
                        required
                        margin="normal"
                        name="user"
                        value={state.user}
                        onChange={handleChange}
                        fullWidth
                        className={"noAsterisk"}
                    />
                    <TextField
                        label={Strings.contrasena}
                        required
                        margin="normal"
                        name="password"
                        type={showPass?"text":"password"}
                        {...(showPass?{autoComplete:"off"}:{})}
                        value={state.password}
                        onChange={handleChange}
                        InputProps={{
                            endAdornment:  <InputAdornment position="end" sx={{position: "absolute",right: 0}}>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    //  onMouseDown={handleMouseDownPassword}
                                    onClick={()=>setShowPass(!showPass)}
                                    size="large">
                                    {showPass ? <Visibility fontSize={"small"}/> : <VisibilityOff fontSize={"small"} />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                        className={"noAsterisk"}
                        fullWidth
                    />
                    {/*<FormControlLabel className={classes.checkCont}*/}
                    {/*    control={*/}
                    {/*        <Checkbox*/}
                    {/*            checked={state.recordar}*/}
                    {/*            onChange={handleChangeBox('recordar')}*/}
                    {/*            value="recordar"*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*    label={Strings.recodarUsuario}*/}
                    {/*/>*/}
                </div>
                <Button
                    disabled={isLoading}
                    variant="contained"
                    color="primary"
                    className={classes.submitBtn}
                    type="submit"
                >
                {isLoading? (
                    <span><CircularProgress size={14} color="inherit" /> &nbsp; {Strings.cargando}</span>
                ):(
                    <span>{Strings.entrar}</span>
                )}
                </Button>
                <Link to="/forgotPassword" className={classes.contraLink}>{Strings.olvideMicon}</Link>
            </form>
            <div className={classes.linksTerm}>
                <a target={"_blank"} rel="noreferrer" href="https://prestamistapp.com/PoliticaDePrivacidad" style={{marginRight:"10px"}}>Política de Privacidad</a>

                <a target={"_blank"} rel="noreferrer" href="https://prestamistapp.com/TerminosYcondiciones">Términos y Condiciones</a>
            </div>


            <Typography className={classes.bmLink}>{Strings.noCuenta} <Link to="/register">{Strings.registrarme}</Link></Typography>

            <div className={classes.lang}>
                <select value={state.lang} onChange={(e)=>{
                    let lang = e.target.value;
                    setLSLang(lang);
                    Strings.setLanguage(lang);
                    setState({...state,lang});
                }}>
                    {Strings.getAvailableLanguages().map(l=>
                        <option key={l} value={l}>{Strings[l]}</option>
                    )}
                </select>
                {/*<label>{Strings.idioma}</label>*/}
            </div>


                {/*<ButtonGroup variant="text" className={classes.moreInfo}>*/}
                {/*        <Button*/}
                {/*            component={"a"}*/}
                {/*            fullWidth*/}
                {/*            href={"https://www.instagram.com/dsegura_srl"}*/}
                {/*            rel="noreferrer"*/}
                {/*            target={"_blank"}>*/}
                {/*            Instagram*/}
                {/*        </Button>*/}
                {/*    <Button*/}
                {/*        component={"a"}*/}
                {/*        fullWidth*/}
                {/*        href={"http://prestamistapp.com/?p=true"}*/}
                {/*        rel="noreferrer"*/}
                {/*        target={"_blank"}>*/}
                {/*        Más info...*/}
                {/*    </Button>*/}
                {/*</ButtonGroup>*/}
        </div>
    );

}
const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(mapStateToProps, {mostrarPerfil })(Login);
