import React, {useState} from 'react';
import {
    FormControl,
    InputLabel,
    NativeSelect,
    List,
    ListItem,
    ListItemText,
    Dialog,
    Box,
    CircularProgress,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Strings from "../assets/strings";
import {getClientes} from "../services";
const useStyles = makeStyles(theme => ({
    status:{
        fontSize: ".8rem",
        // display: "block",
        // width: "100%",
        // padding: "0px 8px",
        // border: 14,
        // borderLeft: "2px solid transparent",

        "&.e0":{
            color:"red",
            borderColor: "red"
        },
        "&.e1":{
            color:"#54e69d",
            borderColor: "#54e69d"
        },
        "&.e2":{
            color:"orange",
            borderColor: "orange"
        }
    },
    inputSearch:{
        position: "sticky",
        top: 5,
        zIndex: 1,
        "& input":{
            padding: 7,
            width: "calc(100% - 28px)",
            margin: 5,
            marginBottom:0,
            outline:"none"
        }
    },
    loading:{
        width: "13px !important",
        height: "13px !important",
        position: "absolute",
        right: 12,
        top: 15,
    },
    overClick:{
        position: "absolute", height: "100%", width: "100%",zIndex: 1, cursor: "pointer"
    }
}));

let searchTimeout = null;

export default function SearchClienteDialog({ onChange, name,value,cliente,required,disabled= false}) {
    const classes = useStyles();
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(true);
    const [items, setItems] = useState([]);
    const [openDialog, setOpenDialog] = useState(false)

    const handleSearchChange = e =>{
        const {value} = e?.target ?? {};
        setSearch(value || "");
        setLoading(true)
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=> {
            //-2 para buscar todos, incluyendo inactivos;
            getClientes({estado: "-2",name:search})
                .then(res=>{
                    setItems(res?.data?.results ?? [])
                }).finally(()=>setLoading(false))
        }, 1000);
    };
    const handleListItemClick = value => {
        setOpenDialog(false)
        if(onChange)onChange(value);
    };
    const handleOpenDialog = () => {
        setOpenDialog(true);
        if(!items.length) handleSearchChange();
    };

    return (
        <>
            <FormControl fullWidth {...((!value) && {required})}  disabled={disabled}>
                <div className={classes.overClick} {...((!disabled) && { onClick: handleOpenDialog})}/>
                <InputLabel htmlFor="cliId" sx={disabled?{}:{color:"inherit"}}>{Strings.prestatario}</InputLabel>
                <NativeSelect
                    value={value}
                    inputProps={{
                        name: name,
                        id: 'cliId',
                    }}
                >
                    <option value={value} >{cliente}</option>
                </NativeSelect>
            </FormControl>
            <Dialog onClose={()=>setOpenDialog(false)}  fullWidth maxWidth={"xs"}  open={openDialog}>
                <div className={classes.inputSearch}>
                    {loading && <CircularProgress className={classes.loading}/>}
                    <input type="text" value={search} placeholder={Strings.nombres} autoFocus
                           onChange={handleSearchChange}/>
                </div>

                <List>
                    {items.map(c => (
                        <ListItem button onClick={() => handleListItemClick(c)} key={c.cliId}>
                            <ListItemText>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <span>{c.cliNombres +" "+c.cliApellidos}</span>
                                    <span className={classes.status +" e"+ c.cliEstado} >{Strings[c.cliEstadoString]}</span>
                                </Box>
                            </ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </>
    );
}
