import {agenteTypes, _FULFILLED, _PENDING, _REJECTED} from '../actions/types';
const initialState = {
    list: [],
    isPending:false,
    isFetching: false,
    fulfilled: false,
    error: null,
    byId:{},
    showModal: false
};

export default function index(state = initialState, action={}) {

    switch (action.type) {
        case agenteTypes.MOSTRAR_AGENTES + _PENDING: {
            return {
                ...state,
                isFetching: true,
                error: {}
            };
        }
        case agenteTypes.MOSTRAR_AGENTES + _FULFILLED: {
            return {
                ...state,
                list: action.payload.data,
                isFetching: false,
                error: {}
            }
        }
        case agenteTypes.MOSTRAR_AGENTES + _REJECTED: {
            return {
                ...state,
                isPending: false,
                isFetching: false,
            }
        }
        //agregar  o editar
        case agenteTypes.AGREGAR_EDITAR_AGENTE + _PENDING: {
            return {
                ...state,
                fulfilled: false,
                isPending: true,
                error: {}
            }
        }
        case agenteTypes.AGREGAR_EDITAR_AGENTE + _FULFILLED: {
            const { data } = action.payload;
            let list = state.list;
            list?
                list = list.filter(e => e.usuId !== data.usuId): list = [];
            data.new = true
            list.unshift(data)
            return {
                ...state,
                list,
                fulfilled: true,
                isPending: false,
                showDetailsModal: false,
                byId:{},
                error: {}
            }
        }
        case agenteTypes.AGREGAR_EDITAR_AGENTE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //TOGGLE LOCK
        case agenteTypes.LOCK_TOGGLE_AGENTE + _PENDING: {
            return {
                ...state,
                fulfilled: false,
                isPending: true,
                error: {}
            }
        }
        case agenteTypes.LOCK_TOGGLE_AGENTE + _FULFILLED: {
            const { data } = action.payload;
            console.log(data)
            let list = state.list;
            list?
                list = list.filter(e => e.usuId !== data.usuId): list = [];
            data.new = true
            list.unshift(data)
            return {
                ...state,
                list,
                fulfilled: true,
                isPending: false,
                showDetailsModal: false,
                byId:{},
                error: {}
            }
        }
        case agenteTypes.LOCK_TOGGLE_AGENTE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        //CHANGE PASSWORD
        case agenteTypes.EDITAR_CONTRASENA_AGENTE + _PENDING: {
            return {
                ...state,
                fulfilled: false,
                isPending: true,
                error: {}
            }
        }
        case agenteTypes.EDITAR_CONTRASENA_AGENTE + _FULFILLED: {
            return {
                ...state,
                fulfilled: true,
                isPending: false,
                byId:{},
                error: {}
            }
        }
        case agenteTypes.EDITAR_CONTRASENA_AGENTE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }

        //Eliminar
        case agenteTypes.ELIMINAR_AGENTE + _PENDING: {
            return {
                ...state,
                fulfilled: false,
                isPending: true,
                error: {}
            }
        }
        case agenteTypes.ELIMINAR_AGENTE + _FULFILLED: {
            const { data: id } = action.payload;
            let list = state.list.filter(e => e.usuId !== id)
            //--list.totalRecordCount
            return {
                ...state,
                list,
                isPending: false,
                fulfilled: true,
                byId:{},
                error: {}
            }
        }
        case agenteTypes.ELIMINAR_AGENTE + _REJECTED: {
            const { data, status } = action.payload.response || { data:"Error no definido"};
            return {
                ...state,
                isPending: false,
                isFetching: false,
                error: { data, status }
            }
        }
        case "RESET": {
            return {
                ...state,
                isPending:false,
                isFetching: false,
                fulfilled: false,
                error: {},
                byId:{}
            }
        }

        default:
      return state;
  }
}
