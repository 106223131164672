import { paisTypes } from './types';
import { getPaises } from '../../services';


export function mostrarPaises() {
    return dispatch => {
        dispatch({
            type: paisTypes.MOSTRAR_PAISES,
            payload: getPaises()
        })
    }
}
export function setPaises(payload) {
    return (dispatch, getState) => {
        const paises = getState().paises
        if (Object.keys(paises.list).length || paises.isFetching) return false;
        dispatch({
            type: paisTypes.SET_PAISES,
            payload
        })
    }
}
export function mostrarPaisesSinoEsta() {
    return (dispatch, getState) => {
        const paises = getState().paises
        if (Object.keys(paises.list).length || paises.isFetching) return false;
        dispatch({
            type: paisTypes.MOSTRAR_PAISES,
            payload: getPaises()
        })
    }
}